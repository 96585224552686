import React, { useEffect, useState } from 'react'
import Flex from 'components/shared-components/Flex'
import { Row } from 'antd'
import IntlMessage from '../util-components/IntlMessage'
import { THEME_CONFIG } from '../../configs/AppConfig'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

const InMaintenanceApplication = () => {

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (isMounted ?

      <Row justify="center" align="middle" style={{ height: '100vh' }}>

        <div className={`h-100`}>
          <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
            <div>
              <img className="img-fluid" src={THEME_CONFIG.APP.LOGO} alt=""/>
            </div>

            <div className="container">
              <div className="text-center mb-5">
                <img className="img-fluid"
                     style={{ maxWidth: 600 }}
                  // onLoad={() => setIsMounted(true)}
                     src="/img/others/img-21.png"
                     alt=""/>
                <h1 className="font-weight-bold mb-4">{setLocale(true, 'system_in_maintenance')}</h1>
              </div>
            </div>

            <Flex mobileFlex={false} justifyContent="end">
            <span>
              Jarvisly Application Copyright  &copy;  {`${new Date().getFullYear()}`}
            </span>
            </Flex>

          </div>
        </div>
      </Row>
      : null
  )
}

export default InMaintenanceApplication

