import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  USER,
  SUBSCRIPTION,
  PORTAL_SECTION_STATUS,
  PORTAL_SECTION_USER,
  PORTAL_SECTION_SUBSCRIPTION, PORTAL_DATA,
} from '../constants/auth'

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN), // user: userSeed,
  rdxUser: null,
  rdxSubscription: null,

  rdxPortalSubscription: null,
  rdxPortalUser: null,
  rdxPortalStatus: 0,
  rdxPortalData: null,
}

const auth = (state = initState, action) => {

  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: '/',
        token: action.token,
      }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      }
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      }
    case SIGNOUT_SUCCESS: {

      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false,
      }
    }

    case SIGNUP_SUCCESS: {

      return {
        ...state,
        loading: false,
        token: action.token,
      }
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      }
    }

    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      }
    }

    case USER: {
      return {
        ...state,
        rdxUser: action.payload,
      }
    }

    case SUBSCRIPTION: {
      return {
        ...state,
        rdxSubscription: action.payload,
      }
    }

    case PORTAL_SECTION_SUBSCRIPTION: {
      return {
        ...state,
        rdxPortalSubscription: action.payload,
      }
    }

    case PORTAL_SECTION_USER: {
      return {
        ...state,
        rdxPortalUser: action.payload,
      }
    }

    case PORTAL_SECTION_STATUS: {
      return {
        ...state,
        rdxPortalStatus: action.payload,
      }
    }

    case PORTAL_DATA: {
      return {
        ...state,
        rdxPortalData: action.payload,
      }
    }

    default:
      return state
  }
}

export default auth
