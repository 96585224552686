import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { Col, Form, Row } from 'antd'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import {
  parseDateToDisplay,
  parsePhoneToDisplay,
  parseDateToDB,
  parsePhoneToDB,
  parseCpfToDB,
  parseCpfToDisplay,
} from 'utils/helpers'
import {
  FormCpf,
  FormDatePicker,
  FormEmail,
  FormInput,
  FormPhone,
  FormSaveButton,
  FormSelectGender,
  FormTitle,
  FormWrapper,
} from 'views/app-views/portals/autaliza-info/FormComponets'
import DropDownButton from '../DropDownButton'

// COMPONENT *******************************************************************
// *****************************************************************************

const ApartmentsTitular = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    formContext,
    _module,
  } = props

  // local variables -----------------------------------------------------------

  const _update = _dataRoots?._update
  const isDisabled = formContext.mode === 'view'
  const formName = 'titularForm'
  const [form] = Form.useForm()
  const elRefs = buildElementsRefs(useRef)
  const intl = useIntl()
  const t = buildTranslations()
  const innerRef = useRef(null)

  // ant dataPicker workaround to fix issue about date
  if (_data?.cpf) _data.cpf = parseCpfToDisplay(_data.cpf)
  if (_data?.birthday) _data.birthday = parseDateToDisplay(_data.birthday)
  if (_data?.entryAt) _data.entryAt = parseDateToDisplay(_data.entryAt)
  if (_data?.phone) _data.phone = parsePhoneToDisplay(_data.phone)

  // component states ----------------------------------------------------------

  console.log('_update data ->', _update)
  console.log('_dataRoots ->', _dataRoots)
  console.log('_data ->', _data)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    setDataContext({ ...dataContext, formsChanged: [] })
    if (_data) {
      form?.setFieldsValue(_data)
      form?.validateFields(['cpf', 'phone', 'email']).then()
    }
  }, [_data, form]) // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
    handleMenuClick: innerRef?.current?.handleMenuClick,
  }))

  function handleFocus (field = 'name', cursorPosition = 'start') {
    if (!isDisabled) elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  const onBeforeSave = values => {

    const data = { ...values }

    // format data to persint in database
    if (data?.cpf) data.cpf = parseCpfToDB(data.cpf)
    if (data?.birthday) data.birthday = parseDateToDB(data.birthday)
    if (data?.entryAt) data.entryAt = parseDateToDB(data.entryAt)
    if (data?.phone) data.phone = parsePhoneToDB(data.phone)

    const body = {
      _id: _dataRoots._id,
      _update: {
        titular: data,
        titularUpdated: false,
      },
    }

    if (_update?.currentStep === 0) body.currentStep = 1

    console.log('body ->', body)

    return body
  }

  const onAfterSave = async doc => {
    console.log('doc saved ->', doc)
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi={_module.api}
      // uriApi="/atualiza-info"
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      // formContext={formContext}
      // setFormContext={setFormContext}
      showToast>

      {/* BASIC INFO */}
      <Row justify="space-between">
        <FormTitle title={'titular_resident'}/>
        <DropDownButton {...props}
                        updated={_update?.titularUpdated}
                        done={_update?.titularAccepted}
                        profile="titular"/>
      </Row>

      <Row gutter={ROW_GUTTER}>

        {/* NAME */}
        <Col xs={24} sm={16}>
          <FormInput name="name"
                     label="full_name"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.name}/>
        </Col>
        {/* NAME */}

        {/* GENDER */}
        <Col xs={24} sm={8}>
          <FormSelectGender elRef={elRefs.gender}
                            disabled={isDisabled}
                            required/>
        </Col>
        {/* GENDER */}

      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* CPF / CNPJ */}
        <Col xs={24} sm={8}>
          <FormCpf elRef={elRefs.cpf}
                   disabled={isDisabled}
                   required
                   form={form}/>
        </Col>
        {/* CPF / CNPJ */}

        {/* RG */}
        <Col xs={24} sm={8}>
          <FormInput name="rg"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.rg}/>
        </Col>
        {/* RG */}

        {/* BIRTHDAY / FOUNDATION DATE */}
        <Col xs={24} sm={8}>
          <FormDatePicker name="birthday"
                          disabled={isDisabled}
                          required
                          elRef={elRefs.birthday}/>
        </Col>
        {/* BIRTHDAY / FOUNDATION DATE */}

      </Row>


      {/* CONTACTS */}
      <FormTitle title={'contacts'}/>

      <Row gutter={ROW_GUTTER}>

        {/* PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone name="phone"
                     form={form}
                     disabled={isDisabled}
                     required
                     elRef={elRefs.phone}/>
        </Col>
        {/* PHONE */}

        {/* EMAIL */}
        <Col xs={24} sm={16}>
          <FormEmail name="email"
                     form={form}
                     disabled={isDisabled}
                     required
                     elRef={elRefs.email}/>
        </Col>
        {/* EMAIL */}


      </Row>
      {/* CONTACTS */}


      {/* ENTRANCE */}
      <FormTitle title={'period'}/>

      <Row gutter={ROW_GUTTER}>

        {/* ENTRY DATE */}
        <Col xs={24} sm={8}>
          <FormDatePicker name="entryAt"
                          label="moved_on"
                          disabled={isDisabled}
                          tooltip={t.approximate_date}
                          required
                          elRef={elRefs.entryAt}/>
        </Col>
        {/* ENTRY DATE */}

      </Row>
      {/* ENTRANCE */}


      {/* SAVE BUTTON */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      form={form}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      disabled={isDisabled}/>
      {/* SAVE BUTTON */}


    </FormWrapper>


  </div>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {
    return {
      approximate_date: translate(intl, 'approximate_date'),
    }
  }

  function buildElementsRefs (hookUseRef) {
    return {
      name: hookUseRef(null),
      gender: hookUseRef(null),
      cpf: hookUseRef(null),
      rg: hookUseRef(null),
      birthday: hookUseRef(null),
      phone: hookUseRef(null),
      email: hookUseRef(null),
      entryAt: hookUseRef(null),
    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default ApartmentsTitular


