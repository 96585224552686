import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, Col, Grid, Row, Switch } from 'antd'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { substitution, modal, msg } from 'utils/helpers'
import { FormTitle } from 'views/app-views/portals/autaliza-info/FormComponets'
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons'
import { clone } from 'jarvisly-helper'
import moment from 'moment/moment'
import { putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import utils from 'utils'
import IntlMessage from 'components/util-components/IntlMessage'
import { useSelector } from 'react-redux'
import SendAtualizaInfoTrigger from '../components/SendAtualizaInfoTrigger'
import DropDownButton from '../components/DropDownButton'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const MovesAgreement = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props

  // local variables -----------------------------------------------------------

  const _atualizaInfo = _dataRoots?.__atualizaInfo
  const isDisabled = formContext.mode === 'view'
  const intl = useIntl()
  const t = buildTranslations()
  const { useBreakpoint } = Grid
  const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const rdxSubscription = useSelector(s => s.auth.rdxSubscription)
  const urlConvention = rdxSubscription?.conventionUrl
  const urlRegulation = rdxSubscription?.regulationUrl
  const innerRef = useRef(null)

  // component states ----------------------------------------------------------
  const [fireBrigadeOptions, setFireBrigadeOptions] = useState(buildFireBrigadeOptions())
  const [agreementOptions, setAgreementOptions] = useState(buildAgreementOptions())
  const [isFormChanged, setIsFormChanged] = useState(false)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    setFireBrigadeOptions(buildFireBrigadeOptions())
    setAgreementOptions(buildAgreementOptions())
  }, [_data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkFormChanged()
  }, [fireBrigadeOptions, agreementOptions]) // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleMenuClick: innerRef?.current?.handleMenuClick,
  }))

  const onChangeSwitch = (item, field, checked, opt, setOpt) => {

    // treat the list
    const l = clone(opt)
    const idx = l.findIndex(x => x?.[field] === item?.[field])

    if (idx > -1) l[idx].checked = checked

    setOpt([...l])
  }

  const onSave = async () => {

    if (!isFormChanged) return

    const body = {
      agreement: _data?.agreement || {},
      updateSentAt: moment(),
      updateSentBy: 'portal',
    }

    // fire brigade
    body.agreement.fireBrigade = []
    fireBrigadeOptions.map(f => f.checked && body.agreement.fireBrigade.push(f.value))

    // agreement
    agreementOptions.map(m => body.agreement[m.value] = m.checked)

    const options = {
      type: 'confirm',
      title: t.forceFinishTitle,
      message: t.forceFinishMessage,
      okText: t.yes,
      cancelText: t.no,
      onOk: () => $done(),
    }

    if (agreementOptions.filter(m => !m.checked).length > 0) {
      modal(options)

    } else {
      await $done()
    }

    async function $done () {
      setDataContext({ ...dataContext, loading: true })
      const doc = await putByUri('/atualiza-info', _atualizaInfo?._id, body)

      setDataContext({
        ...dataContext,
        data: { ...doc },
        dataLock: { ...clone(doc) },
        fullLoading: false,
        partialLoading: false,
        formsChanged: [],
      })

      msg('s', t.update_sent_with_success, 'sent')
      setFormContext({ ...formContext, refreshTime: +new Date(), mode: 'view' })

      // router.replace(_module?.url, { refresh: true })
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <SendAtualizaInfoTrigger innerRef={innerRef} {...props} _data={_dataRoots}/>

    {/* FIRE BRIGADE */}

    <Row justify="space-between">
      <FormTitle title={'fire_brigade_info'} className="mb-0"/>
      <DropDownButton {...props} profile="agreement"/>
    </Row>


    {fireBrigadeOptions?.map(item => (

      <Row key={item.value}
           style={{
             paddingBottom: 24,
             paddingTop: 24,
             borderBottomColor: '#e6ebf1',
             borderBottomWidth: 1,
             borderBottomStyle: 'solid',
           }}>

        <Col xs={24} md={22} className="text-center pr-4">

          <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
            {item.title}
          </h4>

          <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
            {item.description}
          </div>

        </Col>


        <Col xs={24} md={2}
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'flex-end',
             }}>
          <Switch
            checked={item.checked}
                  disabled={isDisabled}
                  className="mt-3"
                  checkedChildren={t.yes}
                  unCheckedChildren={t.no}
                  onChange={checked => onChangeSwitch(item, 'value', checked, fireBrigadeOptions, setFireBrigadeOptions)}
          />
        </Col>

      </Row>
    ))}
    {/* FIRE BRIGADE */}


    {/* AGREEMENT */}
    <FormTitle title={'required_fields'} className="mb-0"/>

    {agreementOptions?.map(item => (

      <Row key={item.value}
           style={{
             paddingBottom: 24,
             paddingTop: 24,
             borderBottomColor: '#e6ebf1',
             borderBottomWidth: 1,
             borderBottomStyle: 'solid',
           }}>

        <Col xs={24} md={22} className="text-center pr-4">

          <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
            {item.title}
          </h4>

          <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
            {item.description}
          </div>

        </Col>

        <Col xs={24} md={24} order={md ? 1 : 0} className="text-center">

          {item?.value === 'declaration'
            ? <>
              <Button type={!urlConvention ? 'dashed' : 'default'}
                      className="m-2 mt-3"
                      disabled={!urlConvention}
                      onClick={() => window.open(urlConvention, '_blank')}>
                {setLocale(true, 'convention')}
              </Button>
              <Button type={!urlRegulation ? 'dashed' : 'default'}
                      className="m-2 mt-3"
                      disabled={!urlRegulation}
                      onClick={() => window.open(urlRegulation, '_blank')}>
                {setLocale(true, 'regulation')}
              </Button>
            </>
            : null}

        </Col>

        <Col xs={24} md={2}
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'flex-end',
             }}>
          <Switch checked={item.checked}
                  disabled={isDisabled}
                  className="mt-3"
                  checkedChildren={<CheckOutlined/>}
                  unCheckedChildren={<CloseOutlined/>}
                  onChange={checked => onChangeSwitch(item, 'value', checked, agreementOptions,
                    setAgreementOptions)}/>
        </Col>

      </Row>
    ))}
    {/* AGREEMENT */}


    <Row justify="end" className="mt-5">
      <Button type={isFormChanged ? 'primary' : 'default'}
              loading={dataContext?.partialLoading}
              onClick={onSave}
              icon={isFormChanged ? <SaveOutlined/> : <CheckOutlined/>}
              disabled={isDisabled}>
          <span className="ml-2">
            {translate(intl, 'save')}
          </span>
      </Button>
    </Row>
  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function checkFormChanged () {
    let isBrigadeChanged = false

    fireBrigadeOptions?.map(x => {
      if ((x?.checked && !_data?.agreement?.fireBrigade?.includes(x?.value)) ||
        (!x?.checked && _data?.agreement?.fireBrigade?.includes(x?.value))) {
        isBrigadeChanged = true
      }
      return x
    })

    let isAgreementChanged = false

    agreementOptions?.map(x => {
      if ((x?.checked && !_data?.agreement?.[x?.value]) ||
        (!x?.checked && _data?.agreement?.[x?.value])) {
        isAgreementChanged = true
      }
      return x
    })

    setIsFormChanged(isBrigadeChanged || isAgreementChanged)
  }

  function buildTranslations () {
    return {
      yes: translate(intl, 'yes'),
      no: translate(intl, 'no'),
      forceFinishTitle: `${translate(intl, 'required_fields')}`,
      forceFinishMessage: `${translate(intl, 'send_update_without_agreement')}`,
      update_sent_with_success: `${translate(intl, 'update_sent_with_success')}`,
    }
  }

  function buildFireBrigadeOptions () {
    return [
      {
        value: 'resident_with_walking_difficulty',
        title: translate(intl, 'special_attention'),
        description: translate(intl, 'special_attention_description'),
        checked: _data?.agreement?.fireBrigade?.includes('resident_with_walking_difficulty') || false,
      },
      {
        value: 'authorize_open_the_door',
        title: translate(intl, 'emergency_case'),
        description: translate(intl, 'emergency_case_description'),
        checked: _data?.agreement?.fireBrigade?.includes('authorize_open_the_door') || false,
      },
    ]
  }

  function buildAgreementOptions () {

    const titularName = (_data?.titular?.name || _data?.name) + ', '
    const agreement_declaration_description = translate(intl, 'agreement_declaration_description')
    const t_agreement_declaration_description = substitution(agreement_declaration_description, ['%TITULAR_NAME%'],
      [titularName])

    return [
      {
        value: 'declaration',
        title: translate(intl, 'agreement_declaration'),
        description: t_agreement_declaration_description,
        checked: _data?.agreement?.declaration || false,
      },
      {
        value: 'commitment',
        title: translate(intl, 'agreement_commitment'),
        description: translate(intl, 'agreement_commitment_description'),
        checked: _data?.agreement?.commitment || false,
      },
      {
        value: 'aware',
        title: translate(intl, 'agreement_aware'),
        description: translate(intl, 'agreement_aware_description'),
        checked: _data?.agreement?.aware || false,
      },
    ]
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default MovesAgreement


