import { Divider, Row } from 'antd'
import logoImage from 'assets/img/avatar-jarvisly.png'
import React, { useEffect, useState } from 'react'
import Loading from 'components/shared-components/Loading'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { MODULE_ATUALIZA_PARAMETERS as _module } from './atualiza-constants'
import LoginComponent from './AtualizaLogin'
import HomeComponent from './AtualizaHome'
import AtualizaProfile from './tiles/RegistrationTile'
import { DataContext } from './DataProvider'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const AtualizaIndex = props => {

  // props deconstruction ------------------------------------------------------

  const {
    rdxPortalStatus,
    rdxPortalSubscription,
    rdxPortalUser,
    rdxPortalData,
  } = props
  const { dataContext } = React.useContext(DataContext)

  // component states ----------------------------------------------------------

  const [loading, setLoading] = useState(true)
  const [style, setStyle] = useState({ height: '80vh' })

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    setTimeout(() => setLoading(false), 400)
    if (rdxPortalStatus === 1) setStyle({ maxWidth: 1400, marginLeft: 'auto', marginRight: 'auto' })
  }, [rdxPortalStatus])  // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const showDevInfo = () => {
    console.log('')
    console.log('')
    console.log('**************************')
    console.log('******** DEV INFO ********')
    console.log('**************************')
    console.log('rdxPortalStatus', rdxPortalStatus)
    console.log('')
    console.log('rdxPortalSubscription', rdxPortalSubscription)
    console.log('')
    console.log('rdxPortalUser', rdxPortalUser)
    console.log('')
    console.log('rdxPortalData', rdxPortalData)
    console.log('')
    console.log('dataContext', dataContext)
    console.log('**************************')
    console.log('******** DEV INFO ********')
    console.log('**************************')
    console.log('')
  }

  // UI COMPONENT --------------------------------------------------------------

  return (loading ? <Loading cover="page"/> : <div>

    <Divider orientation="left">
      <img src={logoImage} alt="logo" width={80} onDoubleClick={showDevInfo}/>
      Atualiza.info
    </Divider>

    <Row justify="center" align="middle" style={style}>

      <Switch>

        <Route exact path={`${_module?.url}`}
               render={() => (

                 rdxPortalStatus === 1

                   ? <HomeComponent setLoading={setLoading}/>

                   : <LoginComponent setLoading={setLoading}/>)}/>


        <Route path={`${_module?.url}/:subscriptionId/:section`}
               render={props => <AtualizaProfile {...props} />}/>


        <Redirect to={`${_module?.url}`}/>

      </Switch>

    </Row>


  </div>)

}

// EXPORT **********************************************************************
// *****************************************************************************

const mapStateToProps = ({ auth }) => {
  const { rdxPortalSubscription, rdxPortalUser, rdxPortalStatus, rdxPortalData } = auth
  return { rdxPortalSubscription, rdxPortalUser, rdxPortalStatus, rdxPortalData }
}

export default connect(mapStateToProps)(AtualizaIndex)
