import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { ACCOUNT_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'
import { onProduction } from 'utils/helpers'
import { THEME_CONFIG } from 'configs/AppConfig'
import { useSelector } from 'react-redux'
import { getActiveModules } from '../../configs/jarvisly-products'
import { clone } from 'jarvisly-helper'

export const AppViews = () => {

  const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription)
  const activeModules = getActiveModules(rdxSubscription)

  // ************************************************************************ //
  // MODULE ROUTES
  // ************************************************************************ //

  const imports = {

    // core modules
    noModuleContent: import(`components/jarvisly-layouts/ModuleComponent/Components/NoModuleContent`),
    account: import(`views/app-views/account/settings/AccountSettings`),
    dashboard: import(`views/app-views/dashboards/home/Home`),
    chat: import(`views/app-views/operational/chat/Chat`),

    // Module Components
    moduleForm: import(`components/jarvisly-layouts/ModuleComponent/ModuleForm/ModuleForm`),
    moduleList: import(`components/jarvisly-layouts/ModuleComponent/ModuleList/ModuleList`),
    moduleListV1: import(`components/jarvisly-layouts/ModuleComponent/ModuleList/ModuleListV1`),

    // custom modules
    // atualizaIndex: import(`views/app-views/records/atualiza-info/AtualizaIndex`),

    // appdedalo
    agendasIndex: import(`views/app-views/_apps/dedalo/records/agendas/AgendasIndex`),
    candidatesList: import(`views/app-views/_apps/dedalo/records/candidates/CandidatesIndex`),
    candidatesForm: import(`views/app-views/_apps/dedalo/records/candidates/CandidatesForm`),
    scheduleList: import(`views/app-views/_apps/dedalo/attendance/schedules/SchedulesIndex`),
    scheduleForm: import(`views/app-views/_apps/dedalo/attendance/schedules/ScheduleForm`),
  }

  const routes = []

  const themeModules = clone(THEME_CONFIG.APP.MODULES)

  themeModules.filter(m => activeModules.includes(m['MODULE_NAME'])).map(m => {

    const isFoos = m['MODULE_NAME'] === 'foos'

    return ((!isFoos && onProduction()) || !onProduction()) &&
      m['ROUTES']?.map(x => {
        routes.push({
          path: `${APP_PREFIX_PATH}/${x.path}`, import: imports[x.import],
        })
        return x
      })
  })

  if (THEME_CONFIG.APP.IS_PORTAL_ATUALIZA_INFO) return

  return (

    <Suspense fallback={<Loading cover="content"/>}>

      <Switch>

        {/* DYNAMIC ROUTES */}
        {routes.map(r => (<Route key={r.path} path={r.path} component={lazy(
          () => r.import)}/>))}
        {/* DYNAMIC ROUTES */}


        {/* ACCOUNTS */}
        <Route path={`${ACCOUNT_PREFIX_PATH}/settings`}
               component={lazy(() => imports['account'])}/>
        {/* ACCOUNTS */}


        {/* DASHBOARDS */}
        <Route path={`${APP_PREFIX_PATH}/dashboard/home`}
               component={lazy(() => imports['dashboard'])}/>
        {/* DASHBOARDS */}

        {/* DASHBOARDS */}
        <Route path={`${APP_PREFIX_PATH}/operational/chat`}
               component={lazy(() => imports['chat'])}/>
        {/* DASHBOARDS */}


        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard/home`}/>

      </Switch>

    </Suspense>

  )

}

// EXPORT **********************************************************************
// *****************************************************************************

export default (React.memo(AppViews))
