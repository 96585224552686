import React, { forwardRef, useState } from 'react'
import { Button, Col, Grid, Row, Switch } from 'antd'
import IntlMessage from 'components/util-components/IntlMessage'
import { clone } from 'jarvisly-helper'
import { translate } from 'utils/react-jarvisly-helper'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons'
import { modal, msg, substitution } from 'utils/helpers'
import { putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import utils from 'utils'
import { MODULE_ATUALIZA_PARAMETERS as _module } from '../../../atualiza-constants'
import { useRouter } from 'hooks/useRouter'
import { FormTitle } from '../../../FormComponets'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

const AgreementStep = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    step,
    setStep,
  } = props


  // local variables -----------------------------------------------------------
  const router = useRouter()
  const intl = useIntl()
  const t = buildTranslations()
  const { useBreakpoint } = Grid
  const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const urlConvention = _dataRoots?.lkq_subscriptions?.conventionUrl
  const urlRegulation = _dataRoots?.lkq_subscriptions?.regulationUrl

  // component states ----------------------------------------------------------
  const [fireBrigadeOptions, setFireBrigadeOptions] = useState(buildFireBrigadeOptions())
  const [agreementOptions, setAgreementOptions] = useState(buildAgreementOptions())

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  const onChangeSwitch = (item, field, checked, opt, setOpt) => {

    // treat the list
    const l = clone(opt)
    const idx = l.findIndex(x => x?.[field] === item?.[field])

    if (idx > -1) l[idx].checked = checked

    setOpt([...l])
  }

  const onSendUpdate = async () => {

    const body = {
      agreement: _data?.agreement || {},
      updateSentAt: moment(),
      updateSentBy: 'portal',
      agreementUpdated: false
    }

    // fire brigade
    body.agreement.fireBrigade = []
    fireBrigadeOptions.map(f => f.checked && body.agreement.fireBrigade.push(f.value))

    // agreement
    agreementOptions.map(m => body.agreement[m.value] = m.checked)

    const options = {
      type: 'confirm',
      title: t.forceFinishTitle,
      message: t.forceFinishMessage,
      okText: t.yes,
      cancelText: t.no,
      onOk: () => $done(),
    }

    if (agreementOptions.filter(m => !m.checked).length > 0) {
      modal(options)

    } else {
      await $done()
    }

    async function $done () {
      setDataContext({ ...dataContext, loading: true })

      // const doc = _dataRoots
      const doc = await putByUri('/atualiza-info', _dataRoots?._id, body)

      setDataContext({
        ...dataContext,
        data: { ...doc },
        dataLock: { ...clone(doc) },
        fullLoading: false,
        partialLoading: false,
        formsChanged: [],
      })

      msg('s', t.update_sent_with_success, 'sent')

      router.replace(_module?.url, { refresh: true })
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    {/* FIRE BRIGADE */}
    <FormTitle title={'fire_brigade_info'} className="mb-0"/>

    {fireBrigadeOptions?.map(item => (

      <Row key={item.value}
           style={{
             paddingBottom: 24,
             paddingTop: 24,
             borderBottomColor: '#e6ebf1',
             borderBottomWidth: 1,
             borderBottomStyle: 'solid',
           }}>

        <Col xs={24} md={22} className="text-center pr-4">

          <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
            {item.title}
          </h4>

          <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
            {item.description}
          </div>

        </Col>


        <Col xs={24} md={2}
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'flex-end',
             }}>
          <Switch checked={item.checked}
                  disabled={_dataRoots?.updateSentAt}
                  className="mt-3"
                  checkedChildren={t.yes}
                  unCheckedChildren={t.no}
                  onChange={checked => onChangeSwitch(item, 'value', checked, fireBrigadeOptions,
                    setFireBrigadeOptions)}/>
        </Col>

      </Row>
    ))}
    {/* FIRE BRIGADE */}


    {/* AGREEMENT */}
    <FormTitle title={'required_fields'} className="mb-0"/>

    {agreementOptions?.map(item => (

      <Row key={item.value}
           style={{
             paddingBottom: 24,
             paddingTop: 24,
             borderBottomColor: '#e6ebf1',
             borderBottomWidth: 1,
             borderBottomStyle: 'solid',
           }}>

        <Col xs={24} md={22} className="text-center pr-4">

          <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
            {item.title}
          </h4>

          <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
            {item.description}
          </div>

        </Col>

        <Col xs={24} md={24} order={md ? 1 : 0} className="text-center">

          {item?.value === 'declaration'
            ? <>
              <Button type="default"
                      className="m-2 mt-3"
                      disabled={!urlConvention}
                      onClick={() => window.open(urlConvention, '_blank')}>
                {setLocale(true, 'convention')}
              </Button>
              <Button type="default"
                      className="m-2 mt-3"
                      disabled={!urlRegulation}
                      onClick={() => window.open(urlRegulation, '_blank')}>
                {setLocale(true, 'regulation')}
              </Button>
            </>
            : null}

        </Col>

        <Col xs={24} md={2}
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'flex-end',
             }}>
          <Switch checked={item.checked}
                  disabled={_dataRoots?.updateSentAt}
                  className="mt-3"
                  checkedChildren={<CheckOutlined/>}
                  unCheckedChildren={<CloseOutlined/>}
                  onChange={checked => onChangeSwitch(item, 'value', checked, agreementOptions,
                    setAgreementOptions)}/>
        </Col>

      </Row>
    ))}
    {/* AGREEMENT */}


    : <Row justify={{ xs: 'space-between', md: 'end' }} align="middle" className="mt-3">

    <Button type="default"
            onClick={() => setStep(step - 1)}
            icon={<ArrowLeftOutlined/>}
            className="mr-3">
          <span className="ml-2">
            {setLocale(true, 'previous')}
          </span>
    </Button>


    <Button type={_data?.updateSentAt ? 'dashed' : 'primary'}
            icon={_data?.updateSentAt ? <CheckOutlined/> : <SendOutlined/>}
            disabled={_data?.updateSentAt}
            onClick={onSendUpdate}>
        <span className="ml-2">
          {setLocale(true, _data?.updateSentAt ? 'done' : 'send')}
        </span>
    </Button>

  </Row>


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {
    return {
      yes: translate(intl, 'yes'),
      no: translate(intl, 'no'),
      forceFinishTitle: `${translate(intl, 'required_fields')}`,
      forceFinishMessage: `${translate(intl, 'send_update_without_agreement')}`,
      update_sent_with_success: `${translate(intl, 'update_sent_with_success')}`,
    }
  }

  function buildFireBrigadeOptions () {
    return [
      {
        value: 'resident_with_walking_difficulty',
        title: translate(intl, 'special_attention'),
        description: translate(intl, 'special_attention_description'),
        checked: _data?.agreement?.fireBrigade?.includes('resident_with_walking_difficulty') || false,
      },
      {
        value: 'authorize_open_the_door',
        title: translate(intl, 'emergency_case'),
        description: translate(intl, 'emergency_case_description'),
        checked: _data?.agreement?.fireBrigade?.includes('authorize_open_the_door') || false,
      },
    ]
  }

  function buildAgreementOptions () {

    const titularName = (_data?.titular?.name || _data?.name) + ', '
    const agreement_declaration_description = translate(intl, 'agreement_declaration_description')
    const t_agreement_declaration_description = substitution(agreement_declaration_description, ['%TITULAR_NAME%'],
      [titularName])

    return [
      {
        value: 'declaration',
        title: translate(intl, 'agreement_declaration'),
        description: t_agreement_declaration_description,
        checked: _data?.agreement?.declaration || false,
      },
      {
        value: 'commitment',
        title: translate(intl, 'agreement_commitment'),
        description: translate(intl, 'agreement_commitment_description'),
        checked: _data?.agreement?.commitment || false,
      },
      {
        value: 'aware',
        title: translate(intl, 'agreement_aware'),
        description: translate(intl, 'agreement_aware_description'),
        checked: _data?.agreement?.aware || false,
      },
    ]
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default AgreementStep


