import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { AUTH_PREFIX_PATH, THEME_CONFIG } from 'configs/AppConfig'

const LoginRoutes = () => {

  return (<Suspense fallback={<Loading cover="page"/>}>
    <Switch>

      <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(
        () => import(`views/auth-views/authentication/login/Login`))}/>
      <Route path={`${AUTH_PREFIX_PATH}/sigin`} component={lazy(
        () => import(`views/auth-views/authentication/sign-in/SignIn`))}/>
      <Route path={`${AUTH_PREFIX_PATH}/signup`} component={lazy(
        () => import(`views/auth-views/authentication/sign-up/SignUp`))}/>
      <Route path={`${AUTH_PREFIX_PATH}/recovery`} component={lazy(
        () => import(`views/auth-views/authentication/recovery/Recovery`))}/>


      <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`}/>


    </Switch>
  </Suspense>)
}

const PortalAtualizaInfoRoutes = () => {

  return (<Suspense fallback={<Loading cover="page"/>}>
    <Switch>

      <Redirect to={`/atualiza-index/:subscriptionId?/:section?`}/>

    </Switch>
  </Suspense>)
}

const AppViews = () => {
  return (

    !THEME_CONFIG.APP.IS_PORTAL_ATUALIZA_INFO

      ? <LoginRoutes/>
      : <PortalAtualizaInfoRoutes/>

  )
}

export default AppViews
