import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Card,
  Col,
  Form,
  Row,
} from 'antd';
import { msg, onFormFinishFailed } from 'utils/helpers';
import IntlMessage from 'components/util-components/IntlMessage';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { AsyncAutoComplete, } from 'components/jarvisly/AsyncAutoComplete';
import { getEntitiesByField } from '../../../../../_old-modules/entities/entities-api';
import RoundedAvatar from 'components/jarvisly/RoundedAvatar';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const MainMemberTab = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    onClose,
    members,
    record,
    formContext,
    setFormContext
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------

  // const {formContext, setFormContext} = React.useContext(ModalContext);
  const [form] = Form.useForm();

  const elRefs = {
    name: useRef(null),
  };

  const rules = {

    name: [
      {
        required: true,
        message: setLocale(true, 'required_field'),
      }],
  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (record) {
      form.setFieldsValue({ ...record });

    } else {
      form.resetFields();
    }

    handleFocus();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
    submitForm: () => form.submit(),
  }));

  // METHODS -------------------------------------------------------------------

  function handleFocus (field) {
    let focus = field ? field : 'name';
    setTimeout(() => elRefs[focus]?.current?.focus({ cursor: 'all' }), 400);
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async () => {

    const found = members.find(
      x => x.memberId === formContext?.selectedEntity?._id);

    // abort
    if (found) {
      msg('w',
        'Este colaborador já se encontra adicionado neste Procedimento!',
        null, 3);
      return;
    }

    members.push({ memberId: formContext?.selectedEntity?._id });
    onClose(null, { members: members });
  };

  // -------

  const handleUserSearch = async searchValue => {

    if (searchValue.length < 3) return; // Busca somente após o 3º caractere

    // const docs = await getEntitiesByField('name', searchValue, 1, 'person', 'collaborator');
    const docs = await getEntitiesByField('documents', 'person', 'collaborator', 'name', searchValue);

    return docs.map((doc) => ({
      key: doc._id,
      label: doc.name,
      value: doc.name,
      item: doc,
    }));
  };

  // UI COMPONENT --------------------------------------------------------------

  return (<div>


    <Form
      form={form}
      name="profile"
      layout="vertical"
      initialValues={record}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateTrigger="onChange"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      noValidate>


      <Row gutter={ROW_GUTTER}>


        {/* NAME */}
        <Col xs={24}>

          <Form.Item
            label={setLocale(true, 'name')}
            name="name"
            rules={rules.name}
          >

            <AsyncAutoComplete ref={elRefs.name}
                               debounceTimeout={300}
                               autoFocus
                               searchFn={handleUserSearch}
                               onSelect={(ev, opt) => setFormContext(
                                 {
                                   ...formContext,
                                   selectedEntity: opt.item
                                 })}
            />

          </Form.Item>

        </Col>
        {/* NAME */}


      </Row>

    </Form>


    {
      formContext?.selectedEntity
        ? <Card>
          <RoundedAvatar user={formContext?.selectedEntity} showName showAge/>
        </Card>
        : null
    }

  </div>);

});

// EXPORT **********************************************************************
// *****************************************************************************

export default MainMemberTab;
