import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Card, Col, Form, Row } from "antd";
import { ROW_GUTTER } from "constants/ThemeConstant";
import {
  FormInput,
  FormInputNumber,
  FormSaveButton,
  FormSimpleSwitch,
  FormWrapper,
} from "views/app-views/portals/autaliza-info/FormComponets";
import IntlMessage from "components/util-components/IntlMessage";

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const MultiPlansParameters = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    _module,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props;

  // component states ----------------------------------------------------------
  const [, setSubmitted] = useState(false);
  const [integrated, setIntegrated] = useState(false);

  // local variables -----------------------------------------------------------
  const isDisabled = formContext.mode === "view";
  const formName = "integrationForm";
  const [form] = Form.useForm();
  const elRefs = buildElementsRefs(useRef);

  // ant dataPicker workaround to fix issue about date
  // if (_data?.city) _data.__city = parseCityToDisplay(_data?.city, _data?.province)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    if (_data?._id) {
      form.resetFields();
      form?.setFieldsValue(_data);
    } else {
      form.resetFields();

      // workaround because after reset form the tag refs be lost
      setTimeout(() => handleFocus(undefined, "all"));
    }

    setIntegrated(_data?.integrated || false);

    // setDataContext({
    //   ...dataContext,
    //   formsChanged: []
    // });
  }, [_data, form, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: (field) => handleFocus(field),
  }));

  function handleFocus(field = "integrated", cursorPosition = "all") {
    elRefs[field]?.current?.focus({ cursor: cursorPosition });
  }

  const onBeforeSave = async (values) => {
    setSubmitted(true);

    console.log("put...", values);

    // return false;

    const body = { ...values };

    body._id = _data?._id;
    return body;
  };

  const onCheckIntegration = (check) => {
    console.log("check?", check);
    setIntegrated(check);
    return check;
  };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi={_module.api}
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      formContext={formContext}
      setFormContext={setFormContext}
      onBeforeSave={onBeforeSave}
      showToast
    >
      {/* ************************************************************
      PROFITS
      ************************************************************ */}
      {/* <FormTitle title={'age_limits'}/> */}

      <Card title={setLocale(true, "values")}>
        <Row gutter={ROW_GUTTER}>
          {/* MARKUP */}
          <Col span={12}>
            <FormInputNumber
              name="markup"
              label="markup"
              elRef={elRefs.markup}
              disabled={isDisabled}
              decimalSeparator=","
              style={{ width: "100%" }}
              addonAfter="%"
              suffixStyle={{ width: 60 }}
              min={0}
              max={100}
              required
            />
          </Col>
          {/* MARKUP */}

          {/* DISCOUNT */}
          <Col span={12}>
            <FormInputNumber
              name="discount"
              label="discount"
              elRef={elRefs.discount}
              disabled={isDisabled}
              decimalSeparator=","
              style={{ width: "100%" }}
              addonAfter="%"
              suffixStyle={{ width: 60 }}
              min={0}
              max={100}
              required
            />
          </Col>
          {/* DISCOUNT */}
        </Row>
      </Card>

      {/* ************************************************************
      INTEGRATION
      ************************************************************ */}
      <Card
        title={
          <Row justify="space-between" align="start">
            <div>{setLocale(true, "webservice_integration")}</div>

            <FormSimpleSwitch
              name="integrated"
              noLabel
              form={form}
              onChange={onCheckIntegration}
              value={_data?.integrated}
              elRef={elRefs?.integrated}
              disabled={isDisabled}
            />
          </Row>
        }
      >
        <Row gutter={ROW_GUTTER}>
          {/* PLAN ID */}
          <Col xs={24} sm={6}>
            <FormInput
              name="operatorPlanCode"
              label="operator_id"
              disabled={isDisabled || !integrated}
              required={integrated}
              elRef={elRefs.operatorPlanId}
            />
          </Col>
          {/* PLAN ID */}

          {/* PLAN NAME */}
          <Col xs={24} sm={12}>
            <FormInput
              name="operatorPlanName"
              label="plan_name"
              disabled={isDisabled || !integrated}
              elRef={elRefs.operatorPlanName}
            />
          </Col>
          {/* PLAN NAME */}

          {/* PLAN RATE */}
          <Col xs={24} sm={6}>
            <FormInput
              name="operatorPlanTaxCode"
              label="rate_code"
              disabled={isDisabled || !integrated}
              elRef={elRefs.operatorPlanRate}
            />
          </Col>
          {/* PLAN RATE */}
        </Row>
      </Card>

      {/* SAVE BUTTON */}
      <FormSaveButton
        loading={dataContext?.partialLoading}
        form={form}
        formName={formName}
        dataContext={dataContext}
        onlySave
        setSubmitted={setSubmitted}
        disabled={isDisabled}
      />
      {/* SAVE BUTTON */}
    </FormWrapper>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildElementsRefs(hookUseRef) {
    return {
      // profit
      markup: hookUseRef(null),
      discount: hookUseRef(null),

      // integration
      operatorPlanId: hookUseRef(null),
      operatorPlanName: hookUseRef(null),
      operatorPlanRate: hookUseRef(null),
    };
  }
});

// EXPORT **********************************************************************
// *****************************************************************************

export default MultiPlansParameters;
