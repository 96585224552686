import React, {useRef} from 'react';
import {Button, Table, Tooltip} from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import {
  EditOutlined, EyeOutlined,
} from '@ant-design/icons';
import {
  buildTableColumnSearch,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import DocumentStatusTag
  from 'components/jarvisly-layouts/ModuleComponent/Components/DocumentStatusTag';
import ImageDescription from 'components/jarvisly/ImageDescription';
import moment from 'moment';
import { useSelector } from 'react-redux'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const ProceduresMainList = props => {

  // props deconstruction ------------------------------------------------------

  const {
    search,
    _module, _data, _summary, _loading,
    rdxModuleQuery,
    onChange, onClickOpen, onClickPreview,
  } = props;

  // local variables -----------------------------------------------------------

  const rdxModuleSettings = useSelector(s => s.moduleRedux.rdxModuleSettings)
  const documentCodeRef = useRef();
  const nameRef = useRef();

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  const tableColumns = [
    {
      hidden: !rdxModuleSettings?.showNumberOfLine,
      title: setLocale(true, 'line_#'),
      width: 80,
      render: (item, record, index) => (<div className="text-left">
        {(_summary?.pagination?.current * _summary?.pagination?.pageSize) -
            _summary?.pagination?.pageSize + index + 1}
      </div>),
    }, {
      title: setLocale(true, 'document'),
      dataIndex: 'documentCode', // dataIndex: '_metadata.documentCode',
      filteredValue: search?.documentCode && search.documentCode !== 'null'
          ? search?.documentCode
          : null, ...buildTableColumnSearch('documentCode', documentCodeRef),
      width: 140,
      render: (_, record) => (<>
        <div>
          {/*<span className="text-uppercase text-muted">*/}
          {/*  {record?.uniqueKey}*/}
          {/*</span><br/>*/}
          <span className="text-uppercase text-muted">
            {record?._metadata?.__documentCode}
          </span>
        </div>
      </>),
      sorter: true, // desabilitado temporariamente até consertarem o bug: 4.20.1 (https://github.com/ant-design/ant-design/issues/38392)
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'documentCode' &&
          rdxModuleQuery?.currentQuery?.sortOrder,
    },

    {
      title: setLocale(true, _module?.singular),
      dataIndex: 'name',
      filteredValue: search?.name && search.name !== 'null'
          ? search?.name
          : null, ...buildTableColumnSearch('name', nameRef),
      shouldCellUpdate: () => false,
      className: 'text-truncate',
      render: (_, record) => (<div className="d-flex" style={{width: 200}}>

        <ImageDescription backColor={record?.backColor}
                          foreColor={record?.foreColor}
                          shape="square"
                          title={record?.name}
                          subtitle={record?.description}
        />
      </div>),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'name' &&
          rdxModuleQuery?.currentQuery?.sortOrder,
    },

    {
      title: setLocale(true, 'status'),
      dataIndex: 'documentStatus',
      width: 100,
      render: (_, record) => (<DocumentStatusTag record={record}/>),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'documentStatus' &&
          rdxModuleQuery?.currentQuery?.sortOrder,
      filters: [
        {
          text: setLocale(true, 'in_analise'), value: 'in_analise',
        }, {
          text: setLocale(true, 'with_reservation'), value: 'with_reservation',
        }, {
          text: setLocale(true, 'revised'), value: 'revised',
        }],
      filteredValue: search?.documentStatus && search.documentStatus !== 'null'
          ? search?.documentStatus
          : null,
    },

    {
      title: setLocale(true, 'registration_date'),
      dataIndex: ['_metadata', 'audit', 'createdAt'],
      width: 160,
      hidden: false,
      render: (_, record) => (<>
        {record?._metadata?.audit?.createdAt &&
            moment(record._metadata.audit.createdAt).format('L LT')}
      </>),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField ===
          '_metadata.audit.createdAt' &&
          rdxModuleQuery?.currentQuery?.sortOrder,
    },

    {
      title: setLocale(true, 'actions'),
      dataIndex: 'actions',
      width: 50,
      render: (_, record) => (
          <div className="text-right d-flex justify-content-end">


            <Tooltip title={setLocale(true, 'edit')}>
              <Button className="mr-2" icon={<EditOutlined/>}
                      onClick={() => onClickOpen(record)} size="small"/>
            </Tooltip>


            <Tooltip title={setLocale(true, 'view')}>
              <Button className="mr-2" icon={<EyeOutlined/>}
                      onClick={() => onClickPreview(record)}
                      size="small"/>
            </Tooltip>
          </div>),
    }];

  return (

      <Table rowKey="_id"
             pagination={_summary?.pagination}
             columns={tableColumns.filter(x => !x.hidden)}
             dataSource={_data}
             onChange={onChange}
             loading={_loading}/>);
};

// EXPORT **********************************************************************
// *****************************************************************************

export default React.memo(ProceduresMainList);
