import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input, message,
  Modal,
  Row,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import IntlMessage from 'components/util-components/IntlMessage';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { InputCpfCnpj } from 'utils/react-jarvisly-helper';
import moment from 'moment-timezone';
import { msg, onFormFinishFailed } from 'utils/helpers';
import { removeCandidateFromSchedule } from '../schedules-api';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString();

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const CancelAppointment = props => {

  // props deconstruction ------------------------------------------------------
  const {
    showCancelModal,
    setShowCancelModal,
    schedule,
    fetchData,
  } = props;

  // component states -----------------------------------------------------------
  const [updating, setUpdating] = useState(false);

  // local variables ------------------------------------------------------------
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const elRefs = {
    cpf: useRef(null),
    birthday: useRef(null),
    'anacInfo.code': useRef(null),
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const c = schedule?.candidate;
    if (c) form.setFieldsValue({ cpf: c?.cpf });
  }, [schedule]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  // close button click or escape
  const onClose = () => {
    setUpdating(false);
    setShowCancelModal(false);
    form.resetFields();
  };

  // confirm button click
  const onOk = () => {
    if (updating || !showCancelModal) return;
    setUpdating(true);
    formRef.current.submit();
  };

  const onFinishFailed = errorInfo => {
    setUpdating(false);
    onFormFinishFailed(errorInfo, elRefs, setUpdating);
  };

  const onFinish = async values => {
    setUpdating(false);

    const matchBirthday = moment(values.birthday).format('YYYY-MM-DD') ===
      moment(schedule.candidate.birthday).format('YYYY-MM-DD');
    const matchAnacId = values?.anacInfo?.code ===
      schedule.candidate.anacInfo?.code;

    if (!matchAnacId || !matchBirthday) {
      msg('e', 'Dados não conferem!');
    } else {
      msg('i', 'Cancelando Agendamento...', 'cancelling');

      const body = {
        candidateId: schedule.candidate._id,
        name: schedule.candidate.name,
        email: schedule.candidate.email,
      };
      await removeCandidateFromSchedule(schedule._id, body);

      message.destroy('cancelling');
      msg('s', 'Agendamento cancelado com sucesso!');

      onClose();
      fetchData().then(x => x);
    }

  };

  // UI COMPONENT --------------------------------------------------------------

  const rules = {
    birthday: [
      {
        required: true,
        message: setLocale(true, 'required_field'),
      }],

    anacId: [
      {
        required: true,
        message: setLocale(true, 'required_field'),
      }, {
        min: 6,
        message: setLocale(true, 'invalid_field'),
      }],
  };

  const modalButtons = [

    <Row justify="space-between" key="footerButtons">

      <Row align="middle">
        {/* {schedule?._id ? <span>_id: {schedule._id}</span> : null} */}
      </Row>

      <Row>


        <Button key="cancel"
                onClick={onClose}
                disabled={updating}>
          <span>{setLocale(true, 'cancel')}</span>
        </Button>

        <Button key="ok"
                type="primary"
                loading={updating}
                onClick={onOk}
        >
          <span>{setLocale(true, 'confirm')}</span>
        </Button>
      </Row>

    </Row>];

  return (<>

    <Modal
      title="Cancelar Agendamento"
      // visible={showCancelModal}
      open={showCancelModal}
      width={320}
      onCancel={onClose}
      getContainer={false}
      footer={modalButtons}
    >

      <Alert
        description="Para cancelar o seu agendamento você deve informar os dados abaixo..."
        type="info"
        className="mb-5"
        showIcon/>


      <Form
        form={form}
        ref={formRef}
        layout="vertical"
        initialValues={{ cpf: schedule?.candidate?.cpf }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >


        <Row gutter={ROW_GUTTER}>

          <Col xs={24}>

            <Form.Item
              label={setLocale(true, 'person_cpf')}
              name="cpf"
              hasFeedback
              validateStatus={'success'}
            >
              <InputCpfCnpj
                ref={elRefs.cpf}
                disabled={true}
                profile="cpf"
                name="cpf"
              />

            </Form.Item>


            <Form.Item
              label={setLocale(true, 'birthdate')}
              name="birthday"
              rules={rules.birthday}
            >
              <DatePicker className="w-100 no-border-radius"
                          autoFocus
                          format="DD/MM/YYYY"
                          placeholder=""
                          allowClear={false}
                          ref={elRefs.birthday}
                          name="birthday"
              />
            </Form.Item>


            <Form.Item
              label={setLocale(true, 'anac_id')}
              name={['anacInfo', 'code']}
              rules={rules.anacId}
            >
              <Input minLength={6} maxLength={6}
                     ref={elRefs['anacInfo.code']}
                     name="anacInfo.code"
              />
            </Form.Item>

          </Col>

        </Row>

      </Form>


    </Modal>

  </>);
};

export default CancelAppointment;
