import { get } from 'configs/ApiConfig'
import { VEHICLES_API_URL } from './vehicles-constants'

export const getVehicles = async _id => {

  let url = `${VEHICLES_API_URL}/combo-list/${_id}`
  const config = { headers: { 'x-resultasobject': false } }

  try {
    return await get(url, config)

  } catch (error) {
    throw error
  }
}

export const getVehiclesCountByBlockId = async blockId => {

  const url = `vehicles/get-counter-by-blockid/${blockId}`
  const config = { headers: { 'x-resultasobject': true } }

  try {
    return await get(url, config) || 0

  } catch (error) {
    throw error
  }
}

export const getVehiclesCountByProfile = async profileId => {

  const url = `vehicles/get-counter-by-profileid/${profileId}`
  const config = { headers: { 'x-resultasobject': true } }

  try {
    return await get(url, config) || 0

  } catch (error) {
    throw error
  }
}

export const getVehiclesByField = async (field, value, level = 1, dataType, dataProfile, resultAsObject = false) => {

  if (!field || !value) {
    return console.error(
      'missing \'field\' or \'value\' parameters! getVehiclesByField()')
  }

  if (value?.length < 3) return null

  let url = `${VEHICLES_API_URL}/get-list-by-field/${field}/${value}/${level}`
  const config = { headers: { 'x-resultasobject': resultAsObject } }

  if (dataType) url += `/${dataType}`
  if (dataProfile) url += `/${dataProfile}`

  try {
    return await get(url, config)

  } catch (error) {
    throw error
  }
}

