import React, { forwardRef, useEffect, useState } from 'react'
import { Col, Divider, Row, Steps } from 'antd'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import Step1 from './atualizaInfoSteps/titularStep'
import Step2 from './atualizaInfoSteps/habitationStep'
import Step3 from './atualizaInfoSteps/residentsStep'
import Step4 from './atualizaInfoSteps/vehiclesStep'
import Step5 from './atualizaInfoSteps/bikesStep'
import Step6 from './atualizaInfoSteps/petsStep'
import Step7 from './atualizaInfoSteps/agreementStep'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { DataContext } from '../../DataProvider'
import { clone } from 'jarvisly-helper'
import { MODULE_ATUALIZA_PARAMETERS as _module } from '../../atualiza-constants'

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const RegistrationTileForm = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const { dataContext, setDataContext } = React.useContext(DataContext)
  const { record } = props

  // local variables -----------------------------------------------------------
  const intl = useIntl()
  const stepItems = buildStepsItems()

  // component states ----------------------------------------------------------
  const [currentStep, setCurrentStep] = useState(0)

  // hooks ---------------------------------------------------------------------
  useEffect(() => {

    setDataContext({
      // ...dataContext,
      // settings: settings,
      // parameters: parameters,
      data: record || null,
      dataLock: clone(record) || null,
      // summary: data?.summary || null,
      fullLoading: false,
    })

    setCurrentStep(record?.currentStep || 0)

  }, [record]) // eslint-disable-line react-hooks/exhaustive-deps

  // }, [_data?.currentStep]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const buildCurrentStep = () => {

    if (!dataContext?.dataLock?._id) return

    switch (currentStep) {

      case 0:
        return (<Step1 {...props}
                       ref={ref}
                       _module={_module}
                       step={currentStep}
                       setStep={setCurrentStep}
                       dataContext={dataContext}
                       setDataContext={setDataContext}
                       _data={dataContext?.data?.titular}
                       _dataLock={dataContext?.dataLock?.titular}
                       _dataRoots={dataContext?.dataLock}/>)

      case 1:
        return (<Step2 {...props}
                       ref={ref}
                       _module={_module}
                       step={currentStep}
                       setStep={setCurrentStep}
                       dataContext={dataContext}
                       setDataContext={setDataContext}
                       _data={dataContext?.data?.habitation}
                       _dataLock={dataContext?.dataLock?.habitation}
                       _dataRoots={dataContext?.dataLock}/>)

      case 2:
        return (<Step3 {...props}
                       ref={ref}
                       _module={_module}
                       step={currentStep}
                       setStep={setCurrentStep}
                       dataContext={dataContext}
                       setDataContext={setDataContext}
                       _data={dataContext?.data?.residents}
                       _dataLock={dataContext?.dataLock?.residents}
                       _dataRoots={dataContext?.dataLock}/>)

      case 3:
        return (<Step4 {...props}
                       ref={ref}
                       _module={_module}
                       step={currentStep}
                       setStep={setCurrentStep}
                       dataContext={dataContext}
                       setDataContext={setDataContext}
                       _data={dataContext?.data?.vehicles}
                       _dataLock={dataContext?.dataLock?.vehicles}
                       _dataRoots={dataContext?.dataLock}/>)
      case 4:
        return (<Step5 {...props}
                       ref={ref}
                       _module={_module}
                       step={currentStep}
                       setStep={setCurrentStep}
                       dataContext={dataContext}
                       setDataContext={setDataContext}
                       _data={dataContext?.data?.bikes}
                       _dataLock={dataContext?.dataLock?.bikes}
                       _dataRoots={dataContext?.dataLock}/>)

      case 5:
        return (<Step6 {...props}
                       ref={ref}
                       _module={_module}
                       step={currentStep}
                       setStep={setCurrentStep}
                       dataContext={dataContext}
                       setDataContext={setDataContext}
                       _data={dataContext?.data?.pets}
                       _dataLock={dataContext?.dataLock?.pets}
                       _dataRoots={dataContext?.dataLock}/>)

      case 6:

        return (<Step7 {...props}
                       ref={ref}
                       _module={_module}
                       step={currentStep}
                       setStep={setCurrentStep}
                       dataContext={dataContext}
                       setDataContext={setDataContext}
                       _data={dataContext?.data}
                       _dataLock={dataContext?.dataLock}
                       _dataRoots={dataContext?.dataLock}/>)

      default:
        return null
    }

  }

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <Steps items={stepItems}
           direction="horizontal"
           size="small"
           current={currentStep}
           onChange={step => setCurrentStep(step)}/>


    <Row gutter={ROW_GUTTER}>

      {/*
      <Col span={6} style={{ borderRight: '1px solid #80808024' }}>
        <Steps items={stepItems}
               direction="vertical"
               size="small"
               current={currentStep}
               onChange={step => setCurrentStep(step)}
               className="mr-4"/>
      </Col>
*/}

      <Divider orientation="left"/>


      <Col span={24} className="mt-2">

        {buildCurrentStep()}

      </Col>

    </Row>


  </div>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildStepsItems () {

    const isDisabled = step => {
      if (!dataContext?.dataLock?.currentStep && step >= 0) return true
      return step > dataContext?.dataLock?.currentStep
    }

    return [
      {
        title: translate(intl, 'titular'),
        description: '', //'', //translate(intl, 'registration_data'),
        disabled: isDisabled(0),
      },
      {
        title: translate(intl, 'habitation'),
        description: '', //translate(intl, 'housing_agreement'),
        disabled: isDisabled(1),
      },
      {
        title: translate(intl, 'dependents'),
        description: '', //translate(intl, 'dependent_registration'),
        disabled: isDisabled(2),
      },
      {
        title: translate(intl, 'vehicles'),
        description: '', //translate(intl, 'motorcycle_vehicle_registration'),
        disabled: isDisabled(3),
      },
      {
        title: translate(intl, 'bikes'),
        description: '', //translate(intl, 'bicycle_registration'),
        disabled: isDisabled(4),
      },
      {
        title: translate(intl, 'pets'),
        description: '', //translate(intl, 'pets_list'),
        disabled: isDisabled(5),
      },
      {
        title: translate(intl, 'conclusion'),
        description: '', //translate(intl, 'registration_finishing'),
        disabled: isDisabled(6),
      },
    ]
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default RegistrationTileForm



