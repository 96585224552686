import React from 'react'
import { Dropdown } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const EllipsisDropdown = props => {
  return (

    <Dropdown placement="bottomRight"
              trigger={['click']}
              menu={props.menu}>

      <div className="ellipsis-dropdown">
        <EllipsisOutlined/>
      </div>

    </Dropdown>
  )
}

EllipsisDropdown.propTypes = {
  trigger: PropTypes.string,
  placement: PropTypes.string,
  menu: PropTypes.object.isRequired,
}

EllipsisDropdown.defaultProps = {
  trigger: 'click',
  placement: 'bottomRight',
  // menu: <Menu/>
}

export default EllipsisDropdown
