import {isDecimals, isMongoObjectId, sort} from 'utils/helpers';
import {
  decorateDataType,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import moment from 'moment';
import {clone} from 'jarvisly-helper';

export function validateSearchFn(keyValueArr, resetSearch) {

  const [k, v] = keyValueArr;

  if (resetSearch || !v) return 'null';

  switch (k) {

    case 'documentCode':
      if (v === 'null') return 'null';
      return v;

    case 'name':
      if (v === 'null') return 'null';
      return v;

    case 'documentStatus': {

      const vArr = Array.isArray(v) ? v : v.split(',');
      const newV = [];

      if (vArr.length === 0) {
        return 'null';

      } else if (vArr[0] === null || vArr[0] === 'null') {
        return 'null';

      } else {
        if (vArr.includes('in_analise')) newV.push('in_analise');
        if (vArr.includes('with_reservation')) newV.push('with_reservation');
        if (vArr.includes('revised')) newV.push('revised');
      }

      if (newV.length === 0) return 'null';

      return newV;
    }

    case 'family':
    case 'ambience': {
      const vArr = Array.isArray(v) ? v : v.split(',');
      const newV = [];

      vArr.map(x => {
        if (isMongoObjectId(x)) newV.push(x);
        return x;
      });

      return newV.length > 0 ? newV : 'null';
    }

    default:
      return 'null';
  }
};

export function validateFilterFn(keyValueArr, defaultFilter) {

  const {dataProfile} = defaultFilter;

  const [k, v] = keyValueArr;

  switch (k) {

    case 'folder':
      return ['documents', 'archive', 'recycle'].includes(v) ? v : 'documents';

    case 'dataProfile':
      return dataProfile;

    default:
      return 'null';
  }

};

export function decorateDocument(docs) {

  const accountId = localStorage.getItem('accountId');

  let isObject = false;

  if (!Array.isArray(docs)) {
    isObject = true;
    docs = [docs];
  }

  docs.map(doc => {

    doc?.discussions?.map(d => {
      d.from = d.authorId === accountId ? 'me' : 'opposite';
      return d;
    });

    if (doc?.initialTime && doc?.finalTime) {
      doc.hour = [
        moment(doc.initialTime, 'HH:mm'),
        moment(doc.finalTime, 'HH:mm'),
      ];
    }

    doc?.process?.map(p => {
      p.__estimatedTime = p.estimatedTime && moment(p.estimatedTime, 'HH:mm');
      p.__time = p.time && moment(p.time, 'HH:mm');
      return p;
    });

    doc?.tasks?.map(t => {
      t.__time = t.time && moment(t.time, 'HH:mm');
      return t;
    });

    return doc;
  });

  return isObject ? docs[0] : docs;
}

export function decorateNewDocument(_settings, intl, _module) {
  let data = {};
  decorateDataType(_settings, data, _module);
  data.frequency = 'weekly';
  return data;

}

export function removeDocumentValidation(_module, data, intl) {

  const _id = data?._id;

  if (!isMongoObjectId(_id)) {
    console.error(
        'Invalid _id! procedures-methods.js: removeDocumentValidation()');
    return 'abort';
  }

  return null;
}

export function dataProfileValidation(_module, body) {

  if (!body || !_module || !_module?.name) return;

  const dataProfile = _module?.initialFilter?.dataProfile;

  if (!body.dataProfile) body.dataProfile = [];

  if (!body.dataProfile.includes(dataProfile)) {
    body.dataProfile.push(dataProfile);
  }
};

// export function reorderProcess(process, from, to, newProcess, currentProcess) {
export function reorderProcess(process, from, to) {

  // const dragRow = process[from];
  let processArr = clone(process);
  let record;

  record = from && processArr[from];
  processArr = processArr.filter(x => !x.__type && x._id !== record?._id);

  if (!record) {

    let counter = 0;
    processArr.forEach((x) => {

      if (!isDecimals(x.order)) {
        counter++;
        x.order = counter;

      } else {

        x.order = counter + 0.5;
      }
    });

  } else {

    const minusIdx = processArr.filter((x, idx) => x.isInterval && idx < to);

    if (to > 1) to -= minusIdx.length;
    if (record?.isInterval) to -= 0.5;
    record.order = to;

    let counter = 1;
    processArr.forEach((x) => {
      if (!isDecimals(x.order)) {
        if (counter === to) counter++;
        x.order = counter;
        counter++;
      }
    });

    processArr.push(record);

  }

  processArr = processArr.sort((a, b) => sort(a, b, 'order'));
  return processArr;

  // ---

  /*  let processArr = clone(process).filter(x => !x.__type);
    let counter = 1; // to === 1 ? 2 : 1;

    if (from !== to || newProcess?.isInterval !== currentProcess?.isInterval) {

      processArr = processArr.filter(x => x._id !== currentProcess?._id);

      processArr.forEach((x, idx, arr) => {
        if (!isDecimals(x.order)) {
          if (counter === to) counter++;
          x.order = counter;
          counter++;
        }
      });
    }

    if (newProcess) processArr.push(newProcess);

    return processArr.sort((a, b) => sort(a, b, 'order'));*/
}
