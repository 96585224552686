import React, {useState} from 'react';
import {rdxSetDataProvider} from 'redux/actions/module';
import {useDispatch} from 'react-redux';

export const DataContext = React.createContext({});

export const DataProvider = ({_module, children}) => {

    const initialContext = {
        module: null,
        subscription: null,

        settings: null,
        parameters: null,
        doc: null,
        docLock: null,
        summary: null,

        loading: true,
    }

    const [state, setState] = useState(initialContext);
    const dispatch = useDispatch();

    const setDataContext = formContext => {

        // create initial data
        if (formContext?.docs && !formContext?.initialDocs && !state.initialDocs) formContext.initialDocs = {...formContext.docs};

        const nextState = {...state, ...formContext};

        setState(nextState);
        dispatch(rdxSetDataProvider(nextState));
    };

    return (
        <DataContext.Provider value={{dataContext: state, setDataContext}}>
            {children}
        </DataContext.Provider>
    );
};
