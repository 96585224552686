import React from 'react';
import {Tag} from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import {getDaysPeriods} from 'utils/helpers';
import {useIntl} from 'react-intl';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const ProceduresFrequencyTag = props => {

  const intl = useIntl();
  const periodsArr = getDaysPeriods(intl, true);

  const getFrequencyColor = frequency => {
    const obj = periodsArr.find(x => x.value === frequency);
    return obj?.color || 'geekblue';
  };

  const {record} = props;

  return (
      record?.frequency
          ? <Tag key={record?.frequency}
                 className="text-uppercase multi-tags unique"
                 color={getFrequencyColor(record.frequency)}>
            {setLocale(true, record.frequency)}
          </Tag>
          : null
  );
};

export default ProceduresFrequencyTag;
