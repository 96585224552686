import React, {useState} from 'react';
import {APP_NAME} from 'configs/AppConfig';
import {connect} from 'react-redux';
import {Button, Col, Modal, Row, Statistic, Tooltip} from 'antd';
import {
  ApartmentOutlined,
  CloudServerOutlined,
  DatabaseOutlined,
  FundViewOutlined, IssuesCloseOutlined,
} from '@ant-design/icons';
import IntlMessage from '../util-components/IntlMessage';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const Footer = ({rdxModuleList}) => {

  const workTime = rdxModuleList?.response?.workTime;

  // component states -----------------------------------------------------------

  const [showDiagnosticModal, setShowDiagnosticModal] = useState(false);

  // local variables ------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  const buttons = [
    <Row justify="end" key="footerButtons">
      <Button key="ok"
              type="primary"
              onClick={() => setShowDiagnosticModal(false)}
      >
        <span>{setLocale(true, 'ok')}</span>
      </Button>
    </Row>];

  return (<>

    <Row justify="space-between" align="middle" className="border-top"
         style={{padding: '9px 12px', minHeight: 60}}>

      <Col xs={24} md={18} className="pb-3 pb-md-0 text-center text-md-left">
            <span>Copyright  &copy;  {`${new Date().getFullYear()}`}
              <span className="font-weight-semibold">&nbsp;{`${APP_NAME}`}</span>
              &nbsp;All rights reserved.
            </span>
      </Col>

      <Col xs={24} md={6} className="text-right">
        {workTime &&
            <Tooltip placement="left"
                     title={setLocale(true, 'diagnostic')}>
              <Button icon={<FundViewOutlined/>}
                      onClick={() => setShowDiagnosticModal(true)}
                  // onClick={showDiagnostic}
              />
            </Tooltip>
        }
      </Col>

    </Row>


    <Modal
        title={<>
          <FundViewOutlined/>
          <span className="ml-2">
            {setLocale(true, 'answer_time')}
          </span>
        </>}
        // visible={showDiagnosticModal}
        open={showDiagnosticModal}
        // width={640}
        // onOk={() => setShowDiagnosticModal(false)}
        onCancel={() => setShowDiagnosticModal(false)}
        closable={true}

        getContainer={false}
        footer={buttons}
    >
      <Row gutter={[36, 36]} justify="space-around">

        <Col span={12}>
          <Statistic title={setLocale(true, 'database')}
                     value={workTime?.databaseProcessed}
                     prefix={<DatabaseOutlined/>}/>
        </Col>

        <Col span={12}>
          <Statistic title={setLocale(true, 'network')}
                     value={workTime?.networkProcessed}
                     prefix={<ApartmentOutlined/>}/>
        </Col>

        <Col span={12}>
          <Statistic title={setLocale(true, 'server')}
                     value={workTime?.serverProcessed}
                     prefix={<CloudServerOutlined/>}/>
        </Col>


        <Col span={12}>
          <Statistic title={setLocale(true, 'spend_time')}
                     value={workTime?.totalProcessed}
                     prefix={<IssuesCloseOutlined/>}/>
        </Col>

      </Row>

    </Modal>


  </>);
};

const mapStateToProps = ({moduleRedux}) => {
  const {rdxModuleList} = moduleRedux;
  return {rdxModuleList};
};

export default connect(mapStateToProps)(React.memo(Footer));
