import React from 'react'
import IntlMessage from 'components/util-components/IntlMessage'
import { Col, Row, Tag, Tooltip } from 'antd'
import { Icon } from 'components/util-components/Icon'
import {
  CalendarOutlined,
  PushpinOutlined,
  UserOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import { parseDateToDisplay, parsePhoneToDisplay } from '../../../utils/helpers'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

export const MovesDocumentSubtitle = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const _dataRootsLock = dataContext?.docLock
  const block = _dataRootsLock?.__apartment?.block

  return (<>

    {block
      ? <Tag key={block._id} color={block.backColor}>
              <span className="text-uppercase" style={{ color: block.foreColor }}>
                {block.name}
              </span>
      </Tag>
      : null}

    {_dataRootsLock?.type === 'exit'
      ? <Tag key={'exit'} color={'red'}>
                          <span className="text-uppercase">
                            {setLocale(true, 'exit')}
                          </span>
      </Tag>
      : <Tag key={'entrance'} color={'green'}>
                          <span className="text-uppercase">
                            {setLocale(true, 'entrance')}
                          </span>
      </Tag>}


  </>)
})

// EMAIL AND PHONE
export const MovesDocumentHeaderColumn2 = React.forwardRef((props, ref) => {

  const { _dataRootsLock } = props

  if (_dataRootsLock?.moveAt) _dataRootsLock.moveAt = parseDateToDisplay(_dataRootsLock.moveAt)

  return (<>
    <Row style={{ display: _dataRootsLock?.moveAt ? 'initial' : 'none' }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          <Tooltip placement="right" title={setLocale(true, 'move_date')}>
            <Icon type={CalendarOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
            <span className="ml-2 font-weight-semibold text-truncate">
              {_dataRootsLock?.moveAt?.format('L')}
            </span>
          </Tooltip>
        </Row>
      </Col>
    </Row>

    <Row style={{ display: _dataRootsLock?.period ? 'initial' : 'none' }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          <Tooltip placement="right" title={setLocale(true, 'period')}>
            <Icon type={PushpinOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
            <span className="ml-2 font-weight-semibold text-truncate">
              {setLocale(true, _dataRootsLock?.period || 'n_a')}
            </span>
          </Tooltip>
        </Row>
      </Col>
    </Row>
  </>)

})

// ADDRESS AND WEBSITE
export const MovesDocumentHeaderColumn3 = React.forwardRef((props, ref) => {

  const { _dataRootsLock } = props

  if (_dataRootsLock?.phone) _dataRootsLock.phone = parsePhoneToDisplay(_dataRootsLock.phone)

  return (<>
    <Row style={{ display: _dataRootsLock?.name ? 'initial' : 'none' }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          <Tooltip placement="right" title={setLocale(true, 'responsible')}>
            <Icon type={UserOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
            <span className="ml-2 font-weight-semibold text-truncate">
              {_dataRootsLock?.name}
            </span>
          </Tooltip>
        </Row>
      </Col>
    </Row>

    <Row style={{
      display: _dataRootsLock?.phone ? 'initial' : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          <Tooltip placement="right" title={setLocale(true, 'phone')}>
            <Icon type={PhoneOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
            <span className="ml-2 font-weight-semibold text-truncate">
              {_dataRootsLock?.phone}
            </span>
          </Tooltip>
        </Row>
      </Col>
    </Row>
  </>)

})
