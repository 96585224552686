import React from "react";
// import VouchersList from './Listing/List'
import { Route } from "react-router-dom";
import {
  defaultDecorateSettings,
  defaultDataProfileValidation
} from "components/jarvisly-layouts/ModuleComponent/module-methods";
import {
  VouchersDocumentHeaderColumn2,
  VouchersDocumentHeaderColumn3,
  VouchersDocumentSubtitle
} from "./Form/Header";
import { FormOutlined } from "@ant-design/icons";
import VouchersProfile from "./Form/sections/Profile";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const VOUCHERS_URL = "/app/financial/vouchers";
export const VOUCHERS_API_URL = "/v2/entities";

const INITIAL_LIST_STATE = {
  showNumberOfLine: localStorage.getItem("showNumberOfLine") === "true",
  showCheckBox: localStorage.getItem("showCheckBox") === "true"
};
const INITIAL_REQUEST = {

  // textSearch: {
  //   default: 'mONTE aLto',
  //   enum: ['a', 'b'],
  // },

  folder: {
    default: "documents",
    enum: ["documents", "recycle", "filed"]
  },

  filter: {
    default: {
      dataProfile: ["voucher"],
      dataType: ["voucher"]
    },
    enum: {
      dataProfile: ["voucher"],
      dataType: ["voucher"]
    }
  },

  // filter: {
  //   default: {
  //     color: { $in: ['black', 'white'] },
  //     city: { $in: ['Campinas'] },
  //   },
  //   enum: {
  //     color: ['branco', 'azul', 'amarelo'],
  //     city: ['Campinas', 'Itu'],
  //   },
  // },

  search: {},
  //   default: {
  //     age: 43,
  //     roomNumber: { $in: [11, 12, 43] },
  //     name: 'Ane Cardinalli',
  //   },
  //   enum: {
  //     age: [10, 30, 40],
  //     roomNumber: [22, 33, 54, 82],
  //   },
  // },

  sorter: {
    // roomNumber: 1,
    // blockId:-1
  },

  // project: {
  //   room: 1,
  //   blockId: 1,
  // },

  pagination: {
    pageNumber: 1,
    // pageSize: 10
    pageSize: ["10", "20", "50", "100"].includes(localStorage.getItem("pageSize"))
      ? Number(localStorage.getItem("pageSize"))
      : 10
  }
};
/*

const INITIAL_FILTER = {
  folder: 'documents',
  // dataProfile: 'plan',
}

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
}

const INITIAL_SEARCH = {
  roomNumber: 'null',
  blockId: 'null',
  documentStatus: 'null',
}
*/

const INITIAL_CONTEXT = {
  hasPicture: false,
  mode: "nope",

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null
};

const METHODS = {
  // parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  // decorateDocument: decorateDocument,
  // decorateNewDocument: decorateNewDocument,

  // removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation

  // refreshParameters: getVoucherModuleParameters,
};

const COMPONENTS = {
  // list: props => <VouchersList {...props}/>,
  // listPreviewHeader: props => <VouchersBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <VouchersBodyPreviewData {...props}/>,
  // listPreviewActions: props => <VouchersBodyPreviewActions {...props}/>,

  documentSubtitle: props => <VouchersDocumentSubtitle {...props} />,
  // documentSubtitle: props => <div>{props?.record?.description}</div>,
  documentHeaderColumn2: props => <VouchersDocumentHeaderColumn2 {...props} />,
  documentHeaderColumn3: props => <VouchersDocumentHeaderColumn3 {...props} />

  // moduleSettings: props => <vouchersSettingsTabs {...props}/>,
  // moduleSettings: vouchersSettingsTabs(),
};

const DATA_TYPES = [
  {
    key: "voucher",
    label: "legal_person",
    description: "vouchers_legal_person_description",
    checked: true
  }
];

const DOCUMENT_SECTIONS = [
  {
    key: "profile",
    icon: <FormOutlined />,
    label: "registration_data",
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${VOUCHERS_URL}/${_id}/profile`}
                                       render={() =>
                                         <VouchersProfile ref={ref} {...props}
                                                          _data={props._dataRoots}
                                                          _dataLock={props._dataRootsLock} />} />
  }
  /*  {
      key: 'fiscal',
      icon: <FundOutlined/>,
      label: 'fiscal',
      route: (props, ref, _id) => <Route key="fiscal"
                                         path={`${VOUCHERS_URL}/${_id}/fiscal`}
                                         render={() =>
                                           <NoSection ref={ref} {...props}
                                                      _data={props._data?.titular}
                                                      _dataLock={props._dataLock?.titular}/>}/>,
    },*/
  /*  {
      key: 'banks',
      icon: <BankOutlined/>,
      label: 'banks',
      route: (props, ref, _id) => <Route key="banks"
                                         path={`${VOUCHERS_URL}/${_id}/banks`}
                                         render={() =>
                                           <NoSection ref={ref} {...props} />}/>,
    },*/
];

const REQUIRED_FIELDS = [

  {
    key: "cnpj",
    label: "cnpj",
    checked: true,
    disabled: false,
    forDataType: ["voucher"]
  },

  {
    key: "foundedAt",
    label: "foundation_date",
    checked: false,
    disabled: false,
    forDataType: ["voucher"]
  },
  {
    key: "trade_name",
    label: "trade_name",
    checked: true,
    disabled: true,
    forDataType: ["voucher"]
  },

  {
    key: "phone",
    label: "phone",
    checked: true,
    disabled: false,
    dependence: "email",
    dependence_msg: "",
    forDataType: ["voucher"]
  },

  {
    key: "email",
    label: "email",
    checked: false,
    disabled: false,
    dependence: "phone",
    forDataType: ["voucher"]
  }

];

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {

  configurations: {
    dataTypes: ["voucher"]
  },

  requiredFields: [
    "cnpj",
    "rg",
    "trade_name",
    "mainPhone"
  ]
};

const DATA_AUTO_FILL = {
  // _id: '6450498ff324dea15b193702',
  _metadata: {
    subscriptionId: "643ec14175fdf864855d7183",
    documentStatus: 0,
    hidden: false,
    system: false,
    audit: {
      createdByAccountId: "64390f9545f33710d79ba062",
      createdOnClient: {
        userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36",
        acceptLanguage: "en-US,en;q=0.9,pt;q=0.8"
      },
      createdAt: "2023-05-01T23:21:51.826Z",
      updatedAt: "2023-05-01T23:21:51.826Z"
    },
    i18n: {
      countryCode: "br",
      timezoneOffset: -180,
      languageCode: "pt-br",
      localeCode: "br"
    },
    documentCode: "193702",
    origin: 0,
    counter: 1,
    __documentCode: "1.193702",
    __documentStatus: "in_analise"
  },
  uniqueKey: "02934311000153",
  dataProfile: ["voucher"],
  dataType: "voucher",
  name: "Infodesk",
  fullName: "Infodesk Technologies",
  backColor: "#b9bcc4",
  foreColor: "#ffffff",
  cnpj: "02934311000153",
  phone: "08001234567",
  email: "contato@infodesk.com.br",
  website: "www.infodesk.com.br",
  zip: "13010-111",
  address1: "Rua General Osório",
  number: "1200",
  neighborhood: "Centro",
  city: "Campinas",
  province: "SP",
  addressMetadata: {
    countryCode: "br",
    foundByApi: true,
    apiUrl: "https://viacep.com.br/ws/13010111/json/",
    zipWithMultipvoucherdress: false
  },
  addressAdditionalInfo: {
    note: "de 762/763 a 1661/1662",
    areaCode: "19",
    giaCode: "2446",
    ibgeCode: "3509502",
    siafiCode: "6291"
  },
  addressGps: [-22.9034349, -47.058309],
  __v: 0,
  __city: "Campinas - SP"
};

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_VOUCHERS_PARAMETERS = {

  root: "vouchers",
  name: "vouchers",
  singular: "voucher",
  titleField: "name",
  url: VOUCHERS_URL,
  api: VOUCHERS_API_URL,
  initialRequest: INITIAL_REQUEST,
  initialListState: INITIAL_LIST_STATE,

  hasDashboard: true,
  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,

  documentActions: {
    archive: false,
    duplicate: false
  },

  // showNumberOfLine: true,

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,

  initialContext: INITIAL_CONTEXT,

  // initialSearch: INITIAL_SEARCH,

  // initialFilter: INITIAL_FILTER,
  // initialSort: INITIAL_SORT,

  labels: {
    new_document_title: "new_voucher"
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: DATA_AUTO_FILL
  }

};


