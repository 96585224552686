import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout, Row } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavProfile from './NavProfile';
import NavPanel from './NavPanel';
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/theme';
import {
  NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH,
} from 'constants/ThemeConstant';
import utils from 'utils';
import SelectSubscription from '../jarvisly/SelectSubscription';
import { withRouter } from 'react-router-dom';
import { THEME_CONFIG } from 'configs/AppConfig';

const { Header } = Layout;

export const HeaderNav = props => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
    rdxUser,
    rdxSubscription,
    routeInfo,
    location,
    rdxModule,
    rdxModuleQuery,
    rdxModuleSettings,
    rdxModuleParameters,
    rdxFormProvider,
    rdxDataProvider,
    rdxModuleDataTmp,
    rdxModuleList,
  } = props;

  const [, setSearchActive] = useState(false);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === 'dark' ? '#00000' : '#ffffff');
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) onSearchClose();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const isActive = rdxUser?.subscriptions?.length > 0

  const enableSubscriptionSelector = location?.pathname?.includes(
    '/account/settings');

  const showDevInfo = () => {
    if (!THEME_CONFIG.APP.DEV_MODE) return;

    console.log('');
    console.log('');
    console.log('**************************');
    console.log('******** DEV INFO ********');
    console.log('**************************');
    console.log('');
    console.log('THEME_CONFIG', THEME_CONFIG);
    console.log('');
    console.log('rdxUser', rdxUser);
    console.log('');
    console.log('rdxSubscription', rdxSubscription);
    console.log('');
    console.log('routeInfo', routeInfo);
    console.log('');
    console.log('location', location);
    console.log('');
    console.log('rdxModule', rdxModule);
    console.log('');
    console.log('rdxModuleList', rdxModuleList);
    console.log('');
    console.log('rdxModuleQuery', rdxModuleQuery);
    console.log('');
    console.log('rdxModuleSettings', rdxModuleSettings);
    console.log('');
    console.log('rdxModuleParameters', rdxModuleParameters);
    console.log('');
    console.log('rdxFormProvider', rdxFormProvider);
    console.log('');
    console.log('rdxDataProvider', rdxDataProvider);
    console.log('');
    console.log('rdxModuleDataTmp', rdxModuleDataTmp);
    console.log('');
    console.log('data -->', rdxDataProvider?.data || rdxModuleList?.response?.data);
    console.log('');
    console.log('**************************');
    console.log('******** DEV INFO ********');
    console.log('**************************');
    console.log('');
  };

  const widthLimit = window.innerWidth - 260; // 260 = buttons width (nav, profile and settings)
  const width = widthLimit > 240 ? 280 : widthLimit;

  return (<Header className={`app-header ${navMode}`}
                  style={{ backgroundColor: headerNavColor }}
                  onDoubleClick={showDevInfo}
  >

    <div className={`app-header-wrapper ${isNavTop
      ? 'layout-top-nav'
      : 'layout-left-nav'}`}>
      <Logo logoType={navMode}/>
      <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
        <div className="nav-left">
          <ul className="ant-menu ant-menu-root ant-menu-horizontal">
            {isNavTop && !isMobile ? null :
              <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {
                onToggle();
              }}>
                {navCollapsed || isMobile ?
                  <MenuUnfoldOutlined className="nav-icon"/> :
                  <MenuFoldOutlined className="nav-icon"/>}
              </li>}

            {/*
            {isActive && !isMobile &&
              <li className="ant-menu-item ant-menu-item-only-child" style={{ cursor: 'auto', maxWidth: 200 }}>
                <SearchInput mode={mode} isMobile={isMobile}/>
              </li>}
*/}

          </ul>
        </div>

        {/*
            {isMobile &&
                <div style={{
                  marginTop: -16,
                  width: '100%',
                }} className="text-right">
                  <SelectSubscription routeInfo={routeInfo}
                                      enableSubscriptionSelector={enableSubscriptionSelector}/>
                </div>

              // <div style={{marginTop: -16, width: '100%'}}>
              //   <SelectSubscription routeInfo={routeInfo}/>
              // </div>
            }
*/}

        <div className="nav-right">

          {/* <NavLanguage/> */}


          {/*{!isMobile &&*/}
          {/*<Row style={{marginTop: -16}} align="middle">*/}
          <Row align="middle">
            <SelectSubscription routeInfo={routeInfo}
                                style={{
                                  maxWidth: width,
                                  minWidth: 172
                                }}
                                enableSubscriptionSelector={enableSubscriptionSelector}/>
          </Row>
          {/*}*/}

          {/*<NavNotification/>*/}
          <NavProfile/>
          <NavPanel direction={direction}/>
        </div>

        {/*
        <NavSearch active={searchActive} close={onSearchClose}/>
*/}

      </div>
    </div>

  </Header>);
};

const mapStateToProps = ({
  theme,
  auth,
  moduleRedux
}) => {

  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;

  const {
    rdxModule,
    rdxModuleQuery,
    rdxModuleSettings,
    rdxModuleParameters,
    rdxFormProvider,
    rdxDataProvider,
    rdxModuleDataTmp,
    rdxModuleList,
  } = moduleRedux;
  const {
    rdxUser,
    rdxSubscription
  } = auth;

  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    rdxUser,
    rdxSubscription,
    rdxModule,
    rdxModuleQuery,
    rdxModuleSettings,
    rdxModuleParameters,
    rdxFormProvider,
    rdxDataProvider,
    rdxModuleDataTmp,
    rdxModuleList,
  };
};

export default withRouter(connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav));
