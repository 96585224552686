export const SELECT_ROUTE = 'SELECT_ROUTE';
export const SELECT_MODULE = 'SELECT_MODULE';
export const SHOW_MODULE_SETTINGS = 'SHOW_MODULE_SETTINGS';
export const SHOW_MODULE_SEARCH = 'SHOW_MODULE_SEARCH';
export const MODULE_SEARCH = 'MODULE_SEARCH';
export const FORM_PROVIDER = 'FORM_PROVIDER';
export const DATA_PROVIDER = 'DATA_PROVIDER';
export const MODULE_SETTINGS = 'MODULE_SETTINGS';
export const MODULE_PARAMETERS = 'MODULE_PARAMETERS';
export const MODULE_LIST = 'MODULE_LIST';
export const MODULE_DASHBOARD = 'MODULE_DASHBOARD';

