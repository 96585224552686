import React, {forwardRef, useState} from 'react';
import RoundedAvatar from '../../../jarvisly/RoundedAvatar';
import {Button, Col, Row, Table, Tooltip} from 'antd';
import {EyeOutlined, PlusOutlined} from '@ant-design/icons';
import IntlMessage from '../../../util-components/IntlMessage';
import NoteModal from '../modals/NoteModal';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

const DocumentNotes = forwardRef((props, ref) => {

    const {formContext} = props

    const isDisabled = formContext.mode === 'view' || false //_loading;

    // props deconstruction ------------------------------------------------------

    // component states ----------------------------------------------------------
    const [showNoteModal, setShowNoteModal] = useState(false);

    // local variables -----------------------------------------------------------
    const document = formContext?.document;

    // hooks ---------------------------------------------------------------------

    // UI COMPONENT --------------------------------------------------------------

    const columns = [
        {
            // title: setLocale(true, 'name'),
            dataIndex: '__name', render: (_, record) => (<Row>
                <Col className="mr-3">
                    <RoundedAvatar user={record} showIcon/>
                </Col>

                <Col>
                    <strong>{record?.description}</strong>
                    <div style={{fontSize: 10}}>Criado há 45 minutos, Ricardo Biruel</div>
                </Col>
            </Row>),
        }, /*
        {
          // title: setLocale(true, 'profile'),
          dataIndex: 'description',
          render: (_, record) => (<>
            <strong>{record?.description}</strong>
            <div style={{fontSize: 10}}>Criado há 45 minutos, Ricardo Biruel</div>
          </>),
        },
    */


        {
            // title: setLocale(true, 'actions'),
            // dataIndex: 'actions',
            width: 50,
            render: (_, record) => (
                <div className="text-right d-flex justify-content-end">

                    <Tooltip title={setLocale(true, 'view')}>
                        <Button className="mr-2" icon={<EyeOutlined/>}
                            // onClick={() => onClickOpen(record)}
                                size="small"/>
                    </Tooltip>

                    {/*
            <Tooltip title={setLocale(true, 'edit')}>
              <Button className="mr-2" icon={<EditOutlined/>}
                  // onClick={() => onClickOpen(record)}
                      size="small"/>
            </Tooltip>

            <Tooltip title={setLocale(true, 'view')}>
              <Button className="mr-2" icon={<EyeOutlined/>}
                  // onClick={() => onClickPreview(record)}
                      size="small"/>
            </Tooltip>
*/}
                </div>),
        },

    ];

    return (<>

        <NoteModal showModal={showNoteModal}
                   setShowModal={setShowNoteModal}
        />


        <Row justify="end" align="middle">

            <Button type="primary"
                    icon={<PlusOutlined/>}
                    className="mb-3"
                    disabled={isDisabled}
                    onClick={() => setShowNoteModal(true)}>
                &nbsp;{setLocale(true, 'add')}
            </Button>
        </Row>

        <Table rowKey="_id"
               className="flat-table"
               columns={columns}
               dataSource={document?.notes || []}
        />
    </>);

});

export default DocumentNotes;
