import React from 'react'
import FoosList from './Listing/List'
import { Route } from 'react-router-dom'
import { defaultDecorateSettings } from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import { FoosDocumentHeaderColumn2, FoosDocumentHeaderColumn3, FoosDocumentSubtitle } from './Form/Header'
import { FormOutlined, FundOutlined } from '@ant-design/icons'
import NoSection from 'components/jarvisly-layouts/ModuleComponent/Components/NoSection'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const FOOS_URL = '/app/scaffolding/foos'
export const FOOS_API_URL = '/v2/foos'

const INITIAL_LIST_STATE = {
  showNumberOfLine: localStorage.getItem('showNumberOfLine') === 'true',
  showCheckBox: localStorage.getItem('showCheckBox') === 'true',
}
const INITIAL_REQUEST = {

  // textSearch: {
  //   default: 'mONTE aLto',
  //   enum: ['a', 'b'],
  // },

  folder: {
    default: 'documents',
    enum: ['documents', 'recycle', 'filed'],
  },

  // filter: {
  //   default: {
  //     color: { $in: ['black', 'white'] },
  //     city: { $in: ['Campinas'] },
  //   },
  //   enum: {
  //     color: ['branco', 'azul', 'amarelo'],
  //     city: ['Campinas', 'Itu'],
  //   },
  // },

  search: {},
  //   default: {
  //     age: 43,
  //     roomNumber: { $in: [11, 12, 43] },
  //     name: 'Ane Cardinalli',
  //   },
  //   enum: {
  //     age: [10, 30, 40],
  //     roomNumber: [22, 33, 54, 82],
  //   },
  // },

  sorter: {
    // roomNumber: 1,
    // blockId:-1
  },

  // project: {
  //   room: 1,
  //   blockId: 1,
  // },

  pagination: {
    pageNumber: 1,
    // pageSize: 10
    pageSize: ['10', '20', '50', '100'].includes(localStorage.getItem('pageSize'))
      ? Number(localStorage.getItem('pageSize'))
      : 10,
  },
}

const INITIAL_CONTEXT = {
  hasPicture: false,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
}

const METHODS = {
  // parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  // decorateDocument: decorateDocument,
  // decorateNewDocument: decorateNewDocument,

  // removeDocumentValidation: removeDocumentValidation,
  // postDocumentValidation: defaultDataProfileValidation,
  // putDocumentValidation: defaultDataProfileValidation,
  // refreshParameters: getFooModuleParameters,
}

const COMPONENTS = {
  list: props => <FoosList {...props}/>,
  // listPreviewHeader: props => <FoosBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <FoosBodyPreviewData {...props}/>,
  // listPreviewActions: props => <FoosBodyPreviewActions {...props}/>,

  documentSubtitle: props => <FoosDocumentSubtitle {...props}/>,
  documentHeaderColumn2: props => <FoosDocumentHeaderColumn2 {...props}/>,
  documentHeaderColumn3: props => <FoosDocumentHeaderColumn3 {...props}/>,
}

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <FormOutlined/>,
    label: 'section_1',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${FOOS_URL}/${_id}/profile`}
                                       render={() =>
                                         <NoSection ref={ref} {...props}
                                                    _data={props._dataRoots}
                                                    _dataLock={props._dataRootsLock}/>}/>,
  },
  {
    key: 'newSection',
    icon: <FundOutlined/>,
    label: 'section_2',
    route: (props, ref, _id) => <Route key="newSection"
                                       path={`${FOOS_URL}/${_id}/new-section`}
                                       render={() =>
                                         <NoSection ref={ref} {...props}
                                                    _data={props._data?.titular}
                                                    _dataLock={props._dataLock?.titular}/>}/>,
  },
]

const REQUIRED_FIELDS = [

  {
    key: 'uniqueKey',
    label: 'unique_key',
    checked: true,
    disabled: false,
  },
]

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {
  requiredFields: [
    'uniqueKey',
  ],
}

const DATA_AUTO_FILL = {
  // _id: '6450498ff324dea15b193702',
  _metadata: {
    subscriptionId: '643ec14175fdf864855d7183',
    documentStatus: 0,
    hidden: false,
    system: false,
    audit: {
      createdByAccountId: '64390f9545f33710d79ba062',
      createdOnClient: {
        userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36',
        acceptLanguage: 'en-US,en;q=0.9,pt;q=0.8',
      },
      createdAt: '2023-05-01T23:21:51.826Z',
      updatedAt: '2023-05-01T23:21:51.826Z',
    },
    i18n: {
      countryCode: 'br',
      timezoneOffset: -180,
      languageCode: 'pt-br',
      localeCode: 'br',
    },
    documentCode: '193702',
    origin: 0,
    counter: 1,
    __documentCode: '1.193702',
    __documentStatus: 'in_analise',
  },
  uniqueKey: '02934311000153',
}

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_FOOS_PARAMETERS = {

  root: 'foos',
  name: 'foos',
  singular: 'foo',
  titleField: 'name',
  url: FOOS_URL,
  api: FOOS_API_URL,
  initialRequest: INITIAL_REQUEST,
  initialListState: INITIAL_LIST_STATE,

  hasDashboard: true,
  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,

  documentActions: {
    archive: false,
    duplicate: false,
  },

  methods: METHODS,
  components: COMPONENTS,
  requiredFields: REQUIRED_FIELDS,

  initialContext: INITIAL_CONTEXT,

  labels: {
    new_document_title: 'new_foo',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: DATA_AUTO_FILL,
  },

}


