import React, {useEffect, useState} from "react";
import {AutoComplete, Input, Spin} from "antd";
import {normalize} from "jarvisly-helper";
import {isMongoObjectId, jarvislyDebounce, modal, substitution} from "utils/helpers";
import {getEntitiesByField} from "views/app-views/_old-modules/entities/entities-api";
import {translate} from "utils/react-jarvisly-helper";
import {useIntl} from "react-intl";

const AsyncEntityAutocomplete = React.forwardRef((props, ref) => {

    const initialState = {
        disabled: undefined,
        key: undefined,
        label: undefined,
        title: undefined,
        value: props?.value
    }

    const {
        delay = 400,
        selectOkFn,
        selectCancelFn,
        form,
        disabled,
        _router,
        onChange
    } = props

    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [valueSearched, setValueSearched] = useState();
    const [value, setValue] = useState(initialState)

    const intl = useIntl()
    const _id = isMongoObjectId(_router?.match?.params?._id) && _router?.match?.params?._id

    useEffect(() => {
        setValue({...initialState, value: props?.value})
    }, [props?.value]) // eslint-disable-line react-hooks/exhaustive-deps

    const debounceSearch = (searchValue) => {

        if (!handleUserSearch || searchValue.length < 3) return

        const sv = normalize(searchValue, 'lower')
        if (sv?.includes(valueSearched)) return;

        setOptions([]);
        setLoading(true);

        jarvislyDebounce(async () => {
            handleUserSearch(searchValue).then(result => {

                if (!result) {
                    const v = normalize(searchValue, 'lower')
                    setValueSearched(v)
                }

                setOptions(result || []);
                setLoading(false);
            })
        }, delay)
    }

    const handleUserSearch = async searchValue => {

        const entities = await getEntitiesByField('documents', null, null, 'name', searchValue)
        if (!entities || entities?.length === 0) {
            return onChangeHandle({value: searchValue})
        }

        return entities.map((item) => ({
            label: item.name,
            value: item.name,
            item: item
        }))
    }

    const handleOnSelect = async (event, option) => {
        const _id = option?.item?._id
        const name = option?.item?.name

        if (!_id || !name) return

        const open_document_description = translate(intl, 'open_document_description')
        const message = substitution(open_document_description, '%REFERENCE%', name)

        const options = {
            type: 'confirm',
            title: translate(intl, 'attention'),
            message: message,
            onOk: async () => {
                setOptions([])
                selectOkFn && selectOkFn({_id, name})
            },
            onCancel: () => {
                setOptions([]);
                form.setFieldsValue({name: value.value})
                setValue(initialState)
                selectCancelFn && selectCancelFn({_id, name})
            }
        };

        modal(options);
    }

    const onChangeHandle = value => {
        const name = value?.target?.value || value?.value
        onChange({name: name})
        form.setFieldValue('name', name)
        setValue({...value, value: name})
    }

    return (
        _id
            ? <Input ref={ref}
                     value={value?.value}
                     onChange={onChangeHandle}
                     disabled={disabled}/>
            :
            <AutoComplete
                ref={ref}
                value={value}
                disabled={disabled}
                labelInValue
                allowClear
                backfill
                onSearch={debounceSearch}
                notFoundContent={loading ? <Spin size="small"/> : null}
                placeholder="Localizar..."
                onChange={onChangeHandle}
                onSelect={handleOnSelect}
                options={options}
                required
            />
    )
})

export default AsyncEntityAutocomplete
