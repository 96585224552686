import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, Table, Tooltip } from 'antd'
import IntlMessage from 'components/util-components/IntlMessage'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { buildTableColumnSearch } from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import ImageDescription from 'components/jarvisly/ImageDescription'
import { useSelector } from 'react-redux'
import { capitalize } from 'jarvisly-helper'
import imgMotorcycle from 'assets/img/motorcycle.png'
import imgCar from 'assets/img/car.png'
import utils from 'utils'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const BikesMainList = props => {

  // props deconstruction ------------------------------------------------------

  const {
    search,

    _module, _data, _summary, _loading,

    rdxModuleQuery,

    onChange, onClickOpen, onClickPreview,
  } = props

  // component states (p.s. need by before 'local variables') -------------------
  const [state, setState] = useState({})

  // local variables -----------------------------------------------------------

  const rdxModuleSettings = useSelector(s => s.moduleRedux.rdxModuleSettings)
  const searchInputRef = useRef(null)
  const { useBreakpoint } = Grid
  const sm = utils.getBreakPoint(useBreakpoint()).includes('sm')
  const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const tableColumns = buildTableColumns()

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    setState({
      ...state, blocks: rdxModuleSettings?.bikes?.blocks || [],
    })
  }, [rdxModuleSettings]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (<>
    {/*<Input ref={searchRef}/>*/}

    <Table rowKey="_id"
           pagination={_summary?.pagination}
           columns={tableColumns.filter(x => !x.hidden)}
           dataSource={_data}
           onChange={onChange}
           loading={_loading}/>

  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns () {

    return [
      {
        hidden: !rdxModuleSettings?.showNumberOfLine,
        title: setLocale(true, 'line_#'),
        width: 80,
        render: (item, record, index) => (<div className="text-left">
          {(_summary?.pagination?.current * _summary?.pagination?.pageSize) - _summary?.pagination?.pageSize + index +
            1}
        </div>),
      },

      {
        title: setLocale(true, _module?.singular),
        dataIndex: 'model',
        filteredValue: search?.model && search.model !== 'null' ? search?.model : null, ...buildTableColumnSearch(
          'model', searchInputRef),
        shouldCellUpdate: () => false,
        className: 'text-truncate',
        render: (_, record) => (<div className="text-left">
          <ImageDescription
            shape="square"
            imageUrl={record?.type === 'motorcycle' ? imgMotorcycle : imgCar}
            title={record?.model?.toUpperCase()}
            subtitle={capitalize(record?.brand)}
            style={{ padding: 10 }}
            backColor="#ecf2fe"
          />
        </div>),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'model' && rdxModuleQuery?.currentQuery?.sortOrder,
      },

      {
        title: setLocale(true, 'apartment'),
        dataIndex: 'apartment',
        // width: 168.5,
        render: (_, record) => (<>
          <div className="font-weight-semibold">
            {record?.__apartment?.apartment}
          </div>
          <div className="text-muted">
            {record?.driver?.fullName}
          </div>
        </>),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'blockId' && rdxModuleQuery?.currentQuery?.sortOrder,
        filters: state?.blocks && state.blocks?.map(x => {
          return {
            value: x._id, text: x.name,
          }
        }),
        filteredValue: search?.blockId && search.blockId !== 'null' ? search?.blockId : null,
      },

      {
        title: setLocale(true, 'plate'),
        hidden: !md,
        width: 100,
        render: (_, record) => (<div className="text-left text-uppercase">
          {record?.plate}
        </div>),
      },

      {
        title: setLocale(true, 'color'),
        hidden: !sm,
        render: (_, record) => (<div className="text-left text-capitalize">
          {setLocale(true, record?.color)}
        </div>),
      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 50,
        render: (_, record) => (<div className="text-right d-flex justify-content-end">


          <Tooltip title={setLocale(true, 'edit')}>
            <Button className="mr-2" icon={<EditOutlined/>}
                    onClick={() => onClickOpen(record)} size="small"/>
          </Tooltip>


          <Tooltip title={setLocale(true, 'view')}>
            <Button className="mr-2" icon={<EyeOutlined/>}
                    onClick={() => onClickPreview(record)}
                    size="small"/>
          </Tooltip>
        </div>),
      }]
  }
}

// EXPORT **********************************************************************
// *****************************************************************************

export default React.memo(BikesMainList)
