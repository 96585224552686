import React, {useState} from 'react';
import {Button, Tooltip} from 'antd';
import {BgColorsOutlined} from '@ant-design/icons';
import {TwitterPicker} from 'react-color';
import {
  generateHexColor,
  TAG_COLORS_TABLE,
  TWITTER_PICKER_DEFAULT_COLORS,
} from 'utils/helpers';

const AntdReactColor = ({
                          setSelectedColor,
                          initialBackColor = '',
                          initialForeColor = '',
                          shape = 'circle',
                          type = '',
                          isCompact = false,
                          disabled,
                        }) => {

  const [backColor, setBackColor] = useState(initialBackColor);
  const [foreColor, setForeColor] = useState(initialForeColor);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleChangeComplete = (color) => {
    setBackColor(color.hex);
    setSelectedColor && setSelectedColor(color.hex);
    const oppositeColor = generateHexColor(color.hex, true);
    setForeColor(oppositeColor);
    setDisplayColorPicker(false);
  };

  const showColors = () => setDisplayColorPicker(true);

  const hideColor = ev => {
    ev.stopPropagation();
    setDisplayColorPicker(false);
  };

  const popover = {
    position: 'absolute', zIndex: '2',
  };

  const cover = {
    position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px', // zIndex: 1,
  };

  const twitterStyle = {
    default: {
      input: {
        display: 'none',
      }, hash: {
        display: 'none',
      },
    },
  };

  return (<div className={`antd-react-color ${isCompact && 'compact'}`}>
    <Tooltip title={!disabled ? 'Escolha uma cor' : 'Cor da Etiqueta'}>
      <Button className="m-0 p-0"
              disabled={disabled}
              shape={shape}
              style={{
                maxWidth: 40,
                maxHeight: 40,
                backgroundColor: backColor,
                borderColor: 'rgb(233, 233, 233)',
                color: foreColor,
              }}
              icon={<BgColorsOutlined/>}
              onClick={showColors}
      />
    </Tooltip>
    {displayColorPicker ?
        <div style={popover} className={`antd-react-color-popover type-${type}`}>

          <div style={cover} className="antd-react-color-cover"
               onClick={hideColor}
          />


          <TwitterPicker
              styles={isCompact ? twitterStyle : {}}
              colors={isCompact
                  ? TAG_COLORS_TABLE
                  : TWITTER_PICKER_DEFAULT_COLORS}
              className="antd-react-color-picker"
              color={backColor}
              onChangeComplete={handleChangeComplete}
          />

        </div> : null}
    {/*</Button>*/}
  </div>);
};

export default AntdReactColor;

