import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'antd';
import {useIntl} from 'react-intl';
import {FormOutlined, StarOutlined} from '@ant-design/icons';
import {translate} from 'utils/react-jarvisly-helper';
import IntlMessage from 'components/util-components/IntlMessage';
import MainTaskTab from './tabs/MainTaskTab.jsx';
import {ModalProvider} from './ProceduresTaskModalProvider';
import {Icon} from 'components/util-components/Icon';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const ProceduresTaskModal = props => {

  // props deconstruction ------------------------------------------------------
  const {showModal, setShowModal, onCloseFn, record} = props;

  // component states -----------------------------------------------------------
  const [state] = useState({
    tabIdx: 'main',
  });

  // local variables ------------------------------------------------------------
  const intl = useIntl();
  const mainTabRef = useRef();
  const alternativeTabRef = useRef();
  const titleIcon = !record
      ? StarOutlined
      : FormOutlined;

  const title = !record
      ? `${translate(intl, 'new_task')}`
      : `${translate(intl, 'edit_task')}`;

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onClose = (event, doc) => {
    setShowModal(false);
    if (onCloseFn && doc) onCloseFn(doc);
  };

  const onSave = () => {

    if (state.tabIdx === 'main') {
      return mainTabRef?.current?.submitForm();

    } else if (state.tabIdx === 'alternative') {
      return alternativeTabRef?.current?.submitForm();
    }
  };

/*
  function onTabClick(key) {

    setState({...state, tabIdx: key});

    switch (key) {

      case 'main':
        mainTabRef?.current?.handleFocus &&
        mainTabRef.current.handleFocus();
        break;

      case 'alternative':
        alternativeTabRef?.current?.handleFocus &&
        alternativeTabRef.current.handleFocus();
        break;

      default:
        break;
    }

  }
*/

  // UI COMPONENT --------------------------------------------------------------

/*
  const tabItems = [
    {
      key: 'main',
      // label: (<span>{setLocale(true, 'task')}</span>),
      children: (<div style={{padding: '4px 24px'}}>

        <MainTaskTab {...props}
                     onClose={onClose}
                     ref={mainTabRef}
        />

      </div>),
    },

  ];
*/

  return (<ModalProvider>

    <Modal
        title={<>
          <Icon type={titleIcon}></Icon>
          <span className="ml-2">
            {title}
          </span>
        </>}
        destroyOnClose={true}
        open={showModal}
        width={640}
        cancelText={setLocale(true, 'cancel')}
        okText={setLocale(true, 'save')}
        onCancel={onClose}
        onOk={onSave}
        okButtonProps={{type: 'primary'}}
        cancelButtonProps={{type: 'default'}}
        getContainer={false}
        className="modal-without-padding">

      {/*<Tabs onChange={onTabClick} items={tabItems} activeKey={state?.tabIdx}/>*/}

      <div style={{padding: "24px 24px 0"}}>
        <MainTaskTab {...props}
                     onClose={onClose}
                     ref={mainTabRef}/>
      </div>

    </Modal>

  </ModalProvider>);

};

// EXPORT **********************************************************************
// *****************************************************************************

export default ProceduresTaskModal;




