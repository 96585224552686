import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Button, Grid, Popconfirm, Row, Table, Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { substitution, getInitials, parsePhoneToDisplay } from 'utils/helpers'
import { FormTitle } from 'views/app-views/portals/autaliza-info/FormComponets'
import moment from 'moment/moment'
import { capitalize } from 'jarvisly-helper'
import ImageDescription from 'components/jarvisly/ImageDescription'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import ResidentModal from '../../portals/autaliza-info/tiles/registration/modals/ResidentModal'
import { pullSubdocumentByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import utils from 'utils'
import IntlMessage from 'components/util-components/IntlMessage'
import SendAtualizaInfoTrigger from '../components/SendAtualizaInfoTrigger'
import DropDownButton from '../components/DropDownButton'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const MovesResidents = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props

  // local variables -----------------------------------------------------------

  // const _atualizaInfo = _dataRoots?.__atualizaInfo
  const isDisabled = formContext.mode === 'view'
  const _atualizaInfo = _dataRoots?.__atualizaInfo
  if (_atualizaInfo) _atualizaInfo.updateSentAt = isDisabled

  const { useBreakpoint } = Grid
  const sm = utils.getBreakPoint(useBreakpoint()).includes('sm')
  const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const intl = useIntl()
  const t = buildTranslations()
  const columns = buildResidentsTableColumn()
  const innerRef = useRef(null)

  // component states ----------------------------------------------------------
  const [showResidentModal, setShowResidentModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState()

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleMenuClick: innerRef?.current?.handleMenuClick,
  }))

  const onAdd = () => {
    setSelectedRecord(null)
    setShowResidentModal(true)
  }

  const onEdit = (record) => {
    setSelectedRecord(record)
    setShowResidentModal(true)
  }

  const onDelete = async _id => {
    setDataContext({ ...dataContext, partialLoading: true })
    await pullSubdocumentByUri('/atualiza-info', _id, 'residents')
    setFormContext({ ...formContext, refreshTime: +new Date() })
  }

  const onCloseFn = (ev, wasSaved) => {
    console.log('onclose function', wasSaved)
    setFormContext({ ...formContext, refreshTime: +new Date() })
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <SendAtualizaInfoTrigger innerRef={innerRef} {...props} _data={_dataRoots}/>

    <ResidentModal {...props}
                   onCloseFn={onCloseFn}
                   _dataRoots={_atualizaInfo}
                   showModal={showResidentModal}
                   setShowModal={setShowResidentModal}
                   record={selectedRecord}/>

    {/* RESIDENTS */}
    <Row justify="space-between">
      <FormTitle title={'dependents_residents'}/>
      <DropDownButton {...props} profile="residents"/>
    </Row>


    <Table rowKey="_id"
           pagination={false}
           columns={columns?.filter(c => !c.hidden) || []}
           dataSource={_data}
           loading={dataContext?.partialLoading}/>

    <Row justify="center" align="middle" className="m-3 mt-4">

      <Button type="primary"
              disabled={isDisabled}
              onClick={onAdd}>
        {setLocale(true, 'add_resident')}
      </Button>

    </Row>
    {/* RESIDENTS */}


  </div>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    const remove_this_item = translate(intl, 'remove_this_item')
    const resident = translate(intl, 'resident')
    const t_remove_this_item = substitution(remove_this_item, ['%ITEM%'], [resident])

    return {
      remove_this_item: t_remove_this_item,
    }
  }

  function buildResidentsTableColumn () {
    return [

      {
        title: setLocale(true, 'name'), render: (_, record) => {

          const age = record?.birthday ? record.birthday && moment().diff(record.birthday, 'years') : null

          const name = age ? `${record?.name}, ${age}` : record?.name
          const subtitle = capitalize(translate(intl, record?.relationship || 'n_a'))

          return (<div className="text-left">
            <ImageDescription
              shape="circle"
              // imageUrl={record?.type === 'motorcycle' ? imgMotorcycle : imgResident}
              title={capitalize(name)}
              initials={getInitials(record?.name, 1)}
              subtitle={subtitle}
              // style={{ padding: 10 }}
              // backColor="#ecf2fe"
              backColor={record?.backColor || '#ecf2fe'}
              foreColor={record?.foreColor || '#ffffff'}
            />
          </div>)
        },
      },

      {
        title: setLocale(true, 'mobile'),
        hidden: !md,
        render: (_, record) => {

          const phone = record?.phone ? parsePhoneToDisplay(record?.phone) : ''

          return (<div className="text-left">
            {phone}
          </div>)
        },
      },

      {
        title: setLocale(true, 'living'),
        hidden: !sm,
        render: (_, record) => (<div className="text-left text-lowercase">
          {record?.entryAt && moment(record?.entryAt).fromNow()}
        </div>),
      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 120, //113
        className: 'text-center',
        render: (_, record) => {
          return (<>
            <Tooltip title={setLocale(true, !isDisabled ? 'edit' : 'view')}>
              <Button size="small"
                // className={!isMobile ? 'mr-2' : 'mb-2'}
                      className="m-2"
                      icon={!isDisabled ? <EditOutlined/> : <EyeOutlined/>}
                      onClick={() => onEdit(record)}/>
            </Tooltip>

            {!isDisabled && <Popconfirm title={t.remove_this_item}
                                        cancelButtonProps={{ className: 'fixed-with-90' }}
                                        okButtonProps={{ className: 'fixed-with-90' }}
                                        onConfirm={() => onDelete(record?._id)}>
              <Button size="small" icon={<DeleteOutlined/>}/>
            </Popconfirm>}
          </>)
        },
      }]
  }
})

// EXPORT **********************************************************************
// *****************************************************************************

export default MovesResidents


