import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Col, Form, Input, Row } from 'antd'
import IntlMessage from 'components/util-components/IntlMessage'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { InputCep, isValidZip } from 'utils/react-jarvisly-helper'
import { returnOnlyNumbers } from 'jarvisly-helper'
import { useModuleForm } from 'components/jarvisly-layouts/ModuleComponent/module-hooks'
import {
  FormAsyncEntityName,
  FormCnpj,
  FormCpf,
  FormDataType,
  FormDatePicker,
  FormEmail,
  FormPhone,
  FormSaveButton,
  FormTitle,
  FormWebsite,
  FormWrapper,
} from 'views/app-views/portals/autaliza-info/FormComponets'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const EntitiesProfile = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------

  let {
    // innerRef,

    // _loading,
    // _module,
    // _router,
    // _autoFill,
    // _id,
    _data,
    // _dataLock,
    // _user,
    // _subscription,
    // _settings,
    // _parameters,

    // dataContext,
    // setDataContext,
    formContext,
    // setFormContext
  } = props

  const isDisabled = false

  // component states ----------------------------------------------------------
  const [address, setAddress] = useState()

  // local variables -----------------------------------------------------------

  const isPerson = _data?.dataType === 'person'
  const [form] = Form.useForm()

  // hooks ---------------------------------------------------------------------

  const {
    // translated,
    elementRefs,
    rules,
    // requiredFields
  } = useModuleForm(form, _data, handleFocus, useImperativeHandle, ref)

  // METHODS -------------------------------------------------------------------

  function handleFocus (field = 'name', cursorPosition = 'all') {
    setTimeout(() => {
      elementRefs[field]?.current?.focus({ cursor: cursorPosition })
    }, 400)
  }

  const onSave = values => {

    const body = { ...values }

    body.uniqueKey = (body.cpf || body.cnpj) ? returnOnlyNumbers(body.cpf || body.cnpj) : null

    if (formContext?._data?.pictureUrl) {
      body.pictureUrl = formContext?._data?.pictureUrl
      body.pictureAttachmentId = formContext?._data?.pictureAttachmentId
    }

    // attachments
    body.attachmentsIds = formContext?._data?.attachmentsIds

    if (address?.addressFoundByApi?._metadata?.foundByApi) {

      const address1 = body?.mainAddress?.address1
      const address2 = body?.mainAddress?.address2
      const number = body?.mainAddress?.number

      body.mainAddress = address?.addressFoundByApi

      // this is needs for cep with multiple address
      body.mainAddress.address1 = address1
      body.mainAddress.address2 = address2
      body.mainAddress.number = number

    } else {
      body['mainAddress.found'] = false
      body['mainAddress._metadata.foundByApi'] = null
      body['mainAddress._metadata.zipWithMultipleAddress'] = false
    }

    return body
  }

  // --------------------------------------------------------
  // CEP METHOD
  // --------------------------------------------------------

  let lastConsumedZip = ''
  let lastZipStatus = ''

  const consumeCep = (event, result) => {

    result.bairro = undefined
    let status

    if (lastConsumedZip === event) {
      setAddress(p => ({ ...p, zipStatus: lastZipStatus }))
      return

    } else if (typeof event === 'string') {
      status = isValidZip(event) ? 'validating' : 'error'

    } else {
      status = isValidZip(result?.cep) ? 'success' : 'error'
    }

    setAddress(p => ({ ...p, zipStatus: status }))

    const value = event?.target?.value

    if (!isValidZip(value)) {
      setAddress(p => ({ ...p, addressFoundByApi: {}, zipStatus: status }))
      return
    }

    lastConsumedZip = value
    lastZipStatus = status

    if (result?.erro) {
      setAddress(p => ({ ...p, addressFoundByApi: {}, zipStatus: status }))
      return
    }

    const zip = returnOnlyNumbers(result.cep)

    const address = {
      found: true,
      _metadata: {
        countryCode: 'br',
        foundByApi: true,
        apiUrl: `https://viacep.com.br/ws/${zip}/json/`,
        zipWithMultipleAddress: !!!result.logradouro,
      }, title: 'Endereço Principal', zip: zip, // '13010120'
      address1: result.logradouro,            // 'Rua do Rocio'
      city: result.localidade,              // 'Campinas'
      province: result.uf,                     // 'SP'
      reference: '', additionalInfo: {
        note: result.complemento,            // '"de 762/763 a 1661/1662"'
        neighborhood: result.bairro,          // 'Centro'
        areaCode: result.ddd,               // '19'
        giaCode: result.gia,                // '2446'     -> Guia de Informação e Apuração do ICMS - GIA
        ibgeCode: result.ibge,              // '3509502'  -> Instituto Brasileiro de Geografia e Estatística
        siafiCode: result.siafi,              // '6291',    -> Sistema Integrado de Administração Financeira
      }, __zip: result.cep,                    // '13010-120'
    }

    const f = form.getFieldsValue()
    const mainAddress = { ...f?.mainAddress, ...address }
    form.setFieldsValue({ mainAddress: mainAddress })

    setAddress(p => ({ ...p, addressFoundByApi: address, zipStatus: status }))
  }
  // --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  const addressFound = !!address?.addressFoundByApi?._metadata?.foundByApi || _data?.mainAddress?.found
  const addressMulti = !!address?.addressFoundByApi?._metadata?.zipWithMultipleAddress

  // ----

  return (<div>

    <FormWrapper {...props} form={form} onSave={onSave} handleFocus={handleFocus}>

      {/* BASIC INFO */}
      <FormTitle title={'basic_info'}/>

      <Row gutter={ROW_GUTTER}>

        {/* ENTITY TYPE */}
        <Col xs={24} sm={8}>
          <FormDataType {...props}/>
          {/*<FormItemDataType {...props}*/}
          {/*                  label={'entity_type'}*/}
          {/*                  elRef={elementRefs.dataType}*/}
          {/*                  rule={rules.dataType}*/}
          {/*                  data={_data}*/}
          {/*                  isDisabled={isDisabled}*/}
          {/*                  formContext={formContext}*/}
          {/*                  _settings={_settings}/>*/}
        </Col>
        {/* ENTITY TYPE */}


        {/* CPF / CNPJ */}
        <Col xs={24} sm={8}>
          {isPerson
            ? <FormCpf {...props} form={form}/>
            : <FormCnpj {...props} form={form}/>}
        </Col>
        {/* CPF / CNPJ */}


        {/* BIRTHDAY / FOUNDATION DATE */}
        <Col xs={24} sm={8}>
          {isPerson
            ? <FormDatePicker {...props} />
            : <FormDatePicker {...props} />}
        </Col>
        {/* BIRTHDAY / FOUNDATION DATE */}


      </Row>
      <Row gutter={ROW_GUTTER}>

        {/* TRADE NAME */}
        <Col xs={24} md={16}>

          <FormAsyncEntityName {...props} form={form}/>

        </Col>
        {/* TRADE NAME */}


        {/* PURCHASES CHANNEL */}

        {/*                <Col xs={24} md={8}>

                    <Form.Item
                        label={setLocale(true, 'purchase_channel')}
                        rules={rules.purchaseChannelId}
                        name="purchaseChannelId">

                        <Select ref={elementRefs.purchaseChannelId}
                                value={_data?.__purchaseChannel}
                                showSearch
                                disabled={isDisabled}
                                filterOption={filterOption}>

                            {_settings?.purchasesChannels &&
                                _settings.purchasesChannels.map(
                                    t =>
                                        <Option key={t._id} value={t._id}>{t.value}</Option>)}
                        </Select>
                    </Form.Item>

                </Col>*/}
        {/* PURCHASES CHANNEL */}


      </Row>


      {/* CONTACTS */}
      <FormTitle title={'contacts'}/>

      <Row gutter={ROW_GUTTER}>


        {/* PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone {...props} form={form}/>
        </Col>
        {/* PHONE */}


        {/* EMAIL */}
        <Col xs={24} sm={8}>
          <FormEmail {...props} form={form}/>
        </Col>
        {/* EMAIL */}


        {/* WEBSITE */}
        <Col xs={24} sm={8}>
          <FormWebsite {...props} form={form}/>
        </Col>
        {/* WEBSITE */}


      </Row>


      {/* ADDRESS */}
      <FormTitle title={'address'}/>

      <Row gutter={ROW_GUTTER}>


        {/* ZIP CODE */}
        <Col xs={24} sm={8} md={6}>
          <Form.Item
            label={setLocale(true, 'zip')}
            name={['mainAddress', '__zip']}
            hasFeedback
            validateStatus={address?.zipStatus}
            rules={rules.zip}
          >
            <InputCep
              ref={elementRefs.zip}
              disabled={isDisabled}
              minLength={9} maxLength={9}
              // name="mainAddress.__zip"
              onChange={consumeCep}
              // className="ant-input"
            />

          </Form.Item>
        </Col>
        {/* ZIP CODE */}

        {/* ADDRESS 1 */}
        <Col xs={24} sm={16} md={18}>
          <Form.Item
            label={setLocale(true, 'address1')}
            name={['mainAddress', 'address1']}
          >
            <Input disabled={isDisabled || (addressFound && !addressMulti)}
              // name="mainAddress.address1"
              // onChange={ev => handleChange(ev)}
            />
          </Form.Item>
        </Col>
        {/* ADDRESS 1 */}


      </Row>


      <Row gutter={ROW_GUTTER}>


        {/* NUMBER */}
        <Col xs={24} sm={5}>
          <Form.Item
            label={setLocale(true, 'number')}
            name={['mainAddress', 'number']}
          >
            <Input disabled={isDisabled}
              // name="mainAddress.number"
              // onChange={ev => handleChange(ev)}
            />
          </Form.Item>
        </Col>
        {/* NUMBER */}


        <Col xs={24} sm={6}>

          <Form.Item
            label={setLocale(true, 'address_2')}
            name={['mainAddress', 'address2']}
          >
            <Input disabled={isDisabled}
              // name="mainAddress.address2"
              // onChange={ev => handleChange(ev)}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={10}>
          <Form.Item
            label={setLocale(true, 'city')}
            name={['mainAddress', 'city']}
          >
            <Input disabled={isDisabled || addressFound}
              // name="mainAddress.city"
              // onChange={ev => handleChange(ev)}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={3}>
          <Form.Item
            label={setLocale(true, 'province')}
            name={['mainAddress', 'province']}
          >
            <Input disabled={isDisabled || addressFound}
              // name="mainAddress.province"
              // onChange={ev => handleChange(ev)}
            />
          </Form.Item>
        </Col>

      </Row>


      {/* SAVE BUTTON */}
      <FormSaveButton {...props}/>
      {/* SAVE BUTTON */}


    </FormWrapper>
  </div>)

})

// EXPORT **********************************************************************
// *****************************************************************************

export default EntitiesProfile



