import React from 'react';
import IntlMessage from 'components/util-components/IntlMessage';
import {Col, Row, Tooltip} from 'antd';
import {Icon} from 'components/util-components/Icon';
import {
  SnippetsOutlined, HourglassOutlined, ClockCircleOutlined, CalendarOutlined,
} from '@ant-design/icons';
import {translate} from 'utils/react-jarvisly-helper';
import {useIntl} from 'react-intl';
import {DataContext} from "../../../../components/jarvisly-layouts/ModuleComponent/ModuleForm/DataProvider";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

export const ProceduresDocumentSubtitle = React.forwardRef((props, ref) => {

  // const {_data, _module} = props;

  return (<>
  </>);

});

// EMAIL AND PHONE
export const ProceduresDocumentHeaderColumn2 = React.forwardRef(
    (props, ref) => {

        const {dataContext} = React.useContext(DataContext);

        const data = dataContext?.data;

      const intl = useIntl();
      const weekdaysArr = data?.weekdays?.map(
          x => translate(intl, x).slice(0, 3)).join(', ');

      return (<>
        <Row style={{display: data?.initialTime ? 'initial' : 'none'}}>
          <Col>
            <Row className="p-1" style={{minHeight: 29}}>
              <Tooltip placement="right" title={setLocale(true, 'at_time')}>
                <Icon type={ClockCircleOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                <span className="ml-2 font-weight-semibold text-truncate">
                    {data?.initialTime} - {data?.finalTime}
                </span>
              </Tooltip>
            </Row>
          </Col>
        </Row>

        <Row style={{display: weekdaysArr ? 'initial' : 'none'}}>
          <Col>
            <Row className="p-1" style={{minHeight: 29}}>
              <Tooltip placement="right" title={setLocale(true, 'weekdays')}>
                <Icon type={CalendarOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                <span className="ml-2 font-weight-semibold text-truncate">
                  {weekdaysArr}
                </span>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </>);

    });

// ADDRESS AND WEBSITE
export const ProceduresDocumentHeaderColumn3 = React.forwardRef(
    (props, ref) => {

        const {dataContext} = React.useContext(DataContext);

        const data = dataContext?.data;

      // const {_data} = props;
      const processQty = data?.process?.filter(
          x => !x.isInterval && !x.__type).length;

      return (<>

        <Row style={{display: processQty ? 'initial' : 'none'}}>
          <Col>
            <Row className="p-1" style={{minHeight: 29}}>
              < Tooltip placement="right" title={setLocale(true,
                  'process_plural')}>
                <Icon type={SnippetsOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                <span className="ml-2 font-weight-semibold text-truncate">
                  {processQty}
                </span>
              </Tooltip>
            </Row>
          </Col>
        </Row>

        <Row style={{display: data?.__workload ? 'initial' : 'none'}}>
          <Col>
            <Row className="p-1" style={{minHeight: 29}}>
              <Tooltip placement="right" title={setLocale(true, 'workload')}>
                <Icon type={HourglassOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                <span className="ml-2 font-weight-semibold text-truncate">
                  {data?.__workload}
                </span>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </>);

    });
