import React from 'react';
import {connect} from 'react-redux';
import {NAV_TYPE_TOP} from 'constants/ThemeConstant';
import utils from 'utils';
import MenuContent from './MenuContent';

export const TopNav = ({topNavColor, localization = true, routeInfo}) => {
  const props = {topNavColor, localization, routeInfo};
  return (
      <div className={`top-nav ${utils.getColorContrast(topNavColor)}`}
           style={{backgroundColor: topNavColor}}>

        <div className="top-nav-wrapper" style={{width: '90%'}}>
          <MenuContent routeInfo={routeInfo}
                       type={NAV_TYPE_TOP}
                       {...props}/>
        </div>

      </div>
  );
};

const mapStateToProps = ({theme}) => {
  const {topNavColor} = theme;
  return {topNavColor};
};

export default connect(mapStateToProps)(TopNav);
