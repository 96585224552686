import React, {
  forwardRef, useCallback, useEffect, useRef, useState,
} from 'react';
import {Button, Col, Row, Table, Tooltip} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  PauseCircleOutlined,
  DeleteOutlined,
  HourglassOutlined,
} from '@ant-design/icons';
import IntlMessage from 'components/util-components/IntlMessage';
import {
  FormContext,
} from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/FormProvider';
import ProceduresProcessModal from '../modals/process/ProceduresProcessModal';
import ImageDescription
  from 'components/jarvisly/ImageDescription';
import {Icon} from 'components/util-components/Icon';
import {
  putDocument,
} from 'components/jarvisly-layouts/ModuleComponent/module-api';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {
  onClickRemoveItem,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import {useIntl} from 'react-intl';
import {reorderProcess} from '../procedures-methods';
import {DataContext} from "../../../../../components/jarvisly-layouts/ModuleComponent/ModuleForm/DataProvider";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

const type = 'DraggableBodyRow';

// COMPONENT *******************************************************************
// *****************************************************************************

const DraggableBodyRow = ({index, moveRow, className, style, ...restProps}) => {

  const ref = useRef(null);

  const [{isOver, dropClassName}, drop] = useDrop({
    accept: type, collect: (monitor) => {

      const {index: dragIndex} = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index
            ? ' drop-over-downward'
            : ' drop-over-upward',
      };
    }, drop: (item) => moveRow(item.index, index),
  });

  const [, drag] = useDrag({
    type, item: () => ({index}), collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (<tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move', ...style,
      }}
      {...restProps}
  />);
};

const ProceduresProcess = forwardRef((props, ref) => {

  const {_loading, _module} = props;
  const {formContext, setFormContext} = React.useContext(FormContext);

  const {dataContext} = React.useContext(DataContext);
  const data = dataContext?.data;

  const isDisabled = formContext.mode === 'view' || _loading;


  // props deconstruction ------------------------------------------------------

  // component states ----------------------------------------------------------
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [process, setProcess] = useState(formContext?.data?.process || []);

  // local variables -----------------------------------------------------------

  const intl = useIntl();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    // temporário durante o desenvolvimento...
    setFormContext({...formContext, mode: 'edit'});
    setProcess(formContext?.data?.process || []);
  }, [formContext?.data]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onUpdated = async document => {

    const dataUpdated = {
      process: document.process, // _metadata: document._metadata,
    };

    const doc = await putDocument(_module, data?._id, dataUpdated);

    setFormContext({
      ...formContext,
      mode: 'edit',
      data: doc,
      docLock: doc,
    });

  };

  const onClickOpen = doc => {
    setSelectedRecord(doc);
    setShowProcessModal(true);
  };

  const onClickAdd = () => {
    setSelectedRecord(null);
    setShowProcessModal(true);
  };

  const onClickRemoveProcess = async record => {

    onClickRemoveItem(record, intl, $done);

    async function $done() {

      setFormContext({...formContext, mode: 'edit', _loading: true});

      const p = process.filter(x => x?._id !== record?._id);
      const processArr = reorderProcess(p);

      await onUpdated({process: processArr});

      /*
            setFormContext({...formContext, _loading: true});
            const doc = await putProcedure(data?._id, 'remove-process',
                {memberId: record?.memberId});

            setFormContext({
              ...formContext,
              mode: 'edit',
              data: doc,
              docLock: doc,
              _loading: false,
            });
      */

    }

  };

  // UI COMPONENT --------------------------------------------------------------

  const columns = [
    {
      title: setLocale(true, 'process'),
      dataIndex: 'order',
      render: (_, record) => (// <Row align={record?.__type ? 'middle' : 'top'}>
          <Row>

            {record?.__type === 'in' ? <>
              <Icon type={ArrowRightOutlined} className="icon-in-list"/>
              <div>
                <strong>{record.name}</strong><br/>
                <span>
                      {setLocale(true, record.description)}
                    </span>
              </div>
            </> : record?.__type === 'out' ? <>
              <Icon type={ArrowLeftOutlined} className="icon-in-list"/>
              <div>
                <strong>{record.name}</strong><br/>
                <span>
                          {setLocale(true, record.description)}
                        </span>
              </div>
            </> : record?.isInterval ? <>
              <Icon type={PauseCircleOutlined} className="icon-in-list"/>
              <div>
                <strong>{record.name}</strong><br/>
                <span>{record.description}</span>
              </div>
            </> : <ImageDescription initials={`${record?.order}ª`}
                                    avatarClassName="mr-2"
                                    shape="circle"
                                    title={record?.name}
                                    size={'middle'}
                                    foreColor="#3e79f7"
                                    backColor="#ecf2fe"
                                    subtitle={record?.description}
            />}

          </Row>),
    }, {
      title: setLocale(true, 'at_time'),
      dataIndex: '__currentTime',
      render: (_, record) => (<>{record?.__currentTime}</>),
    },

    {
      title: setLocale(true, 'time'),
      dataIndex: 'estimatedTime',
      render: (_, record) => (<>{!record.__type && record?.estimatedTime}</>),
    },

    {
      title: setLocale(true, 'edit'),
      dataIndex: 'actions',
      width: 50,
      render: (_, record) => (!record?.__type ?
          <div className="text-right d-flex justify-content-end">

            <Tooltip title={setLocale(true, 'edit')}>
              <Button className="mr-2" icon={<EditOutlined/>}
                      onClick={() => onClickOpen(record)}
                      size="small"/>
            </Tooltip>


            <Tooltip title={setLocale(true, 'remove')}>
              <Button icon={<DeleteOutlined/>}
                      onClick={() => onClickRemoveProcess(
                          record)} size="small"/>
            </Tooltip>


          </div> : null),
    }];

  // -----

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(async (from, to) => {

    setFormContext({...formContext, mode: 'edit', _loading: true});

    const processArr = reorderProcess(process, from, to);
    await onUpdated({process: processArr});

    // change order without result from backend
    // setProcess(
    //     update(process, {
    //       $splice: [
    //         [dragIndex, 1],
    //         [hoverIndex, 0, dragRow],
    //         [hoverIndex, 0, process[from]],
    //       ],
    //     }),
    // );
  }, [process]); // eslint-disable-line react-hooks/exhaustive-deps

  return (<>

    <ProceduresProcessModal procedureId={data?._id}
                            _module={_module}
                            onCloseFn={onUpdated}
                            showModal={showProcessModal}
                            setShowModal={setShowProcessModal}
                            record={selectedRecord}
                            process={process}
    />


    <Row justify="space-between" align="middle">

      <Col>

        {data?.__workload !== '00:00'
            ? <Row align="middle">


              <Tooltip title={setLocale(true, 'workload')}>
                <Icon type={HourglassOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                <span className="ml-2 font-weight-semibold text-truncate">
              {data?.__workload}
            </span>
              </Tooltip>

              {/*</Space>*/}

            </Row>
            : null}
      </Col>

      <Col>
        <Button type="primary"
                icon={<PlusOutlined/>}
                className="mb-3"
                disabled={isDisabled}
                onClick={onClickAdd}>
          &nbsp;{setLocale(true, 'add')}
        </Button>
      </Col>
    </Row>


    <DndProvider backend={HTML5Backend}>


      <Table
          rowKey={'_id' || 'id'}
          className="fix-first-and-last-row"
          columns={columns}
          dataSource={process}
          components={components}
          pagination={false}
          rowClassName={isDisabled ? 'table-disabled-row' : ''}
          onRow={(_, index) => {

            return {
              index, moveRow,
            };

          }}
      />

    </DndProvider>

  </>);

});

export default ProceduresProcess;
