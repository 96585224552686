/* eslint-disable no-unused-vars */
import React from 'react';
import IntlMessage from 'components/util-components/IntlMessage';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// EXPORT TABS *****************************************************************
// *****************************************************************************

export const occurrencesSettingsTabs = (props) => {

  return [
  ];
};


