import React from 'react';
import {
  decorateDocument,
  decorateNewDocument,
  dataProfileValidation,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
} from './procedures-methods';
import ProceduresMainList from './ProceduresMainList';
import ProceduresFrequencyTag from './components/ProceduresFrequencyTag';
import {
  ProceduresDocumentHeaderColumn2,
} from './ProceduresDocumentHeader';
import {
  BookOutlined,
  FieldTimeOutlined,
  NumberOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import ProceduresProfile from './sections/ProceduresProfile';
import {Route} from 'react-router-dom';
import ProceduresProcess from './sections/ProceduresProcess';
import ProceduresTasks from './sections/ProceduresTasks';
import ProceduresMembers from './sections/ProceduresMembers';
import {
  defaultDecorateSettings,
  defaultParseSettings,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_URL = '/app/records/condominium/procedures';
export const PROCEDURES_API_URL = '/procedures';

const INITIAL_FILTER = {
  folder: 'documents',
  dataProfile: 'plan',
};

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
};

const INITIAL_SEARCH = {
  documentCode: 'null',
  name: 'null',
  documentStatus: 'null',
};

const INITIAL_CONTEXT = {
  mode: 'add',
  data: null,
  docLock: null,
  selectedSection: null,
  sectionsChanged: [],
  dataMemory: false,
  hasPicture: false,
};

const METHODS = {
  parseSettings: defaultParseSettings,
  decorateSettings: defaultDecorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: dataProfileValidation,
  putDocumentValidation: dataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,
};

const COMPONENTS = {
  list: props => <ProceduresMainList {...props}/>,
  // listPreviewHeader: props => <ProceduresBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <ProceduresBodyPreviewData {...props}/>,
  // listPreviewActions: props => <ProceduresBodyPreviewActions {...props}/>,

  documentSubtitle: props => <ProceduresFrequencyTag {...props}/>,
  documentHeaderColumn2: props => <ProceduresDocumentHeaderColumn2 {...props}/>,
  // documentHeaderColumn3: props => <ProceduresDocumentHeaderColumn3 {...props}/>,

  // moduleSettings: proceduresSettingsTabs,
};

const DATA_TYPES = [
  {
    key: 'document',  // database value
    label: 'document',
    description: 'procedures_document_description',
    disabled: false,
    checked: true,
  },
];

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <BookOutlined/>,
    label: 'procedure',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_URL}/${_id}/profile`}
                                       render={() =>
                                           <ProceduresProfile ref={ref} {...props} />}/>,
  },
  {
    key: 'process',
    icon: <NumberOutlined/>,
    label: 'ordinals_process_plural',
    route: (props, ref, _id) => <Route key="process"
                                       path={`${MODULE_URL}/${_id}/process`}
                                       render={() =>
                                           <ProceduresProcess ref={ref} {...props} />}/>,
  },
  {
    key: 'tasks',
    icon: <FieldTimeOutlined/>,
    label: 'punctual_tasks',
    route: (props, ref, _id) => <Route key="tasks"
                                       path={`${MODULE_URL}/${_id}/tasks`}
                                       render={() =>
                                           <ProceduresTasks ref={ref} {...props} />}/>,
  },
  {
    key: 'members',
    icon: <TeamOutlined/>,
    label: 'collaborators',
    route: (props, ref, _id) => <Route key="members"
                                       path={`${MODULE_URL}/${_id}/members`}
                                       render={() =>
                                           <ProceduresMembers ref={ref} {...props} />}/>,
  },
  // {
  //   type: 'divider',
  // },
];

/*
const REQUIRED_FIELDS = [
  {
    key: 'name',
    label: 'name',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
];
*/

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_PROCEDURES = {
  root: 'procedures',
  name: 'procedures',
  singular: 'procedure',
  titleField: 'name',
  url: MODULE_URL,
  api: PROCEDURES_API_URL,

  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: [],

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_procedure',
  },

  documentSections: DOCUMENT_SECTIONS,
};

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

export const SETTINGS_SEED = {

  configurations: {
    dataTypes: ['document'],
    dataMemory: false,
  },

  requiredFields: ['name'],
};

