import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Card, Col, Form, Row } from "antd";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { generateHexColor, msg } from "utils/helpers";
import { useIntl } from "react-intl";
import {
  FormInput,
  FormSaveButton,
  FormWrapper,
  FormSelect,
  onFormValuesChange,
  FormInputNumber,
  FormSimpleSwitch,
} from "views/app-views/portals/autaliza-info/FormComponets";
import { translate } from "utils/react-jarvisly-helper";
import AntdReactColor from "components/jarvisly/AntdReactColor";
import {
  buildDevicesOptions,
  getDevicePicture,
  refresh24hGraphicsByChannel,
} from "../../methods";
import IntlMessage from "components/util-components/IntlMessage";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const initialBackColor = "#b9bcc4";
const initialForeColor = "#ffffff";
const initialStateColor = {
  backColor: initialBackColor,
  foreColor: initialForeColor,
};

// COMPONENT *******************************************************************
// *****************************************************************************

const IotProfile = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    _id,
    _module,
    _data,
    _dataLock,
    _dataRoots,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props;

  // component states ----------------------------------------------------------
  const [, setSubmitted] = useState(false);
  const [colors, setColors] = useState(initialStateColor);
  const [selectedDevice, setSelectedDevice] = useState({});

  // local variables -----------------------------------------------------------
  const isDisabled = formContext.mode === "view";
  const formName = "profileForm";
  const [form] = Form.useForm();
  const intl = useIntl();
  // const servicesOptions = buildServicesOptions();
  const devicesOptions = buildDevicesOptions();
  const elRefs = buildElementsRefs(useRef);

  // ant dataPicker workaround to fix issue about date
  // if (_data?.ownerPhone) _data.ownerPhone = parsePhoneToDisplay(_data.ownerPhone)
  // if (_data && _data?.service) _data.service = 'veertical';

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    form.resetFields();

    if (_data?._id) {
      form?.setFieldsValue(_data);

      setColors({
        backColor: _data.backColor,
        foreColor: _data.foreColor,
      });

      // _data.pictureUrl = _data?.device === 'water_level_sensor'
      //   ? imgWaterLevelSensor
      //   : _data?.device === 'temperature_sensor'
      //     ? imgTemperatureSensor
      //     : '';

      _data.pictureUrl = getDevicePicture(_data?.device, intl);
    } else {
      form?.setFieldsValue({ service: "veertical" });

      // workaround because after reset form the tag refs be lost
      setTimeout(() => handleFocus(undefined, "all"));
    }

    onDeviceChanges(_data?.device);
    setDataContext({
      ...dataContext,
      formsChanged: [],
    });
  }, [_data, form, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: (field) => handleFocus(field),
  }));

  function handleFocus(field = "channel", cursorPosition = "all") {
    elRefs[field]?.current?.focus({ cursor: cursorPosition });
  }

  // validate selected device
  const vd = () => form.getFieldValue("device") || _data?.device;

  // device changes
  /*  const onDeviceChanges = value => {
      // if (!dataContext?.data) dataContext.data = {}
      // dataContext.data.pictureUrl = value === 'water_level_sensor'
      //   ? imgWaterTank
      //   : null

      setTimeout(() =>

        _data.pictureUrl = null

        , 3000)
    }*/

  const handleChangeColor = (color) => {
    const foreColor = generateHexColor(color, true);

    setTimeout(() => {
      setColors({
        ...colors,
        backColor: color,
        foreColor: foreColor,
      });
      form.setFieldValue("backColor", color);

      const options = {
        name: formName,
        values: { backColor: color },
        form,
        initialValues: _dataRoots,
        setFormContext,
        formContext,
        setDataContext,
        dataContext,
      };

      onFormValuesChange(options);
    }, 100);
  };

  const onBeforeSave = async (values) => {
    setSubmitted(true);

    const body = { ...values };

    body._id = _data?._id;
    body.backColor = colors?.backColor;
    body.foreColor = colors?.foreColor;

    await refresh24hGraphicsByChannel(_dataLock, body);

    return body;
  };

  const onAfterSave = async (doc) => {
    if (!doc) return;

    if (_id === "add") {
      // reload the page/module
      // router.replace({ pathname: `${_module?.url}/${doc._id}/profile`, state: { isActive: true } })
      setFormContext({
        ...formContext,
        mode: "view",
        dataMemory: null,
      });
    } else {
      // force update the dataContext and refresh all page
      setFormContext({
        ...formContext,
        refreshTime: +new Date(),
        mode: "view",
        dataMemory: null,
      });
    }
  };

  const onError = (error) => {
    if (error?.status === 409) {
      msg("e", translate(intl, "device_already_exists"), "error");
      handleFocus("channel");
    }
  };

  const onDeviceChanges = (value) => {
    const sel = devicesOptions?.find((x) => x?.value === value);
    setSelectedDevice(sel || {});
  };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi={_module.api}
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      formContext={formContext}
      setFormContext={setFormContext}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      onError={onError}
      showToast
    >
      {/* ************************************************************
      IDENTIFICATION
      ************************************************************ */}
      <Card
        className="title-with-switch"
        title={setLocale(true, "identification")}
      >
        <Row gutter={ROW_GUTTER}>
          {/* CHANNEL */}
          <Col xs={24} sm={10}>
            <FormInput
              name="channel"
              elRef={elRefs.channel}
              disabled={isDisabled}
              autoFocus
              required
            />
          </Col>
          {/* CHANNEL */}

          {/* DEVICE */}
          <Col xs={24} sm={14}>
            <FormSelect
              name="device"
              elRef={elRefs.device}
              options={devicesOptions}
              disabled={isDisabled}
              onChange={onDeviceChanges}
              required
            />
          </Col>
          {/* DEVICE */}

          {/* SERVICE */}
          {/* <Col xs={24} sm={8}> */}
          {/*   <FormSelect name="service" */}
          {/*               elRef={elRefs.service} */}
          {/*               options={servicesOptions} */}
          {/*               disabled={isDisabled} */}
          {/*               required/> */}
          {/* </Col> */}
          {/* SERVICE */}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* TITLE */}
          <Col xs={24} sm={8}>
            <FormInput
              name="title"
              elRef={elRefs.title}
              disabled={isDisabled}
              required
            />
          </Col>
          {/* TITLE */}

          {/* DESCRIPTION */}
          <Col xs={24} sm={14}>
            <FormInput
              name="description"
              elRef={elRefs.description}
              disabled={isDisabled}
              required
            />
          </Col>
          {/* DESCRIPTION */}

          {/* COLOR */}
          <Col span={2} style={{ alignSelf: "end" }} className="text-right">
            <Form.Item name="backColor">
              <AntdReactColor
                isCompact={true}
                disabled={isDisabled}
                initialBackColor={colors?.backColor}
                initialForeColor={colors?.foreColor}
                setSelectedColor={handleChangeColor}
              />
            </Form.Item>
          </Col>
          {/* COLOR */}
        </Row>
      </Card>

      {/* ************************************************************
      PARAMETERS
      ************************************************************ */}
      <Card
        className="title-with-switch"
        title={setLocale(true, "sensor_parameters")}
      >
        <Row gutter={ROW_GUTTER}>
          {/* MIN DISTANCE */}
          <Col xs={24} md={8}>
            <FormInputNumber
              name="minDistance"
              label="minimum_distance"
              elRef={elRefs.minDistance}
              disabled={isDisabled}
              decimalSeparator=","
              min={20}
              max={100}
              addonAfter={selectedDevice?.metric || "?"}
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          {/* MIN DISTANCE */}

          {/* MAX DISTANCE */}
          <Col xs={24} md={8}>
            <FormInputNumber
              name="maxDistance"
              label="maximum_distance"
              elRef={elRefs.maxDistance}
              disabled={isDisabled}
              decimalSeparator=","
              min={110}
              max={400}
              addonAfter={selectedDevice?.metric || "?"}
              suffixStyle={{ width: 60 }}
            />
          </Col>
          {/* MAX DISTANCE */}

          {/* CALIBRATION */}
          <Col xs={24} md={8}>
            <FormInputNumber
              name="calibration"
              elRef={elRefs.calibration}
              disabled={isDisabled}
              decimalSeparator=","
              min={-10}
              max={10}
              addonAfter={selectedDevice?.metric || "?"}
              suffixStyle={{ width: 60 }}
            />
          </Col>
          {/* CALIBRATION */}
        </Row>
      </Card>

      {/* ************************************************************
      TANK DIMENSIONS
      ************************************************************ */}
      {vd() === "water_level_sensor" ? (
        <Card
          className="title-with-switch"
          title={setLocale(true, "tank_dimensions")}
        >
          <Row gutter={ROW_GUTTER}>
            {/* DEPTH */}
            <Col xs={24} sm={8}>
              <FormInputNumber
                name="depth"
                elRef={elRefs.depth}
                disabled={isDisabled}
                decimalSeparator=","
                min={0.01}
                max={9900}
                addonAfter={selectedDevice?.metric || "?"}
                suffixStyle={{ width: 60 }}
                required
              />
            </Col>
            {/* DEPTH */}

            {/* WIDTH */}
            <Col xs={24} sm={8}>
              <FormInputNumber
                name="width"
                elRef={elRefs.width}
                disabled={isDisabled}
                decimalSeparator=","
                min={0.01}
                max={9900}
                addonAfter={selectedDevice?.metric || "?"}
                suffixStyle={{ width: 60 }}
                required
              />
            </Col>
            {/* WIDTH */}

            {/* length */}
            <Col xs={24} sm={8}>
              <FormInputNumber
                name="length"
                elRef={elRefs.length}
                disabled={isDisabled}
                decimalSeparator=","
                min={0.01}
                max={9900}
                addonAfter={selectedDevice?.metric || "?"}
                suffixStyle={{ width: 60 }}
                required
              />
            </Col>
            {/* length */}
          </Row>

          <Row>
            {/* NOTE */}
            <Col span={24}>
              <FormInput
                name="note"
                disabled={isDisabled}
                elRef={elRefs.note}
              />
            </Col>
            {/* NOTE */}
          </Row>
        </Card>
      ) : null}

      {/* ************************************************************
      ALARM PARAMS
      ************************************************************ */}

      <Card className="title-with-switch" title={setLocale(true, "alarms")}>
        <Row gutter={ROW_GUTTER}>
          {/* SHORTAGE ALERT */}
          <Col xs={24} md={12} lg={6}>
            <FormInputNumber
              name={"shortageAlert"}
              label="shortage_alert"
              elRef={elRefs.alert}
              disabled={isDisabled}
              decimalSeparator=","
              style={{ width: "100%" }}
              suffixStyle={{ width: 60 }}
              addonAfter="%"
              min={0}
              max={99}
              required
            />
          </Col>
          {/* SHORTAGE ALERT */}

          {/* CRITICAL SHORTAGE */}
          <Col xs={24} md={12} lg={6}>
            <FormInputNumber
              name={"criticalShortage"}
              label="critical_shortage"
              elRef={elRefs.alert}
              disabled={isDisabled}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              addonAfter="%"
              min={0}
              max={99}
              required
            />
          </Col>
          {/* CRITICAL SHORTAGE */}

          {/* EVASION ALERT */}
          <Col xs={24} md={12} lg={6}>
            <FormInputNumber
              name={"evasionAlert"}
              label="evasion_alert"
              elRef={elRefs.alert}
              disabled={isDisabled}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              addonAfter="%"
              min={0}
              max={199}
              required
            />
          </Col>
          {/* EVASION ALERT */}

          {/* CRITICAL EVASION */}
          <Col xs={24} md={12} lg={6}>
            <FormInputNumber
              name={"criticalEvasion"}
              label="critical_evasion"
              elRef={elRefs.alert}
              disabled={isDisabled}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              addonAfter="%"
              min={0}
              max={199}
              required
            />
          </Col>
          {/* CRITICAL EVASION */}
        </Row>
      </Card>

      {/* ************************************************************
      SAVE BUTTON
      ************************************************************ */}
      <FormSaveButton
        loading={dataContext?.partialLoading}
        form={form}
        formName={formName}
        dataContext={dataContext}
        onlySave
        setSubmitted={setSubmitted}
        disabled={isDisabled}
      />
    </FormWrapper>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildElementsRefs(hookUseRef) {
    return {
      // identification
      device: hookUseRef(null),
      channel: hookUseRef(null),
      service: hookUseRef(null),
      title: hookUseRef(null),
      description: hookUseRef(null),
      color: hookUseRef(null),

      // parameters
      calibration: hookUseRef(null),
      minDistance: hookUseRef(null),
      maxDistance: hookUseRef(null),

      // water tank
      width: hookUseRef(null),
      length: hookUseRef(null),
      depth: hookUseRef(null),
      note: hookUseRef(null),
    };
  }

  /*
    function buildServicesOptions () {

      return [
        {
          value: 'veertical',
          text: 'Veertical',
          label: 'Veertical',
          disabled: false,
        },
        {
          value: 'thingspeak',
          text: 'ThingSpeak',
          label: 'ThingSpeak',
          disabled: false,
        },
        {
          value: 'ietecnologia',
          text: 'IE Tecnologia',
          label: 'IE Tecnologia',
          disabled: false,
        }
      ];
    }
  */
});

// EXPORT **********************************************************************
// *****************************************************************************

export default IotProfile;
