import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMongoObjectId, isObjEquals, msg, onProduction } from 'utils/helpers';
import { rdxSetModule, rdxSetModuleQuery, rdxSetModuleSettings, rdxSetModuleParameters } from 'redux/actions/module';
import { buildListQueryString, defaultParseModuleSettings, getModuleByUrl } from './module-methods';
import { useRouter } from 'hooks/useRouter';
import { getDocument, getList, getSettingsById } from './module-api';
import { useIntl } from 'react-intl';
import { translate } from 'utils/react-jarvisly-helper';
import { DataContext } from 'views/app-views/portals/autaliza-info/DataProvider';
import { FormContext } from './ModuleForm/FormProvider';
import { clone } from 'jarvisly-helper';

const forceEdit = false;

// ************************************************************************** //
// HOOKS
// ************************************************************************** //

export const useModule = () => {
  const url = useLocation().pathname;
  return getModuleByUrl(url);
};

export const useModuleForm = _id => {

  const { setDataContext } = React.useContext(DataContext);
  const { formContext } = React.useContext(FormContext);

  const _module = useModule();
  const intl = useIntl();
  const router = useRouter();
  const dispatch = useDispatch();
  // const rdxModuleQuery = useSelector(s => s?.moduleRedux?.rdxModuleQuery || {})
  // const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription || {})
  const document_not_found = `${translate(intl, 'document_not_found')}`;

  const mode = (forceEdit && isMongoObjectId(_id) && !onProduction())
    ? 'edit'
    : (isMongoObjectId(_id)
      ? 'view'
      : _id);

  useEffect(() => {

    let isMounted = true;

    (async () => {
      if (!isMounted) return;

      // loading settings
      const settings = await defaultParseModuleSettings(_module);

      // loading parameters
      const parameters = await buildParameters(_module, settings);

      let result = null;

      if (formContext?.dataMemory) {

        // loading data from dataMemory (duplicate)
        result = {
          data: formContext.dataMemory,
          dataLock: formContext.dataMemory,
        };

      } else {

        // loading data from seed or database
        result = await getModuleDocumentById(_id, _module, settings);
      }

      setDataContext({
        settings: settings,
        parameters: parameters,
        data: result?.data || null,
        dataLock: result?.dataLock || null,
        formsChanged: [],
        fullLoading: false,
        partialLoading: false,
      });

      if ((['view', 'edit'].includes(mode) && !result?.data?._id) || (!result?.data?._id && mode !== 'add')) {
        msg('w', document_not_found);
        router.replace(_module?.url);
        return;
      }

      // save module and query to redux
      dispatch(rdxSetModule(_module));
      dispatch(rdxSetModuleSettings(settings));
      dispatch(rdxSetModuleParameters(parameters));
    })();

    return () => {
      isMounted = false;
    };

  }, [_id, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return mode;

};

/*


export const useModuleForm = (form, _data, handleFocus, useImperativeHandle, ref, elRefs) => {

  const { dataContext } = React.useContext(DataContext)

  // const _data = dataContext?.data // data tem que ser do profile (pode ser um base property diferente)
  const _dataLock = dataContext?.dataLock

  useEffect(() => {

    if (_data?._id) {
      form.setFieldsValue(_data)
    } else {
      form.resetFields()
    }

  }, [_dataLock]) // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
  }))

  return useDocumentForm(dataContext?.settings, useRef, useIntl, elRefs)
}
*/

// ************************************************************************** //
// FUNCTIONS
// ************************************************************************** //

/*export const getSettings = async (dataContext, setDataContext, fModule, fSubscription) => {

  if (!dataContext.module) dataContext.module = fModule
  if (!dataContext.subscription) dataContext.subscription = fSubscription

  const subscriptionId = dataContext.subscription?._id
  const result = await getSettingsById()
  // const result = await getSettingsBySubscriptionId(subscriptionId)

  return await parseModuleSettings(dataContext, setDataContext, result)
}*/

export const buildParameters = async (_module, _settings) => {

  if (_module?.methods?.refreshParameters) {
    return await _module?.methods?.refreshParameters(_module, _settings);
  } else {
    return null;
  }
};

export const getModuleDocumentById = async (_id, module, settings) => {

  if (_id === 'add') {    // new document

    return module?.methods?.decorateNewDocument ? {
      data: { ...module?.methods?.decorateNewDocument(settings, module) },
      dataLock: {
        ...module?.methods?.decorateNewDocument(settings, module),
      },
    } : {
      data: null,
      dataLock: null
    };

  } else if (isMongoObjectId(_id)) {  // open specifc document

    const response = await getDocument(module, _id);
    const docs = (response?.docs && response?.docs?.length > 0 && response?.docs[0]) || response?.doc;

    return {
      // moduleQuery: {
      //   module: module?.name,
      //   searchSummary: response?.summary,
      //   searchResult: response,
      // },
      // summary: response?.summary,
      data: docs ? clone(docs) : null,
      dataLock: docs ? clone(docs) : null,
    };

  } else {
    return null;
  }
};
/*

export const getDocumentById = async (dataContext, setDataContext, _id, fModule, fSettings) => {

  const persist = !!fModule === false

  if (!dataContext.module) dataContext.module = fModule
  if (!dataContext.settings) dataContext.settings = fSettings

  const { module, settings } = dataContext

  if (_id === 'add') {    // new document

    const data = module?.methods?.decorateNewDocument
      ? {
        data: { ...module?.methods?.decorateNewDocument(settings, module) },
        dataLock: { ...module?.methods?.decorateNewDocument(settings, module) },
      }
      : { data: null, dataLock: null }

    if (persist) setDataContext({ ...dataContext, data: data.doc, dataLock: data.dataLock, summary: null })

    return data

  } else if (isMongoObjectId(_id)) {  // open specifc document

    const response = await getDocument(module, _id)

    const data = {
      moduleQuery: {
        module: module?.name,
        searchSummary: response?.summary,
        searchResult: response,
      },
      summary: response?.summary,
      data: (response?.docs && response?.docs?.length > 0 && response?.docs[0]) || response?.doc || 'oef',
      dataLock: response?.dataLock,
    }

    if (persist) setDataContext({ ...dataContext, data: data.doc, dataLock: data.dataLock, summary: data.summary })

    return data

  } else {
    return null
  }
}
*/

export const useDatabaseList = _id => {

  const _module = useModule();

  const initialState = {
    _module: _module,
    _settings: null,
    _data: null,
    _summary: null,
    _loading: true,
  };

  const [state, setState] = useState(initialState);
  const [settings, setSettings] = useState(null);
  const dispatch = useDispatch();
  const router = useRouter();
  const intl = useIntl();
  const rdxModuleQuery = useSelector(s => s?.moduleRedux?.rdxModuleQuery || {});
  const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription || {});
  // const options = {
  //   rdxUser,
  //   rdxSubscription,
  //   subscription: rdxSubscription,
  //   dispatch,
  //   _module,
  //   module: _module,
  // }

  if (rdxSubscription?.marketplace && _module?.name) {
    _module.marketplace = rdxSubscription.marketplace.find(m => m.name === _module.name);
  }

  useEffect(() => {

    let isMounted = true;

    (async () => {
      if (!isMounted) return;

      if (_module?.methods?.refreshParameters) {
        await _module?.methods?.refreshParameters(_module, rdxSubscription);
      }

      // const _settings = await parseModuleSettings(options)
      const _settings = await getSettingsById();
      const parameters = await buildParameters({}, null, _module, _settings);

      setSettings(_settings);
      setState({
        ...initialState,
        _settings
      });

      if (_id === true) {
        await _refresh(null);

      } else if (isMongoObjectId(_id)) {
        await _refresh(_id);

      } else if (_id === 'add') {

        const data = {
          ..._module?.methods?.decorateNewDocument(_settings, intl, _module),
        };

        setState({
          ...state,
          _loading: false,
          _data: data
        });
      }

      dispatch(rdxSetModule(_module));
      dispatch(rdxSetModuleSettings(_settings));
      dispatch(rdxSetModuleParameters(parameters));

    })();

    return () => {
      isMounted = false;
    };

  }, [_module?.name, rdxSubscription?._id, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Refresh data from database according options
   * @param {any} options - cam be a url query string filter, sidenav filter, table search, pagination changes or just a Mongo Object Id
   * @description If options === 'undefined', it will use the _id params from useDatabase Hook
   * */
  async function _refresh (options = _id) {

    setState({
      ...state,
      _loading: true
    });

    const qs = !isMongoObjectId(options) ? buildListQueryString({
      router,
      _module,
      rdxModuleQuery, ...options
    }) : {};
    const response = isMongoObjectId(options) ? await getDocument(_module, options) : options === null || qs.str
      ? await getList(_module, qs.str, qs.apiHeaders)
      : null;

    let moduleQuery = {
      module: _module?.name,
      searchSummary: response?.summary,
      searchResult: response,
    };

    if (!isMongoObjectId(options)) {

      moduleQuery = {
        ...moduleQuery,
        currentQuery: qs.currentQuery,
        defaultQuery: qs.defaultQuery,
        defaultFilter: qs.defaultFilter,
        currentFilter: qs.currentFilter,
        isFilterChanged: !isObjEquals(qs.defaultFilter, qs.currentFilter),
        defaultSearch: qs.defaultSearch,
        currentSearch: qs.currentSearch,
        isSearchChanged: !isObjEquals(qs.defaultSearch, qs.currentSearch),
      };
    }

    dispatch(rdxSetModuleQuery({ ...rdxModuleQuery, ...moduleQuery }));

    setState({
      ...state,
      _module: _module,
      _loading: false,
      _data: response?.docs || response?.doc || null,
      _summary: response?.summary,
    });
  }

  return {
    ...state,
    _settings: settings,
    _module: _module,
    _refresh
  };
};

// ******************************************************************************** //
// *********************** EXPIRIENCES
// ******************************************************************************** //

/*
export const useDataProviderList = qs => {

    const {dataContext, setDataContext} = React.useContext(DataContext);
    const _module = useModule();
    const dispatch = useDispatch();
    const router = useRouter();
    const intl = useIntl();
    const rdxUser = useSelector(s => s?.auth?.rdxUser || {});
    const rdxModuleQuery = useSelector(s => s?.moduleRedux?.rdxModuleQuery || {});
    const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription || {});
    const options = {rdxUser, rdxSubscription, dispatch, _module};


    useEffect(() => {

        let isMounted = true;

        (async () => {
            if (!isMounted) return;


            // loading settings
            const settings = await getSettings(dataContext, _module, rdxSubscription)

            // loading parameters
            const parameters = await getParameters(dataContext, _module, rdxSubscription)

            // loading data
            const data = getDocumentByQs(qs, dataContext, _module, settings)

            setDataContext({
                ...dataContext,
                module: _module,
                subscription: rdxSubscription,

                settings: settings,
                parameters: parameters,
                data: data?.docs || null,
                summary: data?.summary || null,
                loading: false,
            })

            // save module and query to redux
            dispatch(rdxSetModule(_module));
            dispatch(rdxSetModuleQuery({...rdxModuleQuery, ...data?.moduleQuery}));
        })();

        return () => {
            isMounted = false;
        };

    }, [_module?.name, rdxSubscription?._id]); // eslint-disable-line react-hooks/exhaustive-deps


    // INTERNAL FUNCTIONS
// ======================================================================== //


};
*/

/*
export const getDocumentByQs = async (qs = {}, dataContext, fModule, fSettings) => {

    if (!dataContext.module) dataContext.module = fModule
    if (!dataContext.settings) dataContext.settings = fSettings

    const {module, settings} = dataContext
    const response = await getList(module, qs.str, qs?.apiHeaders)

    return {
        moduleQuery: {
            module: module?.name,
            searchSummary: response?.summary,
            searchResult: response,

            currentQuery: qs.currentQuery,
            defaultQuery: qs.defaultQuery,
            defaultFilter: qs.defaultFilter,
            currentFilter: qs.currentFilter,
            isFilterChanged: !isObjEquals(qs.defaultFilter, qs.currentFilter),
            defaultSearch: qs.defaultSearch,
            currentSearch: qs.currentSearch,
            isSearchChanged: !isObjEquals(qs.defaultSearch, qs.currentSearch),
        },
        summary: response?.summary,
        docs: response?.docs || 'eof'
    }
}
*/

/*
export const useDatabaseList = _id => {

    const _module = useModule();


    const initialState = {
        _module: _module,
        _settings: null,
        _data: null,
        _summary: null,
        _loading: true,
    };

    const [state, setState] = useState(initialState);
    const [settings, setSettings] = useState(null);
    const dispatch = useDispatch();
    const router = useRouter();
    const intl = useIntl();
    const rdxUser = useSelector(s => s?.auth?.rdxUser || {});
    const rdxModuleQuery = useSelector(s => s?.moduleRedux?.rdxModuleQuery || {});
    const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription || {});
    const options = {rdxUser, rdxSubscription, dispatch, _module: _module};

    if (rdxSubscription?.marketplace && _module?.name) {
        _module.marketplace = rdxSubscription.marketplace.find(
            m => m.name === _module.name);
    }

    useEffect(() => {

        let isMounted = true;

        (async () => {
            if (!isMounted) return;

            if (_module?.methods?.refreshParameters) {
                await _module?.methods?.refreshParameters(_module, rdxSubscription)
            }

            const _settings = await parseModuleSettings(options);

            setSettings(_settings);
            setState({...initialState, _settings});

            if (_id === true) {
                await _refresh(null);

            } else if (isMongoObjectId(_id)) {
                await _refresh(_id);

            } else if (_id === 'add') {

                const data = {
                    ..._module?.methods?.decorateNewDocument(_settings, intl, _module),
                };

                setState({...state, _loading: false, _data: data});
            }

            dispatch(rdxSetModule(_module));

        })();

        return () => {
            isMounted = false;
        };

    }, [_module?.name, rdxSubscription?._id, _id]); // eslint-disable-line react-hooks/exhaustive-deps

    /!**
     * Refresh data from database according options
     * @param {any} options - cam be a url query string filter, sidenav filter, table search, pagination changes or just a Mongo Object Id
     * @description If options === 'undefined', it will use the _id params from useDatabase Hook
     * *!/
    async function _refresh(options = _id) {

        setState({...state, _loading: true});

        const qs = !isMongoObjectId(options)
            ? buildListQueryString({router, _module, rdxModuleQuery, ...options})
            : {};

        const response = isMongoObjectId(options) ?
            await getDocument(_module, options)
            : options === null || qs.str
                ? await getList(_module, qs.str, qs.apiHeaders)
                : null;

        let moduleQuery = {
            module: _module?.name,
            searchSummary: response?.summary,
            searchResult: response,
        };

        if (!isMongoObjectId(options)) {
            moduleQuery = {
                ...moduleQuery,
                currentQuery: qs.currentQuery,
                defaultQuery: qs.defaultQuery,
                defaultFilter: qs.defaultFilter,
                currentFilter: qs.currentFilter,
                isFilterChanged: !isObjEquals(qs.defaultFilter, qs.currentFilter),
                defaultSearch: qs.defaultSearch,
                currentSearch: qs.currentSearch,
                isSearchChanged: !isObjEquals(qs.defaultSearch, qs.currentSearch),
            };
        }

        dispatch(rdxSetModuleQuery({...rdxModuleQuery, ...moduleQuery}));

        setState({
            ...state,
            _module: _module,
            _loading: false,
            _data: response?.docs || response?.doc || 'eof',
            _summary: response?.summary,
        });
    }

    return {...state, _settings: settings, _module: _module, _refresh};
};
*/

export const useRefreshRecord = (form, record) => {
  useEffect(() => {
    if (record) form?.setFieldsValue(record);
  }, [record, form]);  // eslint-disable-line react-hooks/exhaustive-deps

};

export const useFocus = (run, elRef, timeout = 200, selected = { cursor: 'all' }) => {
  useEffect(() => {
    setTimeout(() => elRef?.current?.focus && elRef.current.focus(selected), timeout);
  }, [run]); // eslint-disable-line react-hooks/exhaustive-deps
};

