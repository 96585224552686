import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { Col, Form, Row } from 'antd'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { parseDateToDisplay, parsePhoneToDisplay, msg, parsePhoneToDB } from 'utils/helpers'
import {
  FormDatePicker,
  FormEmail,
  FormInput,
  FormPhone,
  FormSaveButton,
  FormSelect,
  FormSelectApartment,
  FormTitle,
  FormWrapper,
} from 'views/app-views/portals/autaliza-info/FormComponets'
import moment from 'moment/moment'
import { postByUri } from '../../../../components/jarvisly-layouts/ModuleComponent/module-api'
import SendAtualizaInfoTrigger from '../components/SendAtualizaInfoTrigger'

// COMPONENT *******************************************************************
// *****************************************************************************

const MovesProfile = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _module,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,

    _parameters,
  } = props

  // local variables -----------------------------------------------------------

  const isDisabled = formContext.mode === 'view'
  const formName = 'profileForm'
  const [form] = Form.useForm()
  const intl = useIntl()
  const elRefs = buildElementsRefs(useRef)
  const typesOptions = buildTypesOptions()
  const periodsOptions = buildPeriodOptions()
  const innerRef = useRef(null)

  // ant dataPicker workaround to fix issue about date
  if (_data?.moveAt) _data.moveAt = parseDateToDisplay(_data.moveAt)
  if (_data?.phone) _data.phone = parsePhoneToDisplay(_data.phone)

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (_data?._id) {
      form.setFieldsValue(_data)
      form?.validateFields(['phone', 'email']).then()

    } else {
      form.resetFields()
      // workaround because after reset form the tag refs be lost
      setTimeout(() => handleFocus(undefined, 'all'))
    }

    setDataContext({ ...dataContext, formsChanged: [] })

  }, [_data, form]) // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
    handleMenuClick: innerRef?.current?.handleMenuClick,
  }))

  function handleFocus (field = 'apartmentId', cursorPosition = 'all') {
    elRefs?.[field]?.current?.focus({ cursor: cursorPosition })
  }

  const onApartmentChanges = apartmentId => {

    const selApartment = _parameters?.apartments?.find(a => a._id === apartmentId)
    // setSelectedApartment(selApartment)

    if (selApartment?.occupedBy) {
      form.setFieldValue('type', 'exit')

    } else {
      form.setFieldValue('type', 'entrance')
    }
  }


  const onBeforeSave = async values => {

    const selApartment = _parameters?.apartments?.find(a => a._id === values?.apartmentId)
    const body = { ...values }

    body.blockId = selApartment?.blockId
    body.uniqueKey = selApartment?.uniqueKey
    body.roomNumber = selApartment?.roomNumber

    if (!_data?.atualizaInfoId) {

      const atualizaInfoBody = {
        updateRequiredAt: moment(),
        updateSentAt: null,
        updateSentBy: null,
        apartmentId: selApartment._id,
        description: `${selApartment.__apartment} | ${selApartment.__block.name}`,
        name: body.name,
        phone: parsePhoneToDB(body.phone),
        email: body.email,
      }

      const doc = await postByUri('/atualiza-info', atualizaInfoBody)
      body.atualizaInfoId = doc?._id
    }

    console.log('save body....', body)

    return body
  }

  const onError = error => {
    if (error?.status === 409) {
      msg('e', translate(intl, 'move_in_progress_already_exists'), 'error')
      handleFocus('apartmentId')
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <SendAtualizaInfoTrigger innerRef={innerRef} {...props}/>

    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi={_module.api}
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      onBeforeSave={onBeforeSave}
      onError={onError}
      formContext={formContext}
      setFormContext={setFormContext}
      showToast>


      {/* BASIC INFO */}
      <FormTitle title={'basic_info'}/>

      <Row gutter={ROW_GUTTER}>

        {/* APARTMENT */}
        <Col xs={24} md={8}>
          <FormSelectApartment _parameters={_parameters || {}}
                               elRef={elRefs.apartmentId}
                               disabled={isDisabled}
                               onChange={onApartmentChanges}
                               required/>
        </Col>
        {/* APARTMENT */}


        {/* TYPE */}
        <Col xs={24} md={4}>
          <FormSelect name="type"
                      elRef={elRefs.type}
                      options={typesOptions}
                      disabled={true}
                      required/>
        </Col>
        {/* TYPE */}


        {/* DATE */}
        <Col xs={24} md={6}>
          <FormDatePicker name="moveAt"
                          label="move_date"
                          disabled={isDisabled}
                          required
                          elRef={elRefs.moveAt}/>
        </Col>
        {/* DATE */}


        {/* PERIOD */}
        <Col xs={24} md={6}>
          <FormSelect name="period"
                      elRef={elRefs.period}
                      options={periodsOptions}
                      disabled={isDisabled}
                      required/>
        </Col>
        {/* PERIOD */}

      </Row>


      {/* RESPONSIBLE */}
      <FormTitle title={'responsible'}/>

      <Row gutter={ROW_GUTTER}>

        {/* NAME */}
        <Col xs={24} md={8}>
          <FormInput name="name"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.name}/>
        </Col>
        {/* NAME */}


        {/* PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone form={form}
                     disabled={isDisabled}
                     required
                     elRef={elRefs.phone}/>
        </Col>
        {/* PHONE */}


        {/* EMAIL */}
        <Col xs={24} sm={8}>
          <FormEmail form={form}
                     disabled={isDisabled}
                     elRef={elRefs.email}/>
        </Col>
        {/* EMAIL */}

      </Row>
      {/* RESPONSIBLE */}


      {/* SAVE BUTTON */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      disabled={isDisabled}/>
      {/* SAVE BUTTON */}

    </FormWrapper>

  </div>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildElementsRefs (hookUseRef) {
    return {
      apartmentId: hookUseRef(null),
      type: hookUseRef(null),

      moveAt: hookUseRef(null),
      period: hookUseRef(null),

      name: hookUseRef(null),
      phone: hookUseRef(null),
      email: hookUseRef(null),
    }
  }

  function buildPeriodOptions () {

    const list = ['morning', 'afternoon', 'night', 'dawn']

    return list.map(i => {
      return {
        value: i,
        text: translate(intl, i),
        label: translate(intl, i),
        disabled: false,
      }
    })

  }

  function buildTypesOptions () {

    const list = ['entrance', 'exit']

    return list.map(i => {
      return {
        value: i,
        text: translate(intl, i),
        label: translate(intl, i),
        disabled: false,
      }
    })
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default MovesProfile


