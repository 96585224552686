import React from 'react'
import {
  decorateDocument,
  decorateNewDocument,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
  parseSettings,
  getPetModuleParameters,
} from './pets-methods'
import PetsMainList from './PetsMainList'
import { CarOutlined } from '@ant-design/icons'
import { Route } from 'react-router-dom'
import {
  defaultDecorateSettings,
  defaultDataProfileValidation,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import PetsProfile from './sections/PetsProfile'
import {
  PetsDocumentHeaderColumn2,
  PetsDocumentHeaderColumn3,
  PetsDocumentSubtitle,
} from './PetsDocumentHeader'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_PETS_URL = '/app/records/condominium/pets'
export const PETS_API_URL = '/pets'
export const PETS_API_URL_V2 = '/v2/pets'

const INITIAL_REQUEST = {
  filters: {
    colors: ['black', 'white'],
  },
  search: {
    name: 'rick',
    age: 46,
  },
  sort: {
    name: 1,
    age: -1,
  },
  projection: {
    name: 1,
    age: 1,
    city: 1,
  },
  pagination: {
    page: 1,
    size: 10,
  },
}

const INITIAL_FILTER = {
  folder: 'documents',
}

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
}

const INITIAL_SEARCH = {
  name: 'null', // main driver
  roomNumber: 'null',
  blockId: 'null',
  documentStatus: 'null',
}

const INITIAL_CONTEXT = {
  hasPicture: true,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
}

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,

  refreshParameters: getPetModuleParameters,
}

const COMPONENTS = {
  list: props => <PetsMainList {...props}/>,
  // tableListV2: props => <PetsMainListV2 {...props}/>,
  // listPreviewHeader: props => <PetsBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <PetsBodyPreviewData {...props}/>,
  // listPreviewActions: props => <PetsBodyPreviewActions {...props}/>,

  documentSubtitle: props => <PetsDocumentSubtitle {...props}/>,
  documentHeaderColumn2: props => <PetsDocumentHeaderColumn2 {...props}/>,
  documentHeaderColumn3: props => <PetsDocumentHeaderColumn3 {...props}/>,

  // moduleSettings: props => <petsSettingsTabs {...props}/>,
  // moduleSettings: petsSettingsTabs(),
}

const DATA_TYPES = [
  // {
  //   key: 'document',  // database value
  //   label: 'document',
  //   description: 'document_description',
  //   disabled: true,
  //   checked: true,
  // },
]

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <CarOutlined/>,
    label: 'pet',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_PETS_URL}/${_id}/profile`}
                                       render={() =>
                                         <PetsProfile ref={ref} {...props}
                                                       _data={props._dataRoots}
                                                       _dataLock={props._dataRootsLock}/>}/>,
  },
]

const REQUIRED_FIELDS = []

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {

  configurations: {},

  requiredFields: [],
}

const AUTO_FILL_PETS = {}

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_PETS_PARAMETERS = {

  root: 'pets',
  name: 'pets',
  singular: 'pet',
  titleField: 'model',
  url: MODULE_PETS_URL,
  api: PETS_API_URL,
  apiV2: PETS_API_URL_V2,
  initialRequest: INITIAL_REQUEST,

  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,
  documentActions: {
    archive: false,
    duplicate: false,
  },

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_pet',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: AUTO_FILL_PETS,
  },

}


