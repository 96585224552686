import React from 'react'
import { Col, Row } from 'antd'
import { Icon } from 'components/util-components/Icon'
import {
  GlobalOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import { parsePhoneToDisplay } from 'utils/helpers'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

export const LeadsDocumentSubtitle = React.forwardRef((props, ref) => {

  // const {_data, _module} = props;

  return (<>
  </>)

})

// EMAIL AND PHONE
export const LeadsDocumentHeaderColumn2 = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const _dataLock = dataContext?.dataLock

  return (<>

    <Row style={{ display: _dataLock?.email ? 'initial' : 'none' }}>

      <Col>

        {_dataLock?.email &&
          // <Tooltip placement="left" title={setLocale(true, 'email')}>

          <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
            <Icon type={MailOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
            <div className="ml-2 font-weight-semibold text-truncate" style={{ flex: 1 }}>
              {_dataLock?.email}
            </div>
          </Row>

          // </Tooltip>
        }

      </Col>

    </Row>


    <Row style={{ display: _dataLock?.phone ? 'initial' : 'none' }}>

      <Col>

        {_dataLock?.phone &&
          // <Tooltip placement="left" title={setLocale(true, 'phone')}>

          <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
            <Icon type={PhoneOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
            <div className="ml-2 font-weight-semibold text-truncate" style={{ flex: 1 }}>
              {parsePhoneToDisplay(_dataLock?.phone)}
            </div>
          </Row>

          // </Tooltip>
        }

      </Col>

    </Row>


  </>)

})

// ADDRESS AND WEBSITE
export const LeadsDocumentHeaderColumn3 = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const _dataLock = dataContext?.dataLock

  return (<>

    <Row style={{ display: _dataLock?.__city ? 'initial' : 'none' }}>

      <Col>

        {_dataLock?.__city &&
          // <Tooltip placement="left" title={setLocale(true, 'city')}>

          <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
            <Icon type={HomeOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
            <div className="ml-2 font-weight-semibold text-truncate" style={{ flex: 1 }}>
              {_dataLock?.__city}
            </div>
          </Row>

          // </Tooltip>
        }

      </Col>

    </Row>


    <Row style={{ display: _dataLock?.website ? 'initial' : 'none' }}>

      <Col>

        {_dataLock?.website &&
          // <Tooltip placement="left" title={setLocale(true, 'website')}>

          <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
            <Icon type={GlobalOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
            <div className="ml-2 font-weight-semibold text-truncate" style={{ flex: 1 }}>
              {_dataLock?.website}
            </div>
          </Row>

          // </Tooltip>
        }

      </Col>

    </Row>
  </>)

})

