import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { Col, Form, Row } from 'antd'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { parsePhoneToDisplay, parsePhoneToDB } from 'utils/helpers'
import {
  FormInput,
  FormPhone,
  FormSaveButton,
  FormSelect,
  FormSelectRelationship,
  FormTitle,
  FormWrapper,
} from 'views/app-views/portals/autaliza-info/FormComponets'
import SendAtualizaInfoTrigger from '../components/SendAtualizaInfoTrigger'
import DropDownButton from '../components/DropDownButton'

// COMPONENT *******************************************************************
// *****************************************************************************

const MovesHabitation = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props

  // local variables -----------------------------------------------------------

  const _atualizaInfo = _dataRoots?.__atualizaInfo
  const isDisabled = formContext.mode === 'view'

  const formName = 'habitationForm'
  const [form] = Form.useForm()
  const intl = useIntl()
  const elRefs = buildElementsRefs(useRef)
  const profileOptions = buildProfileOptions()
  const contractOptions = buildContractOptions()
  const innerRef = useRef(null)

  // ant dataPicker workaround to fix issue about date
  if (_data?.ownerPhone) _data.ownerPhone = parsePhoneToDisplay(_data.ownerPhone)
  if (_data?.realEstateAgentPhone) _data.realEstateAgentPhone = parsePhoneToDisplay(_data.realEstateAgentPhone)
  if (_data?.renterPhone) _data.renterPhone = parsePhoneToDisplay(_data.renterPhone)

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    setDataContext({ ...dataContext, formsChanged: [] })
    if (_data) {
      form?.setFieldsValue(_data)
      form?.validateFields(['ownerPhone', 'realEstateAgentPhone', 'renterPhone']).then()
    }
  }, [_data, form]) // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
    handleMenuClick: innerRef?.current?.handleMenuClick,
  }))

  function handleFocus (field = 'profile', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  // validate profile
  const vp = () => {

    const value = form.getFieldValue('profile') || _data?.profile

    let profile

    switch (value) {

      case 'owner':
        profile = 1
        break

      case 'tenant':
        profile = 2
        break

      case 'non_tenant_resident':
        profile = 3
        break

      default:
        profile = 0
    }

    return profile
  }

  // validate contract
  const vc = () => {

    let contract

    const value = form.getFieldValue('contract') || _data?.contract

    switch (value) {
      case 'rental_through_agency':
        contract = 1
        break

      case 'rental_direct_owner':
        contract = 2
        break

      default:
        contract = 0
    }

    return contract
  }

  const onBeforeSave = values => {

    const data = { ...values }

    // format data to persint in database
    if (data?.ownerPhone) data.ownerPhone = parsePhoneToDB(data.ownerPhone)
    if (data?.realEstateAgentPhone) data.realEstateAgentPhone = parsePhoneToDB(data.realEstateAgentPhone)
    if (data?.renterPhone) data.renterPhone = parsePhoneToDB(data.renterPhone)

    const body = {
      _id: _atualizaInfo._id,
      habitation: data,
    }

    if (_atualizaInfo.currentStep <= 1) body.currentStep = 2
    return body
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <SendAtualizaInfoTrigger innerRef={innerRef}{...props} _data={_dataRoots}/>


    {/* HABITATION */}
    <FormWrapper name={formName}
                 form={form}
                 elRefs={elRefs}
                 uriApi="/atualiza-info"
                 initialValues={_data}
                 dataContext={dataContext}
                 setDataContext={setDataContext}
                 onBeforeSave={onBeforeSave}
                 formContext={formContext}
                 setFormContext={setFormContext}
                 showToast>

      {/* BASIC INFO */}
      <Row justify="space-between">
        <FormTitle title={'habitation'}/>
        <DropDownButton {...props} profile="habitation"/>
      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* PROFILE */}
        <Col xs={24} md={12}>
          <FormSelect name="profile"
                      elRef={elRefs.profile}
                      options={profileOptions}
                      onChange={vp}
                      disabled={isDisabled}
                      required/>
        </Col>
        {/* PROFILE */}


        {/* CONTRACT */}
        {vp() > 1 ? <Col xs={24} md={12}>
          <FormSelect name="contract"
                      elRef={elRefs.contract}
                      options={contractOptions}
                      onChange={vc}
                      disabled={isDisabled}
                      required/>
        </Col> : null}
        {/* CONTRACT */}


      </Row>


      {/* RENTER DATA */}
      {vp() === 3 && vc() >= 1 ? <Row gutter={ROW_GUTTER}>


        {/* RENTER NAME */}
        <Col xs={24} md={8}>
          <FormInput name="renterName"
                     label="renter_name"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.renterName}/>
        </Col>
        {/* RENTER NAME */}


        {/* RENTER PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone name="renterPhone"
                     label="phone"
                     form={form}
                     disabled={isDisabled}
                     required
                     elRef={elRefs.renterPhone}/>
        </Col>
        {/* RENTER PHONE */}


        {/* RENTER RELATIONSHIP */}
        <Col xs={24} md={8}>
          <FormSelectRelationship name="renterRelationship"
                                  label="relationship"
                                  elRef={elRefs.renterRelationship}
                                  disabled={isDisabled}
                                  required/>
        </Col>
        {/* RENTER RELATIONSHIP */}


      </Row> : null}
      {/* RENTER DATA */}


      {/* AGENT DATA */}
      {vp() > 1 && vc() === 1 ? <Row gutter={ROW_GUTTER}>


        {/* AGENT NAME */}
        <Col xs={24} md={8}>
          <FormInput name="realEstateAgentName"
                     label="agent_name"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.realEstateAgentName}/>
        </Col>
        {/* AGENT NAME */}


        {/* AGENT PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone name="realEstateAgentPhone"
                     label="phone"
                     form={form}
                     disabled={isDisabled}
                     required
                     elRef={elRefs.realEstateAgentPhone}/>
        </Col>
        {/* AGENT PHONE */}


        {/* REAL ESTATE */}
        <Col xs={24} md={8}>
          <FormInput name="realEstateCompanyName"
                     label="real_estate_name"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.realEstateCompanyName}/>
        </Col>
        {/* REAL ESTATE */}


      </Row> : null}
      {/* AGENT DATA */}


      {/* OWNER DATA */}
      {vp() > 1 && vc() === 2 ? <Row gutter={ROW_GUTTER}>


        {/* OWNER NAME */}
        <Col xs={24} md={8}>
          <FormInput name="ownerName"
                     label="owner_name"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.ownerName}/>
        </Col>
        {/* OWNER NAME */}


        {/* OWNER PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone name="ownerPhone"
                     label="phone"
                     form={form}
                     disabled={isDisabled}
                     required
                     elRef={elRefs.ownerPhone}/>
        </Col>
        {/* OWNER PHONE */}


        {/* OWNER RELATIONSHIP */}
        <Col xs={24} md={8}>
          <FormSelectRelationship name="ownerRelationship"
                                  label="relationship"
                                  elRef={elRefs.ownerRelationship}
                                  disabled={isDisabled}
                                  required/>
        </Col>
        {/* OWNER RELATIONSHIP */}


      </Row> : null}
      {/* OWNER DATA */}


      <Row gutter={ROW_GUTTER}>

        {/* NOTE */}
        <Col xs={24}>
          <FormInput name="note"
                     label="observation"
                     disabled={isDisabled}
                     elRef={elRefs.note}/>
        </Col>
        {/* NOTE */}

      </Row>

      {/* SAVE BUTTON */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      disabled={isDisabled}/>
      {/* SAVE BUTTON */}


    </FormWrapper>
    {/* HABITATION */}


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildProfileOptions () {

    const t = {
      owner: translate(intl, 'owner'),
      tenant: translate(intl, 'tenant'),
      non_tenant_resident: translate(intl, 'non_tenant_resident'),
    }

    return [
      {
        value: 'owner', text: t.owner, label: t.owner, disabled: false,
      }, {
        value: 'tenant', text: t.tenant, label: t.tenant, disabled: false,
      }, {
        value: 'non_tenant_resident', text: t.non_tenant_resident, label: t.non_tenant_resident, disabled: false,
      }]
  }

  function buildContractOptions () {

    const t = {
      rental_through_agency: translate(intl, 'rental_through_agency'),
      rental_direct_owner: translate(intl, 'rental_direct_owner'),
    }

    return [
      {
        value: 'rental_through_agency', text: t.rental_through_agency, label: t.rental_through_agency, disabled: false,
      }, {
        value: 'rental_direct_owner', text: t.rental_direct_owner, label: t.rental_direct_owner, disabled: false,
      }]
  }

  function buildElementsRefs (hookUseRef) {
    return {
      profile: hookUseRef(null),
      contract: hookUseRef(null),

      renterName: hookUseRef(null),
      renterPhone: hookUseRef(null),
      renterRelationship: hookUseRef(null),

      realEstateAgentName: hookUseRef(null),
      realEstateAgentPhone: hookUseRef(null),
      realEstateCompanyName: hookUseRef(null),

      ownerName: hookUseRef(null),
      ownerPhone: hookUseRef(null),
      ownerRelationship: hookUseRef(null),

      note: hookUseRef(null),
    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default MovesHabitation


