import axios from "axios";
import { APP_HEADERS } from "./AppConfig";
import { THEME_CONFIG } from "configs/AppConfig";
import { getMyDomain, isMongoObjectId, objToStrNotation } from "utils/helpers";
import { clone } from "jarvisly-helper";

const backendUrl = THEME_CONFIG.APP.DEV_MODE
  ? "http://localhost:8000"
  : THEME_CONFIG.APP.API_ENDPOINT_URL; // = 'https://jarvisly-backend.herokuapp.com';

// let backendUrl = THEME_CONFIG.APP.API_ENDPOINT_URL;

const apiConfig = axios.create({
  baseURL: backendUrl,
  headers: APP_HEADERS,
});

// GET
export const get = async (url, config, returnRoot) => {
  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  return await apiConfig
    .get(url, config)
    .then(async (response) => {
      const current = response?.data?.summary?.pagination?.current;
      const total = response?.data?.summary?.pagination?.totalPages;

      if (current > total && url.includes(`current=${current}`)) {
        url = url.replace(`current=${current}`, `current=${total}`);
        return await get(url, config, returnRoot);
      }

      if (returnRoot) {
        return response?.data;
      } else {
        return response?.data?.doc || response?.data?.docs;
      }
    })
    .catch((err) => {
      console.error("Backend Error: GET:", url, err);
      throw err?.response;
    });
};

// POST
export const post = async (url, body, config, returnRoot) => {
  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  return await apiConfig
    .post(url, body, config)
    .then((response) => {
      if (returnRoot) {
        return response?.data;
      } else {
        return response?.data?.doc || response?.data?.docs;
      }
    })
    .catch((err) => {
      console.error("Backend Error: POST:", url, err);
      throw err?.response;
    });
};

// PUT
export const put = async (url, body, config, returnRoot, ignoreNotation) => {
  const _body = ignoreNotation ? body : objToStrNotation(body); // normalize mongo sub documents

  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  return await apiConfig
    .put(url, _body, config)
    .then((response) => {
      if (returnRoot) {
        return response?.data;
      } else {
        return response?.data?.doc || response?.data?.docs;
      }
    })
    .catch((err) => {
      console.error("Backend Error: PUT:", url, err);
      throw err?.response;
    });
};

// DEL
export const del = async (url, config, returnRoot) => {
  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  return await apiConfig
    .delete(url, config)
    .then((response) => {
      if (returnRoot) {
        return response?.data;
      } else {
        return response?.data?.doc || response?.data?.docs;
      }
    })
    .catch((err) => {
      console.error("Backend Error: DELETE:", url, err);
      throw err?.response;
    });
};

// ------------------------------------------------------------------------ //
// API V2
// ------------------------------------------------------------------------ //

export const getV2 = async (url, config) => {
  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  return await apiConfig
    .get(url, config)
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response;
    });
};

export const postV2 = async (url, body, config) => {
  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  return await apiConfig
    .post(url, body, config)
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response;
    });
};

export const putV2 = async (url, body, config, ignoreNotation) => {
  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();
  if (!ignoreNotation) body = objToStrNotation(body); // normalize mongo sub documents

  return await apiConfig
    .put(url, body, config)
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response;
    });
};

export const delV2 = async (url, config) => {
  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  return await apiConfig
    .delete(url, config)
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error?.response;
    });
};

export const getByUriV2 = async (uri, _id) => {
  const url = _id ? `${uri}/${_id}` : uri;

  const config = { headers: { "x-resultasobject": !!_id } };

  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  try {
    return await getV2(url, config);
  } catch (error) {
    throw error;
  }
};

export const postByUriV2 = async (uri, body) => {
  const url = `${uri}`;
  const config = { headers: { "x-resultasobject": true } };

  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  try {
    return await postV2(url, body, config);
  } catch (error) {
    throw error;
  }
};

export const putByUriV2 = async (uri, _id, values) => {
  const body = clone(values);

  if (!isMongoObjectId(_id)) return console.error("Invalid _id! putByUri()");

  const url = `${uri}/${_id}`;
  const config = { headers: { "x-resultasobject": true } };

  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  try {
    return await putV2(url, body, config);
  } catch (error) {
    throw error;
  }
};

export const addToSet = async (uri, _id, operator, field, body) => {
  const url = `${uri}/add-to-set/${_id}/${operator}/${field}`;
  const config = { headers: { "x-resultasobject": true } };

  config.headers["x-serverdomain"] = backendUrl;
  config.headers["x-clientdomain"] = getMyDomain();
  config.headers["x-clientsent"] = +new Date();

  try {
    return await putV2(url, body, config);
  } catch (error) {
    throw error;
  }
};

export default apiConfig;
