import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Modal,
  Row,
  Button,
  Col,
  Input,
  DatePicker,
  Select,
  message,
  Descriptions,
  Tag,
  notification,
} from "antd";
import IntlMessage from "components/util-components/IntlMessage";
import {
  InputCpfCnpj,
  InputPhone,
  isValidCpf,
  isValidEmail,
} from "utils/react-jarvisly-helper";
import moment from "moment-timezone";
import {
  getSchedule,
  getScheduleById,
  postSchedule,
  putCandidateToSchedule,
} from "../schedules-api";
import {
  getDateName,
  msg,
  objToStrNotation,
  onFormFinishFailed,
  isValidPhoneNumber,
} from "utils/helpers";
import { ROW_GUTTER } from "constants/ThemeConstant";
import categories from "assets/data/dedalo/categories.json";
import {
  getCandidateByCpf,
  putCandidate,
  postCandidate,
} from "../../../records/candidates/candidates-api";
import {
  getTypeTagColor,
  getTypeTagName,
} from "../../../records/agendas/agendas-helper";
import apiConfig from "configs/ApiConfig";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const { Option } = Select;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const OnlineSchedule = (props) => {
  // props deconstruction ------------------------------------------------------
  const {
    showModal,
    setShowModal,
    type,
    schedule,
    mode,
    candidate,
    fetchData,
    cpf,
  } = props;

  // component states -----------------------------------------------------------
  const [updating, setUpdating] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [phoneStatus, setPhoneStatus] = useState("");
  const [cpfStatus, setCpfStatus] = useState("");
  const [lastCpfSearched, setLastCpfSearched] = useState(null);
  const [data, setData] = useState(candidate);

  // local variables ------------------------------------------------------------
  const elRefs = {
    cpf: useRef(null),
    name: useRef(null),
    birthday: useRef(null),
    gender: useRef(null),
    email: useRef(null),
    "mainContact.__phone": useRef(null),
    "anacInfo.code": useRef(null),
    "anacInfo.categoryId": useRef(null),
  };

  const [form] = Form.useForm();
  // const formRef = useRef(null);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (candidate) {
      setLastCpfSearched(candidate.cpf);

      // form.resetFields();

      if (candidate?.birthday) {
        candidate.birthday = moment(candidate.birthday);
      }

      if (candidate?.anacInfo?.expiryDate) {
        candidate.anacInfo.expiryDate = moment(candidate.anacInfo.expiryDate);
      }

      setData(candidate);
      form.setFieldsValue({ ...candidate });

      if (candidate?.email) setEmailStatus("success");
      if (candidate?.mainContact?.__phone) setPhoneStatus("success");
    } else {
      if (cpf) {
        form.resetFields();
        setCpfStatus("success");
        form.setFieldsValue({ cpf });
      }

      setData(null);
    }
  }, [form, candidate, cpf, showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  // close button click or escape
  const onClose = () => {
    setUpdating(false);
    setShowModal(false);
    setData(null);
    setEmailStatus(null);
    setPhoneStatus(null);
  };

  // UI COMPONENT --------------------------------------------------------------

  // modal ok button
  const onOk = () => {
    if (updating || !showModal) return;

    setUpdating(true);
    // formRef.current.submit();
    form.submit();
  };

  const modalButtons = [
    <Row justify="space-between" key="footerButtons">
      <Row align="middle">
        {/* {data?._id ? <span>_id: {data._id}</span> : null} */}
      </Row>

      <Row>
        <Button key="cancel" onClick={onClose} disabled={updating}>
          <span>{setLocale(true, "cancel")}</span>
        </Button>

        <Button
          key="ok"
          type="primary"
          loading={updating}
          // disabled={showModal && hasFormError(form)}
          onClick={onOk}
        >
          <span>{setLocale(true, "confirm")}</span>
        </Button>
      </Row>
    </Row>,
  ];

  const onChange = async (event) => {
    const name = event?.target?.name;
    const value = event?.target?.value;

    if (!name) return;

    switch (name) {
      case "cpf":
        await $searchCpf(value);
        break;

      default:
        break;
    }

    async function $searchCpf(cpf) {
      if (!isValidCpf(cpf) || cpf === lastCpfSearched) return;

      msg("i", "Buscando Candidato! Aguarde...");
      setTimeout(() => setCpfStatus("validating"));
      setLastCpfSearched(cpf);

      const candidate = await getCandidateByCpf(cpf);

      if (candidate) {
        form.resetFields();

        if (candidate?.birthday) {
          candidate.birthday = moment(candidate.birthday);
        }

        if (candidate?.anacInfo?.expiryDate) {
          candidate.anacInfo.expiryDate = moment(candidate.anacInfo.expiryDate);
        }

        setData(candidate);
        form.setFieldsValue({ ...candidate });

        if (candidate?.email) setEmailStatus("success");
        if (candidate?.mainContact?.__phone) setPhoneStatus("success");
      } else {
        setData(null);
      }

      message.destroy("searching");
      setCpfStatus("success");
      elRefs.name.current.focus({ cursor: "all" });
    }
  };

  const rules = {
    cpf: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
      {
        validator: (rule, cpf) => {
          if (cpf && !isValidCpf(cpf)) {
            setCpfStatus("error");
            return Promise.reject(setLocale(true, "invalid_cpf"));
          } else {
            setCpfStatus(cpf ? "success" : "error");
            return Promise.resolve();
          }
        },
      },
    ],
    name: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
    ],
    birthday: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
    ],
    email: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
      {
        validator: (rule, email) => {
          if (email && !isValidEmail(email)) {
            setEmailStatus("error");
            return Promise.reject(setLocale(true, "invalid_email"));
          } else {
            setEmailStatus(email ? "success" : "error");
            return Promise.resolve();
          }
        },
      },
    ],
    phone: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
      {
        validator: (rule, pn) => {
          if (pn && !isValidPhoneNumber(pn, "MOBILE")) {
            setPhoneStatus("error");
            return Promise.reject(setLocale(true, "invalid_phone_number"));
          } else {
            setPhoneStatus(pn ? "success" : "error");
            return Promise.resolve();
          }
        },
      },
    ],
    anacId: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
      {
        min: 6,
        message: setLocale(true, "invalid_field"),
      },
    ],
    categoryId: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
    ],
  };

  const onFinishFailed = (errorInfo) => {
    setUpdating(false);
    onFormFinishFailed(errorInfo, elRefs, setUpdating);
  };

  const onFinish = async (values) => {
    msg("i", "Adicionando Candidato...", "adding");

    // candidate postSchedule/putCandidate
    let candidate, body, result;

    if (data?._id) {
      const wasChanged =
        data.cpf !== values.cpf ||
        data.name !== values.name ||
        moment(data.birthday).format("YYYY-MM-DD") !==
          moment(values.birthday).format("YYYY-MM-DD") ||
        data.gender !== values.gender ||
        data?.mainContact?.__phone !== values?.mainContact?.__phone ||
        data?.anacInfo?.categoryId !== values?.anacInfo?.categoryId ||
        data?.anacInfo?.code !== values?.anacInfo?.code ||
        !data.backColor ||
        !data.foreColor;

      try {
        body = objToStrNotation(values);

        candidate =
          (wasChanged && (await putCandidate(data._id, body))) || data;
      } catch (error) {
        let errorMessage;

        message.destroy("adding");

        if (error?.data?.code === 409) {
          errorMessage = `Erro 409: Já existe outro CPF com este 'email'!`;

          setTimeout(() => {
            setEmailStatus("error");
            elRefs?.email?.current?.focus();
          }, 300);
        } else {
          errorMessage = `Erro ${error?.data?.code}: ${error?.data?.errorMessage}`;
        }

        message
          .error({
            content: errorMessage,
            duration: 5,
          })
          .then((r) => r);

        setUpdating(false);
        return;
      }

      if (!candidate) {
        notification["error"]({
          message: "Candidato Duplicado!",
          description:
            "Este candidato possui mais de um registro cadastral com o mesmo CPF ou EMAIL.",
          duration: 0,
        });
      }

      if (!candidate) candidate = data; // 282.339.428-12
    } else {
      try {
        candidate = await postCandidate(values);
      } catch (error) {
        let errorMessage;

        message.destroy("adding");

        if (error?.data?.code === 409) {
          errorMessage = `Erro 409: Já existe outro CPF com este 'email'!`;
          setTimeout(() => {
            setEmailStatus("error");
            elRefs?.email?.current?.focus();
          }, 300);
        } else {
          errorMessage = `Erro ${error?.data?.code}: ${error?.data?.errorMessage}`;
        }

        message
          .error({
            content: errorMessage,
            duration: 5,
          })
          .then((r) => r);

        setUpdating(false);
        return;
      }
    }

    const candidateObj = {
      candidateId: candidate?._id || data?._id,
      categoryId: candidate.anacInfo.categoryId,
      typeId: type,
      status: 0,
      note: candidate.note,
      name: candidate.name,
      email: candidate.email,
      sendEmail: true,
      office:
        apiConfig.defaults.headers["x-subscriptionid"] ===
        "6282e7831b7d873e20a479a1"
          ? "RP"
          : "SP",
    };

    console.log(
      "x-subscription:",
      apiConfig.defaults.headers["x-subscriptionid"],
      candidateObj,
    );

    const dateStr =
      schedule?.dateStr || moment(schedule.date).format("DD/MM/YYYY");
    const hourStr = schedule?.hourStr || schedule?.__hour;

    if (!schedule._id) {
      const query = {
        agendaId: schedule.agendaId,
        dateStr: dateStr,
        hourStr: hourStr,
      };

      result = await getSchedule(query);

      if (result?._id) result = await getScheduleById(result._id);
    }

    if (!result && !schedule._id) {
      // POST SCHEDULE
      body = {
        agendaId: schedule.agendaId,
        types: schedule.types,
        candidates: [candidateObj],
        timezone: schedule.__timezone,
        dateStr: dateStr,
        hourStr: hourStr,
      };

      result = await postSchedule(body);
    } else {
      // PUSH CANDIDATE TO SCHEDULE
      body = candidateObj;

      console.log("PUT AND SEND EMAIL ->", body);
      result = await putCandidateToSchedule(result?._id || schedule?._id, body);
    }

    msg("s", "Candidato adicionado com Sucesso!");
    onClose();
    fetchData().then((x) => x);
  };

  return (
    <>
      <Modal
        title={setLocale(true, "do_register_yourself")}
        // visible={showModal}
        open={showModal}
        width={640}
        onCancel={onClose}
        getContainer={false}
        footer={modalButtons}
      >
        <Row className="mb-2" justify="space-between">
          <h5 className="text-muted">{setLocale(true, "agenda")}</h5>
          <span className="font-size-xs text-muted">{schedule.__timezone}</span>
        </Row>

        <Descriptions bordered size="default">
          <Descriptions.Item label="Data" span={2}>
            {setLocale(true, getDateName(moment(schedule.date)))}&nbsp;
            {moment(schedule.date).format("LL")}
          </Descriptions.Item>

          <Descriptions.Item label="Horário">
            {schedule?.__hour}
          </Descriptions.Item>

          <Descriptions.Item label="Tipo" span={2}>
            {type && (
              <Tag
                key={type}
                color={getTypeTagColor(type)}
                className="tag mb-1 mt-1"
              >
                {getTypeTagName(type).toLowerCase()}
              </Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Vagas">
            {schedule?.__seats}
          </Descriptions.Item>
        </Descriptions>

        <Form
          form={form}
          // ref={formRef}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger="onChange"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row className="mb-2 mt-5">
            <h5 className="text-muted">{setLocale(true, "basic_info")}</h5>
          </Row>

          <Row>
            <Col xs={24}>
              <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={10}>
                  <Form.Item
                    label={setLocale(true, "person_cpf")}
                    name="cpf"
                    hasFeedback
                    validateStatus={cpfStatus}
                    rules={rules.cpf}
                  >
                    <InputCpfCnpj
                      ref={elRefs.cpf}
                      disabled={true}
                      profile="cpf"
                      name="cpf"
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={14}>
                  <Form.Item
                    label={setLocale(true, "name")}
                    name="name"
                    rules={rules.name}
                  >
                    <Input
                      ref={elRefs.name}
                      autoFocus
                      disabled={mode === "view"}
                      name="name"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={10}>
                  <Form.Item
                    label={setLocale(true, "birthdate")}
                    name="birthday"
                    rules={rules.birthday}
                  >
                    <DatePicker
                      className="w-100 no-border-radius"
                      format="DD/MM/YYYY"
                      disabled={mode === "view"}
                      placeholder=""
                      allowClear={false}
                      ref={elRefs.birthday}
                      name="birthday"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={14}>
                  <Form.Item
                    label={setLocale(true, "gender")}
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: setLocale(true, "required_field"),
                      },
                    ]}
                  >
                    <Select
                      value={document.gender}
                      showSearch
                      disabled={mode === "view"}
                      filterOption={(input, option) =>
                        option.text
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      ref={elRefs.gender}
                    >
                      <Option value="F" text="feminino">
                        {setLocale(true, "female")}
                      </Option>

                      <Option value="M" text="masculino">
                        {setLocale(true, "male")}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={14}>
                  <Form.Item
                    label={setLocale(true, "email")}
                    name="email"
                    hasFeedback
                    validateStatus={emailStatus}
                    rules={rules.email}
                  >
                    <Input
                      ref={elRefs.email}
                      disabled={mode === "view"}
                      name="email"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={10}>
                  <Form.Item
                    label={setLocale(true, "mobile")}
                    name={["mainContact", "__phone"]}
                    hasFeedback
                    validateStatus={phoneStatus}
                    rules={rules.phone}
                  >
                    <InputPhone
                      ref={elRefs["mainContact.__phone"]}
                      antd="Input"
                      name="mainContact.__phone"
                      mobileownername={data?.name}
                      profile="MOBILE"
                      disabled={mode === "view"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-2 mt-4">
            <h5 className="text-muted">{setLocale(true, "anac_info")}</h5>
          </Row>

          <Row>
            <Col xs={24}>
              <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={7}>
                  <Form.Item
                    label={setLocale(true, "anac_id")}
                    name={["anacInfo", "code"]}
                    rules={rules.anacId}
                  >
                    <Input
                      minLength={6}
                      maxLength={6}
                      disabled={mode === "view"}
                      ref={elRefs["anacInfo.code"]}
                      name="anacInfo.code"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={17}>
                  <Form.Item
                    label={setLocale(true, "category")}
                    name={["anacInfo", "categoryId"]}
                    rules={rules.categoryId}
                  >
                    <Select
                      // value={anacCategoryId}
                      ref={elRefs["anacInfo.categoryId"]}
                      disabled={mode === "view"}
                      showSearch
                      filterOption={(input, option) =>
                        option.text
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      name="anacInfo.categoryId"
                    >
                      {categories.map((c) => (
                        <Option
                          key={c.id}
                          value={c.id}
                          text={c._index.toLowerCase()}
                        >
                          {setLocale(true, `name.${c.acronym}`)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

// EXPORT **********************************************************************
// *****************************************************************************

export default OnlineSchedule;
