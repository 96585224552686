import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Button, Grid, Popconfirm, Row, Table, Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { substitution } from 'utils/helpers'
import { FormTitle } from 'views/app-views/portals/autaliza-info/FormComponets'
import { capitalize } from 'jarvisly-helper'
import ImageDescription from 'components/jarvisly/ImageDescription'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import VehicleModal from '../../portals/autaliza-info/tiles/registration/modals/VehicleModal'
import { pullSubdocumentByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import utils from 'utils'
import IntlMessage from 'components/util-components/IntlMessage'
import imgMotorcycle from 'assets/img/motorcycle.png'
import imgCar from 'assets/img/car.png'
import SendAtualizaInfoTrigger from '../components/SendAtualizaInfoTrigger'
import DropDownButton from '../components/DropDownButton'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const MovesVehicles = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props

  // local variables -----------------------------------------------------------

  const isDisabled = formContext.mode === 'view'
  const _atualizaInfo = _dataRoots?.__atualizaInfo
  if (_atualizaInfo) _atualizaInfo.updateSentAt = isDisabled

  const { useBreakpoint } = Grid
  const sm = utils.getBreakPoint(useBreakpoint()).includes('sm')
  const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const intl = useIntl()
  const t = buildTranslations()
  const columns = buildVehiclesTableColumn()
  const innerRef = useRef(null)

  // component states ----------------------------------------------------------
  const [showCarModal, setShowCarModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState()

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleMenuClick: innerRef?.current?.handleMenuClick,
  }))

  const onAdd = () => {
    setSelectedRecord(null)
    setShowCarModal(true)
  }

  const onEdit = (record) => {
    setSelectedRecord(record)
    setShowCarModal(true)
  }

  const onDelete = async _id => {
    setDataContext({ ...dataContext, partialLoading: true })
    await pullSubdocumentByUri('/atualiza-info', _id, 'vehicles')
    setFormContext({ ...formContext, refreshTime: +new Date() })
  }

  const onCloseFn = () => setFormContext({ ...formContext, refreshTime: +new Date() })

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <SendAtualizaInfoTrigger innerRef={innerRef} {...props} _data={_dataRoots}/>

    <VehicleModal {...props}
                  onCloseFn={onCloseFn}
                  _dataRoots={_atualizaInfo}
                  showModal={showCarModal}
                  setShowModal={setShowCarModal}
                  record={selectedRecord}/>

    {/* VEHICLES */}
    <Row justify="space-between">
      <FormTitle title={'vehicles'}/>
      <DropDownButton {...props} profile="vehicles"/>
    </Row>


    <Table rowKey="_id"
           pagination={false}
           columns={columns?.filter(c => !c.hidden) || []}
           dataSource={_data}
           loading={dataContext?.partialLoading}/>

    <Row justify="center" align="middle" className="m-3 mt-4">

      <Button type="primary"
              disabled={isDisabled}
              onClick={onAdd}>
        {setLocale(true, 'add_vehicle')}
      </Button>

    </Row>
    {/* VEHICLES */}

  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    const remove_this_item = translate(intl, 'remove_this_item')
    const vehicle = translate(intl, 'vehicle')
    const t_remove_this_item = substitution(remove_this_item, ['%ITEM%'], [vehicle])

    return {
      remove_this_item: t_remove_this_item,
    }
  }

  function buildVehiclesTableColumn () {
    return [

      {
        title: setLocale(true, 'vehicle'),
        render: (_, record) => (<div className="text-left">
          <ImageDescription
            shape="square"
            imageUrl={record?.type === 'motorcycle' ? imgMotorcycle : imgCar}
            title={record?.model?.toUpperCase()}
            subtitle={capitalize(record?.brand)}
            style={{ padding: 10 }}
            backColor="#ecf2fe"
          />
        </div>),
      },

      {
        title: setLocale(true, 'plate'),
        hidden: !md,
        width: 100,
        render: (_, record) => (<div className="text-left text-uppercase">
          {record?.plate}
        </div>),
      },

      {
        title: setLocale(true, 'color'),
        hidden: !sm,
        render: (_, record) => (<div className="text-left text-capitalize">
          {setLocale(true, record?.color)}
        </div>),
      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (<>
            <Tooltip title={setLocale(true, !isDisabled ? 'edit' : 'view')}>
              <Button size="small"
                // className={!isMobile ? 'mr-2' : 'mb-2'}
                      className="m-2"
                      icon={!isDisabled ? <EditOutlined/> : <EyeOutlined/>}
                      onClick={() => onEdit(record)}/>
            </Tooltip>

            {!isDisabled && <Popconfirm title={t.remove_this_item}
                                        cancelButtonProps={{ className: 'fixed-with-90' }}
                                        okButtonProps={{ className: 'fixed-with-90' }}
                                        onConfirm={() => onDelete(record?._id)}>
              <Button size="small" icon={<DeleteOutlined/>}/>
            </Popconfirm>}
          </>)
        },
      }]
  }
})

// EXPORT **********************************************************************
// *****************************************************************************

export default MovesVehicles


