import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { buildCarBrandOptions, buildMotorcycleBrandOptions, msg } from 'utils/helpers'
import { useIntl } from 'react-intl'
import {
  FormInput,
  FormPlaca,
  FormSaveButton,
  FormSelect,
  FormSelectResident,
  FormSimpleSwitch,
  FormTitle,
  FormWrapper,
} from 'views/app-views/portals/autaliza-info/FormComponets'
import { useRouter } from 'hooks/useRouter'
import { translate } from 'utils/react-jarvisly-helper'
import IntlMessage from 'components/util-components/IntlMessage'
import imgMotorcycle from 'assets/img/motorcycle.png'
import imgCar from 'assets/img/car.png'
import { putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'

// COMPONENT *******************************************************************
// *****************************************************************************

const BikesProfile = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _id,
    _module,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
    _parameters,
  } = props

  // local variables -----------------------------------------------------------

  const isDisabled = formContext.mode === 'view'
  const formName = 'profileForm'
  const [form] = Form.useForm()
  const intl = useIntl()
  const router = useRouter()
  const elRefs = buildElementsRefs(useRef)
  const elRules = buildElementsRules()
  const typeOptions = buildTypeOptions()
  const colorOptions = buildColorOptions()

  // component states ----------------------------------------------------------
  const [, setSubmitted] = useState(false)
  const [validating, setValidating] = useState(false)
  const [plateFound, setPlateFound] = useState()
  const [brandOptions, setBrandOptions] = useState([])
  const [selectedDriver, setSelectedDriver] = useState(null)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    // blockId
    if (_data?.blockId && _parameters?.blocks?.findIndex(b => b._id === _data.blockId) === -1) {
      _data.blockId = null
      form?.setFieldValue('blockId', null)
    }

    // profileId
    if (_data?.profileId && _parameters?.profiles?.findIndex(b => b._id === _data.profileId) === -1) {
      _data.profileId = null
      form?.setFieldValue('profileId', null)
    }

    refreshSelectedDriver(_data?.driverId)

  }, [_parameters, _data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (_data?._id) {
      form?.setFieldsValue(_data)
      form?.validateFields(['plate']).then()

      if (!_data?.pictureUrl) {
        _data.pictureUrl = _data?.type === 'motorcycle' ? imgMotorcycle : imgCar
      }

    } else {
      form.resetFields()
      // workaround because after reset form the tag refs be lost
      setTimeout(() => handleFocus(undefined, 'all'))
    }

    refreshBrands()

    setDataContext({ ...dataContext, formsChanged: [], data: _data })
    setFormContext({ ...formContext, disablePicture: true })

  }, [_data]) // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
  }))

  function handleFocus (field = 'driverId', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  const onPlateValidating = value => setValidating(value)

  const onPlateSearched = result => {
    if (result) setPlateFound(result)
  }

  function refreshBrands (value = _data?.type) {
    const brands = value === 'motorcycle'
      ? buildMotorcycleBrandOptions(intl)
      : buildCarBrandOptions(intl)

    if (brands.findIndex(b => b.value === form.getFieldValue('brand')) === -1) {
      form.setFieldValue('brand', '')
    }

    if (value) {
      dataContext.data.pictureUrl = value === 'motorcycle'
        ? imgMotorcycle
        : imgCar
      setDataContext({ ...dataContext })
    }

    setBrandOptions(brands)
  }

  const onBeforeSave = values => {
    setDataContext({ ...dataContext, partialLoading: true })
    console.log('values...', values)
    return { ...values }
  }

  const onAfterSave = async doc => {

    if (!doc) return

    // -------------------------------------------------------------------------------------
    // change bikes from apartment
    // -------------------------------------------------------------------------------------
    const afterApartmentId = selectedDriver.__apartmentId
    const afterBikesIds = selectedDriver.__apartmentBikesIds || []
    const beforeApartmentId = _data?.__apartment?._id
    const beforeBikesIds = _data?.__apartment?.bikesIds || []

    // add bike to apartment.bikesIds array
    if (afterBikesIds?.findIndex(v => v.bikeId === doc._id) === -1) {
      const bikesIds = [
        ...afterBikesIds,
        { bikeId: doc._id, entryAt: doc._metadata.audit.createdAt },
      ]
      await putByUri('/apartments', afterApartmentId, { bikesIds })
    }

    // remove bike from apartment.bikesId if the condutor was changed
    console.log('..')
    if (beforeApartmentId && beforeBikesIds.length > 0 && beforeApartmentId !== afterApartmentId) {
      const bikesIds = beforeBikesIds.filter(v => v.bikeId !== doc._id)
      await putByUri('/apartments', beforeApartmentId, { bikesIds })
    }
    // -------------------------------------------------------------------------------------

    if (_id === 'add') { // reload the page/module
      router.replace({ pathname: `${_module?.url}/${doc._id}/profile`, state: { isActive: true } })
      setFormContext({ ...formContext, mode: 'view' })

    } else {
      // force update the dataContext and refresh all page
      setFormContext({ ...formContext, refreshTime: +new Date(), mode: 'view' })
    }

  }

  const onError = error => {
    if (error?.status === 409) {
      msg('e', translate(intl, 'bike_already_exists'), 'error')
      handleFocus('room')
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (

    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi={_module.api}
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      onError={onError}
      showToast>


      {/* BASIC INFO */}
      <FormTitle title={'bike_data'}/>


      <Row gutter={ROW_GUTTER}>


        {/* DRIVER */}
        <Col xs={24} md={16}>
          <FormSelectResident name="driverId"
                              _parameters={_parameters || {}}
                              label="main_driver"
                              profile={{ apartment: 34, block: 1 }}
                              elRef={elRefs.driverId}
                              showAge
                              showApartment
                              disabled={isDisabled}
                              onChange={refreshSelectedDriver}
                              required/>
        </Col>
        {/* DRIVER */}


        {/* TYPE */}
        <Col xs={24} md={8}>
          <FormSelect name="type"
                      elRef={elRefs.type}
                      options={typeOptions}
                      onChange={refreshBrands}
                      disabled={validating || plateFound || isDisabled}
                      required/>
        </Col>
        {/* TYPE */}


      </Row>


      <Row gutter={ROW_GUTTER}>


        {/* PLATE */}
        <Col xs={24} md={8}>
          <FormPlaca form={form}
                     elRef={elRefs.plate}
                     onValidating={onPlateValidating}
                     onSearched={onPlateSearched}
                     rules={elRules.plate}
                     disabled={isDisabled}
                     required/>
        </Col>
        {/* PLATE */}


        {/* BRAND */}
        <Col xs={24} md={8}>
          <FormSelect name="brand"
                      elRef={elRefs.brand}
                      options={brandOptions}
                      disabled={validating || plateFound || isDisabled}
                      required/>
        </Col>
        {/* BRAND */}

        {/* MODEL */}
        <Col xs={24} md={8}>
          <FormInput name="model"
                     elRef={elRefs.model}
                     disabled={validating || plateFound || isDisabled}
                     required/>
        </Col>
        {/* MODEL */}


      </Row>


      <Row gutter={ROW_GUTTER}>


        {/* YEAR */}
        <Col xs={24} md={8}>
          <FormInput name="year"
                     elRef={elRefs.year}
                     rules={elRules.year}
                     maxLength={4}
                     disabled={validating || plateFound || isDisabled}
                     required/>
        </Col>
        {/* YEAR */}


        {/* COLOR */}
        <Col xs={24} md={8}>
          <FormSelect name="color"
                      elRef={elRefs.color}
                      options={colorOptions}
                      disabled={validating || plateFound || isDisabled}
                      required/>
        </Col>
        {/* COLOR */}


        {/* MULTIPLE DRIVERS */}
        <Col xs={24} md={8}>
          <FormSimpleSwitch name="multipleDrivers"
                            form={form}
                            value={_data?.multipleDrivers}
                            _parameters={_parameters || {}}
                            label="have_other_drivers"
                            elRef={elRefs.multipleDrivers}
                            disabled={isDisabled}/>
        </Col>
        {/* MULTIPLE DRIVERS */}

      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* TAG NUMBER */}
        <Col xs={24} md={8}>
          <FormInput name="tagNumber"
                     label="tag_number"
                     disabled={isDisabled}
                     elRef={elRefs.tagNumber}/>
        </Col>
        {/* TAG NUMBER */}


        {/* NOTE */}
        <Col xs={24} md={16}>
          <FormInput name="note"
                     label="observation"
                     disabled={isDisabled}
                     elRef={elRefs.note}/>
        </Col>
        {/* NOTE */}

      </Row>


      {/* SAVE BUTTON */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      setSubmitted={setSubmitted}
                      disabled={isDisabled}/>
      {/* SAVE BUTTON */}


    </FormWrapper>

  )

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildColorOptions () {

    const colorsList = [
      'beige',
      'black',
      'blue',
      'bronze',
      'brown',
      'burgundy',
      'caramel',
      'champagne',
      'gray',
      'green',
      'golden',
      'orange',
      'pink',
      'purple',
      'red',
      'silver',
      'turquoise',
      'white',
      'wine',
      'yellow',
    ]

    const colors = colorsList.map((color) => {
      return {
        value: color,
        text: translate(intl, color),
        label: translate(intl, color),
        disabled: false,
      }
    })

    colors.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return colors
  }

  function buildTypeOptions () {

    const t = {
      car: translate(intl, 'car'),
      motorcycle: translate(intl, 'motorcycle'),
    }

    return [
      {
        value: 'car', text: t.car, label: t.car, disabled: false,
      }, {
        value: 'motorcycle', text: t.motorcycle, label: t.motorcycle, disabled: false,
      },
    ]
  }

  function buildElementsRules () {

    const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

    return {

      year: [
        {
          validator: (rule, value) => {

            const currentYear = new Date().getFullYear()

            if (value && (isNaN(Number(value)) || value < 1900 || value > (currentYear + 1))) {
              return Promise.reject(setLocale(true, 'invalid_year'))

            } else {
              return Promise.resolve()
            }
          },
        },
      ],
    }
  }

  function buildElementsRefs (hookUseRef) {
    return {
      plate: hookUseRef(null),
      type: hookUseRef(null),
      year: hookUseRef(null),

      brand: hookUseRef(null),
      model: hookUseRef(null),
      color: hookUseRef(null),

      driverId: hookUseRef(null),
      multipleDrivers: hookUseRef(null),

      note: hookUseRef(null),
      tagNumber: hookUseRef(null),
    }
  }

  function refreshSelectedDriver (driverId) {
    if (driverId && _parameters?.residents?.length > 0) {
      const selDriver = _parameters.residents.find(r => r._id === driverId)
      console.log('selectedDriver ->', selDriver)
      if (selDriver) setSelectedDriver(selDriver)
    }

  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default BikesProfile
