import React, { useState } from 'react';
import AppBreadcrumb from 'components/layout-components/AppBreadcrumb';
import IntlMessage from '../util-components/IntlMessage';
import { Button, Col, Row, Tooltip, Dropdown, Segmented } from 'antd';
import {
  AppstoreOutlined,
  ClearOutlined,
  FilterOutlined,
  ImportOutlined,
  LockOutlined,
  NumberOutlined, ReloadOutlined,
  SettingOutlined, UnorderedListOutlined,
} from '@ant-design/icons';
import { connect, useSelector } from 'react-redux';
import ModuleAccessControl from '../jarvisly-layouts/ModuleComponent/modals/AccessControl';
import {
  rdxSetModuleDashboard,
  rdxSetModuleList,
  rdxSetShowModuleFilter,
  rdxSetShowModuleSettings
} from 'redux/actions/module';
import { translate } from 'utils/react-jarvisly-helper';
import { useIntl } from 'react-intl';
import { useRouter } from 'hooks/useRouter';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

export const PageHeader = props => {

  // props deconstruction ------------------------------------------------------

  const {
    title,
    display,
    rdxModule,
    rdxSetShowModuleFilter,
    rdxSetModuleList,
    rdxModuleList,
    rdxSetModuleDashboard,
    rdxModuleDashboard
  } = props;

  // local variables -----------------------------------------------------------
  const intl = useIntl();
  const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription);
  const router = useRouter();
  const actionsButtons = buildActionButtons();
  const segmentViewsOptions = buildSegmentViewsOptions();
  // const segmentFoldersOptions = buildSegmentFoldersOptions();
  const isDisabled = !rdxModule?.components?.list;

  // component states ----------------------------------------------------------
  const [showModuleControlAccess, setShowModuleControlAccess] = useState(false);

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onActionClick = value => {
    const key = value?.key || 'new';

    switch (key) {

      case 'new': {
        const path = `${rdxModule?.url}/add/profile`;
        router.push(path);
        break;
      }

      case 'clearSearch': {
        router.push(rdxModule?.url);
        rdxModuleList?.resetTableSearch && rdxModuleList.resetTableSearch();
        break;
      }

      case 'refreshList': {
        // router.push(rdxModule?.url)
        rdxModuleList?.refreshData && rdxModuleList.refreshData('url');
        break;
      }

      case 'showLineNumbers': {
        localStorage.setItem('showNumberOfLine', !rdxModuleList?.showNumberOfLine);
        rdxSetModuleList({
          ...rdxModuleList,
          showNumberOfLine: !rdxModuleList?.showNumberOfLine
        });
        break;
      }

      case 'showCheckBox': {
        localStorage.setItem('showCheckBox', !rdxModuleList?.showCheckBox);
        rdxSetModuleList({
          ...rdxModuleList,
          showCheckBox: !rdxModuleList?.showCheckBox
        });
        break;
      }

      default:
        console.error('no \'key\' found to onActionClick() function!');
    }
  };

  const onChangeView = view => {
    rdxSetModuleList({
      ...rdxModuleList,
      view: view || 'list',
    });
  };

  /*   const onChangeFolder = async folder => {

      const filter = {
        ...rdxModuleList.restoreQsObj,
        folder: folder
      };
      rdxSetModuleList({
        ...rdxModuleList,
        restoreQsObj: filter
      });
      // await refreshData(filter);

      /!*

          rdxSetModuleList({
            ...rdxModuleList,
            folder: folder || 'documents',
          });
      *!/
    }; */

  // UI COMPONENT --------------------------------------------------------------
  return (<>

    <ModuleAccessControl
      showModal={showModuleControlAccess}
      setShowModal={setShowModuleControlAccess}/>

    {/*{rdxModule?.components?.modalSettings({...settingsProps})}*/}

    {/*<ModuleSettings {...settingsProps}/>*/}


    {display ? (

      <Row justify="space-between" align="middle">

        {/* TITLE AND BREADCRUMB */}
        {/*<Col xs={24} sm={12} className="app-page-header">*/}
        <Col xs={24} sm={12} className="mb-3">

          <Row align="bottom">
            <h3 className="mb-0 mr-3 font-weight-semibold">{title}</h3>
          </Row>
          <AppBreadcrumb/>

        </Col>
        {/* TITLE AND BREADCRUMB */}


        {/* ACTIONS BUTTONS */}
        {!isDisabled
          ? <Col xs={24} sm={12} className="text-center text-sm-right mb-3">

            <Row justify={{
              xs: 'space-around',
              sm: 'end'
            }}>


              {/* NEW/MORE */}
              {rdxModule && rdxModuleList?.view !== 'dashboard'
                ? <Col className="m-1">
                  <Dropdown.Button type="primary"
                                   menu={{
                                     items: actionsButtons,
                                     onClick: onActionClick
                                   }}
                                   buttonsRender={([leftButton, rightButton]) => [
                                     <Tooltip title={setLocale(true, 'new_document')} key="top">
                                       {leftButton}
                                     </Tooltip>, <Tooltip title={`${translate(intl, 'more_actions')}...`} key="top">
                                       {rightButton}
                                     </Tooltip>]}
                                   onClick={onActionClick}>

                    {setLocale(true, 'new')}

                  </Dropdown.Button>
                </Col>
                : null}
              {/* NEW/MORE */}


              {/* FOLDERS */}
              {/* <Col className="m-1"> */}
              {/*   <Tooltip title={setLocale(true, 'folders')} placement="top"> */}
              {/*     <Segmented value={rdxModuleList?.folder || 'documents'} */}
              {/*                onChange={onChangeFolder} */}
              {/*                options={segmentFoldersOptions}/> */}
              {/*   </Tooltip> */}
              {/* </Col> */}
              {/* FOLDERS */}


              {/* DASHBOARD PANELS */}
              {rdxModule?.components?.dashboard &&
              rdxModule.dashboardViewsSegmentsOptions &&
              rdxModule && rdxModuleList?.view === 'dashboard'
                ? <Col className="m-1">
                  <Tooltip title={setLocale(true, 'dashboard_panels')} placement="top">
                    <Segmented className="only-centered-icons"
                               onChange={value => rdxSetModuleDashboard({
                                 ...rdxModuleDashboard,
                                 panel: value
                               })}
                               defaultValue={rdxModuleDashboard?.panel || 'water_level_sensor'}
                               options={rdxModule.dashboardViewsSegmentsOptions}
                    />
                  </Tooltip>
                </Col>
                : null
              }
              {/* DASHBOARD PANELS */}


              {/* VIEW MODE */}
              {rdxModule?.components?.dashboard
                ? <Col className="m-1">
                  <Tooltip title={setLocale(true, 'view_mode')} placement="top">
                    <Segmented value={rdxModuleList?.view || 'list'}
                               onChange={onChangeView}
                               options={segmentViewsOptions}/>
                  </Tooltip>
                </Col>
                : null
              }
              {/* VIEW MODE */}


              <Col className="m-1">

                {/* FILTER */}
                {rdxModule?.hasFilter && <Tooltip title={setLocale(true, 'filter')} placement="top">
                  <Button
                    // className="ml-3 mr-1"
                    danger={rdxModuleList?.isFilterChanged}
                    icon={<FilterOutlined/>}
                    disabled={rdxModuleList?.view === 'dashboard'}
                    onClick={() => rdxSetShowModuleFilter(true)}/>
                </Tooltip>}
                {/* FILTER */}


                {/* PARAMETERS */}
                {['owner', 'keyUser'].includes(rdxSubscription?.profile) && rdxModule?.sectionSettingsComponent &&
                  <Tooltip title={setLocale(true, 'parameters')}
                           placement={!rdxModule?.hasSettings ? 'topRight' : 'top'}>
                    <Button
                      className="ml-2"
                      danger={rdxModuleList?.isFilterChanged}
                      icon={<SettingOutlined/>}
                      onClick={() => rdxSetShowModuleFilter(true)}/>
                  </Tooltip>}
                {/* PARAMETERS */}


                {/* CONTROL ACCESS */}
                {['owner', 'keyUser'].includes(rdxSubscription?.profile) && rdxModule?.hasAccessControl &&
                  <Tooltip title={setLocale(true, 'access_control')} placement="topRight">
                    <Button
                      className="ml-2"
                      icon={<LockOutlined/>}
                      onClick={() => setShowModuleControlAccess(true)}/>
                  </Tooltip>}
                {/* CONTROL ACCESS */}

              </Col>

            </Row>

          </Col>
          : null}
        {/* TITLE AND BREADCRUMB */}

      </Row>

    ) : null}

  </>);

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  /*
    function buildSegmentFoldersOptions () {

      // const documentsTitle = `${translate(intl, 'documents')}`;
      // const recycleTitle = `${translate(intl, 'recycle')}`;
      // const archiveTitle = `${translate(intl, 'archived')}`;

      return [
        {
          // label: documentsTitle,
          value: 'documents',
          icon: <FolderOpenOutlined/>,
        },
        {
          // label: archiveTitle,
          value: 'filed',
          icon: <FolderOutlined/>,
        },
        {
          // label: recycleTitle,
          value: 'recycle',
          icon: <DeleteOutlined/>,
        },
      ];
    }
  */

  function buildSegmentViewsOptions () {

    // const dashboardTitle = `${translate(intl, 'dashboard_mode')}`
    // const listTitle = `${translate(intl, 'list_mode')}`
    // const cardTitle = `${translate(intl, 'cards_mode')}`

    return [
      {
        // label: listTitle,
        value: 'list',
        icon: <UnorderedListOutlined/>,
      },
      /*
              {
                // label: cardTitle,
                value: 'cards',
                icon: <PicCenterOutlined/>,
              },
      */
      {
        // label: dashboardTitle,
        value: 'dashboard',
        icon: <AppstoreOutlined/>,
      },
    ];
  }

  function buildActionButtons () {

    return [
      {
        key: 'refreshList',
        label: translate(intl, 'refresh_list'),
        icon: <ReloadOutlined/>,
        // disabled: rdxModuleList?.isUrlDefault === true,
      },

      {
        key: 'clearSearch',
        label: translate(intl, 'clear_filter'),
        icon: <ClearOutlined/>,
        disabled: rdxModuleList?.isUrlDefault === true,
      },

      {
        key: 'showLineNumbers',
        label: !rdxModuleList?.showNumberOfLine
          ? translate(intl, 'show_number_of_lines')
          : translate(intl, 'hide_number_of_lines'),
        icon: <NumberOutlined/>,
      },

      // {
      //   key: 'showCheckBox',
      //   label: !rdxModuleList?.showCheckBox
      //     ? translate(intl, 'show_documents_checkbox')
      //     : translate(intl, 'hide_documents_checkbox'),
      //   icon: <CheckSquareOutlined/>,
      // },

      // {
      //   key: 'askForUpdate',
      //   label: translate(intl, 'request_update'),
      //   icon: <SendOutlined/>,
      //   disabled: true,
      // },

      {
        type: 'divider',
      },

      {
        key: 'import',
        label: translate(intl, 'data_import'),
        icon: <ImportOutlined/>,
      }];
  }
};

const mapDispatchToProps = {
  rdxSetShowModuleSettings,
  rdxSetShowModuleFilter,
  rdxSetModuleList,
  rdxSetModuleDashboard
};

const mapStateToProps = ({ moduleRedux }) => {
  const {
    rdxModule,
    rdxModuleList,
    rdxModuleDashboard
  } = moduleRedux;
  return {
    rdxModule,
    rdxModuleList,
    rdxModuleDashboard
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);

