import React from 'react';
import {CheckOutlined, GlobalOutlined, DownOutlined} from '@ant-design/icons';
import {Dropdown} from 'antd';
import lang from 'assets/data/language.data.json';
import {connect} from 'react-redux';
import {onLocaleChange} from 'redux/actions/theme';
import IntlMessage from '../util-components/IntlMessage';
import {putAccount} from 'auth/auth-api';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

function getLanguageDetail(locale) {
  const data = lang.filter(elm => (elm.langId === locale));
  return data[0];
}

const SelectedLanguage = ({locale}) => {
  const language = getLanguageDetail(locale);
  const {langName, icon} = language;
  return (<div className="d-flex align-items-center">
    <img style={{maxWidth: '20px'}} src={`/img/flags/${icon}.png`} alt={langName}/>
    <span className="font-weight-semibold ml-2">{setLocale(true,
        langName)}&nbsp;&nbsp;
      <DownOutlined className="font-size-xs"/></span>
  </div>);
};


export const NavLanguage = ({
                              locale, configDisplay, onLocaleChange, rdxUser,
                            }) => {

  const savePreference = async langId => {
    if (locale === langId) return;

    onLocaleChange(langId);
    const data = {'systemPreferences.language': langId};
    if (!rdxUser._id) return;
    await putAccount(rdxUser._id, data);
  };

  const items = lang.map(x => {

    return {
      key: x['langId'], label: (

          <div className={locale === x['langId']
              ? 'ant-dropdown-menu-item-active'
              : ''}
               onClick={() => savePreference(x['langId'])}>

            <span className="d-flex justify-content-between align-items-center">

							<div>
								<img style={{maxWidth: '20px'}} src={`/img/flags/${x['icon']}.png`} alt={x['langName']}/>
								<span className="font-weight-normal ml-2">
                  {setLocale(true, x['langName'])}
                </span>
							</div>

              {locale === x['langId'] ?
                  <CheckOutlined className="text-success"/> : null}
						</span>

          </div>),
    };

  });

  return (

      // <Dropdown placement="bottomRight" menu={languageOption} trigger={['click']}>

      <Dropdown placement="bottomRight"
                trigger={['click']}
                menu={{items, selectable: true, defaultSelectedKeys: [locale]}}
      >

        {configDisplay ?
            <a href="#/" className="text-gray" onClick={e => e.preventDefault()}>
              <SelectedLanguage locale={locale}/>
            </a>

            : <a href="#/" onClick={e => e.preventDefault()}>
              <GlobalOutlined className="nav-icon mr-0"/>
            </a>}

      </Dropdown>);
};

const mapStateToProps = ({theme, auth}) => {
  const {locale} = theme;
  const {rdxUser} = auth;

  return {locale, rdxUser};
};

export default connect(mapStateToProps, {onLocaleChange})(NavLanguage);
