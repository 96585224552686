import React, { useRef, useState } from 'react'
import { Modal } from 'antd'
import { TagOutlined } from '@ant-design/icons'
import IntlMessage from 'components/util-components/IntlMessage'
import PetModalRegistrationTab from './PetModalRegistrationTab'
import moment from 'moment'
import { pushSubdocumentByUri, putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import { clone } from 'jarvisly-helper'
import { useIntl } from 'react-intl'
import { translate } from '../../../../../../../utils/react-jarvisly-helper'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const PetModal = props => {

  const {
    dataContext,
    setDataContext,
    _dataRoots,
    record,
    showModal,
    setShowModal,
    onCloseFn,
  } = props

  // props deconstruction ------------------------------------------------------

  // component states -----------------------------------------------------------
  const [isFormChanged, setIsFormChanged] = useState(false)

  // local variables ------------------------------------------------------------
  const ref = useRef(null)
  const intl = useIntl()
  const title = record?._id
    ? (_dataRoots?.updateSentAt
      ? translate(intl, 'view')
      : translate(intl, 'edit_pet'))
    : translate(intl, 'new_pet')

  // ant dataPicker workaround to fix issue about date
  if (record?.birthday) record.birthday = moment(record.birthday)
  if (record?.vaccinatedAt) record.vaccinatedAt = moment(record.vaccinatedAt)

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  const onClose = () => {
    setIsFormChanged(false)
    setShowModal(false)
    onCloseFn && onCloseFn()
  }

  async function onSave (values) {

    if (!isFormChanged) return onClose()

    setDataContext({ ...dataContext, partialLoading: true })

    const body = { ...values }
    if (record?._id) body._id = record._id

    const doc = await pushSubdocumentByUri('/atualiza-info', _dataRoots?._id, 'pets', body)
    await putByUri('/atualiza-info', _dataRoots?._id, { petsUpdated: false })

    if (!onCloseFn) {
      setDataContext({
        ...dataContext, data: { ...doc }, dataLock: { ...clone(doc) }, partialLoading: false,
      })
    }

    onClose()
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Modal
      title={<><TagOutlined/><span className="ml-2">{title}</span></>}
      destroyOnClose={true}
      open={showModal}
      width={640}
      okText={setLocale(true, (isFormChanged || !record) ? 'save' : 'close')}
      onCancel={onClose}
      onOk={() => ref?.current?.onSubmit()}
      cancelButtonProps={{ style: { display: 'none' } }}
      getContainer={false}
      okType={isFormChanged ? 'primary' : 'default'}
      confirmLoading={dataContext?.partialLoading}>

      <PetModalRegistrationTab {...props}
                               ref={ref}
                               setIsFormChanged={setIsFormChanged}
                               loading={dataContext?.partialLoading}
                               onSave={onSave}/>

    </Modal>

  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

}

// EXPORT **********************************************************************
// *****************************************************************************

export default PetModal
