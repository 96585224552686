import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'antd';
import {useIntl} from 'react-intl';
import {FormOutlined, StarOutlined} from '@ant-design/icons';
import {translate} from 'utils/react-jarvisly-helper';
import IntlMessage from 'components/util-components/IntlMessage';
import MainProcessTab from './tabs/MainProcessTab';
import {ModalProvider} from './ProceduresProcessModalProvider';
import {Icon} from 'components/util-components/Icon';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const ProceduresProcessModal = props => {

  // props deconstruction ------------------------------------------------------
  const {showModal, setShowModal, onCloseFn, record} = props;

  // component states -----------------------------------------------------------
  const [state] = useState({
    tabIdx: 'main',
  });

  // local variables ------------------------------------------------------------
  const intl = useIntl();
  const mainTabRef = useRef();
  const alternativeTabRef = useRef();
  const titleIcon = !record
      ? StarOutlined
      : FormOutlined;

  const title = !record
      ? `${translate(intl, 'new_process')}`
      : `${translate(intl, 'edit_process')}`;

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  // const setSettings = result => {
  //   setState(result?.settings[rdxModule.name]);
  // };

  const onClose = (event, doc) => {
    setShowModal(false);
    if (onCloseFn && doc) onCloseFn(doc);
  };

  const onSave = () => {

    if (state.tabIdx === 'main') {
      return mainTabRef?.current?.submitForm();

    } else if (state.tabIdx === 'alternative') {
      return alternativeTabRef?.current?.submitForm();
    }
  };

  // UI COMPONENT --------------------------------------------------------------

  return (<ModalProvider>

    <Modal
        title={<>
          <Icon type={titleIcon}></Icon>
          <span className="ml-2">
            {title}
          </span>
        </>}
        destroyOnClose={true}
        open={showModal}
        width={640}
        cancelText={setLocale(true, 'cancel')}
        okText={setLocale(true, 'save')}
        onCancel={onClose}
        onOk={onSave}
        okButtonProps={{type: 'primary'}}
        cancelButtonProps={{type: 'default'}}
        getContainer={false}
        className="modal-without-padding">

      {/*<Tabs onChange={onTabClick} items={tabItems} activeKey={state?.tabIdx}/>*/}

      <div style={{padding: '24px 24px 0'}}>

        <MainProcessTab {...props}
                        onClose={onClose}
                        ref={mainTabRef}
        />

      </div>

    </Modal>

  </ModalProvider>);

};

// EXPORT **********************************************************************
// *****************************************************************************

export default ProceduresProcessModal;




