import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  USER,
  SUBSCRIPTION, PORTAL_SECTION_STATUS, PORTAL_SECTION_USER, PORTAL_SECTION_SUBSCRIPTION, PORTAL_DATA,
} from '../constants/auth'

import apiConfig from 'configs/ApiConfig'

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  }
}

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  }
}

export const signOut = () => {
  return {
    type: SIGNOUT,
  }
}

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
}

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  }
}

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  }
}

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE,
  }
}

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token,
  }
}

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK,
  }
}

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token,
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  }
}

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  }
}

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  }
}

export const rdxSetUser = user => {

  // if (user?._id && localStorage.getItem('accountId') !== user._id) {
    localStorage.setItem('accountId', user?._id)
  // }

  apiConfig.defaults.headers['x-accountId'] = user?._id || null

  return {
    type: USER,
    payload: user,
  }
}

export const rdxSetSubscription = subscription => {

  // if (subscription?._id && localStorage.getItem('subscriptionId') !== subscription._id) {
    localStorage.setItem('subscriptionId', subscription?._id)
  // }

  apiConfig.defaults.headers['x-subscriptionid'] = subscription?._id || null

  return {
    type: SUBSCRIPTION,
    payload: subscription,
  }
}

export const rdxSetPortalSubscription = subscription => {

  localStorage.setItem('subscriptionId', subscription?._id)
  apiConfig.defaults.headers['x-subscriptionid'] = subscription?._id || null

  return {
    type: PORTAL_SECTION_SUBSCRIPTION,
    payload: subscription,
  }
}

export const rdxSetPortalUser = user => {

  apiConfig.defaults.headers['x-accountId'] = user?._id || null
  localStorage.setItem('accountId', user?._id)

  return {
    type: PORTAL_SECTION_USER,
    payload: user,
  }
}

export const rdxSetPortalStatus = status => {

  return {
    type: PORTAL_SECTION_STATUS,
    payload: status,
  }
}

export const rdxSetPortalData = status => {

  return {
    type: PORTAL_DATA,
    payload: status,
  }
}
