import React, {forwardRef, useState} from 'react';
import {Button, Row, Table, Tooltip} from 'antd';
import {
    DeleteOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import IntlMessage from 'components/util-components/IntlMessage';
import {
    FormContext,
} from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/FormProvider';
import ProceduresMemberModal from '../modals/member/ProceduresMemberModal';
import {
    putDocument,
} from 'components/jarvisly-layouts/ModuleComponent/module-api';
import RoundedAvatar from 'components/jarvisly/RoundedAvatar';
import {putProcedure} from '../procedures-api';
import {useIntl} from 'react-intl';
import {
    onClickRemoveItem,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import {DataContext} from "../../../../../components/jarvisly-layouts/ModuleComponent/ModuleForm/DataProvider";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const ProceduresMembers = forwardRef((props, ref) => {


    const {formContext, setFormContext} = React.useContext(FormContext);
    const {dataContext} = React.useContext(DataContext);

    const data = dataContext?.data;

    const {_loading, _module} = props;
    const isDisabled = formContext.mode === 'view' || _loading;


    // props deconstruction ------------------------------------------------------

    // component states ----------------------------------------------------------
    const [showMembersModal, setShowMembersModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();

    // local variables -----------------------------------------------------------
    const intl = useIntl();

    // hooks ---------------------------------------------------------------------
    /*
      useEffect(() => {
        // temporário durante o desenvolvimento...
        // setFormContext({...formContext, mode: 'edit'});

      }, [formContext?.data]); // eslint-disable-line react-hooks/exhaustive-deps
    */

    // METHODS -------------------------------------------------------------------

    const onUpdatedMembers = async document => {

        setFormContext({...formContext, _loading: true});

        const body = {members: document.members};
        const doc = await putDocument(_module, data?._id, body);

        setFormContext({
            ...formContext,
            data: {...formContext.data, ...doc},
            docLock: {...dataContext.docLock, ...doc},
            _loading: false,
        });
    };

    const onClickAdd = () => {
        setSelectedRecord(null);
        setShowMembersModal(true);
    };

    const onClickRemove = async record => {

        onClickRemoveItem(record, intl, $done);

        async function $done() {
            setFormContext({...formContext, _loading: true});
            const doc = await putProcedure(data?._id, 'remove-member',
                {memberId: record?.memberId});

            setFormContext({
                ...formContext,
                mode: 'edit',
                data: doc,
                docLock: doc,
                _loading: false,
            });
        }
    };

    // UI COMPONENT --------------------------------------------------------------

    const columns = [

        {
            title: setLocale(true, 'collaborator'),
            dataIndex: '__name',
            render: (_, record) => (
                <RoundedAvatar user={record} showName showAge/>),
        },

        // {
        //   title: setLocale(true, 'company'),
        //   dataIndex: '__company',
        //   render: (_, record) => (<span>Company</span>),
        // },

        {
            // title: setLocale(true, 'edit'),
            dataIndex: 'actions',
            width: 50,
            render: (_, record) => (
                <div className="justify-content-end align-content-center">

                    <Tooltip title={setLocale(true, 'remove')}>
                        <Button icon={<DeleteOutlined/>}
                                onClick={() => onClickRemove(record)} size="small"/>
                    </Tooltip>

                </div>),
        },
    ];

    return (<>

            <ProceduresMemberModal procedureId={data?._id}
                                   _module={_module}
                                   onCloseFn={onUpdatedMembers}
                                   showModal={showMembersModal}
                                   setShowModal={setShowMembersModal}
                                   record={selectedRecord}
                                   members={data?.members || []}
            />


            <Row justify="end" align="middle">

                <Button type="primary"
                        icon={<PlusOutlined/>}
                        className="mb-3"
                        disabled={isDisabled}
                        onClick={onClickAdd}>
                    &nbsp;{setLocale(true, 'add')}
                </Button>
            </Row>

            <Table rowKey={'memberId'}
                // className="flat-table"
                   pagination={false}
                   columns={columns}
                   dataSource={data?.members || []}
            />
        </>
    );

});

export default ProceduresMembers;
