import React, { useImperativeHandle, useRef, useState } from 'react'
import { Button, Col, Divider, Form, Grid, Input, Popconfirm, Row, Table, Tag, Tooltip } from 'antd'
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import IntlMessage from '../../../util-components/IntlMessage'
import { generateHexColor, getFocus, isObjEquals, modal, substitution } from 'utils/helpers'
import AntdReactColor from '../../../jarvisly/AntdReactColor'
import utils from 'utils'
import { delValueFromSettings, postValueToSettings, putValuesInSettings } from '../module-api'
import { translate } from 'utils/react-jarvisly-helper'
import { useIntl } from 'react-intl'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

const initialBackColor = '#FFFFFF'
const initialForeColor = '#000000'
const initialState = {
  backColor: initialBackColor, foreColor: initialForeColor,
}

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const ModuleSettingsValues = React.forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------'
  const {
    setSettings,
    _settings,
    subscriptionId,
    _module,
    parValueType,
    parTitle,
    parPlaceholder,
    withColor,
  } = props

  // component states ----------------------------------------------------------
  const [state, setState] = useState(initialState)
  const [editingKey, setEditingKey] = useState('')

  // local variables ------------------------------------------------------------

  const { useBreakpoint } = Grid
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const [form] = Form.useForm()
  const [formTable] = Form.useForm()
  const intl = useIntl()
  const elRefs = {
    value: useRef(null), updateValue: useRef(null),
  }

  // hooks ---------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    getFirstFocus () {
      getFirstFocus()
    },
  }))

  // METHODS -------------------------------------------------------------------

  function getFirstFocus () {
    handleCancel()
    setTimeout(() => elRefs.value?.current?.focus(), 200)
  }

  const refresh = result => {
    form.resetFields()

    setEditingKey('')
    setState({
      ...state, backColor: initialBackColor, foreColor: initialForeColor,
    })

    setTimeout(() => {
      if (result) setSettings(result)
      elRefs.value.current.focus()
    }, 200)
  }

  const onAdd = async values => {
    values.backColor = state.backColor
    values.foreColor = state.foreColor

    const options = {
      subscriptionId, _module, valueType: parValueType, body: values,
    }
    refresh(await postValueToSettings(options))
  }

  const onEdit = (record) => {
    setState({ backColor: record?.backColor, foreColor: record?.foreColor })
    formTable.setFieldsValue({ value: '', backColor: '', ...record })
    setEditingKey(record._id)
    setTimeout(() => elRefs?.updateValue?.current?.focus({ cursor: 'all' }))
  }

  const handleUpdate = async record => {

    let row = formTable.validateFields().then()

    row.backColor = state.backColor || record.backColor
    row.foreColor = state.foreColor || record.foreColor
    if (!row._id) row._id = record._id

    if (isObjEquals(row, record)) return refresh()

    const options = {
      valueId: row._id, _module, valueType: parValueType, body: row,
    }
    refresh(await putValuesInSettings(options))
  }

  const handleCancel = () => refresh()

  const onDelete = async _id => {

    // Check if it has occurrences using this tag in some document
    const options = { valueId: _id, _module, valueType: parValueType }
    // const counter = await getValuesCounter(options)
    const counter = 999999
    const message = translate(intl, 'exclude_not_allowed_description')
    const translatedMessage = substitution(message, ['%COUNTER%'], [counter])

    if (counter > 0) {
      const options = {
        type: 'warning',
        title: translate(intl, 'deletion_not_allowed'),
        message: translatedMessage,
        okText: 'OK',
      }

      modal(options)
      return
    }

    refresh(await delValueFromSettings(options))
  }

  const handleChangeColor = color => {
    const foreColor = generateHexColor(color, true)

    setTimeout(() => {
      setState({ ...state, backColor: color, foreColor: foreColor })
    }, 100)
  }

  // UI COMPONENT --------------------------------------------------------------

  const isEditing = (record) => record._id === editingKey

  const EditableCell = ({
    editing, dataIndex, record, children, ...restProps
  }) => {

    let inputNode = <Input ref={elRefs.updateValue}/>

    if (dataIndex === 'backColor') {

      inputNode = <AntdReactColor
        isCompact={true}
        setSelectedColor={handleChangeColor}
        initialBackColor={state?.backColor}
        initialForeColor={state?.foreColor}
      />

    }

    return (<td {...restProps}>
      {editing ? (<Form.Item
        name={dataIndex}
        style={{
          margin: 0,
        }}
        rules={[
          {
            required: true, message: '',
          }]}
      >
        {inputNode}
      </Form.Item>) : (children)}
    </td>)
  }

  const columns = [
    {
      title: setLocale(true, parTitle),
      dataIndex: 'value', // width: 168.5,
      editable: true,
      render: (_, record) => (
        withColor
          ? <Tag key={record._id} color={record?.backColor}>
                  <span className="text-uppercase" style={{ color: record?.foreColor }}>
                    {record?.value}
                  </span>
          </Tag>
          : <span className="text-uppercase">{record?.value}</span>
      ),
    }, {
      title: setLocale(true, 'color'),
      hidden: !withColor,
      dataIndex: 'backColor',
      width: 73.2,
      editable: true,
      render: (_, record) => (<>
        <AntdReactColor type={1}
                        disabled={true}
                        initialForeColor={record?.foreColor}
                        initialBackColor={record?.backColor}
        />
      </>),
    }, {
      title: setLocale(true, 'actions'),
      dataIndex: 'actions',
      width: 113,
      className: 'text-center',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (<>
            <Tooltip title={setLocale(true, 'save')}>
              <Button size="small"
                      className="mr-2"
                      type="primary"
                      icon={<SaveOutlined/>}
                      onClick={() => handleUpdate(record)}/>
              {/*onClick={() => save(record._id)}/>*/}
            </Tooltip>

            <Tooltip title={setLocale(true, 'cancel')}>
              <Button size="small"
                      danger
                      icon={<CloseOutlined/>}
                      onClick={handleCancel}/>
            </Tooltip>
          </>)

          : (<>
            <Tooltip title={setLocale(true, 'edit')}>
              <Button size="small"
                      className="mr-2"
                      icon={<EditOutlined/>}
                      onClick={() => onEdit(record)}/>
            </Tooltip>

            <Popconfirm title="Excluir este Valor?"
                        cancelButtonProps={{ className: 'fixed-with-90' }}
                        okButtonProps={{ className: 'fixed-with-90' }}
                        onConfirm={() => onDelete(record?._id)}>
              <Button size="small" icon={<DeleteOutlined/>}/>
            </Popconfirm>
          </>)
      },
    }]

  const mergedColumns = columns.filter(x => !x.hidden).map((col) => {
    if (!col.editable) return col

    return {
      ...col, onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  return (<>

    <h5 className="text-muted">
      {props?.valuesTitle && setLocale(true, props.valuesTitle)}
    </h5>


    <Form form={form}
          name="mainForm"
          layout="inline"
          className="inline-form-without-margin col-min-height-64"
          onFinish={onAdd}
          onFinishFailed={error => getFocus(elRefs, error)}
          autoComplete="off">

      <Row align="start"
           justify="space-between"
           className="p-2 width-100"
           gutter={[8, 16]}>


        {/* VALUE */}
        <Col xs={14} sm={10} style={{ minHeight: 64 }}>
          <Form.Item
            name="value"
            rules={[
              {
                required: true, message: 'Campo Obrigatório!',
              }]}
          >
            <Input ref={elRefs.value}
                   disabled={!!editingKey}
                   placeholder={translate(intl, parPlaceholder)}
                   autoFocus
            />
          </Form.Item>
        </Col>
        {/* VALUE */}


        {/* COLOR */}
        <Col xs={5} sm={6} className="text-left">
          {withColor && <Form.Item name="backColor">
            <AntdReactColor disabled={!!editingKey}
                            isCompact={true}
                            setSelectedColor={handleChangeColor}/>
          </Form.Item>}
        </Col>
        {/* COLOR */}


        {/* CONFIRM */}
        <Col xs={5} sm={8} className="text-right">
          <Form.Item className="mr-0">
            <Button shape="square"
                    type="primary"
                    htmlType="submit"
                    disabled={!!editingKey}
                    icon={<CheckOutlined/>}>
              {!isMobile && <span>{setLocale(true, 'add')}</span>}
            </Button>
          </Form.Item>
        </Col>
        {/* CONFIRM */}

      </Row>
    </Form>

    <Divider orientation="left" className="m-0"/>

    <Form form={formTable} component={false}>

      <Table
        className="editable-tab min-height-220 mt-3"
        rowKey="_id"
        components={{ body: { cell: EditableCell } }}
        dataSource={_settings[parValueType] || []}
        columns={mergedColumns || []}
        rowClassName="editable-row"
        pagination={{
          pageSize: 5, showSizeChanger: false, onChange: handleCancel,
        }}
      />
    </Form>

  </>)

})

// EXPORT **********************************************************************
// *****************************************************************************

export default ModuleSettingsValues



