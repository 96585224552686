import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Badge, Col, Form, Row } from 'antd'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { msg, parsePhoneToDisplay } from 'utils/helpers'
import { returnOnlyNumbers } from 'jarvisly-helper'
import { useIntl } from 'react-intl'
import {
  FormEmail,
  FormInput, FormPhone,
  FormSaveButton,
  FormSelect,
  FormTitle,
  FormWrapper,
} from 'views/app-views/portals/autaliza-info/FormComponets'
import { useRouter } from 'hooks/useRouter'
import { translate } from 'utils/react-jarvisly-helper'

// COMPONENT *******************************************************************
// *****************************************************************************

const ApartmentsProfile = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _id,
    _module,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
    _parameters,
  } = props

  // let {} = props

  // component states ----------------------------------------------------------
  const [submitted, setSubmitted] = useState(false)

  // local variables -----------------------------------------------------------
  const isDisabled = formContext.mode === 'view'
  const formName = 'profileForm'
  const [form] = Form.useForm()
  const intl = useIntl()
  const router = useRouter()
  const elRefs = buildElementsRefs(useRef)
  const blockOptions = buildBlockOptions()
  const profileOptions = buildProfileOptions()

  // ant dataPicker workaround to fix issue about date
  if (_data?.dataResponsible?.phone) _data.dataResponsible.phone = parsePhoneToDisplay(_data.dataResponsible.phone)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    // blockId
    if (_data?.blockId && _parameters?.blocks?.findIndex(b => b._id === _data.blockId) === -1) {
      _data.blockId = null
      form?.setFieldValue('blockId', null)
    }

    // profileId
    if (_data?.profileId && _parameters?.profiles?.findIndex(b => b._id === _data.profileId) === -1) {
      _data.profileId = null
      form?.setFieldValue('profileId', null)
    }

  }, [_parameters, _data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (_data?._id) {
      form?.setFieldsValue(_data)
      form?.validateFields([['dataResponsible', 'phone'], ['dataResponsible', 'email']]).then(x => x).catch(e => e)

    } else {
      form.resetFields()

      // workaround because after reset form the tag refs be lost
      setTimeout(() => handleFocus(undefined, 'all'))
    }

    setDataContext({ ...dataContext, formsChanged: [] })

  }, [_data, form]) // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
  }))

  function handleFocus (field = 'room', cursorPosition = 'all') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  const getProfile = profileId => {
    if (!profileId) profileId = form.getFieldValue('profileId')
    if (profileId) return _parameters?.profiles?.find(i => i._id === profileId)
  }

  const getBlock = blockId => {
    if (!blockId) blockId = form.getFieldValue('blockId')
    if (blockId) return _parameters?.blocks?.find(i => i._id === blockId)
  }

  const getLimitFloor = () => {
    const selectedBlock = getBlock()
    return selectedBlock?.floors || 99
  }

  const generateDirectCall = async () => {

    const selectedBlock = getBlock()
    const selectedProfile = getProfile()
    const room = form?.getFieldValue('room')

    if (room && selectedBlock?.prefixNumber && selectedProfile?.apartmentDigits) {
      const prefix = selectedBlock?.prefixNumber
      const digits = selectedProfile?.apartmentDigits
      const directCall = `${prefix}${room.toString().padStart(digits, '0')}`
      form.setFieldValue('directCall', directCall)
    }

    if (isNaN(room)) return

    const floor = room.length === 1 ? 0 : room.substring(0, (room.length - 1))
    form.setFieldValue('floor', floor)

    submitted && form.validateFields(['floor', 'directCall']).then(x => x).catch(e => e)
  }

  const onBeforeSave = values => {

    setSubmitted(true)

    const body = { ...values }

    const selectBlock = getBlock()
    const profile = getProfile()

    body.backColor = selectBlock?.backColor
    body.foreColor = selectBlock?.foreColor
    body.roomNumber = returnOnlyNumbers(body.room) || 0

    if (_data?._id && !body?.dataResponsible?.accessCode) {
      if (!body?.dataResponsible) body.dataResponsible = {}
      body.dataResponsible.accessCode = _data._id.toString().slice(-4).toUpperCase()
    }

    // build uniqueKey
    const room = `${selectBlock.name} (${selectBlock.abbreviation})`
    const prefix = selectBlock?.prefixNumber ? `#${selectBlock?.prefixNumber?.toString().padStart(2, '0')} ` : ''
    const reference = selectBlock?.reference ? ` | ${selectBlock?.reference}` : ''

    body.uniqueKey = `${body?.room?.padStart(profile?.apartmentDigits,
      '0')} | ${prefix}${room}${reference} | ${selectBlock.abbreviation} | ${body.roomNumber} | ${body.roomNumber}${selectBlock.abbreviation} | ${selectBlock.abbreviation}${body.roomNumber}`

    /*
        if (formContext?._data?.pictureUrl) {
          body.pictureUrl = formContext?._data?.pictureUrl
          body.pictureAttachmentId = formContext?._data?.pictureAttachmentId
        }

        // attachments
        body.attachmentsIds = formContext?._data?.attachmentsIds
    */

    return body
  }

  const onAfterSave = async doc => {

    if (!doc) return

    if (_id === 'add') { // reload the page/module
      router.replace({ pathname: `${_module?.url}/${doc._id}/profile`, state: { isActive: true } })
      setFormContext({ ...formContext, mode: 'view' })

    } else {
      // force update the dataContext and refresh all page
      setFormContext({ ...formContext, refreshTime: +new Date(), mode: 'view' })
    }
  }

  const onError = error => {
    if (error?.status === 409) {
      msg('e', translate(intl, 'apartment_already_exists'), 'error')
      handleFocus('room')
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (

    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi={_module.api}
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      onError={onError}
      showToast>


      {/* BASIC INFO */}
      <FormTitle title={'basic_info'}/>


      <Row gutter={ROW_GUTTER}>

        {/* APARTMENT NUMBER */}
        <Col xs={8} md={3}>
          <FormInput name="room"
                     label="apartment_abbreviation"
                     disabled={isDisabled}
                     required
                     onChange={generateDirectCall}
                     elRef={elRefs.room}/>
        </Col>
        {/* APARTMENT NUMBER */}


        {/* PROFILE */}
        <Col xs={16} md={7}>
          <FormSelect name="profileId"
                      label="profile"
                      elRef={elRefs.profileId}
                      options={profileOptions}
                      disabled={isDisabled}
                      onChange={generateDirectCall}
                      required/>
        </Col>
        {/* PROFILE */}


        {/* BLOCK */}
        <Col xs={16} md={8}>
          <FormSelect name="blockId"
                      label="block_unit"
                      elRef={elRefs.blockId}
                      options={blockOptions}
                      onChange={generateDirectCall}
                      disabled={isDisabled}
                      required/>
        </Col>
        {/* BLOCK */}


        {/* FLOOR */}
        <Col xs={8} md={3}>
          <FormInput name="floor"
                     disabled={isDisabled}
                     required
                     style={{ width: '100%' }}
                     formatter={v => ('0' + v).slice(-2)}
                     min={0}
                     max={getLimitFloor()}
                     elRef={elRefs.floor}/>
        </Col>
        {/* FLOOR */}


        {/* DIRECT CALL */}
        <Col xs={8} md={3}>
          <FormInput name="directCall"
                     label="direct_call"
                     elRef={elRefs.directCall}
                     disabled={isDisabled}
                     required/>
        </Col>
        {/* DIRECT CALL */}


      </Row>


      {/* RESPONSIBLE */}
      <FormTitle title={'responsible'}/>

      <Row gutter={ROW_GUTTER}>

        {/* NAME */}
        <Col xs={24} md={8}>
          <FormInput name={['dataResponsible', 'name']}
                     disabled={isDisabled}
                     required
                     elRef={elRefs.name}/>
        </Col>
        {/* NAME */}


        {/* PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone name={['dataResponsible', 'phone']}
                     form={form}
                     disabled={isDisabled}
                     required={!form?.getFieldValue(['dataResponsible', 'email'])}
                     elRef={elRefs.phone}/>
        </Col>
        {/* PHONE */}


        {/* EMAIL */}
        <Col xs={24} sm={8}>
          <FormEmail name={['dataResponsible', 'email']}
                     form={form}
                     disabled={isDisabled}
                     required={!form?.getFieldValue(['dataResponsible', 'phone'])}
                     elRef={elRefs.email}/>
        </Col>
        {/* EMAIL */}


      </Row>
      {/* RESPONSIBLE */}


      {/* SAVE BUTTON */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      form={form}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      setSubmitted={setSubmitted}
                      disabled={isDisabled}/>
      {/* SAVE BUTTON */}


    </FormWrapper>

  )

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildProfileOptions () {

    return _parameters?.profiles?.map(profile => {

      return {
        value: profile._id,
        text: profile.name,
        label: profile.name,
        disabled: false,
      }

    }) || []
  }

  function buildBlockOptions () {

    return _parameters?.blocks?.map(block => {

      return {
        value: block._id,
        text: `${block.abbreviation} ${block.name}`,
        label: <>
          <Badge dot
                 style={{
                   marginRight: 8,
                   color: block?.foreColor,
                   backgroundColor: block?.backColor,
                   marginTop: 4,
                 }}/>
          {block.name}
        </>,
        disabled: false,
      }

    }) || []
  }

  function buildElementsRefs (hookUseRef) {
    return {
      blockId: hookUseRef(null),
      room: hookUseRef(null),
      floor: hookUseRef(null),
      profileId: hookUseRef(null),
      directCall: hookUseRef(null),

      name: hookUseRef(null),
      phone: hookUseRef(null),
      email: hookUseRef(null),
      responsibleProfile: hookUseRef(null),
    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default ApartmentsProfile
