import React from 'react';
import {
  decorateDocument,
  decorateNewDocument,
  decorateSettings,
  dataProfileValidation,
  parseSettings,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
} from './ambiences-methods';
import AmbiencesMainList from './AmbiencesMainList';
import DocumentDataProfiles
  from 'components/jarvisly-layouts/ModuleComponent/Components/DocumentDataProfiles';
import {
  AlertOutlined,
  AppstoreAddOutlined, AuditOutlined,
  BankOutlined,
  BookOutlined,
  ClearOutlined, EyeOutlined,
  FileProtectOutlined, FormOutlined,
  PaperClipOutlined,
  PicCenterOutlined,
  PictureOutlined,
  SettingOutlined,
  SolutionOutlined,
  SwapOutlined,
  ToolOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import {Route} from 'react-router-dom';
import AmbiencesProfile from './sections/AmbiencesProfile';

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_URL = '/app/records/condominium/ambiences';
export const AMBIENCES_API_URL = '/ambiences';

const INITIAL_FILTER = {
  folder: 'documents',
  dataProfile: 'plan',
};

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
};

const INITIAL_SEARCH = {
  documentCode: 'null',
  name: 'null',
  documentStatus: 'null',
};

const INITIAL_CONTEXT = {
  mode: 'add',
  data: null,
  docLock: null,
  selectedSection: null,
  sectionsChanged: [],
  dataMemory: false,
  hasPicture: true,
};

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: decorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: dataProfileValidation,
  putDocumentValidation: dataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,
};

const COMPONENTS = {
  list: props => <AmbiencesMainList {...props}/>,
  // listPreviewHeader: props => <ProceduresBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <ProceduresBodyPreviewData {...props}/>,
  // listPreviewActions: props => <ProceduresBodyPreviewActions {...props}/>,

  documentSubtitle: props => <DocumentDataProfiles {...props}/>,
  // documentHeaderColumn2: props => <ProceduresDocumentHeaderColumn2 {...props}/>,
  // documentHeaderColumn3: props => <ProceduresDocumentHeaderColumn3 {...props}/>,

  // moduleSettings: productsSettingsTabs,
};

const DATA_TYPES = [
  {
    key: 'private',
    label: 'private',
    description: 'ambience_private_description',
    checked: true,
  }, {
    key: 'collective',
    label: 'collective',
    description: 'ambience_collective_description',
    checked: true,
  }];

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <AppstoreAddOutlined/>,
    label: 'ambience',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_URL}/${_id}/profile`}
                                       render={() =>
                                           <AmbiencesProfile ref={ref} {...props} />}/>,

  },
  {
    key: 'use_parameters',
    icon: <SettingOutlined/>,
    label: 'use_parameters',
  },
  {
    key: 'attachments',
    icon: <PaperClipOutlined/>,
    label: 'attachments',
  },
  {
    key: 'photos',
    icon: <PictureOutlined/>,
    label: 'photos',
  },
  {
    key: 'assets',
    icon: <TrophyOutlined/>,
    label: 'assets',
  },
  {
    type: 'divider',
  },
  {
    key: 'procedures',
    icon: <FileProtectOutlined/>,
    label: 'procedures',
  },
  {
    key: 'cleaning',
    icon: <ClearOutlined/>,
    label: 'cleaning',
  },
  {
    key: 'organization',
    icon: <PicCenterOutlined/>,
    label: 'organization',
  },
  {
    key: 'furniture_maintenance',
    icon: <ToolOutlined/>,
    label: 'furniture_maintenance',
  },
  {
    key: 'structural_maintenance',
    icon: <BankOutlined/>,
    label: 'structural_maintenance',
  },
  {
    key: 'signaling',
    icon: <SwapOutlined/>,
    label: 'signaling',
  },
  {
    key: 'safety',
    icon: <AlertOutlined/>,
    label: 'safety',
  },
  {
    type: 'divider',
  },
  {
    key: 'guidance',
    icon: <SolutionOutlined/>,
    label: 'guidance',
  },
  {
    key: 'internal_regulation',
    icon: <BookOutlined/>,
    label: 'internal_regulation',
  },
  {
    key: 'convention',
    icon: <AuditOutlined/>,
    label: 'convention',
  },
  {
    type: 'divider',
  },
  {
    key: 'notes',
    icon: <FormOutlined/>,
    label: 'notes',
  },
  {
    key: 'logs',
    icon: <EyeOutlined/>,
    label: 'logs',
  },
];

/*
const REQUIRED_FIELDS = [
  {
    key: 'name',
    label: 'name',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
];
*/

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_AMBIENCES_PARAMETERS = {
  root: 'ambiences',
  name: 'ambiences',
  singular: 'ambience',
  titleField: 'name',
  url: MODULE_URL,
  api: AMBIENCES_API_URL,

  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: [],

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  // initialFilter: {...INITIAL_FILTER, dataProfile: 'ambience'},
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_ambience',
  },

  documentSections: DOCUMENT_SECTIONS,
};

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

export const SETTINGS_SEED = {

  configurations: {
    dataTypes: ['private', 'collective'],
    dataMemory: false,
  },

  requiredFields: ['name'],
};
