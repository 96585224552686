import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { MODULE_ATUALIZA_PARAMETERS as _module } from '../atualiza-constants'
import { useRouter } from 'hooks/useRouter'
import { Button, Card, Col, Grid, Row, Tag, Tooltip } from 'antd'
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons'
import RegistrationTileForm from './registration/RegistrationTileForm'
import { DataProvider } from '../DataProvider'
import utils from 'utils'
import IntlMessage from 'components/util-components/IntlMessage'
import Icon from 'components/util-components/Icon'
import { getDocumentStatusColor } from '../../../../../utils/helpers'
import moment from 'moment'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// INDEX COMPONENT *************************************************************
// *****************************************************************************

const RegistrationTile = props => {

  props = { ...props, record: props?.location?.state?.record }
  const { rdxPortalStatus, record } = props

  // props deconstruction ------------------------------------------------------
  const { useBreakpoint } = Grid
  const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const router = useRouter()

  // tag for atualizaInfo status
  const atualizaInfoWaitingFor = record?.updateRequiredAt && moment(record?.updateRequiredAt).fromNow()
  const atualizaInfoUpdatedFor = record?.updateSentAt && moment(record?.updateSentAt).fromNow()
  const atualizaInfoStatus = record?.updateSentAt
    ? 'updated'
    : record?.updateRequiredAt
      ? 'pending_update'
      : 'not_updated'

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    if (rdxPortalStatus === 0) router.replace(_module.url)
  }, [rdxPortalStatus])  // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onClickBack = () => {
    router.replace(_module?.url)
  }

  // UI COMPONENT --------------------------------------------------------------

  return (

    <div style={{ width: '100%' }} className="pl-4 pr-4 pl-lg-5 pr-lg-5">

      <Card>


        <Row justify={{ xs: 'center' }} align="middle">

          <Col xs={24} md={20} className={md ? 'text-left' : 'text-center'}>
            <h3 className="mb-0">
              <Icon type={HomeOutlined} className="mr-2"/>
              {record?.description}
            </h3>

            <div className="mt-2">
              {record &&
                <Tooltip placement="top" title={atualizaInfoUpdatedFor || atualizaInfoWaitingFor}>
                  <Tag className="text-capitalize" style={{ margin: 4 }}
                       color={getDocumentStatusColor(atualizaInfoStatus)}>
                    {setLocale(true, atualizaInfoStatus)}
                  </Tag>
                </Tooltip>
              }
            </div>
          </Col>

          <Col xs={24} md={4} className={md ? 'text-right' : 'text-center'}>
            <Button type="default"
                    className={!md && 'mt-3'}
                    onClick={onClickBack}
                    icon={<ArrowLeftOutlined/>}
                    style={{ marginRight: 4, width: 116 }}>
              &nbsp;{setLocale(true, 'back')}
            </Button>
          </Col>

        </Row>


      </Card>


      <Card title="Dados Cadastrais">
        <DataProvider>
          <RegistrationTileForm {...props}/>
        </DataProvider>
      </Card>

    </div>)
}

// EXPORT **********************************************************************
// *****************************************************************************

// export default AtualizaProfile

const mapStateToProps = ({ auth }) => {
  const { rdxPortalStatus } = auth
  return { rdxPortalStatus }
}

export default connect(mapStateToProps)(RegistrationTile)
