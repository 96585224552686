import React, {forwardRef, useEffect, useState} from 'react';
import {Button, Col, Row, Table, Tooltip} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import IntlMessage from 'components/util-components/IntlMessage';
import {
  FormContext,
} from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/FormProvider';
import {Icon} from 'components/util-components/Icon';
import {useIntl} from 'react-intl';
import ProceduresTaskModal from '../modals/task/ProceduresTaskModal';
import {putProcedure} from '../procedures-api';
import {
  onClickRemoveItem,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import {DataContext} from "../../../../../components/jarvisly-layouts/ModuleComponent/ModuleForm/DataProvider";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const ProceduresTasks = forwardRef((props, ref) => {

  const {_loading, _module} = props;

  const {formContext, setFormContext} = React.useContext(FormContext);
  const {dataContext} = React.useContext(DataContext);

  const data = dataContext?.data;
  const isDisabled = formContext.mode === 'view' || _loading;

  // props deconstruction ------------------------------------------------------

  // component states ----------------------------------------------------------
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [tasks, setTasks] = useState(formContext?.data?.tasks || []);

  // local variables -----------------------------------------------------------
  const intl = useIntl();

/*
  const startButton = document.querySelector('#startButton'),
      stopButton = document.querySelector('#stopButton');

  let audioContext = new AudioContext(),
      oscillator = audioContext.createOscillator();
*/

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    // temporário durante o desenvolvimento...
    setFormContext({...formContext, mode: 'edit'});
    setTasks(formContext?.data?.tasks || []);

  }, [formContext?.data]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onUpdated = async task => {

    const doc = await putProcedure(data?._id, 'add-task', task);

    setFormContext({
      ...formContext,
      mode: 'edit',
      data: doc,
      docLock: doc,
    });

  };

  const onClickOpen = record => {

    /*
        console.log('doc->', record)
        const teste = new Audio();
        teste.src = 'https://www.soundjay.com/clock/alarm-clock-01.mp3';
        teste.play()
    */

    // start();

    setSelectedRecord(record);
    setShowTaskModal(true);
  };

  const onClickAdd = () => {
    setSelectedRecord(null);
    setShowTaskModal(true);
  };

  const onClickRemove = async record => {

    // stop();

    onClickRemoveItem(record, intl, $done);

    async function $done() {
      setFormContext({...formContext, _loading: true});
      const doc = await putProcedure(data?._id, 'remove-task',
          {_id: record?._id});

      setFormContext({
        ...formContext,
        mode: 'edit',
        data: doc,
        docLock: doc,
        _loading: false,
      });
    }

  };


/*
  function start(){
    // startButton.style.display = 'none';
    // stopButton.style.display = 'block';
    audioContext = new AudioContext();
    oscillator = audioContext.createOscillator();
    oscillator.type = "sine";
    oscillator.connect(audioContext.destination);
    oscillator.start();
  }
*/

/*
  function stop(){
    // startButton.style.display = 'block';
    // stopButton.style.display = 'none';
    oscillator.stop(audioContext?.currentTime + 0);
  }
*/


  // UI COMPONENT --------------------------------------------------------------

  const columns = [
    {
      title: setLocale(true, 'tasks'),
      dataIndex: 'task',
      render: (_, record) => (
          <Row>
            <Icon type={ClockCircleOutlined} className="icon-in-list"
                  style={{color: 'gray !important'}}/>
            <div>
              <strong>{record.task}</strong><br/>
              <span>{record.note}</span>
            </div>
          </Row>),
    },
    {
      title: setLocale(true, 'at_time'),
      dataIndex: 'time',
      render: (_, record) => (<>{record?.time}</>),
    },

    {
      title: setLocale(true, 'edit'),
      dataIndex: 'actions',
      width: 50,
      render: (_, record) => (!record?.__type ?
          <div className="text-right d-flex justify-content-end">

            <Tooltip title={setLocale(true, 'edit')}>
              <Button className="mr-2" icon={<EditOutlined/>}
                      onClick={() => onClickOpen(record)}
                      size="small"/>
            </Tooltip>


            <Tooltip title={setLocale(true, 'remove')}>
              <Button icon={<DeleteOutlined/>}
                      onClick={() => onClickRemove(
                          record)} size="small"/>
            </Tooltip>


          </div> : null),
    }];

  return (<>

    <ProceduresTaskModal procedureId={data?._id}
                         _module={_module}
                         onCloseFn={onUpdated}
                         showModal={showTaskModal}
                         setShowModal={setShowTaskModal}
                         record={selectedRecord}
                         tasks={tasks}
    />


    <Row justify="space-between" align="middle">


      <Col>

        {/*        <Row>

          <Space size={28}>

            <Tooltip title={setLocale(true, 'tasks')}>
              <Icon type={SnippetsOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
              {data?.tasks?.length || 0}
            </span>
            </Tooltip>

          </Space>

        </Row>*/}
      </Col>

      <Col>

        <Button type="primary"
                icon={<PlusOutlined/>}
                className="mb-3"
                disabled={isDisabled}
                onClick={onClickAdd}>
          &nbsp;{setLocale(true, 'add')}
        </Button>
      </Col>

    </Row>


    <Table
        rowKey={'_id' || 'id'}
        columns={columns}
        dataSource={tasks}
        pagination={false}
    />

  </>);

});

export default ProceduresTasks;
