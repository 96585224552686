import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { onFormFinishFailed } from 'utils/helpers'
import { formDataChanges, FormDatePicker, FormInput, FormSelect, FormTitle } from '../../../FormComponets'

const PetModalRegistrationTab = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    record,
    onSave,
    setIsFormChanged,
    _dataRoots,
  } = props

  // component states ----------------------------------------------------------
  const [breedOptions, setBreedOptions] = useState([])
  const [kind, setKind] = useState('puppy')

  // local variables -----------------------------------------------------------

  const [form] = Form.useForm()
  const intl = useIntl()
  const elRefs = buildElementsRefs(useRef)
  const kindOptions = buildKindOptions()
  const sizeOptions = buildSizeOptions()
  const colorOptions = buildPetColorOptions()

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    refreshKind()
    handleFocus()
  }, [record]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  function handleFocus (field = 'name', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  useImperativeHandle(ref, () => ({
    onSubmit: field => form.submit(),
  }))

  function refreshKind (value = form.getFieldValue('kind') || record?.kind) {

    setKind(value)

    let breeds
    switch (value) {

      case 'puppy':
        breeds = buildPuppyBreedOptions()
        break

      case 'cat':
        breeds = buildCatBreedOptions()
        break

      case 'bird':
        breeds = buildBirdBreedOptions()
        break

      case 'hamster':
        breeds = []
        break

      default:
        breeds = []
    }

    if (breeds.findIndex(b => b.value === form.getFieldValue('breed')) === -1) {
      form.setFieldValue('breed', '')
    }

    setBreedOptions(breeds)
  }

  const onValuesChanges = () => {
    setIsFormChanged(formDataChanges(form, record))
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs)

  const onFinish = values => onSave && onSave(values)

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <Form
      form={form}
      layout="vertical"
      initialValues={record}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChanges}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      preserve={false}>


      {/* BASIC INFO */}
      <FormTitle title={'pet_data'}/>


      <Row gutter={ROW_GUTTER}>


        {/* NAME */}
        <Col xs={24} md={8}>
          <FormInput name="name"
                     elRef={elRefs.name}
                     disabled={_dataRoots?.updateSentAt}
                     required/>
        </Col>
        {/* NAME */}


        {/* KIND */}
        <Col xs={24} md={8}>
          <FormSelect name="kind"
                      onChange={refreshKind}
                      elRef={elRefs.kind}
                      options={kindOptions}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* KIND */}


        {/* COLOR */}
        <Col xs={24} md={8}>
          <FormSelect name="color"
                      label="predominant_color"
                      elRef={elRefs.color}
                      options={colorOptions}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* COLOR */}


      </Row>


      {['puppy', 'cat'].includes(kind) && <Row gutter={ROW_GUTTER}>


        {/* BREED */}
        <Col xs={24} md={!['puppy'].includes(kind) ? 24 : 16}>
          <FormSelect name="breed"
                      elRef={elRefs.breed}
                      options={breedOptions}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* BREED */}


        {/* SIZE */}
        {['puppy'].includes(kind) && <Col xs={24} md={8}>
          <FormSelect name="size"
                      elRef={elRefs.size}
                      options={sizeOptions}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col>}
        {/* SIZE */}

      </Row>}


      <Row gutter={ROW_GUTTER}>


        {/* BIRTHDAY */}
        <Col xs={24} sm={12}>
          <FormDatePicker name="birthday"
                          label="birthday"
                          elRef={elRefs.birthday}
                          disabled={_dataRoots?.updateSentAt}/>
        </Col>
        {/* BIRTHDAY */}


        {/* VACCINATED AT */}
        <Col xs={24} sm={12}>
          <FormDatePicker name="vaccinatedAt"
                          label="last_vaccination"
                          elRef={elRefs.vaccinatedAt}
                          disabled={_dataRoots?.updateSentAt}/>
        </Col>
        {/* VACCINATED AT */}


      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* NOTE */}
        <Col xs={24}>
          <FormInput name="note"
                     label="observation"
                     disabled={_dataRoots?.updateSentAt}
                     elRef={elRefs.note}/>
        </Col>
        {/* NOTE */}

      </Row>



    </Form>


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildPetColorOptions () {

    const colorsList = [
      'black',
      'brown',
      'beige',
      'caramel',
      'checkered',
      'gray',
      'orange',
      'peach',
      'sand',
      'spotted',
      'white',
      'yellow',
    ]

    const colors = colorsList.map((color) => {
      return {
        value: color,
        text: translate(intl, color),
        label: translate(intl, color),
        disabled: false,
      }
    })

    colors.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return colors
  }

  function buildSizeOptions () {

    const sizeBrands = [
      'small',
      'medium',
      'large',
    ]

    return sizeBrands.map((size) => {
      return {
        value: size,
        text: translate(intl, size),
        label: translate(intl, size),
        disabled: false,
      }
    })

  }

  function buildKindOptions () {

    const kindBrands = [
      'puppy',
      'cat',
      'bird',
      'hamster',
    ]

    const kinds = kindBrands.map((kind) => {
      return {
        value: kind,
        text: translate(intl, kind),
        label: translate(intl, kind),
        disabled: false,
      }
    })

    kinds.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return kinds
  }

  function buildPuppyBreedOptions () {

    const puppyBreeds = [
      'akita_inu',
      'american_cocker_spaniel',
      'american_pit_bull_terrier',
      'argentinian_dogo',
      'australian_shepherd',
      'basset_hound',
      'basenji',
      'beagle',
      'beauce_shepherd',
      'bernese_mountain_dog',
      'bichon_frise',
      'border_collie',
      'bordeaux_dog',
      'boxer',
      'cane_corso',
      'cavalier_king_charles_spaniel',
      'chihuahua',
      'chow_chow',
      'dachshund',
      'dalmatian',
      'doberman_pinscher',
      'english_bulldog',
      'french_bulldog',
      'german_mastiff',
      'german_shepherd',
      'german_spitz',
      'golden_retriever',
      'irish_setter',
      'jack_russell_terrier',
      'kuvasz',
      'labrador_retriever',
      'lhasa_apso',
      'maremmano_abruzzese_shepherd',
      'maltese',
      'miniature_schnauzer',
      'neapolitan_mastiff',
      'pinscher',
      'pomeranian',
      'poodle',
      'rottweiler',
      'shar_pei',
      'shetland_sheepdog',
      'shih_tzu',
      'siberian_husky',
      'staffordshire_bull_terrier',
      'weimaraner',
      'welsh_corgi_pembroke',
      'west_highland_white_terrier',
      'yorkshire_terrier',
      'mongrel',
    ]

    const puppys = puppyBreeds.map((puppy) => {
      return {
        value: puppy,
        text: translate(intl, puppy),
        label: translate(intl, puppy),
        disabled: false,
      }
    })

    puppys.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return puppys
  }

  function buildCatBreedOptions () {

    const catBreeds = [
      'siamese',
      'persa',
      'maine_coon',
      'ragdoll',
      'siamese_snowshoe',
      'sphynx',
      'british_shorthair',
      'munchkin',
      'exótico',
      'abyssinian',
      'mongrel',
    ]

    const cats = catBreeds.map((cat) => {
      return {
        value: cat,
        text: translate(intl, cat),
        label: translate(intl, cat),
        disabled: false,
      }
    })

    cats.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return cats
  }

  function buildBirdBreedOptions () {

    const birdBreeds = [
      'cockatiels',
      'canaries',
      'budgerigars',
      'toucans',
      'macaws',
      'parrots',
      'mandarins',
    ]

    const birds = birdBreeds.map((bird) => {
      return {
        value: bird,
        text: translate(intl, bird),
        label: translate(intl, bird),
        disabled: false,
      }
    })

    birds.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return birds
  }

  function buildElementsRefs (hookUseRef) {
    return {
      kind: hookUseRef(null),
      name: hookUseRef(null),
      breed: hookUseRef(null),
      color: hookUseRef(null),

      size: hookUseRef(null),
      birthday: hookUseRef(null),
      vaccinatedAt: hookUseRef(null),

      note: hookUseRef(null),
    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default PetModalRegistrationTab


