import React from 'react';
import { Col, Grid, Row, Spin } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import NoData from './NoData';
import { connect } from 'react-redux';
import utils from 'utils';
import { NAV_TYPE_TOP } from '../../constants/ThemeConstant';

const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString();

const Loading = ({
  isFetching,
  data,
  navCollapsed,
  navType,
  fullscreen,
  ignoreNoData,
  marginTop,
  children,
}) => {

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  if (!isFetching) {
    if (!ignoreNoData && (!data || data.length === 0)) return <NoData/>;
    return null;
  }

  const keyLanguage = data ? 'refreshing_data' : 'loading_data';
  const isNavTop = navType === NAV_TYPE_TOP;

  const left = (isMobile || fullscreen || isNavTop)
    ? 0
    : navCollapsed ? 80 : 250;

  return (

    <Row justify="center" align="middle" style={{
      // height: '100%',
      height: '80%',
      position: 'fixed',
      left: left,
      right: 0,
      zIndex: 2,
      background: '#FAFAFB',
      opacity: data ? 0.9 : 1,
      // marginTop: marginTop || -62,
    }}>
      <Col>
        <Spin tip={setLocale(true, keyLanguage)} size="large"
              style={{ marginTop: -120 }}
        />
      </Col>
    </Row>

  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType
  } = theme;
  return {
    navCollapsed,
    navType
  };
};

export default connect(mapStateToProps)(Loading);

