import React, { forwardRef, useState } from 'react'
import { Button, Grid, Popconfirm, Row, Table, Tooltip } from 'antd'
import PetModal from '../modals/PetModal'
import IntlMessage from 'components/util-components/IntlMessage'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import utils from 'utils'
import { translate } from 'utils/react-jarvisly-helper'
import { useIntl } from 'react-intl'
import { substitution } from 'utils/helpers'
import ImageDescription from 'components/jarvisly/ImageDescription'
import imgHamster from 'assets/img/hamster.png'
import imgEgg from 'assets/img/egg.png'
import imgPuppy from 'assets/img/puppy.png'
import imgCat from 'assets/img/cat.png'
import imgBird from 'assets/img/bird.png'
import { pullSubdocumentByUri, putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import { capitalize, clone } from 'jarvisly-helper'
import { FormNextPrevious, FormTitle } from '../../../FormComponets'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// STEP 5 PETS COMPONENT *******************************************************
// *****************************************************************************

const PetsStep = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    step,
    setStep,
  } = props


  // local variables -----------------------------------------------------------
  const { useBreakpoint } = Grid
  const sm = utils.getBreakPoint(useBreakpoint()).includes('sm')
  const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const intl = useIntl()
  const t = buildTranslations()
  const columns = buildPetsTableColumn()

  // component states ----------------------------------------------------------
  const [showPetModal, setShowPetModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState()


  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  const onAdd = () => {
    setSelectedRecord(null)
    setShowPetModal(true)
  }

  const onEdit = (record) => {
    setSelectedRecord(record)
    setShowPetModal(true)
  }

  const onDelete = async _id => {

    setDataContext({ ...dataContext, partialLoading: true })

    const doc = await pullSubdocumentByUri('/atualiza-info', _id, 'pets')
    await putByUri('/atualiza-info', _dataRoots?._id, { petsUpdated: false })

    setDataContext({
      ...dataContext, data: { ...doc }, dataLock: { ...clone(doc) }, partialLoading: false,
    })

  }

  const onNextStep = async () => {
    if (_dataRoots.currentStep <= step) {

      setDataContext({ ...dataContext, partialLoading: true })

      const body = { currentStep: step + 1 }
      const doc = await putByUri('/atualiza-info', _dataRoots?._id, body)

      setDataContext({
        ...dataContext, data: { ...doc }, dataLock: { ...clone(doc) }, partialLoading: false,
      })
    }

    // setStep(prev => prev + 1)
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <PetModal {...props}
              showModal={showPetModal}
              setShowModal={setShowPetModal}
              record={selectedRecord}/>

    {_data?.length > 0 && <div>

      {/* PETS */}
      <FormTitle title={'pets'}/>


      <Table rowKey="_id"
             pagination={false}
             columns={columns?.filter(c => !c.hidden) || []}
             dataSource={_data}
             loading={dataContext?.partialLoading}/>
    </div>}


    <Row justify="center" align="middle" className="m-3 mt-4">
      <Button type="primary"
              disabled={_dataRoots?.updateSentAt}
              onClick={onAdd}>
        {setLocale(true, 'add_pet')}
      </Button>

    </Row>
    {/* PETS */}


    {/* PREVIOUS AND NEXT BUTTON */}
    <FormNextPrevious dataContext={dataContext}
                      step={step}
                      setStep={setStep}
                      onNextClick={onNextStep}
                      _dataRoots={_dataRoots}/>
    {/* PREVIOUS AND NEXT BUTTON */}


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    const remove_this_item = translate(intl, 'remove_this_item')
    const pet = translate(intl, 'pet')
    const t_remove_this_item = substitution(remove_this_item, ['%ITEM%'], [pet])

    return {
      remove_this_item: t_remove_this_item,
    }
  }

  function buildPetsTableColumn () {
    return [

      {
        title: setLocale(true, 'name'), render: (_, record) => {

          let img
          switch (record?.kind) {

            case 'puppy':
              img = imgPuppy
              break

            case 'cat':
              img = imgCat
              break

            case 'bird':
              img = imgBird
              break

            case 'hamster':
              img = imgHamster
              break

            default:
              img = imgEgg
              break
          }

          const subtitle = translate(intl, record?.kind)

          return (<div className="text-left">
            <ImageDescription
              shape="square"
              imageUrl={img}
              title={record?.name?.toUpperCase()}
              subtitle={capitalize(subtitle)}
              style={{ padding: 10 }}
              backColor="#ecf2fe"
            />
          </div>)
        },
      },

      {
        title: setLocale(true, 'breed'),
        hidden: !md,
        render: (_, record) => (<div className="text-left text-capitalize">
          {record?.breed && setLocale(true, record?.breed)}
        </div>),
      },

      {
        title: setLocale(true, 'color'),
        hidden: !sm,
        render: (_, record) => (<div className="text-left text-capitalize">
          {setLocale(true, record?.color)}
        </div>),
      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 120, //113,
        className: 'text-center',
        render: (_, record) => {
          return (<>
            <Tooltip title={setLocale(true, !_dataRoots?.updateSentAt ? 'edit' : 'view')}>
              <Button size="small"
                // className={!isMobile ? 'mr-2' : 'mb-2'}
                      className="m-2"
                      icon={!_dataRoots?.updateSentAt ? <EditOutlined/> : <EyeOutlined/>}
                      onClick={() => onEdit(record)}/>
            </Tooltip>

            {!_dataRoots?.updateSentAt && <Popconfirm title={t.remove_this_item}
                                                      cancelButtonProps={{ className: 'fixed-with-90' }}
                                                      okButtonProps={{ className: 'fixed-with-90' }}
                                                      onConfirm={() => onDelete(record?._id)}>
              <Button size="small" icon={<DeleteOutlined/>}/>
            </Popconfirm>}
          </>)
        },
      }]
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default PetsStep


