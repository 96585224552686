import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Row } from 'antd';
import utils from 'utils';

const AvatarOrText = props => {

  let {
    name,
    // type,
    text,
    backcolor,
    forecolor,
  } = props;

  const style = {
    backgroundColor: backcolor || 'cyan',
    color: forecolor || 'white',
  };

  if (!text) text = utils.getNameInitial(name);

  return (

    <Avatar {...props}
            style={style}>
      {text}
    </Avatar>

  );
};

export const AvatarStatus = props => {
    let {
      name,
      suffix,
      subTitle,
      subtitle,
      id, // type,
      src, // icon,
      // size,
      // shape,
      // gap,
      // text,
      onNameClick,
      notitle,
      center,
    } = props;

    return (
      center
        ?
        <Row justify="center" className="avatar-status d-flex align-items-center">
          <AvatarOrText {...props} />

          {notitle !== 'true' &&
            <div className="ml-2">
              <div>
                {onNameClick ? <div onClick={() => onNameClick({
                    name,
                    subTitle,
                    subtitle,
                    src,
                    id,
                  })} className="avatar-status-name clickable">{name}</div> :
                  <div className="avatar-status-name">{name}</div>}
                <span>{suffix}</span>
              </div>
              <div className="text-muted avatar-status-subtitle">
                {subTitle || subtitle}
              </div>
            </div>
          }
        </Row>
        :
        <div className="avatar-status d-flex align-items-center">
          <AvatarOrText {...props} />

          {notitle !== 'true' &&
            <div className="ml-2">
              <div>
                {onNameClick ? <div onClick={() => onNameClick({
                    name,
                    subTitle,
                    subtitle,
                    src,
                    id,
                  })} className="avatar-status-name clickable">{name}</div> :
                  <div className="avatar-status-name">{name}</div>}
                <span>{suffix}</span>
              </div>
              <div className="text-muted avatar-status-subtitle">
                {subTitle || subtitle}
              </div>
            </div>
          }
        </div>
    );
  }
;

AvatarStatus.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  onNameClick: PropTypes.func,
};

export default AvatarStatus;
;
