import React, {forwardRef} from 'react';
import IntlMessage from '../../../util-components/IntlMessage';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const NoSection = forwardRef((props, ref) => {

  // UI COMPONENT --------------------------------------------------------------
  return (<div className="p-4">
    <h3 className="text-uppercase">
      {setLocale(true, 'section_in_development')}
    </h3>
    <div>/{props?.match?.params?.section}</div>
    {/*<div>{props?.match?.path}</div>*/}
  </div>);
});

// EXPORT **********************************************************************
// *****************************************************************************

export default NoSection;
