import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, } from 'react';
import { Card, Col, Form, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  FormInputNumber,
  FormSaveButton,
  FormSimpleSwitch,
  FormWrapper
} from '../../../portals/autaliza-info/FormComponets';
import IntlMessage from 'components/util-components/IntlMessage';
import { buildDevicesOptions, getDevicePicture } from '../../methods';
import { useIntl } from 'react-intl';
import { translate } from 'utils/react-jarvisly-helper';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const IotAlarms = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _module,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props;

  console.log('_data...', _data);

  // component states ----------------------------------------------------------
  const [, setSubmitted] = useState(false);
  const [alarmed, setAlarmed] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [preSelected, setPreSelected] = useState({
    alertActivation: 'reduced',
    emergencyActivation: 'reduced',
  });

  // local variables -----------------------------------------------------------
  const isDisabled = formContext.mode === 'view';
  const formName = 'alarmForm';
  const intl = useIntl();
  const t = buildTranslations();
  const [form] = Form.useForm();
  const devicesOptions = buildDevicesOptions();
  const prefixOptions = buildPrefixOptions();
  const elRefs = buildElementsRefs(useRef);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {

    form.resetFields();

    if (_data?._id) {

      form?.setFieldsValue(_data);
      _data.pictureUrl = getDevicePicture(_data?.device, intl);

      // } else {
      //   // workaround because after reset form the tag refs be lost
      //   setTimeout(() => handleFocus(undefined, 'all'));
    }

    const sel = devicesOptions?.find(x => x?.value === _data?.device);
    setSelectedDevice(sel || {});
    if (_data?.alarm?.alertActivation) {
      setPreSelected({
        ...preSelected,
        alertActivation: _data?.alarm?.alertActivation
      });
    }
    if (_data?.alarm?.emergencyActivation) {
      setPreSelected({
        ...preSelected,
        emergencyActivation: _data?.alarm?.emergencyActivation
      });
    }
    setDataContext({
      ...dataContext,
      formsChanged: []
    });

    setTimeout(() => handleFocus(undefined, 'all'));

  }, [_data, form, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
  }));

  function handleFocus (field = 'alert', cursorPosition = 'all') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition });
    console.log('=>', field, elRefs?.[field], elRefs?.[field]?.current);
  }

  const onCheckAlarm = check => {
    console.log('check?', check);
    setAlarmed(check);
    return check;
  };

  const onBeforeSave = async values => {

    setSubmitted(true);

    const body = {
      _id: _data._id,
      ...values,
    };

    body.alarm = { ...body.alarm, ...preSelected };

    console.log('PUT BODY ->', body);

    return false;
    //
    // body._id = _data?._id;
    // return body;
  };

  const setPrefixes = (value, field) => {
    setPreSelected({
      ...preSelected,
      [field]: value
    });
  };

  // UI COMPONENT --------------------------------------------------------------

  return (

    <FormWrapper name={formName}
                 form={form}
                 elRefs={elRefs}
                 uriApi={_module.api}
                 initialValues={_data}
                 dataContext={dataContext}
                 setDataContext={setDataContext}
                 formContext={formContext}
                 setFormContext={setFormContext}
                 onBeforeSave={onBeforeSave}
                 showToast>


      {/* ************************************************************
      ALARM PARAMS
      ************************************************************ */}

      <Card className="title-with-switch"
            title={
              <Row justify="space-between" align="start">
                <div>{setLocale(true, 'activate_alarm')}</div>

                <FormSimpleSwitch name={['alarm', 'activated']}
                                  label="activated"
                                  style={{ margin: 0 }}
                                  noLabel
                                  form={form}
                                  onChange={onCheckAlarm}
                                  value={_data?.activated}
                                  elRef={elRefs?.activated}
                                  disabled={isDisabled}/>
              </Row>
            }>


        <Row gutter={ROW_GUTTER}>

          {/* ALERT */}
          <Col xs={24} lg={8}>
            <FormInputNumber name={['alarm', 'alert']}
                             label="alert"
                             autoFocus
                             elRef={elRefs.alert}
                             disabled={isDisabled || !alarmed}
                             decimalSeparator=","
                             suffixStyle={{ width: 60 }}
                             addonAfter={selectedDevice?.symbol}
                             onPrefixChange={value => setPrefixes(value, 'alertActivation')}
                             prefixStyle={{
                               maxWidth: 120,
                               textAlign: 'left'
                             }}
                             prefixDefaultValue={preSelected?.alertActivation}
                             addonBefore={prefixOptions}
                             min={1}
                             max={100}
                             required/>
          </Col>
          {/* ALERT */}


          {/* EMERGENCY */}
          <Col xs={24} lg={8}>
            <FormInputNumber name={['alarm', 'emergency']}
                             label="emergency"
                             elRef={elRefs.emergency}
                             disabled={isDisabled || !alarmed}
                             decimalSeparator=","
                             suffixStyle={{ width: 60 }}
                             addonAfter={selectedDevice?.symbol}
                             onPrefixChange={value => setPrefixes(value, 'emergencyActivation')}
                             prefixStyle={{
                               maxWidth: 120,
                               textAlign: 'left'
                             }}
                             prefixDefaultValue={preSelected?.emergencyActivation}
                             addonBefore={prefixOptions}
                             min={1}
                             max={100}
                             required/>
          </Col>
          {/* EMERGENCY */}


          {/* STATUS */}
          <Col xs={24} lg={8}>
            <FormInputNumber name={['alarm', 'status']}
                             label="status"
                             elRef={elRefs.status}
                             disabled={isDisabled || !alarmed}
                             decimalSeparator=","
                             suffixStyle={{ width: 60 }}
                             addonAfter={'minutes'}
                             min={3}
                             max={1440} // 1440 minutes = 24h
                             required/>
          </Col>
          {/* STATUS */}
        </Row>


      </Card>


      {/* ************************************************************
      SAVE BUTTON
      ************************************************************ */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      form={form}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      setSubmitted={setSubmitted}
                      disabled={isDisabled}/>


    </FormWrapper>

  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    return {
      reduced_to: translate(intl, 'reduced_to'),
      elevated_to: translate(intl, 'elevated_to'),
    };

  }

  function buildPrefixOptions () {

    console.log('t', t.reduced_to, t.elevated_to);

    return [
      {
        value: 'reduced',
        label: t.reduced_to,
        // disabled: false,
      },
      {
        value: 'elevated',
        label: t.elevated_to,
        // disabled: false,
      },
    ];

  }

  function buildElementsRefs (hookUseRef) {
    return {

      // alarm
      alert: hookUseRef(null),
      emergency: hookUseRef(null),
      status: hookUseRef(null),

    };
  }

});

export default IotAlarms;
