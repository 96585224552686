import React from 'react';
import { Card, Popover } from 'antd';
import PropTypes from 'prop-types';
import { ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const StatisticWidget = ({
  title,
  value,
  status,
  subtitle,
  prefix,

  suffix = '',
  infoContent,
  infoTitle,
  notFoundTitle,
  record
}) => {
  return (
    <Card>

      {infoContent
        ? <div className="text-right">
          <Popover placement="top"
                   title={infoTitle}
                   content={infoContent}
                   trigger="hover">
            <ExclamationCircleOutlined/>
          </Popover>
        </div>
        : null
      }


      <div className="text-center">
        {title && <h4 className="mb-0">{title}</h4>}

        <div className={`${prefix ? 'd-flex' : ''} ${title ? 'mt-3' : ''}`}>
          {prefix ? <div className="mr-2">{prefix}</div> : null}

          <div>

            <div className="d-flex align-items-center justify-content-center m-4">

              <h1 className="mb-0 font-weight-bold"
                  style={{ opacity: (!record || record?.installed) ? 1 : 0.3 }}>
                {(!record || record?.installed) ? `${value}${suffix}` : (notFoundTitle || `0${suffix}`)}
              </h1>

              {
                status ?
                  <span style={{ opacity: (!record || record?.installed) ? 1 : 0.3 }}
                        className={`font-size-md font-weight-bold ml-3 ${status !== 0 && status > 0 ? 'text-success' : 'text-danger'}`}>
                    {status}{suffix}
                    {status !== 0 && status > 0
                      ? <ArrowUpOutlined/>
                      : <ArrowDownOutlined/>}
				</span>
                  : null
              }
            </div>

            {subtitle && <div className="text-gray-light mt-1">{subtitle}</div>}
          </div>

        </div>

      </div>

    </Card>
  );
};

StatisticWidget.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  value: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.number,
  prefix: PropTypes.element
};

export default StatisticWidget;
