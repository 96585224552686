import IntlMessage from 'components/util-components/IntlMessage';
import React from 'react';
import imgWaterLevelSensor from 'assets/img/water_level_sensor.png';
import imgTemperatureSensor from 'assets/img/temperature_sensor.png';
import imgHumiditySensor from 'assets/img/humidity_sensor.png';
import imgDistanceSensor from 'assets/img/distance_sensor.png';
import imgSwitchDevice from 'assets/img/switch_device.png';
import imgPowerSensor from 'assets/img/power_sensor.png';
import imgWaterFlowSensor from 'assets/img/water_flow_sensor.png';
import { BiWater } from 'react-icons/bi';
import { LuThermometerSnowflake } from 'react-icons/lu';
import { ImSwitch } from 'react-icons/im';
import { TfiRulerAlt } from 'react-icons/tfi';
import { RiContrastDrop2Line, RiDashboard3Line } from 'react-icons/ri';
import { getIotCollectorDeviceByChannel } from './api';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { FaFaucet } from 'react-icons/fa';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// ************************************************************************** //
// MODULE CUSTOM METHODS
// ************************************************************************** //

export const buildDevicesFilter = () => {
  return [
    {
      value: 'not_identified',
      text: setLocale(true, 'not_identified'),
      color: '#c0c0c0',
      textColor: 'white',
    },
    {
      value: 'water_level_sensor',
      text: setLocale(true, 'water_level_sensor'),
      // color: '#2db7f5',
      color: '#ecf2fe',
      textColor: 'black',
    },
    {
      value: 'temperature_sensor',
      text: setLocale(true, 'temperature_sensor'),
      // color: '#2db7f5',
      // color: '#ff7a7a',
      color: '#ecf2fe',
      textColor: 'black',
    },
  ];
};

export const buildOnOffStatusFilter = onOff => {
  return [
    {
      value: onOff ? 'on' : 'online',
      text: setLocale(true, onOff ? 'on' : 'online'),
      color: 'green',
    },
    {
      value: onOff ? 'off' : 'offline',
      text: setLocale(true, onOff ? 'off' : 'offline'),
      color: 'gray',
    },
  ];
};

export const buildDevicesOptions = (hideTitle) => {

  const t = {
    all_sensors: setLocale(true, 'all_sensors'),
    water_level_sensor: setLocale(true, 'water_level_sensor'),
    water_level: setLocale(true, 'water_level'),
    temperature_sensor: setLocale(true, 'temperature_sensor'),
    temperature: setLocale(true, 'temperature'),
    humidity_sensor: setLocale(true, 'humidity_sensor'),
    humidity: setLocale(true, 'humidity'),
    distance_sensor: setLocale(true, 'distance_sensor'),
    distance: setLocale(true, 'distance'),
    switch_device: setLocale(true, 'switch_device'),
    switch: setLocale(true, 'switch'),
    power_sensor: setLocale(true, 'power_sensor'),
    power_consumption: setLocale(true, 'power_sensor'),
    water_flow_sensor: setLocale(true, 'water_flow_sensor'),
    water_flow: setLocale(true, 'water_flow_sensor'),
  };

  const options = [
    {
      value: 'all_sensors',
      text: t.all_sensors,
      label: t.all_sensors,
      title: t.all_sensors,
      // img: imgWaterLevelSensor,
      icon: <RiDashboard3Line/>,
      // disabled: false,
      // color: '#ecf2fe',
      // textColor: 'black',
      // symbol: '%',
      // metric: 'cm',
      // hide: true
    },
    {
      value: 'water_level_sensor',
      text: t.water_level_sensor,
      label: t.water_level_sensor,
      title: t.water_level,
      img: imgWaterLevelSensor,
      icon: <BiWater/>,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: '%',
      metric: 'cm'
    },
    {
      value: 'temperature_sensor',
      text: t.temperature_sensor,
      label: t.temperature_sensor,
      title: t.temperature,
      img: imgTemperatureSensor,
      icon: <LuThermometerSnowflake/>,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: '°',
      metric: '°'
    },
    {
      value: 'humidity_sensor',
      text: t.humidity_sensor,
      label: t.humidity_sensor,
      title: t.humidity,
      img: imgHumiditySensor,
      icon: <RiContrastDrop2Line/>,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: '%',
      metric: '%',
    },
    {
      value: 'distance_sensor',
      text: t.distance_sensor,
      label: t.distance_sensor,
      title: t.distance,
      img: imgDistanceSensor,
      icon: <TfiRulerAlt/>,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: 'cm',
      metric: 'cm'
    },

    {
      value: 'power_sensor',
      text: t.power_sensor,
      label: t.power_sensor,
      title: t.power_consumption,
      img: imgPowerSensor,
      icon: <AiOutlineThunderbolt/>,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: 'cm',
      metric: 'cm'
    },

    {
      value: 'water_flow_sensor',
      text: t.water_flow_sensor,
      label: t.water_flow_sensor,
      title: t.water_flow,
      img: imgWaterFlowSensor,
      icon: <FaFaucet/>,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: 'cm',
      metric: 'cm'
    },

    {
      value: 'switch_device',
      text: t.switch_device,
      label: t.switch_device,
      title: t.switch,
      img: imgSwitchDevice,
      icon: <ImSwitch/>,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: 'on/off',
      metric: '1/0'
    },
  ];

  return hideTitle
    ? options.map(x => {
      return {
        value: x.value,
        icon: x.icon
      };
    })
    : options.filter(x => x.metric);
};

export const getDevicePicture = device => {
  if (!device) return;

  const d = buildDevicesOptions();
  const selectedImg = d.find(x => x.value === device);
  return selectedImg?.img;
};

export const refresh24hGraphicsByChannel = async (_dataLock, body) => {

  if (_dataLock?.channel === body.channel) return;

  const iot = await getIotCollectorDeviceByChannel(body.service, body.channel);

  body.feeds = iot?.feeds || [];
  body.status = iot?.status || 'offline';
  body.lastReadingAt = iot?.lastReadingAt;
  body.lastEntryKey = iot?.lastEntryKey;
  body.lastField1 = iot?.lastField1;
  body.lastField2 = iot?.lastField2;
  body.lastField3 = iot?.lastField3;
  body.lastField4 = iot?.lastField4;

};

