import React, { useEffect, useRef, useState } from 'react'
import { Button, Table, Tag, Tooltip } from 'antd'
import IntlMessage from 'components/util-components/IntlMessage'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { buildTableColumnSearch } from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import ImageDescription from 'components/jarvisly/ImageDescription'
import moment from 'moment'
import DocumentTags from 'components/jarvisly-layouts/ModuleComponent/Components/DocumentTags'
import { useSelector } from 'react-redux'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const MovesMainList = props => {

  // props deconstruction ------------------------------------------------------

  const {
    search,
    _module,
    _data,
    _summary,
    _loading,
    rdxModuleQuery,
    onChange,
    onClickOpen,
    onClickPreview,
  } = props

  // local variables -----------------------------------------------------------

  const nameRef = useRef()
  const rdxModuleSettings = useSelector(s => s.moduleRedux.rdxModuleSettings)

  // component states ----------------------------------------------------------
  const [state, setState] = useState({})

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    setState({
      ...state,
      blocks: rdxModuleSettings?.apartments?.blocks || [],
    })
  }, [rdxModuleSettings]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  const tableColumns = [
    {
      hidden: !rdxModuleSettings?.showNumberOfLine,
      title: setLocale(true, 'line_#'),
      width: 80,
      render: (item, record, index) => (<div className="text-left">
        {(_summary?.pagination?.current * _summary?.pagination?.pageSize) -
          _summary?.pagination?.pageSize + index + 1}
      </div>),
    },

    {
      title: setLocale(true, 'type'),
      dataIndex: 'type',
      width: 140,
      render: (_, record) => (<>
        {record?.type === 'exit'
          ? <Tag key={'exit'} color={'red'}>
                          <span className="text-uppercase">
                            {setLocale(true, 'exit')}
                          </span>
          </Tag>
          : <Tag key={'entrance'} color={'green'}>
                          <span className="text-uppercase">
                            {setLocale(true, 'entrance')}
                          </span>
          </Tag>}
      </>),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'type' && rdxModuleQuery?.currentQuery?.sortOrder,
    },

    {
      title: setLocale(true, _module?.singular),
      dataIndex: 'roomNumber',
      filteredValue: search?.room && search.room !== 'null'
        ? search?.room
        : null, ...buildTableColumnSearch('roomNumber', nameRef),
      shouldCellUpdate: () => false,
      className: 'text-truncate',
      render: (_, record) => {

        // const tFloor = translate(intl, 'floor')
        // const tDirectCall = translate(intl, 'direct_call')
        // const subtitle = `${record?.__apartment?.floor}º ${tFloor}, ${tDirectCall} ${record?.__apartment?.directCall}`

        return (<div className="d-flex" style={{ width: 200 }}>

          <ImageDescription
            shape="square"
            initials={record?.__apartment?.room}
            title={record?.__apartmentTitle}
            // subtitle={record?.roomNumber}
            subtitle={record?.name}
            foreColor={record?.__apartment?.block?.foreColor}
            backColor={record?.__apartment?.block?.backColor}
          />
        </div>)
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'roomNumber' &&
        rdxModuleQuery?.currentQuery?.sortOrder,
    },
    {
      title: setLocale(true, 'block_unit'),
      dataIndex: 'blockId', // width: 168.5,
      render: (_, record) => (<>
        <Tag key={record._id} color={record?.__apartment.block?.backColor}>
            <span className="text-uppercase" style={{ color: record?.__apartment.block?.foreColor }}>
              {record?.__apartment.block?.name}
            </span>
        </Tag>
      </>),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'blockId' && rdxModuleQuery?.currentQuery?.sortOrder,
      filters: state?.blocks && state.blocks?.map(x => {
        return {
          value: x._id,
          text: x.name,
        }
      }),
      filteredValue: search?.blockId && search.blockId !== 'null' ? search?.blockId : null,
    },

    {
      title: setLocale(true, 'date'),
      width: 140,
      render: (_, record) => (<div className="text-left">
        <div className="font-weight-bold">
          {record?.moveAt && moment(record.moveAt).format('L')}
        </div>
        <div className="text-uppercase">
          {setLocale(true, record?.period || 'n_a')}
        </div>
      </div>),
    },

    {
      title: setLocale(true, 'status'),
      dataIndex: 'documentStatus',
      width: 280,
      render: (_, record) => (<DocumentTags record={record}/>),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField === 'documentStatus' &&
        rdxModuleQuery?.currentQuery?.sortOrder,
      filters: [
        {
          text: setLocale(true, 'updated'), value: 'updated',
        },
        {
          text: setLocale(true, 'not_updated'), value: 'not_updated',
        },
        {
          text: setLocale(true, 'pending_update'), value: 'pending_update',
        },
        {
          text: setLocale(true, 'in_analise'), value: 'in_analise',
        },
        {
          text: setLocale(true, 'with_reservation'), value: 'with_reservation',
        },
        {
          text: setLocale(true, 'revised'), value: 'revised',
        },
      ],
      filteredValue: search?.documentStatus && search.documentStatus !== 'null'
        ? search?.documentStatus
        : null,
    },

    {
      title: setLocale(true, 'last_update'),
      dataIndex: ['_metadata', 'audit', 'updatedAt'],
      width: 160,
      hidden: false,
      render: (_, record) => (<>
        {record?._metadata?.audit?.updatedAt &&
          moment(record._metadata.audit.updatedAt).format('L LT')}
      </>),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: rdxModuleQuery?.currentQuery?.sortField === '_metadata.audit.updatedAt' &&
        rdxModuleQuery?.currentQuery?.sortOrder,
    },

    /*

            {
                title: setLocale(true, 'last_update'),
                dataIndex: ['_metadata', 'audit', 'updatedAt'],
                width: 160,
                hidden: false,
                render: (_, record) => (<>
                    {record?._metadata?.audit?.updatedAt &&
                        moment(record._metadata.audit.updatedAt).format('L LT')}
                </>),
                sorter: true,
                sortDirections: ['ascend', 'descend', 'ascend'],
                sortOrder: rdxModuleQuery?.currentQuery?.sortField === '_metadata.audit.updatedAt' && rdxModuleQuery?.currentQuery?.sortOrder,
            },
    */

    {
      title: setLocale(true, 'actions'),
      dataIndex: 'actions',
      width: 50,
      render: (_, record) => (
        <div className="text-right d-flex justify-content-end">


          <Tooltip title={setLocale(true, 'edit')}>
            <Button className="mr-2" icon={<EditOutlined/>}
                    onClick={() => onClickOpen(record)} size="small"/>
          </Tooltip>


          <Tooltip title={setLocale(true, 'view')}>
            <Button className="mr-2" icon={<EyeOutlined/>}
                    onClick={() => onClickPreview(record)}
                    size="small"/>
          </Tooltip>
        </div>),
    }]

  return (

    <Table rowKey="_id"
           pagination={_summary?.pagination}
           columns={tableColumns.filter(x => !x.hidden)}
           dataSource={_data}
           onChange={onChange}
           loading={_loading}/>)
}

// EXPORT **********************************************************************
// *****************************************************************************

export default React.memo(MovesMainList)
