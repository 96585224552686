import React, { useEffect } from 'react'
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import AppLocale from 'lang'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import {
  ACCOUNT_PREFIX_PATH, APP_HEADERS,
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass'
import apiConfig from 'configs/ApiConfig'
import SchedulesIndex
  from 'views/app-views/_apps/dedalo/attendance/schedules/SchedulesIndex'
import { THEME_CONFIG } from 'configs/AppConfig'
import ErrorNoJarvislyApplication
  from 'components/jarvisly/ErrorNoJarvislyApplication'
import AtualizaIndex from '../views/app-views/portals/autaliza-info/AtualizaIndex'
import { signOut } from '../redux/actions/auth'
import InMaintenanceApplication from '../components/jarvisly/InMaintenance'

const inMaintenance = THEME_CONFIG.APP.IN_MAINTENANCE && !THEME_CONFIG.APP.DEV_MODE
const CAN_ACCESS_PORTAL_ATUALIZA_INFO = THEME_CONFIG.APP.DEV_MODE || THEME_CONFIG.APP?.PORTAL?.ATUALIZA_INFO

function RouteInterceptor ({
  children,
  isAuthenticated,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={({ location }) =>

        !THEME_CONFIG.APP.APPLICATION_CODE
          ? (<Redirect to="/failure"/>)
          : inMaintenance
            ? (<Redirect to="/in-maintenance"/>)
            : isAuthenticated
              ? (children)
              : (<Redirect to={{
                pathname: AUTH_PREFIX_PATH,
                state: { from: location },
              }}/>)
      }
    />
  )
}

export const Views = (props) => {

  const {
    locale,
    token,
    location,
    direction,
  } = props

  const dispatch = useDispatch()

  const currentAppLocale = AppLocale[locale]

  apiConfig.defaults.headers = APP_HEADERS

  useBodyClass(`dir-${direction}`)

  useEffect(() => {
    if (inMaintenance) dispatch(signOut())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>

      <ConfigProvider locale={currentAppLocale.antd}
                      direction={direction}>


        {inMaintenance
          ? <Switch>
            <Route exact path="/in-maintenance" component={InMaintenanceApplication}/>
            <Redirect to="/in-maintenance"/>
          </Switch>

          : <Switch>

            {!THEME_CONFIG.APP.APPLICATION_CODE &&
              <Route exact path="/failure" component={ErrorNoJarvislyApplication}/>
            }

            <Route exact path="/online-schedule">
              {
                ['jarvisly', 'dedalo'].includes(THEME_CONFIG.APP.ID)
                  ? <SchedulesIndex/>
                  :
                  <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard/home`}/>
              }
            </Route>


            {/* ATUALIZA PORTAL */}
            <Route exact path="/atualiza-index/:subscriptionId?/:section?">
              {
                ['jarvisly', 'sindicoerp'].includes(THEME_CONFIG.APP.ID) && CAN_ACCESS_PORTAL_ATUALIZA_INFO
                  ? <AtualizaIndex/>
                  : <ErrorNoJarvislyApplication/>
              }
            </Route>

            {/*
            <Route exact path="/atualiza-index/:section?">
              {
                ['jarvisly', 'sindicoerp'].includes(THEME_CONFIG.APP.ID)
                  ? <AtualizaIndex/>
                  : null
              }
            </Route>
*/}
            {/* ATUALIZA PORTAL */}


            {THEME_CONFIG.APP.APPLICATION_CODE &&
              <Route exact path="/">
                <Redirect to={APP_PREFIX_PATH}/>
              </Route>}

            {THEME_CONFIG.APP.APPLICATION_CODE &&
              <Route path={AUTH_PREFIX_PATH}>
                <AuthLayout direction={direction}/>
              </Route>}

            <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
              <AppLayout direction={direction} location={location}/>
            </RouteInterceptor>

            <RouteInterceptor path={ACCOUNT_PREFIX_PATH} isAuthenticated={token}>
              <AppLayout direction={direction} location={location}/>
            </RouteInterceptor>

            {!THEME_CONFIG.APP.APPLICATION_CODE
              ? <Redirect to="/failure"/>
              : <Redirect to={`${APP_PREFIX_PATH}/dashboard/home`}/>
            }

          </Switch>}

      </ConfigProvider>

    </IntlProvider>
  )
}

const mapStateToProps = ({
  theme,
  auth,
}) => {

  const {
    locale,
    direction,
  } = theme

  const {
    token,
  } = auth

  return {
    locale,
    direction,
    token,
  }
}

export default withRouter(connect(mapStateToProps)(Views))
