import React from 'react'
import {
  decorateDocument,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
  getMovesModuleParameters,
  parseSettings,
} from './moves-methods'
import MovesMainList from './MovesMainList'
import {
  AuditOutlined,
  CarOutlined,
  NodeExpandOutlined,
  RocketOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Route } from 'react-router-dom'
import {
  defaultDecorateSettings,
  defaultDataProfileValidation,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import { MovesDocumentHeaderColumn2, MovesDocumentHeaderColumn3, MovesDocumentSubtitle } from './MovesDocumentHeader'
import MovesProfile from './sections/MovesProfile'
import moment from 'moment'
import MovesTitular from './sections/MovesTitular'
import MovesHabitation from './sections/MovesHabitation'
import MovesResidents from './sections/MovesResidents'
import MovesVehicles from './sections/MovesVehicles'
import { TbBike, TbDog } from 'react-icons/tb'
import { FaRegHandshake } from 'react-icons/fa'
import MovesBikes from './sections/MovesBikes'
import MovesPets from './sections/MovesPets'
import MovesAgreement from './sections/MovesAgreement'

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_MOVES_URL = '/app/operational/moves'
export const MOVES_API_URL = '/moves'

const INITIAL_FILTER = {
  folder: 'documents',
  // dataProfile: 'plan',
}

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
}

const INITIAL_SEARCH = {
  roomNumber: 'null',
  blockId: 'null',
  documentStatus: 'null',
}

const INITIAL_CONTEXT = {
  hasPicture: false,
  mode: 'add',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
}

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  decorateDocument: decorateDocument,
  // decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,

  refreshParameters: getMovesModuleParameters,
}

const COMPONENTS = {
  list: props => <MovesMainList {...props}/>,
  // listPreviewHeader: props => <MovesBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <MovesBodyPreviewData {...props}/>,
  // listPreviewActions: props => <MovesBodyPreviewActions {...props}/>,

  documentSubtitle: props => <MovesDocumentSubtitle {...props}/>,
  // documentSubtitle: props => <div>{props?.record?.description}</div>,
  documentHeaderColumn2: props => <MovesDocumentHeaderColumn2 {...props}/>,
  documentHeaderColumn3: props => <MovesDocumentHeaderColumn3 {...props}/>,

  // moduleSettings: props => <movesSettingsTabs {...props}/>,
  // moduleSettings: movesSettingsTabs(),
}

const DATA_TYPES = [
  {
    key: 'document',  // database value
    label: 'document',
    description: 'document_description',
    disabled: true,
    checked: true,
  },
]

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <NodeExpandOutlined/>,
    label: 'home_move',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_MOVES_URL}/${_id}/profile`}
                                       render={() =>
                                         <MovesProfile {...props}
                                                       ref={ref}
                                                       _data={props._dataRoots}
                                                       _dataLock={props._dataRootsLock}/>}/>,
  },

  {
    type: 'divider',
  },

  {
    key: 'titular',
    icon: <UserOutlined/>,
    label: 'titular_resident',
    sectionStatusField: '__atualizaInfo.titularUpdated' ,
    // sectionStep: 1,
    route: (props, ref, _id) => <Route key="titular"
                                       path={`${MODULE_MOVES_URL}/${_id}/titular`}
                                       render={() =>
                                         <MovesTitular {...props}
                                                       ref={ref}
                                                       _data={props._dataRoots?.__atualizaInfo?.titular}
                                                       _dataLock={props._dataRoots?.__atualizaInfo?.titular}/>}/>,
  },

  {
    key: 'habitation',
    icon: <AuditOutlined/>,
    label: 'habitation',
    sectionStatusField: '__atualizaInfo.habitationUpdated' ,
    // sectionStep: 2,
    route: (props, ref, _id) => <Route key="habitation"
                                       path={`${MODULE_MOVES_URL}/${_id}/habitation`}
                                       render={() =>
                                         <MovesHabitation {...props}
                                                          ref={ref}
                                                          _data={props._dataRoots?.__atualizaInfo?.habitation}
                                                          _dataLock={props._dataRoots?.__atualizaInfo?.habitation}/>}/>,
  },

  {
    key: 'residents',
    icon: <TeamOutlined/>,
    label: 'dependents',
    sectionStatusField: '__atualizaInfo.residentsUpdated' ,
    // sectionStep: 3,
    route: (props, ref, _id) => <Route key="residents"
                                       path={`${MODULE_MOVES_URL}/${_id}/residents`}
                                       render={() =>
                                         <MovesResidents {...props}
                                                         ref={ref}
                                                         _data={props._dataRoots?.__atualizaInfo?.residents}
                                                         _dataLock={props._dataRoots?.__atualizaInfo?.residents}/>}/>,
  },

  {
    key: 'vehicles',
    icon: <CarOutlined/>,
    label: 'vehicles',
    sectionStatusField: '__atualizaInfo.vehiclesUpdated' ,
    // sectionStep: 4,
    route: (props, ref, _id) => <Route key="vehicles"
                                       path={`${MODULE_MOVES_URL}/${_id}/vehicles`}
                                       render={() =>
                                         <MovesVehicles {...props}
                                                        ref={ref}
                                                        _data={props._dataRoots?.__atualizaInfo?.vehicles}
                                                        _dataLock={props._dataRoots?.__atualizaInfo?.vehicles}/>}/>,
  },

  {
    key: 'bikes',
    icon: <TbBike/>,
    label: 'bikes',
    sectionStatusField: '__atualizaInfo.bikesUpdated' ,
    // sectionStep: 5,
    route: (props, ref, _id) => <Route key="bikes"
                                       path={`${MODULE_MOVES_URL}/${_id}/bikes`}
                                       render={() =>
                                         <MovesBikes {...props}
                                                     ref={ref}
                                                     _data={props._dataRoots?.__atualizaInfo?.bikes}
                                                     _dataLock={props._dataRoots?.__atualizaInfo?.bikes}/>}/>,
  },

  {
    key: 'pets',
    icon: <TbDog/>,
    label: 'pets',
    sectionStatusField: '__atualizaInfo.petsUpdated' ,
    // sectionStep: 6,
    route: (props, ref, _id) => <Route key="pets"
                                       path={`${MODULE_MOVES_URL}/${_id}/pets`}
                                       render={() =>
                                         <MovesPets {...props}
                                                    ref={ref}
                                                    _data={props._dataRoots?.__atualizaInfo?.pets}
                                                    _dataLock={props._dataRoots?.__atualizaInfo?.pets}/>}/>,
  },

  {
    key: 'agreement',
    icon: <FaRegHandshake/>,
    label: 'housing_agreement',
    sectionStatusField: '__atualizaInfo.agreementUpdated' ,
    // sectionStep: 7,
    route: (props, ref, _id) => <Route key="agreement"
                                       path={`${MODULE_MOVES_URL}/${_id}/agreement`}
                                       render={() =>
                                         <MovesAgreement {...props}
                                                         ref={ref}
                                                         _data={props._dataRoots?.__atualizaInfo}
                                                         _dataLock={props._dataRoots?.__atualizaInfo}/>}/>,
  },

]

const REQUIRED_FIELDS = [
  {
    key: 'apartmentId',
    label: 'apartment',
    checked: true,
    disabled: true,
  },
  {
    key: 'name',
    label: 'name',
    checked: true,
    disabled: true,
  },
  // {
  //   key: 'period',
  //   label: 'period',
  //   checked: true,
  //   disabled: true,
  // },
  {
    key: 'mainPhone',
    label: 'mainPhone',
    checked: true,
    disabled: true,
  },
]

const CUSTOM_ACTIONS_FORM = [
  {
    key: 'moves_sendAtualizaInfo',
    icon: <RocketOutlined/>,
    labelKey: 'request_update',
  },
]

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {

  configurations: {
    dataTypes: ['document'],
    dataMemory: true,
  },
}

const AUTO_FILL_MOVES = {
  apartmentId: '63fce4159045db33ea6aed2c',
  date: moment('14/04/2023', 'DD/MM/YYYY'),
  mainContact: {
    __phone: '(19) 9.8232-1234',
    email: 'rick@info.com.br',
  },
  name: 'Ricardo Biruel',
  period: 'afternoon',
  type: 'entrance',
  uniqueKey: '1677530657779',
  atualizaInfo: {
    birthday: moment('10/10/2000', 'DD/MM/YYYY'),
    cpf: '281.001.518-06',
    gender: 'male',
    mainContact: {
      __phone: '(19) 9.8232-0000',
      email: 'rick@info.com',
    },
    name: 'Riccky',
    rg: '326081604',
  },
}

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_MOVES_PARAMETERS = {

  root: 'moves',
  name: 'moves',
  singular: 'apartment',
  titleField: '__apartmentTitle',
  url: MODULE_MOVES_URL,
  api: MOVES_API_URL,

  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,
  documentActions: {
    archive: false,
    duplicate: false,
  },

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,
  customActionsForm: CUSTOM_ACTIONS_FORM,

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_move',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: AUTO_FILL_MOVES,
  },

}


