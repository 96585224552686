import React from 'react'
import {
  decorateDocument,
  decorateNewDocument,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
  parseSettings,
  getVehicleModuleParameters,
} from './vehicles-methods'
import VehiclesMainList from './VehiclesMainList'
import { CarOutlined } from '@ant-design/icons'
import { Route } from 'react-router-dom'
import {
  defaultDecorateSettings,
  defaultDataProfileValidation,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import VehiclesProfile from './sections/VehiclesProfile'
import {
  VehiclesDocumentHeaderColumn2,
  VehiclesDocumentHeaderColumn3,
  VehiclesDocumentSubtitle,
} from './VehiclesDocumentHeader'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_VEHICLES_URL = '/app/records/condominium/vehicles'
export const VEHICLES_API_URL = '/vehicles'
export const VEHICLES_API_URL_V2 = '/v2/vehicles'

const INITIAL_REQUEST = {
  filters: {
    colors: ['black', 'white'],
  },
  search: {
    name: 'rick',
    age: 46,
  },
  sort: {
    name: 1,
    age: -1,
  },
  projection: {
    name: 1,
    age: 1,
    city: 1,
  },
  pagination: {
    page: 1,
    size: 10,
  },
}

const INITIAL_FILTER = {
  folder: 'documents',
  // dataProfile: 'plan',
}

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
}

const INITIAL_SEARCH = {
  roomNumber: 'null',
  blockId: 'null',
  documentStatus: 'null',
}

const INITIAL_CONTEXT = {
  hasPicture: true,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
}

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,

  refreshParameters: getVehicleModuleParameters,
}

const COMPONENTS = {
  list: props => <VehiclesMainList {...props}/>,
  // tableListV2: props => <VehiclesMainListV2 {...props}/>,
  // listPreviewHeader: props => <VehiclesBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <VehiclesBodyPreviewData {...props}/>,
  // listPreviewActions: props => <VehiclesBodyPreviewActions {...props}/>,

  documentSubtitle: props => <VehiclesDocumentSubtitle {...props}/>,
  documentHeaderColumn2: props => <VehiclesDocumentHeaderColumn2 {...props}/>,
  documentHeaderColumn3: props => <VehiclesDocumentHeaderColumn3 {...props}/>,

  // moduleSettings: props => <vehiclesSettingsTabs {...props}/>,
  // moduleSettings: vehiclesSettingsTabs(),
}

const DATA_TYPES = [
  {
    key: 'document',  // database value
    label: 'document',
    description: 'document_description',
    disabled: true,
    checked: true,
  },
]

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <CarOutlined/>,
    label: 'vehicle',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_VEHICLES_URL}/${_id}/profile`}
                                       render={() =>
                                         <VehiclesProfile ref={ref} {...props}
                                                            _data={props._dataRoots}
                                                            _dataLock={props._dataRootsLock}/>}/>,
    // settingsComponent: [
      // {
      //   key: 'blocks',
      //   label: (<span>{setLocale(true, 'blocks')}</span>),
      //   children: (<div style={{ padding: '4px 24px' }}>
      //     <ModuleSettingsBlocks/>
      //   </div>),
      // },
      // {
      //   key: 'profiles',
      //   label: (<span>{setLocale(true, 'profiles')}</span>),
      //   children: (<div style={{ padding: '4px 24px' }}>
      //     <ModuleSettingsProfiles/>
      //   </div>),
      // },
    // ],
  },
  /*
    {
      key: 'titular',
      icon: <UserOutlined/>,
      label: 'titular_resident',
      route: (props, ref, _id) => <Route key="titular"
                                         path={`${MODULE_VEHICLES_URL}/${_id}/titular`}
                                         render={() =>
                                           <ResidentsProfile ref={ref} {...props}
                                                             _data={props._data?.titular}
                                                             _dataLock={props._dataLock?.titular}/>}/>,
    },
    {
      key: 'habitation',
      icon: <AuditOutlined/>,
      label: 'housing_agreement',
      route: (props, ref, _id) => <Route key="habitation"
                                         path={`${MODULE_VEHICLES_URL}/${_id}/habitation`}
                                         render={() =>
                                           <NoSection ref={ref} {...props} />}/>,
    },
    {
      key: 'residents',
      icon: <TeamOutlined/>,
      label: 'residents',
      route: (props, ref, _id) => <Route key="residents"
                                         path={`${MODULE_VEHICLES_URL}/${_id}/residents`}
                                         render={() =>
                                           <NoSection ref={ref} {...props} />}/>,
    },
    {
      type: 'divider',
    },
    {
      key: 'owner',
      icon: <TrophyOutlined/>,
      label: 'owner',
      route: (props, ref, _id) => <Route key="owner"
                                         path={`${MODULE_VEHICLES_URL}/${_id}/owner`}
                                         render={() =>
                                           <EntitiesProfile ref={ref}
                                                            {...props}
                                                            _data={props._data?.owner}
                                                            _dataLock={props._dataLock?.owner}/>}/>,
    },
  */
]

const REQUIRED_FIELDS = [
  {
    key: 'uniqueKey',
    label: 'unique_key',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'block',
    label: 'block',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'profile',
    label: 'profile',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'floor',
    label: 'floor',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
]

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {

  configurations: {
    dataTypes: ['document'],
    dataMemory: true,
  },

  requiredFields: [
    'name',
    'block',
    'profile',
    'floor',
  ],
}

const AUTO_FILL_VEHICLES = {
  blockId: '63e30416e92d2016f98a9ab1',
  dataProfile: 'plan',
  dataType: 'document',
  directCall: 3031,
  floor: 3,
  name: '31',
  number: 31,
  profileId: '63e3de2bc4e78cac557502f6',
  uniqueKey: '031 | #03 Campo Belo (CB) | Torre Verde',
}

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_VEHICLES_PARAMETERS = {

  root: 'vehicles',
  name: 'vehicles',
  singular: 'vehicle',
  titleField: 'model',
  url: MODULE_VEHICLES_URL,
  api: VEHICLES_API_URL,
  apiV2: VEHICLES_API_URL_V2,
  initialRequest: INITIAL_REQUEST,

  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,
  documentActions: {
    archive: false,
    duplicate: false,
  },

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_unit',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: AUTO_FILL_VEHICLES,
  },

}


