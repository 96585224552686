import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import {Checkbox, Select, TimePicker} from 'antd';
import {Button, Col, Form, Input, message, Row} from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import {ROW_GUTTER} from 'constants/ThemeConstant';
import {
  buildDataLikeForm, filterOption, getDaysPeriods, getWeekdaysArr, // filterOption,
  isObjEquals, msg, onFormFinishFailed, substitution, // substitution,
} from 'utils/helpers';
import {translate} from 'utils/react-jarvisly-helper';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
  FormContext,
} from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/FormProvider';
import {
  postDocument, putDocument,
} from 'components/jarvisly-layouts/ModuleComponent/module-api';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {
  addSection,
  removeSection,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import {DataContext} from "../../../../../components/jarvisly-layouts/ModuleComponent/ModuleForm/DataProvider";


// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const {Option} = Select;


const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const ProceduresProfile = forwardRef((props, ref) => {

  const {formContext, setFormContext} = React.useContext(FormContext);
  const {dataContext} = React.useContext(DataContext);

  const data = dataContext?.data;

  // props deconstruction ------------------------------------------------------
  const {_loading, _module} = props;
  const initialState = {
    isFormChanged: formContext?.sectionsChanged?.includes(formContext?.selectedSection),
  };

  // rewrite the _settings after go back from module settings
  const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription);
  const _settings = rdxSubscription?.settings &&
      rdxSubscription.settings[_module.name];

  // const _parameters = rdxSubscription?.parameters;

  // component states ----------------------------------------------------------
  const [state, setState] = useState(initialState);
  const [initialFormData, setInitialFormData] = useState({});

  // local variables -----------------------------------------------------------

  // translations
  const intl = useIntl();
  const msgSaving = `${translate(intl, 'saving_document')}...`;
  const msgUpdating = `${translate(intl, 'updating_document')}...`;
  const msgSavingSuccess = `${translate(intl, 'document_saved_with_success')}!`;
  const msgUpdatingSuccess = `${translate(intl,
      'document_updated_with_success')}!`;

  const history = useHistory();

  const weekdaysArr = getWeekdaysArr(intl, true);
  const isDisabled = formContext.mode === 'view' || _loading;
  const [form] = Form.useForm();

  const frequencyArr = getDaysPeriods(intl).filter(x => x.value === 'weekly');

  const elRefs = {
    frequency: useRef(null),
    name: useRef(null),
    description: useRef(null),
    hour: useRef(null),
    weekdays: useRef(null),
  };

  const rules = {

    frequency: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    name: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    description: [],

    hour: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    weekdays: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (data) {

      form.setFieldsValue({...data});

      if (dataContext?.docLock) {
        const builtData = buildDataLikeForm(form, dataContext?.docLock);
        setInitialFormData(builtData);
      }

    } else {

      form.resetFields();
      setState({...initialState});
      setInitialFormData({});
      handleFocus();
    }

  }, [dataContext?.docLock, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    reloadDocument: memorize => reloadDocument(memorize),
    handleFocus: field => handleFocus(field),
  }));

  // METHODS -------------------------------------------------------------------

  function handleFocus(field) {
    let focus = field ? field : 'name';
    setTimeout(() => elRefs[focus]?.current?.focus({cursor: 'all'}), 400);
  }

  function reloadDocument(memorize) {
    setState({...state, isFormChanged: memorize});
    setTimeout(() => form.resetFields());
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async values => {

    // change post/put body
    if (values?.hour) {
      values.initialTime = values.hour[0].format('HH:mm');
      values.finalTime = values.hour[1].format('HH:mm');
    }

    try {

      let doc;

      if (formContext.mode === 'add') {
        msg('i', msgSaving, 'saving');
        doc = await postDocument(_module, values);

      } else {
        msg('i', msgUpdating, 'saving');
        doc = await putDocument(_module, data?._id, values);
      }

      message.destroy('saving');

      msg('s', formContext?.mode === 'add' ? msgSavingSuccess : msgUpdatingSuccess,
          'add');

      if (formContext?.mode === 'add') {
        history.replace({
          pathname: `${_module?.url}/${doc._id}/profile`,
          state: {isActive: true},
        });
      }

      setFormContext({
        ...formContext,
        mode: 'view',
        sectionsChanged: removeSection(formContext),
        data: doc,
        docLock: doc,
        dataMemory: _settings?.configurations?.dataMemory ? values : null,
      });

      setState({...state, isFormChanged: false});

    } catch (error) {

      let errorMessage;

      message.destroy('saving');

      if (error?.data?.code === 409) {

        const name = translate(intl, 'name');

        const m = translate(intl, 'response_error_409');
        errorMessage = substitution(m, ['%DOCUMENT_TYPE%'], [name]);

      } else {
        errorMessage = `Erro ${error?.data?.code}: ${error?.data?.errorMessage}`;
      }

      msg('e', errorMessage, 'error', 4);
      elRefs?.name?.current?.focus({cursor: 'all'});

    }
  };

  const saveContext = _.debounce(value => {

    const currentData = {...initialFormData, ...value};
    const isChanged = !isObjEquals(currentData, initialFormData);

    setState({...state, isFormChanged: isChanged});

    setFormContext({
      ...formContext,
      data: {...formContext.data, ...value},
      sectionsChanged: isChanged ? addSection(formContext) : removeSection(formContext),
    });
  });

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <Form
        form={form}
        name="profile"
        layout="vertical"
        initialValues={data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={saveContext}
        validateTrigger="onChange"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        noValidate>

      {/* BASIC INFO */}
      <Row className="mb-2">
        <h5 className="text-muted">
          {setLocale(true, 'basic_info')}
        </h5>
      </Row>


      <Row gutter={ROW_GUTTER}>


        {/* NAME */}
        <Col xs={24} md={16} xl={6}>

          <Form.Item
              label={setLocale(true, 'name')}
              name="name"
              rules={rules.name}
          >
            <Input ref={elRefs.name}
                   disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* NAME */}


        {/* HOUR */}
        <Col xs={24} md={8} xl={4}>


          <Form.Item
              label={setLocale(true, 'hour')}
              name="hour"
              valuePropName="value"
              className="form-item-hour"
              rules={rules.hour}
          >
            <TimePicker.RangePicker
                style={{width: '100%'}}
                disabled={isDisabled}
                disabledTime={() => [
                  0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]}
                format="HH:mm"
                showNow={false}
                minuteStep={30}
                order={false}
            />

          </Form.Item>


        </Col>
        {/* HOUR */}


        {/* DESCRIPTION */}
        <Col xs={24} xl={14}>

          <Form.Item
              label={setLocale(true, 'description')}
              name="description"
              rules={rules.description}
          >
            <Input ref={elRefs.description}
                   disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* DESCRIPTION */}


      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* FREQUENCY */}
        <Col xs={24} xl={6}>

          <Form.Item name="frequency"
                     label={setLocale(true, 'frequency')}
                     rules={rules.frequency}>

            <Select ref={elRefs.frequency}
                    value={data?.frequency}
                    showSearch
                    disabled={isDisabled}
                    filterOption={filterOption}>

              {frequencyArr?.map(x => (
                      <Option key={x.key} value={x.value}>{x.label}</Option>
                  ),
              )}

            </Select>
          </Form.Item>

        </Col>
        {/* FREQUENCY */}


        {/* INTERVAL */}
        <Col xs={24} xl={18}>

          {/* weekdays */}
          <Form.Item
              label={setLocale(true, 'weekdays')}
              name="weekdays"
              rules={rules.weekdays}>

            <Checkbox.Group ref={elRefs.weekdays}
                            options={weekdaysArr}
                            className="checkbox-like-radio"
                            disabled={isDisabled}/>

          </Form.Item>
          {/* weekdays */}

        </Col>
        {/* INTERVAL */}

      </Row>


      <Row align="middle" justify="end">

        <Button type="primary"
                htmlType="submit"
                disabled={formContext.mode === 'view' || !state.isFormChanged}
                style={{minWidth: 100}}>
          {setLocale(true, 'save')}
        </Button>

      </Row>


    </Form>
  </div>);

});

// EXPORT **********************************************************************
// *****************************************************************************

export default ProceduresProfile;
