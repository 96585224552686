import React from 'react'
import IntlMessage from 'components/util-components/IntlMessage'
import { Col, Row, Tag, Tooltip } from 'antd'
import { Icon } from 'components/util-components/Icon'
import {
  MobileOutlined,
  PhoneOutlined,
  UserOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { parsePhoneToDisplay } from 'utils/helpers'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

export const ApartmentsDocumentSubtitle = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const _dataLock = dataContext?.dataLock
  const block = _dataLock?.__block || _dataLock?.__apartment?.block

  return (<>

    {block
      ? <Tag key={block._id} color={block.backColor}>
              <span className="text-uppercase" style={{ color: block.foreColor }}>
                {block.name}
              </span>
      </Tag>
      : null}

    {!_dataLock?.titularId && _dataLock?._id
      ? <Tag key={'unoccupied'} color={'gray'}>
              <span className="text-uppercase" style={{ color: 'white' }}>
                {setLocale(true, 'unoccupied')}
              </span>
      </Tag>
      : null}
  </>)
})

// EMAIL AND PHONE
export const ApartmentsDocumentHeaderColumn2 = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const _dataLock = dataContext?.dataLock

  return (<>
    <Row style={{
      display: _dataLock?.dataResponsible?.name
        ? 'initial'
        : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          {_dataLock?.dataResponsible?.name &&
            <Tooltip placement="right" title={setLocale(true, 'responsible')}>
              <Icon type={UserOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
                {_dataLock?.dataResponsible?.name}
              </span>
            </Tooltip>
          }
        </Row>
      </Col>
    </Row>

    <Row style={{
      display: _dataLock?.directCall
        ? 'initial'
        : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          {_dataLock?.directCall &&
            <Tooltip placement="right" title={setLocale(true, 'direct_call')}>
              <Icon type={PhoneOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
                {_dataLock?.directCall}
              </span>
            </Tooltip>}
        </Row>
      </Col>
    </Row>
  </>)

})

// ADDRESS AND WEBSITE
export const ApartmentsDocumentHeaderColumn3 = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const _dataLock = dataContext?.dataLock
  const mobile = parsePhoneToDisplay(_dataLock?.dataResponsible?.phone)

  return (<>
    <Row style={{
      display: mobile
        ? 'initial'
        : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          {mobile &&
            <Tooltip placement="right" title={setLocale(true, 'phone')}>
              <Icon type={MobileOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
                {mobile}
              </span>
            </Tooltip>}
        </Row>
      </Col>
    </Row>

    <Row style={{
      display: _dataLock?.dataResponsible?.email
        ? 'initial'
        : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          {_dataLock?.dataResponsible?.email &&
            <Tooltip placement="right" title={setLocale(true, 'email')}>
              <Icon type={MailOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
                {_dataLock?.dataResponsible?.email}
              </span>
            </Tooltip>
          }
        </Row>
      </Col>
    </Row>
  </>)

})
