import moment from 'moment';
import {SETTINGS_SEED} from './ambiences-constants';
import {isMongoObjectId} from 'utils/helpers';
import {
  decorateDataType,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';

export function validateSearchFn(keyValueArr, resetSearch) {

  const [k, v] = keyValueArr;

  if (resetSearch || !v) return 'null';

  switch (k) {

    case 'documentCode':
      if (v === 'null') return 'null';
      return v;

    case 'name':
      if (v === 'null') return 'null';
      return v;

    case 'documentStatus':

      const vArr = Array.isArray(v) ? v : v.split(',');
      const newV = [];

      if (vArr.length === 0) {
        return 'null';

      } else if (vArr[0] === null || vArr[0] === 'null') {
        return 'null';
        // newV.push('in_analise', 'with_reservation', 'revised');

      } else {
        if (vArr.includes('in_analise')) newV.push('in_analise');
        if (vArr.includes('with_reservation')) newV.push('with_reservation');
        if (vArr.includes('revised')) newV.push('revised');
      }

      if (newV.length === 0) return 'null';
      /*
            if (newV.length === 0) {
              newV.push('in_analise', 'with_reservation', 'revised');
            }
      */

      return newV;

    default:
      return 'null';
  }
};

export function validateFilterFn(keyValueArr, defaultFilter) {

  const {dataProfile} = defaultFilter;

  const [k, v] = keyValueArr;

  switch (k) {

    case 'folder':
      return ['documents', 'archive', 'recycle'].includes(v) ? v : 'documents';

    case 'dataProfile':
      return dataProfile;

    default:
      return 'null';
  }

};

export function parseSettings(_module, _settings) {

  const body = {};

  // configuration dataType
  if (!_settings?.configurations?.dataTypes) {
    body[`settings.${_module.name}.configurations.dataTypes`] = SETTINGS_SEED.configurations.dataTypes;
  }

  return body;
}

export function decorateSettings(options) {

  // const {result, _module} = options;
  const {result} = options;

  // const _settings = result?.settings;

  // settings.configurations

  return result;
}

export function decorateDocument(docs) {

  const accountId = localStorage.getItem('accountId');

  let isObject = false;

  if (!Array.isArray(docs)) {
    isObject = true;
    docs = [docs];
  }

  docs.map(doc => {

    doc?.discussions?.map(d => {
      d.from = d.authorId === accountId ? 'me' : 'opposite';
      return d;
    });

    if (doc?.birthday) doc.birthday = moment(doc.birthday);

    return doc;
  });

  return isObject ? docs[0] : docs;
}

export function decorateNewDocument(_settings, intl) {

  let data = {};
  decorateDataType(_settings, data);
  return data;

}

export function removeDocumentValidation(_module, data, intl) {

  const _id = data?._id;

  if (!isMongoObjectId(_id)) {
    console.error(
        'Invalid _id! ambiences-methods.js: removeDocumentValidation()');
    return 'abort';
  }

  return null;

}

export function dataProfileValidation(_module, body) {

  if (!body || !_module || !_module?.name) return;

  const dataProfile = _module?.initialFilter?.dataProfile;

  if (!body.dataProfile) body.dataProfile = [];

  if (!body.dataProfile.includes(dataProfile)) {
    body.dataProfile.push(dataProfile);
  }
};
