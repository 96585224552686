import React from 'react'
import {DatePicker as AntdDatePicker} from 'antd'
import IMask from 'imask'
import moment from 'moment'

// const DATE_FORMAT = 'MM/DD/YYYY'
const DATE_FORMAT = 'DD/MM/YYYY'
const MASKED = IMask.createMask({
    blocks: {
        DD: {from: 1, mask: IMask.MaskedRange, to: 31},
        MM: {from: 1, mask: IMask.MaskedRange, to: 12},
        YYYY: {from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE},
    },
    format: date => moment(date).format(DATE_FORMAT),
    mask: Date,
    parse: date => moment(date, DATE_FORMAT),
    pattern: DATE_FORMAT,
})

const DatePicker = React.forwardRef((props, ref) => (
    <AntdDatePicker
        ref={ref}
        format={props.format}
        onKeyDown={event => {
            const input = event.target
            input.value = MASKED.resolve(input.value)
        }}
        picker="date"
        placeholder={DATE_FORMAT.toLowerCase()}
        {...props}
    />
))

export default DatePicker
