import React, { useState } from 'react'
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import IntlMessage from 'components/util-components/IntlMessage'
import { CheckCircleOutlined } from '@ant-design/icons'
import { getEntitiesByField, getEntitiesByUniqueKey } from '../../_old-modules/entities/entities-api'
import { postByUri, putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import { buildPhone, msg, parseCpfToDB, parsePhoneToDB } from 'utils/helpers'
import { translate } from 'utils/react-jarvisly-helper'
import { useIntl } from 'react-intl'
import moment from 'moment/moment'
import { getVehiclesByField } from '../../vehicles/vehicles-api'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const DropDownButton = props => {

  // props deconstruction ------------------------------------------------------
  const {
    _data,
    _dataRoots,
    profile,
    setDataContext,
    dataContext,
    setFormContext,
    formContext,
  } = props

  // local variables -----------------------------------------------------------
  const _atualizaInfo = _dataRoots?.__atualizaInfo
  const intl = useIntl()
  const t = buildTranslations()
  const initialDisabled = {
    titular: _atualizaInfo?.titularUpdated,
    habitation: _atualizaInfo?.habitationUpdated,
    residents: _atualizaInfo?.residentsUpdated,
    vehicles: _atualizaInfo?.vehiclesUpdated,
    bikes: _atualizaInfo?.bikesUpdated,
    pet: _atualizaInfo?.petsUpdated,
    agreement: _atualizaInfo?.agreementUpdated,
  }

  // component states ----------------------------------------------------------
  const [disabled, setDisabled] = useState(initialDisabled)

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onMenuClick = async value => {

    switch (value.key) {

      case 'update_database': {

        switch (profile) {

          case 'titular':
            await updateTitular()
            break

          case 'habitation':
            await updateHabitation()
            break

          case 'residents':
            await updateResidents()
            break

          case 'vehicles':
            await updateVehicles()
            break

          case 'bikes':
            await updateBikes()
            break

          case 'pets':
            await updatePets()
            break

          case 'agreement':
            await updateAgreement()
            break

          default:
            console.error('Profile not implemented!', profile)
        }
        break
      }

      default:
        return
    }

  }

  const actionsItems = [
    {
      key: 'update_database',
      disabled: _atualizaInfo?.[`${profile}Updated`],
      icon: <CheckCircleOutlined/>,
      label: (
        <div>
          {setLocale(true, 'confirm_data')}
        </div>
      ),
    },
  ]

  let menu = {
    items: actionsItems,
    onClick: onMenuClick,
  }

  // UI COMPONENT --------------------------------------------------------------

  return (
    <EllipsisDropdown {...props} menu={menu}/>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {
    return {
      updated: translate(intl, 'data_updated_success'),
    }
  }

  // UPDATE TITULAR
  async function updateTitular (data = _data) {

    const titular = { ...data }

    // ---------------------------------------------------------------------------
    // PARSE TITULAR TO DB
    // ---------------------------------------------------------------------------
    const p = buildPhone(titular.phone)

    titular.dataProfile = 'resident'
    titular.dataType = 'person'
    titular.phoneDialCode = p?.dialCode
    titular.phone = parsePhoneToDB(p?.number)
    titular.cpf = parseCpfToDB(titular.cpf)
    titular.fullName = titular.name
    // ---------------------------------------------------------------------------

    // ---------------------------------------------------------------------------
    // SEARCH IF ALREADY EXISTS
    // ---------------------------------------------------------------------------
    // let entity = await getEntitiesByField('cpf', parseCpfToDB(titular.cpf), undefined, null, null, true)
    let entity = await getEntitiesByField(null, null, null, 'cpf', parseCpfToDB(titular.cpf), true)

    if (!entity) {
      entity = await postByUri('/entities', titular)

    } else {
      entity = await putByUri('/entities', entity._id, titular)
    }
    // ---------------------------------------------------------------------------

    // ---------------------------------------------------------------------------
    // UPDATE APARTMENT WITH TITULAR
    // ---------------------------------------------------------------------------
    const apartmentId = _dataRoots?.apartmentId
    await putByUri('/apartments', apartmentId, { titularId: entity._id })
    // ---------------------------------------------------------------------------
    await refresh()

    return entity
  }

  // UPDATE HABITATION
  async function updateHabitation () {

    // ---------------------------------------------------------------------------
    // UPDATE APARTMENT
    // ---------------------------------------------------------------------------
    const apartmentId = _dataRoots?.apartmentId
    await putByUri('/apartments', apartmentId, { habitation: _data })
    // ---------------------------------------------------------------------------
    await refresh()
  }

  // UPDATE RESIDENTS
  async function updateResidents () {

    const residentsIds = []

    for (const doc of _data) {
      const entity = await $addResident(doc)

      residentsIds.push({
        entityId: entity._id,
        entryAt: entity.entryAt,
        relationship: entity.relationship,
        note: entity.note,
      })
    }

    // ---------------------------------------------------------------------------
    // UPDATE APARTMENT
    // ---------------------------------------------------------------------------
    const apartmentId = _dataRoots?.apartmentId
    await putByUri('/apartments', apartmentId, { residents: _data, residentsIds })
    // ---------------------------------------------------------------------------
    await refresh()

    async function $addResident (resident) {

      const body = { ...resident }

      // ---------------------------------------------------------------------------
      // PARSE RESIDENT TO DB
      // ---------------------------------------------------------------------------
      const cpf = body?.cpf && parseCpfToDB(body.cpf)
      const p = buildPhone(body.phone)

      delete body._id
      body.uniqueKey = cpf || resident._id
      body.dataProfile = 'resident'
      body.fullName = body.name
      body.dataType = 'person'
      body.phoneDialCode = p?.dialCode
      body.phone = parsePhoneToDB(p?.number)
      body.cpf = cpf
      // ---------------------------------------------------------------------------

      // ---------------------------------------------------------------------------
      // SEARCH IF ALREADY EXISTS
      // ---------------------------------------------------------------------------

      let entity

      if (cpf) {
        // entity = await getEntitiesByField('cpf', cpf, undefined, null, null, true)
        entity = await getEntitiesByField(null, null, null, 'cpf', cpf, true)
      } else {
        entity = await getEntitiesByUniqueKey(body.uniqueKey, undefined, null, null, true)
      }

      if (!entity) {
        entity = await postByUri('/entities', body)

      } else {
        entity = await putByUri('/entities', entity._id, body)
      }

      entity.relationship = resident.relationship
      entity.entryAt = resident.entryAt
      entity.note = resident.note

      return entity
      // ---------------------------------------------------------------------------
    }
  }

  // UPDATE VEHICLES
  async function updateVehicles () {

    const vehiclesIds = []

    for (const doc of _data) {
      const vehicle = await $addVehicle(doc)

      vehiclesIds.push({
        vehicleId: vehicle._id,
        entryAt: vehicle.entryAt,
      })

      console.log('1', vehicle)
    }

    console.log('2', vehiclesIds)

    // ---------------------------------------------------------------------------
    // UPDATE APARTMENT
    // ---------------------------------------------------------------------------
    const apartmentId = _dataRoots?.apartmentId
    await putByUri('/apartments', apartmentId, { vehicles: _data, vehiclesIds })
    // ---------------------------------------------------------------------------
    await refresh()

    async function $addVehicle (vehicle) {

      const body = { ...vehicle }

      // get driver (temporally) been the titular
      const driver = await updateTitular(_atualizaInfo.titular)

      // ---------------------------------------------------------------------------
      // PARSE VEHICLE TO DB
      // ---------------------------------------------------------------------------
      delete body._id
      body.uniqueKey = vehicle?.plate
      body.driverId = driver._id
      body.multipleDrivers = false

      // ---------------------------------------------------------------------------

      // ---------------------------------------------------------------------------
      // SEARCH IF ALREADY EXISTS
      // ---------------------------------------------------------------------------

      let doc = await getVehiclesByField('plate', body.plate, undefined, null, null, true)

      if (!doc) {
        doc = await postByUri('/vehicles', body)

      } else {
        doc = await putByUri('/vehicles', doc._id, body)
      }

      doc.entryAt = vehicle?.entryAt || _dataRoots?._metadata?.audit?.createdAt
      doc.note = vehicle?.note

      return doc
      // ---------------------------------------------------------------------------
    }
  }

  // UPDATE BIKES
  async function updateBikes () {

    // ---------------------------------------------------------------------------
    // UPDATE APARTMENT
    // ---------------------------------------------------------------------------
    const apartmentId = _dataRoots?.apartmentId
    await putByUri('/apartments', apartmentId, { bikes: _data })
    // ---------------------------------------------------------------------------
    await refresh()
  }

  // UPDATE PETS
  async function updatePets () {

    // ---------------------------------------------------------------------------
    // UPDATE APARTMENT
    // ---------------------------------------------------------------------------
    const apartmentId = _dataRoots?.apartmentId
    await putByUri('/apartments', apartmentId, { pets: _data })
    // ---------------------------------------------------------------------------
    await refresh()
  }

  // UPDATE AGREEMENT
  async function updateAgreement () {

    const agreement = _data?.agreement
    if (!agreement?.fireBrigade) agreement.fireBrigade = _data?.fireBrigade

    // ---------------------------------------------------------------------------
    // UPDATE APARTMENT
    // ---------------------------------------------------------------------------
    const apartmentId = _dataRoots?.apartmentId
    await putByUri('/apartments', apartmentId, { agreement })
    // ---------------------------------------------------------------------------
    await refresh()
  }

  // update atualizaInfo collection and refresh screen states
  async function refresh () {

    const field = `${profile}Updated`
    _atualizaInfo[field] = true

    const body = { [field]: true }
    const updateFinished = _atualizaInfo.titularUpdated && _atualizaInfo.habitationUpdated &&
      _atualizaInfo.residentsUpdated && _atualizaInfo.vehiclesUpdated &&
      _atualizaInfo.bikesUpdated && _atualizaInfo.petsUpdated &&
      _atualizaInfo.agreementUpdated

    /*
        console.log('updateFinished', updateFinished, _atualizaInfo)

        console.log('titularUpdated', _atualizaInfo.titularUpdated)
        console.log('habitationUpdated', _atualizaInfo.habitationUpdated)
        console.log('residentsUpdated', _atualizaInfo.residentsUpdated)
        console.log('vehiclesUpdated', _atualizaInfo.vehiclesUpdated)
        console.log('bikesUpdated', _atualizaInfo.bikesUpdated)
        console.log('petsUpdated', _atualizaInfo.petsUpdated)
        console.log('agreementUpdated', _atualizaInfo.agreementUpdated)
    */

    if (updateFinished) {
      body.updateSentAt = moment()
      body.updateSentBy = 'system'
    }

    await putByUri('/atualiza-info', _atualizaInfo._id, body)
    _dataRoots.__atualizaInfo[field] = true
    dataContext.data = _dataRoots
    setDataContext({ ...dataContext })
    setDisabled({ ...disabled, [profile]: true })
    if (updateFinished) {
      setFormContext({ ...formContext, refreshTime: +new Date(), mode: 'view' })
    }
    msg('s', t.updated)
  }
}

export default DropDownButton
