import React, {Component} from 'react';
import {MoreOutlined} from '@ant-design/icons';
import {Drawer, Menu, Tooltip} from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import {connect} from 'react-redux';
import {DIR_RTL} from 'constants/ThemeConstant';
import IntlMessage from '../util-components/IntlMessage';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

export class NavPanel extends Component {
  state = {visible: false};

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  items = [
    {
      key: 'panel',
      label: (
          <div onClick={this.showDrawer}>
            <Tooltip title={setLocale(true, 'system_preferences')}
                     placement="bottomRight">
              <span><MoreOutlined className="nav-icon mr-0"/></span>
            </Tooltip>
          </div>
      )
    }
  ]

  render() {
    return (
        <>
          <Menu mode="horizontal" items={this.items}/>

          <Drawer
              title={setLocale(true, 'preferences')}
              placement={this.props.direction === DIR_RTL ? 'left' : 'right'}
              width={350}
              onClose={this.onClose}
              open={this.state.visible}
          >
            <ThemeConfigurator/>
          </Drawer>
        </>
    );
  }
}

const mapStateToProps = ({theme}) => {
  const {locale} = theme;
  return {locale};
};

export default connect(mapStateToProps)(NavPanel);
