import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { buildMenu } from 'configs/jarvisly-products'
import IntlMessage from 'components/util-components/IntlMessage'
import { useSelector } from 'react-redux'

const BreadcrumbRoute = withRouter(props => {

  const { location } = props
  const pathSnippets = location.pathname.split('/').filter(i => i)
  const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription)

  let breadcrumbData = {
    '/app': <IntlMessage id="home"/>,
  }

  const navigationConfig = buildMenu(rdxSubscription)

  navigationConfig.forEach((elm, i) => {
    const assignBreadcrumb = (obj) => {
      breadcrumbData[obj.key] = {
        label: obj.label,
        icon: obj.icon,
        moduleIcon: obj._icon,
      }
    }

    assignBreadcrumb(elm)
    if (elm.children) {
      elm.children.forEach(elm => {
        assignBreadcrumb(elm)
        if (elm.children) {
          elm.children.forEach(elm => {
            assignBreadcrumb(elm)
          })
        }
      })
    }
  })

  const buildBreadcrumb = pathSnippets.map((_, index) => {

    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`

    if (!breadcrumbData[url]) return null

    return index > 0 && url
      ?
      <Breadcrumb.Item key={url} className="breadcrumb-fix-svg">
        {breadcrumbData[url] && breadcrumbData[url].icon
          ? <span className="mr-2">{breadcrumbData[url].icon}</span>
          : breadcrumbData[url] && breadcrumbData[url].moduleIcon
            ? <span className="mr-2">{breadcrumbData[url].moduleIcon}</span>
            : null
        }

        {breadcrumbData[url] && breadcrumbData[url].label}
      </Breadcrumb.Item>
      : null
  })

  return (
    <Breadcrumb>
      {buildBreadcrumb}
    </Breadcrumb>
  )
})

export class AppBreadcrumb extends Component {
  render () {
    return (
      <BreadcrumbRoute/>
    )
  }
}

export default AppBreadcrumb
