import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { returnOnlyNumbers } from 'jarvisly-helper'
import { Input } from 'antd'

// ************************************************************************** //
// COMPONENTS FUNCTIONS
// ************************************************************************** //

// -------------------------------------------------------------------------- //
// InputCpfCnpj
// return cpf or cnpj formatting data. credits:
// -------------------------------------------------------------------------- //
export const InputCpfCnpj = React.forwardRef((props, ref) => {

  const {
    onChange, type, profile,
  } = props

  const TYPES = {
    CPF: '999.999.999-99', CNPJ: '99.999.999/9999-99',
  }

  const MAX_LENGTH = profile?.toLowerCase() === 'cpf' ? returnOnlyNumbers(TYPES.CPF).length : returnOnlyNumbers(
    TYPES.CNPJ).length

  let value = returnOnlyNumbers(props.value)

  if (value) {
    value = applyNumberMask(value, TYPES[getMask(value)])
  }

  function onLocalChange (ev) {
    let value = returnOnlyNumbers(ev.target.value)
    const mask = getMask(value)

    let nextLength = value.length

    if (nextLength > MAX_LENGTH) return

    value = applyNumberMask(value, TYPES[mask])

    ev.target.value = value

    onChange(ev, mask)
  }

  function getMask (value) {

    if (profile?.toLowerCase() === 'cpf') {
      return 'CPF'

    } else if (profile?.toLowerCase() === 'cnpj') {
      return 'CNPJ'

    } else {
      return value.length > 11 ? 'CNPJ' : 'CPF'
    }
  }

  return (<Input {...props} ref={ref} type={type} value={value} onChange={onLocalChange}/>)
})

InputCpfCnpj.propTypes = {
  type: PropTypes.string, value: PropTypes.string, profile: PropTypes.string, onChange: PropTypes.func,
}

InputCpfCnpj.defaultProps = {
  type: 'tel', value: '', profile: '', onChange: () => {
  },
}

// -------------------------------------------------------------------------- //
// InputCep
// return cep formatting data. credits:
// -------------------------------------------------------------------------- //
class InputCepClass extends React.Component {

  constructor (props) {
    super(props)

    this.MASK = '99999-999'
    this.MAX_LENGTH = returnOnlyNumbers(this.MASK).length
    this.props = props
    this.lastCepSearched = ''

    // this.value = returnOnlyNumbers(props.value);
    // if (this.value) {
    //   this.value = applyNumberMask(this.value, this.MASK);
    // }
  }

  shouldSearchCep = cep => {
    return cep.length === this.MAX_LENGTH && cep !== this.lastCepSearched
  }

  searchCep = value => {

    const viaCepUrl = `https://viacep.com.br/ws/${value}/json/`

    return fetch(viaCepUrl, {
      method: 'GET', headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',
      }, // body: JSON.stringify({ cep: value }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('No cep found')
        }
        return response
      })
      .then((response) => {
        return response.json()
      })

  }

  onLocalChange = event => {

    const value = returnOnlyNumbers(event.target.value)

    if (value.length > this.MAX_LENGTH) return

    const maskedValue = applyNumberMask(value, this.MASK)

    this.props.onChange(maskedValue)

    if (this.shouldSearchCep(value)) {

      this.lastCepSearched = value

      this.searchCep(value)
        .then(result => this.props.onChange(event, result))
        .catch(err => console.error(err))
    }
  }

  render () {

    const {
      innerRef, value, ...props
    } = this.props

    return (<Input {...props} ref={innerRef} type="tel" value={value || ''} onChange={this.onLocalChange}/>)
  }
}

export const InputCep = React.forwardRef((props, ref) => (<InputCepClass {...props} innerRef={ref}/>))

// -------------------------------------------------------------------------- //
// InputPhone
// return phone formatted data
// -------------------------------------------------------------------------- //
export const InputPhone = React.forwardRef((props, ref) => {

  const {
    onChange, type, profile,
  } = props

  const BR_TYPES = {
    WIRED: '(99) 9999-9999',    // 9999999999   -> 10
    MOBILE: '(99) 9.9999-9999', // 99999999999  -> 11
    FREE: '9999-999-9999',      // 99999999999  -> 11
  }

  const digits = profile ? returnOnlyNumbers(BR_TYPES[profile.toUpperCase()]) : returnOnlyNumbers(BR_TYPES.FREE)

  const MAX_LENGTH = digits.length

  let value = props.value && returnOnlyNumbers(props.value)

  if (value) {
    value = applyNumberMask(value, BR_TYPES[getMask(value)], true)
  } else {
    value = ''
  }

  // local onChange + parent onChange
  function onLocalChange (ev) {

    const valueNumber = returnOnlyNumbers(ev.target.value)
    const mask = getMask(valueNumber)

    let nextLength = valueNumber.length

    if (nextLength > MAX_LENGTH) return
    onChange(ev, mask)

    /*
        const value = applyNumberMask(valueNumber, BR_TYPES[mask], true);

        ev.target.value = value;

        if (props.sendsmscode === 'true' && isValidPhoneNumber(value, 'mobile')) {

          onChange(ev, mask, $sendingSms);

        } else {

          onChange(ev, mask);

        }
    */
  }

  function getMask (value) {

    if (profile) {
      return profile.toUpperCase()

    } else {

      if (value.substring(0, 1) === '0') return 'FREE'
      if (value.substring(2, 3) === '9') return 'MOBILE'
      if (value.length <= 10) return 'WIRED'

      return 'MOBILE'
    }
  }

  if (props.antd === 'Input') {
    return (<Input {...props} ref={ref} type={type} value={value} onChange={onLocalChange}/>)

  } else if (props.antd === 'Input.Search') {
    return (<Input.Search {...props} ref={ref} type={type} value={value} onChange={onLocalChange}/>)

  } else {
    return (<Input {...props} ref={ref} type={type} value={value} onChange={onLocalChange}/>
      // <input {...props} ref={ref} type={type} value={value} onChange={onLocalChange}/>
    )

  }
})

InputPhone.propTypes = {
  type: PropTypes.string, value: PropTypes.string, profile: PropTypes.string, onChange: PropTypes.func,
}

InputPhone.defaultProps = {
  type: 'tel',  // to show mobile keyboard numbers
  value: '', profile: '', onChange: () => {
  },
}

// -------------------------------------------------------------------------- //
// InputEmail
// return phone formatted data
// -------------------------------------------------------------------------- //
export const InputEmail = React.forwardRef((props, ref) => {

  const { onChange } = props

  let value = props?.value ? props.value.toLowerCase() : ''

  function onLocalChange (ev) {
    const valueLower = ev?.target?.value?.toLowerCase()
    onChange(ev, valueLower)
  }

  return (<Input {...props} ref={ref} value={value} onChange={onLocalChange}/>)
})

InputEmail.propTypes = {
  value: PropTypes.string, onChange: PropTypes.func,
}

InputEmail.defaultProps = {
  value: '',
  onChange: () => {
  },
}

// -------------------------------------------------------------------------- //
// InputWebsite
// return phone formatted data
// -------------------------------------------------------------------------- //
export const InputWebsite = React.forwardRef((props, ref) => {

  const { onChange } = props

  let value = props?.value ? props.value.toLowerCase() : ''

  function onLocalChange (ev) {
    const valueLower = ev?.target?.value?.toLowerCase()
    onChange(ev, valueLower)
  }

  return (<Input {...props} ref={ref} value={value} onChange={onLocalChange}/>)
})

InputWebsite.propTypes = {
  value: PropTypes.string, onChange: PropTypes.func,
}

InputWebsite.defaultProps = {
  value: '', onChange: () => {
  },
}

// ************************************************************************** //
// VALIDATION FUNCTIONS
// ************************************************************************** //

// -------------------------------------------------------------------------- //
// isValidCpf
// check if the cpf is valid
// -------------------------------------------------------------------------- //
export const isValidCpf = cpf => {

  if (!cpf) return

  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf === '') return false

  // remove invalids cpfs
  if (cpf.length !== 11 || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222' || cpf ===
    '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666' || cpf === '77777777777' ||
    cpf === '88888888888' || cpf === '99999999999') {
    return false
  }

  // check the first digit
  let add = 0
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i)) * (10 - i)
  }

  let rev = 11 - (add % 11)

  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(9))) return false

  // check the second digit
  add = 0
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i)) * (11 - i)
  }

  rev = 11 - (add % 11)

  if (rev === 10 || rev === 11) rev = 0

  return rev === parseInt(cpf.charAt(10))
}

// -------------------------------------------------------------------------- //
// isValidCnpj
// check if the cnpj is valid
// -------------------------------------------------------------------------- //
export const isValidCnpj = cnpj => {

  if (typeof cnpj === 'undefined') return false

  cnpj = returnOnlyNumbers(cnpj)

  if (cnpj === '' || cnpj.length !== 14 || cnpj === '00000000000000' || cnpj === '11111111111111' || cnpj ===
    '22222222222222' || cnpj === '33333333333333' || cnpj === '44444444444444' || cnpj === '55555555555555' || cnpj ===
    '66666666666666' || cnpj === '77777777777777' || cnpj === '88888888888888' || cnpj === '99999999999999') {
    return false
  }

  // check digits
  let length = cnpj.length - 2
  let number = cnpj.slice(0, length)
  let digits = cnpj.slice(length)
  let sum = 0
  let suffix = length - 7

  for (let i = length; i >= 1; i--) {
    sum += number.charAt(length - i) * suffix--
    if (suffix < 2) {
      suffix = 9
    }
  }

  let resultado = sum % 11 < 2 ? 0 : 11 - sum % 11

  if (resultado.toString() !== digits.charAt(0)) {
    return false
  }

  length = length + 1
  number = cnpj.slice(0, length)
  sum = 0
  suffix = length - 7

  for (let i = length; i >= 1; i--) {
    sum += number.charAt(length - i) * suffix--
    if (suffix < 2) {
      suffix = 9
    }
  }
  resultado = sum % 11 < 2 ? 0 : 11 - sum % 11
  return resultado.toString() === digits.charAt(1)

}

// -------------------------------------------------------------------------- //
// isValidEmail
// check if the email is valid
// -------------------------------------------------------------------------- //
export const isValidEmail = email => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

// -------------------------------------------------------------------------- //
// isValidZip
// check if the CEP is valid
// -------------------------------------------------------------------------- //
export const isValidZip = cep => {
  const regex = /[0-9]{5}-[\d]{3}/
  return regex.test(cep)
}

export const translate = (intl, id) => {
  return intl.formatMessage({ id: id || 'ntt' })
}

export const isValidUrl = website => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(website)
}

// export const isValidPlate = plate => {
//   const pattern = new RegExp('[A-Z]{3}[0-9][0-9A-Z][0-9]{2}', 'i') // fragment locator
//   return !!pattern.test(plate)
// }

// ************************************************************************** //
// INTERNAL FUNCTIONS
// ************************************************************************** //

function applyNumberMask (value, mask, acceptSpace) {

  if (!value) return

  let result = ''

  acceptSpace = false

  const rule = acceptSpace ? '[0-9] ' : '[0-9]'
  const regex = new RegExp(rule, 'ig')

  let inc = 0
  Array.from(value).forEach((letter, index) => {

    if (!mask[index + inc]) return result

    if (!mask[index + inc].match(regex)) {
      setMask(index)
    }

    result += letter

  })

  return result

  function setMask (index) {
    result += mask[index + inc]
    inc++

    if (mask[index + inc] && !mask[index + inc].match(regex)) setMask(index)
  }
}

// -------------------------------------------------------------------------- //
// InputPlate
// return plate formatted data
// -------------------------------------------------------------------------- //
export const InputPlate = React.forwardRef((props, ref) => {

  const [lastSearched, setLastSearched] = useState()

  const {
    onChange,
    elementType,
    profile,  // mercosul, brazil, <empty>:automatic
  } = props

  const PLATES_MASKS = {
    MERCOSUL: '9999999',  // 7
    BRAZIL: '999-9999',   // 7
  }

  let value = props.value && returnOnlyLettersAndNumbers(props.value)

  if (value) {
    value = applyNumberMask(value, PLATES_MASKS[getMask(value)], true)

  } else {
    value = ''
  }

  // local onChange + parent onChange
  function onLocalChange (ev) {
    let value = returnOnlyLettersAndNumbers(ev.target.value)
    value = applyNumberMask(value, PLATES_MASKS[getMask(value)], true)

    if (value === lastSearched) return

    setLastSearched(value)
    onChange(ev, value)
  }

  function getMask (value) {

    if (!value) return

    if (profile) {
      return profile.toUpperCase()

    } else {

      const joker = value.substring(4, 5)

      if (!isNaN(parseInt(joker))) return 'BRAZIL'

      return 'MERCOSUL'
    }
  }

  if (props.antd === 'Input.Search') {
    return (<Input.Search {...props} ref={ref} type={elementType} value={value} onChange={onLocalChange}/>)

  } else {
    return (<Input {...props} ref={ref} type={elementType} value={value} onChange={onLocalChange}/>)
  }

})

InputPlate.propTypes = {
  value: PropTypes.string,
  profile: PropTypes.string,
  onChange: PropTypes.func,
}

InputPlate.defaultProps = {
  value: '', profile: '', onChange: () => {
  },
}

export const returnOnlyLettersAndNumbers = str => {

  if (!str) return

  const regex = /[A-Za-z0-9]+/g
  const result = str.match(regex)

  return result ? result.join('').toUpperCase() : ''
}

export const isValidPlate = plate => {

  const str = returnOnlyLettersAndNumbers(plate)
  const patternBrazil = /^[A-Za-z]{3}\d{4}$/
  const patternMercosul = /^[A-Za-z]{3}\d[A-Za-z]\d{2}$/

  return patternBrazil.test(str) || patternMercosul.test(str)
}

