import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// import firebaseConfig from 'configs/FirebaseConfig';
import {THEME_CONFIG} from 'configs/AppConfig';

if (!THEME_CONFIG?.APP || !THEME_CONFIG?.APP['FIREBASE_CONFIG']) {
  console.error('*** No \'FIREBASE_CONFIG\' on application !json file! ***');
}

const FirebaseConfig = {
  apiKey: THEME_CONFIG.APP['FIREBASE_CONFIG']['API_KEY'],
  authDomain: THEME_CONFIG.APP['FIREBASE_CONFIG']['AUTH_DOMAIN'],
  projectId: THEME_CONFIG.APP['FIREBASE_CONFIG']['PROJECT_ID'],
  storageBucket: THEME_CONFIG.APP['FIREBASE_CONFIG']['STORAGE_BUCKET'],
  messagingSenderId: THEME_CONFIG.APP['FIREBASE_CONFIG']['MESSAGING_SENDER_ID'],
  appId: THEME_CONFIG.APP['FIREBASE_CONFIG']['APP_ID'],
  measurementId: THEME_CONFIG.APP['FIREBASE_CONFIG']['MEASUREMENT_ID'],
};

firebase.initializeApp(FirebaseConfig);

// firebase utils
const db = firebase.firestore();
const storage = firebase.storage().ref()
const auth = firebase.auth();
const currentUser = auth.currentUser;
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();

export {
  db,
  auth,
  currentUser,
  googleAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  githubAuthProvider,
  storage,
  firebase,
  FirebaseConfig
};
