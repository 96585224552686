import React from "react";
import ChartWidget from "components/shared-components/ChartWidget";
import { Badge, Col, Row } from "antd";
import { useSelector } from "react-redux";
import GoalWidget from "components/shared-components/GoalWidget";
import { OnOffStatusTag } from "../../Form/Components";
import moment from "moment";
import NoData from "components/layout-components/NoData";
import Loading from "components/jarvisly/Loading";
import { translate } from "utils/react-jarvisly-helper";
import { useIntl } from "react-intl";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { formatThousandsSeparator } from "../../../../../utils/helpers";

const IotDashboardWaterLevel = (props) => {
  // props deconstruction ------------------------------------------------------
  const { rdxModuleList, devices, monitoring24h } = props;

  // local variables -----------------------------------------------------------
  const { direction } = useSelector((state) => state.theme);
  const intl = useIntl();
  const t = buildTranslations();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const toMeters = (centimeters) => (centimeters ? centimeters / 100 : 0);

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Loading
        isFetching={rdxModuleList?.loading}
        data={monitoring24h?.series}
      />

      {monitoring24h?.series?.length === 0 && devices?.length === 0 && (
        <NoData />
      )}

      {monitoring24h?.series?.length > 0 ? (
        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <ChartWidget
              title={t.water_level_last_24h}
              series={monitoring24h?.series}
              xAxis={monitoring24h?.categories}
              height={"300px"}
              direction={direction}
              customOptions={buildLast24hChartOptions()}
            />
          </Col>
        </Row>
      ) : null}

      {devices?.length > 0 ? (
        <Row gutter={ROW_GUTTER}>
          {devices &&
            devices?.map((i) => (
              <Col xs={24} sm={12} lg={6} key={i?._id}>
                <GoalWidget
                  record={i}
                  suffix="%"
                  infoTitle={
                    i?.installed ? t.additional_info : t.device_not_installed
                  }
                  infoContent={
                    !i?.installed ? (
                      <div>
                        <div>
                          {t.channel}: <strong>{i?.channel}</strong>
                        </div>
                        <div>
                          {t.tank_dimension}:{" "}
                          <strong>{`${formatThousandsSeparator(
                            toMeters(i?.width),
                          )}m X ${formatThousandsSeparator(
                            toMeters(i?.depth),
                          )}m X ${formatThousandsSeparator(
                            toMeters(i?.length),
                          )}m (${formatThousandsSeparator(
                            toMeters(i?.mt2),
                          )}}m²)`}</strong>
                        </div>
                        <div>
                          {t.capacity}:{" "}
                          <strong>{`${formatThousandsSeparator(
                            i?.capacity,
                          )}m³ (${formatThousandsSeparator(
                            i?.capacity ? i.capacity * 1000 : 0,
                          )}L)`}</strong>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {t.channel}: <strong>{i?.channel}</strong>
                        </div>
                        <div>
                          {t.tank_dimension}:{" "}
                          <strong>{`${formatThousandsSeparator(
                            toMeters(i?.width),
                          )}m X ${formatThousandsSeparator(
                            toMeters(i?.depth),
                          )}m X ${formatThousandsSeparator(
                            toMeters(i?.length),
                          )}m`}</strong>
                        </div>
                        <div>
                          {t.capacity}:{" "}
                          <strong>{`${formatThousandsSeparator(
                            i?.capacity,
                          )}m³ (${formatThousandsSeparator(
                            i?.capacity ? i.capacity * 1000 : 0,
                          )}L)`}</strong>
                        </div>
                        <div>
                          {t.current_content}:{" "}
                          <strong>
                            {i?.lastReadingAt
                              ? `${formatThousandsSeparator(
                                  i?.content,
                                )}m³ (${formatThousandsSeparator(
                                  i?.content ? i.content * 1000 : 0,
                                )}L)`
                              : "n/a"}
                          </strong>
                        </div>

                        <div>
                          {t.last_reading}:{" "}
                          <strong>
                            {i?.lastReadingAt
                              ? moment(i?.lastReadingAt).fromNow()
                              : "n/a"}
                          </strong>
                        </div>
                        <div>
                          {t.sensor_distance}:{" "}
                          <strong>
                            {i?.distance
                              ? `${i?.distance}cm ${
                                  i?.calibration ? `(${i.calibration})` : ""
                                }`
                              : "n/a"}
                          </strong>
                        </div>

                        <div>
                          {t.limits_reached}:
                          <strong>
                            Min: {i?.minReached ? `${i?.minReached}cm` : "n/a"}{" "}
                            | Max:{" "}
                            {i?.maxReached ? `${i?.maxReached}cm` : "n/a"}
                          </strong>
                        </div>

                        {i.status !== "online" ? (
                          <>
                            <div>
                              {t.last_try}:{" "}
                              <strong>
                                {moment(i?.lastReadingAttemptAt).format("L LT")}
                              </strong>
                            </div>
                            {/* <div>{t.last_value}: <strong>{`${i?.lastValue}%`}</strong></div> */}
                          </>
                        ) : null}

                        {/*<div>*/}
                        {/*  Última Leitura:{" "}*/}
                        {/*  <strong>{moment(i?.lastReadingAt).fromNow()}</strong>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*  Distância do Sensor: <strong>{i?.distance}cm</strong>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*  {t.quantity}: <strong>3.234L de 4.232L</strong>*/}
                        {/*</div>*/}
                      </div>
                    )
                  }
                  // notFoundTitle={!i.installed ? t.not_installed.toUpperCase() : ''}
                  notFoundTitle={
                    !i.installed ? t.device_not_installed.toUpperCase() : ""
                  }
                  strokeColor={i?.backColor}
                  title={
                    <Badge
                      className="badge-size-12"
                      color={i?.backColor}
                      text={i?.title}
                    />
                  }
                  subtitle={
                    <>
                      {/* <div><span>{i?.subtitle}</span></div> */}
                      <div>
                        <OnOffStatusTag record={i} className="mt-3" />
                      </div>
                    </>
                  }
                  value={i?.value || i.lastValue}
                />
              </Col>
            ))}
        </Row>
      ) : null}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations() {
    return {
      additional_info: translate(intl, "additional_info"),
      device_not_installed: translate(intl, "device_not_installed"),
      not_installed: translate(intl, "not_installed"),
      channel: translate(intl, "channel"),
      tank_dimension: translate(intl, "tank_dimension"),
      capacity: translate(intl, "capacity"),
      current_content: translate(intl, "current_content"),
      sensor_distance: translate(intl, "sensor_distance"),
      limits_reached: translate(intl, "limits_reached"),
      last_reading: translate(intl, "last_reading"),
      last_try: translate(intl, "last_try"),
      quantity: translate(intl, "quantity"),
      last_value: translate(intl, "last_value"),
      water_level_last_24h: translate(intl, "water_level_last_24h"),
    };
  }

  function buildLast24hChartOptions() {
    return {
      title: {
        margin: 16, // valor da margem inferior em pixels
      },
      colors: monitoring24h?.colors,
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        max: 110,
        labels: {
          formatter: function (value) {
            return value + "%";
          },
        },
      },
      markers: {
        size: 0,
      },
      fill: {
        type: "gradient", // 'solid' / 'pattern' / 'image'

        gradient: {
          enabled: true,
          opacityFrom: 0.3,
          opacityTo: 0.3,
          type: "vertical",
          // shade: 'dark',
          // shadeIntensity: 0.5,
          // gradientToColors: undefined,
          // inverseColors: true,
          // opacityFrom: 1,
          // opacityTo: 1,
          // stops: [0, 50, 100],
          // colorStops: []
        },
      },
    };
  }
};

export default IotDashboardWaterLevel;
