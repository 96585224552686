import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Radio, Switch} from 'antd';
import {
  toggleCollapsedNav,
  onNavTypeChange,
  onNavStyleChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onSwitchTheme,
} from 'redux/actions/theme';
import NavLanguage from './NavLanguage';
import {
  SIDE_NAV_LIGHT, NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_DARK,
} from 'constants/ThemeConstant';
import IntlMessage from '../util-components/IntlMessage';
import {putAccount} from 'auth/auth-api';
import {withRouter} from 'react-router-dom';
// import navigationConfig from 'configs/NavigationConfig';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

const colorOptions = [
  '#3e82f7', '#24a772', '#de4436', '#924aca', '#193550'];

const ListOption = ({name, selector, disabled, vertical}) => (
    <div className={`my-4 ${vertical
        ? ''
        : 'd-flex align-items-center justify-content-between'}`}>
      <div className={`${disabled ? 'opacity-0-3' : ''} ${vertical
          ? 'mb-3'
          : ''}`}>{name}</div>
      <div>{selector}</div>
    </div>);

export const ThemeConfigurator = withRouter(({
                                               navType,
                                               sideNavTheme,
                                               navCollapsed,
                                               currentTheme,
                                               toggleCollapsedNav,
                                               onNavTypeChange,
                                               onNavStyleChange,
                                               onTopNavColorChange,
                                               onHeaderNavColorChange,
                                               onSwitchTheme,
                                               rdxUser,
                                               location,
                                             }) => {

  const isNavTop = navType === NAV_TYPE_TOP;

  const [menuCollapsed, setMenuCollapsed] = useState(
      !!rdxUser?.systemPreferences?.menuCollapsed);

  const savePreferences = async (data, event) => {

    if (data === 'menuColor') {
      const value = event.target.value === SIDE_NAV_DARK ? 'dark' : 'light';

      data = {'systemPreferences.menuColor': value};
      onNavStyleChange(event.target.value);

    } else if (data === 'menuCollapsed') {
      if (!rdxUser?.systemPreferences) rdxUser.systemPreferences = {};
      rdxUser.systemPreferences.menuCollapsed = !rdxUser.systemPreferences.menuCollapsed;
      setMenuCollapsed(rdxUser.systemPreferences.menuCollapsed);

      data = {'systemPreferences.menuCollapsed': rdxUser.systemPreferences.menuCollapsed};

      if (rdxUser.systemPreferences.menuCollapsed !== navCollapsed) {
        toggleCollapsedNav(!navCollapsed);
      }
    }

    if (!rdxUser._id) return;
    await putAccount(rdxUser._id, data);
  };

  /*
    const toggleTheme = async (isChecked) => {
      onHeaderNavColorChange('');
      const changedTheme = isChecked ? 'dark' : 'light';
      onSwitchTheme(changedTheme);
      // switcher({theme: themes[changedTheme]});
      await savePreferences(
          {'systemPreferences.darkMode': changedTheme === 'dark'});
    };
  */

  const onNavTypeClick = async (value) => {
    onHeaderNavColorChange('');
    if (value === NAV_TYPE_TOP) {
      onTopNavColorChange(colorOptions[0]);
      toggleCollapsedNav(false);
      await savePreferences({'systemPreferences.menuPosition': 'top'});
    } else {
      await savePreferences({'systemPreferences.menuPosition': 'side'});
    }
    onNavTypeChange(value);

  };

  return (<>

    <div className="mb-5">
      <h4 className="mb-3 font-weight-bold">{setLocale(true, 'navigation')}</h4>

      <ListOption
          name={setLocale(true, 'menu_position')}
          selector={<Radio.Group
              size="small"
              onChange={e => onNavTypeClick(e.target.value)}
              value={navType}
          >
            <Radio.Button value={NAV_TYPE_SIDE}>{setLocale(true,
                'side')}</Radio.Button>
            <Radio.Button value={NAV_TYPE_TOP}>{setLocale(true,
                'top')}</Radio.Button>
          </Radio.Group>}
      />
      <ListOption
          name={setLocale(true, 'menu_color')}
          selector={<Radio.Group
              disabled={isNavTop}
              size="small"
              onChange={e => savePreferences('menuColor', e)}
              value={sideNavTheme}
          >
            <Radio.Button value={SIDE_NAV_LIGHT}>{setLocale(true,
                'light')}</Radio.Button>
            <Radio.Button value={SIDE_NAV_DARK}>{setLocale(true,
                'dark')}</Radio.Button>
          </Radio.Group>}
          disabled={isNavTop}
      />
      <ListOption
          name={setLocale(true, 'collapse_menu')}
          selector={<Switch
              disabled={isNavTop}
              checked={menuCollapsed}
              onChange={() => savePreferences('menuCollapsed')}
          />}
          disabled={isNavTop}
      />
      {/*<ListOption*/}
      {/*    name={setLocale(true, 'dark_mode')}*/}
      {/*    selector={<Switch checked={currentTheme ===*/}
      {/*        'dark'} onChange={toggleTheme}/>}*/}
      {/*/>*/}
    </div>
    <div className="mb-5">
      <h4 className="mb-3 font-weight-bold">{setLocale(true, 'locale')}</h4>
      <ListOption
          name={setLocale(true, 'language')}
          selector={<NavLanguage configDisplay/>}
      />
    </div>

  </>);
});

const mapStateToProps = ({theme, auth}) => {

  const {
    navType,
    sideNavTheme,
    navCollapsed,
    topNavColor,
    headerNavColor,
    locale,
    currentTheme,
    direction,
  } = theme;

  const {rdxUser} = auth;

  return {
    navType,
    sideNavTheme,
    navCollapsed,
    topNavColor,
    headerNavColor,
    locale,
    currentTheme,
    direction,
    rdxUser,
  };

};

const mapDispatchToProps = {
  toggleCollapsedNav,
  onNavTypeChange,
  onNavStyleChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onSwitchTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeConfigurator);
