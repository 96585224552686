import { Card } from 'antd'
import Meta from 'antd/es/card/Meta'
import React, { useEffect } from 'react'
import ImageDescription from 'components/jarvisly/ImageDescription'
import { getInitials } from 'utils/helpers'
import { MODULE_ATUALIZA_PARAMETERS as _module } from './atualiza-constants'
import { useRouter } from 'hooks/useRouter'
import { connect } from 'react-redux'
import { rdxSetPortalData, rdxSetPortalSubscription, rdxSetPortalUser } from '../../../../redux/actions/auth'
import { clone } from 'jarvisly-helper'
import { withRouter } from 'react-router-dom'
import { getAtualizaInfoByPhone } from './atualiza-api'

// INDEX COMPONENT *************************************************************
// *****************************************************************************

const HomeComponent = props => {

  // props deconstruction ------------------------------------------------------
  const {
    rdxPortalStatus,
    rdxPortalData,
    rdxSetPortalData,

    rdxSetPortalSubscription,
    rdxSetPortalUser,
    location,
  } = props

  // local variables -----------------------------------------------------------
  const router = useRouter()
  const refreshData = location?.state?.refresh

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    if (rdxPortalStatus === 1) {
      localStorage.setItem('portal', 'active')
      // onClick(rdxPortalData[0])
    }
  }, [rdxPortalStatus])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!refreshData) return

    (async () => {
      const data = await getAtualizaInfoByPhone(rdxPortalData[0].phone)
      rdxSetPortalData(data)
    })()

  }, [refreshData])// eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onClick = record => {

    const user = record?.lkq_accounts || null
    const subscription = record?.lkq_subscriptions || null

    rdxSetPortalSubscription(subscription)
    rdxSetPortalUser(user)

    const path = `${_module?.url}/${subscription._id}/registration`
    router.replace(path, { record: clone(record) })
  }

  // UI COMPONENT --------------------------------------------------------------


  return (

    rdxPortalData?.map(record => {

      const styles = {
        card: {
          width: 300,
          cursor: 'pointer',
          margin: 16
        },
        image: {
          height: 200,
          backgroundImage: `url(${record?.lkq_subscriptions?.avatarUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center -100px', // Corta 100px na parte inferior
          borderTopLeftRadius: '0.625rem',
          borderTopRightRadius: '0.625rem',
        }
      };

      return (<Card
        className="m-4"
        key={record?._id}
        // style={styles}
        // style={{ width: 300, cursor: 'pointer' }}
        onClick={() => onClick(record)}
        // cover={<img alt="example" src={record?.lkq_subscriptions?.avatarUrl} height={200}/>}

        style={styles.card}
        cover={<div style={styles.image} />}
      >
        <Meta
          className="title-no-margin"
          avatar={<ImageDescription
            imageUrl={record?.avatarUrl}
            initials={getInitials(record?.name)}
            shape="circle"/>}
          title={record?.name}
          description={record?.description}
        />
      </Card>)
    }))
}

// EXPORT **********************************************************************
// *****************************************************************************

// export default HomeComponent

const mapDispatchToProps = {
  rdxSetPortalData,
  rdxSetPortalSubscription,
  rdxSetPortalUser,
}

const mapStateToProps = ({ auth }) => {
  const { rdxPortalSubscription, rdxPortalUser, rdxPortalStatus, rdxPortalData } = auth
  return { rdxPortalSubscription, rdxPortalUser, rdxPortalStatus, rdxPortalData }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeComponent))

