import React from 'react';
import {Row, Tooltip, Button, Modal} from 'antd';
import {
  MobileOutlined,
  MailOutlined,
  GlobalOutlined,
  CompassOutlined,
} from '@ant-design/icons';
import IntlMessage from 'components/util-components/IntlMessage';
import 'moment/locale/pt-br';
import AvatarStatus
  from 'components/shared-components/AvatarStatus';
import DocumentDataProfiles
  from 'components/jarvisly-layouts/ModuleComponent/Components/DocumentDataProfiles';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

export const EntitiesBodyPreviewHeader = props => {

  // props deconstruction ------------------------------------------------------

  const {_module, record} = props;

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <div className="text-center mt-3">
      <AvatarStatus size={80}
                    notitle="true"
                    center="true"
                    backcolor={record?.backColor || ''}
                    forecolor={record?.foreColor || ''}
                    src={record?.pictureUrl}
                    name={record?.name || ''}
      />
      <h3 className="mt-2 mb-0">{record?.name}</h3>
      {/*<div className="text-muted">{age}&nbsp;{age &&*/}
      {/*    setLocale(true, 'years_old')}</div>*/}
      <div>
        {record?.cpf || record?.cnpj}
      </div>
      <DocumentDataProfiles record={record} _module={_module}/>
    </div>


  </>);

};

export const EntitiesBodyPreviewData = props => {

  // props deconstruction ------------------------------------------------------

  const {record} = props;

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <h6 className="text-muted text-uppercase mb-3">
      {setLocale(true, 'contacts')}
    </h6>

    <Row className="view-row text-truncate" wrap={false} align={'middle'}>
      <Tooltip title={setLocale(true, 'telephone')}
               placement="right">
        <MobileOutlined/>
        {(record?.mainContact?.__phone &&
                record.mainContact.__phoneNumberFull) &&
            <span className="ml-3 text-dark">
                    <a href={`tel:${record.mainContact.__phoneNumberFull}`} rel="noreferrer">
                      {record.mainContact.__phone}
                    </a>
                  </span>}
      </Tooltip>

    </Row>

    <Row className="view-row text-truncate" wrap={false} align={'middle'}>
      <Tooltip title={setLocale(true, 'email')}
               placement="right">
        <MailOutlined/>
        {record?.mainContact?.email && <span className="ml-3 text-dark">
                  <a href={`mailto:${record.mainContact.email}`}>
                    {record?.mainContact?.email
                        ? record.mainContact.email
                        : '-'}
                  </a>
                </span>}
      </Tooltip>
    </Row>

    <Row className="view-row text-truncate" wrap={false} align={'middle'}>
      <Tooltip title={setLocale(true, 'website')}
               placement="right">
        <GlobalOutlined/>
        {record?.mainContact?.website &&
            <span className="ml-3 text-dark">
                  <a href={`${record.mainContact.website.slice(0, 4) === 'http'
                      ? ''
                      : 'http://'}${record?.mainContact?.website}`} target="_black" rel="noreferrer">
                    {record?.mainContact?.website
                        ? record?.mainContact?.website
                        : '-'}
                  </a>
                </span>}
      </Tooltip>
    </Row>


    <Row className="view-row text-truncate" wrap={false} align={'middle'}>
      <Tooltip title={setLocale(true, 'location')}
               placement="right">
        <CompassOutlined/>
        {record?.mainAddress?.city &&
            <span className="ml-3 text-dark">
              <a href={`https://www.google.com/maps/place/Av.+Ant%C3%B4nio+Carvalho+de+Miranda,+720`} target="_black" rel="noreferrer">
                {record?.mainAddress?.city},&nbsp;{record?.mainAddress?.province}
              </a>
            </span>
        }
      </Tooltip>
    </Row>

  </>);
};

export const EntitiesBodyPreviewActions = props => {

  // METHODS -------------------------------------------------------------------

  const onDevelopment = () => {
    Modal.warning({
      title: 'Função Indisponível!',
      content: 'Este recurso está em desenvolvimento.',
    });
  };

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Row justify="center" className="mt-3">
      <Button type="default"
              style={{width: 180}}
              onClick={onDevelopment}>
        {setLocale(true, 'new_note')}
      </Button>
    </Row>


    <Row justify="center" className="mt-3">
      <Button type="default"
              style={{width: 180}}
              onClick={onDevelopment}>
        {setLocale(true, 'new_occurrence')}
      </Button>
    </Row>

  </>);

};


