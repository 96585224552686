import React from 'react';
import {Row, Tooltip, Button, Modal} from 'antd';
import {
  MobileOutlined,
  MailOutlined,
  GlobalOutlined,
  CompassOutlined,
} from '@ant-design/icons';
import IntlMessage from 'components/util-components/IntlMessage';
import 'moment/locale/pt-br';
import ImageDescription from 'components/jarvisly/ImageDescription';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

export const ProductsBodyPreviewHeader = props => {

  // props deconstruction ------------------------------------------------------

  const {record} = props;

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <div className="text-center mt-3">

      <ImageDescription user={record}
                        shape="square"
                        size={64}
                        center
                        initials={record?.__productName}
                        title={record?.__productFullName}
                        subtitle={record?.description}

      />


      {/*
      <AvatarStatus size={80}
                    notitle="true"
                    center="true"
                    backcolor={record?.backColor || ''}
                    forecolor={record?.foreColor || ''}
                    src={record?.pictureUrl}
                    name={record?.name || ''}/>
*/}


      {/*
      <h3 className="mt-2 mb-0">{record?.__productFullName}</h3>
      <div>
        {record?.description}
      </div>
*/}
      {/*<DocumentDataProfiles record={record} _module={_module}/>*/}
    </div>


  </>);

};

export const ProductsBodyPreviewData = props => {

  // props deconstruction ------------------------------------------------------

  const {record} = props;

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <h6 className="text-muted text-uppercase mb-3">
      {setLocale(true, 'contacts')}
    </h6>

    <Row className="view-row text-truncate" wrap={false} align={'middle'}>
      <Tooltip title={setLocale(true, 'telephone')}
               placement="right">
        <MobileOutlined/>
        {record?.mainContact?.__phone &&
            <span className="ml-3 text-dark">
                    <a href={`tel:${record?.mainContact?.__phoneNumberFull}`} rel="noreferrer">
                      {record?.mainContact?.__phone}
                    </a>
                  </span>}
      </Tooltip>

    </Row>

    <Row className="view-row text-truncate" wrap={false} align={'middle'}>
      <Tooltip title={setLocale(true, 'email')}
               placement="right">
        <MailOutlined/>
        {record?.email && <span className="ml-3 text-dark">
                  <a href={`mailto:${record?.email}`} target="_black" rel="noreferrer">
                    {record?.email ? record?.email : '-'}
                  </a>
                </span>}
      </Tooltip>
    </Row>

    <Row className="view-row text-truncate" wrap={false} align={'middle'}>
      <Tooltip title={setLocale(true, 'website')}
               placement="right">
        <GlobalOutlined/>
        {record?.mainContact?._website &&
            <span className="ml-3 text-dark">
                  <a href={`${record?.mainContact?._website}`} target="_black" rel="noreferrer">
                    {record?.mainContact?._website
                        ? record?.mainContact?._website
                        : '-'}
                  </a>
                </span>}
      </Tooltip>
    </Row>


    <Row className="view-row text-truncate" wrap={false} align={'middle'}>
      <Tooltip title={setLocale(true, 'location')}
               placement="right">
        <CompassOutlined/>
        {record?.mainAddress?.__city &&
            <span className="ml-3 text-dark">{record?.mainAddress?.__city},&nbsp;
              {record?.mainAddress?.__province}</span>}
      </Tooltip>
    </Row>


  </>);

};

export const ProductsBodyPreviewActions = props => {

  // METHODS -------------------------------------------------------------------

  const onDevelopment = () => {
    Modal.warning({
      title: 'Função Indisponível!',
      content: 'Este recurso está em desenvolvimento.',
    });
  };

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Row justify="center" className="mt-3">
      <Button type="default"
              style={{width: 180}}
              onClick={onDevelopment}>
        {setLocale(true, 'new_note')}
      </Button>
    </Row>


    <Row justify="center" className="mt-3">
      <Button type="default"
              style={{width: 180}}
              onClick={onDevelopment}>
        {setLocale(true, 'new_occurrence')}
      </Button>
    </Row>

  </>);

};


