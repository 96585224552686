import React from 'react'
import PropTypes from 'prop-types'
import { Card, Popover, Progress, Row } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

export const GoalWidget = props => {

  const {
    title,
    value,
    size,
    subtitle,
    strokeWidth,
    strokeColor,
    extra,

    suffix = '',
    infoContent,
    infoTitle,
    notFoundTitle,
    record,
  } = props

  const isNotInstalled = !record || !record?.installed
  const isOffline = record?.status !== 'online'
  const isUnavailable = isNotInstalled || isOffline

  return (

    <Card>

      {infoContent
        ? <div className="text-right">
          <Popover placement="top"
                   title={infoTitle}
                   content={infoContent}
                   trigger="hover">
            <ExclamationCircleOutlined/>
          </Popover>
        </div>
        : null
      }


      <div className="text-center" style={{ position: 'relative' }}>
        {title && <h4 className="mb-3 font-weight-bold">{title}</h4>}

        <Progress type="dashboard"
                  className="hide-percentage"
                  style={{ opacity: isUnavailable ? 0.3 : 1 }}
                  percent={value}
                  format={percent => isNotInstalled
                    ? (notFoundTitle || `0${suffix}`)
                    : `${percent}${suffix}`}
                  width={size}
                  strokeWidth={strokeWidth}
                  strokeColor={strokeColor}/>

        <div style={{
          position: 'absolute',
          top: '47%',
          left: '51%',
          transform: 'translate(-50%, -50%)',
          fontWeight: 'bold',
          opacity: isUnavailable ? 0.3 : 1,
          fontSize: isNotInstalled ? 'large' : 'x-large',
        }}>
          {isNotInstalled
            ? (notFoundTitle || `0${suffix}`)
            : `${value}${suffix}`}
        </div>

        <div className={`mt-2 mx-auto text-muted ${extra ? 'mb-3' : ''}`} style={{ maxWidth: `${size + 30}px` }}>
          {subtitle}
        </div>
        {extra}
      </div>
    </Card>
  )
}

GoalWidget.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  value: PropTypes.number,
  size: PropTypes.number,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  extra: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
}

GoalWidget.defaultProps = {
  strokeWidth: 4,
  size: 150,
}

export default GoalWidget
