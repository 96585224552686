import React, { useEffect, useState } from 'react'
import { Badge, Col, Menu, Row, Tooltip } from 'antd'
import { buildMenu } from 'configs/jarvisly-products'
import { connect, useDispatch } from 'react-redux'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import { onMobileNavToggle } from 'redux/actions/theme'
import { useRouter } from 'hooks/useRouter'
import { withRouter } from 'react-router-dom'
import { version } from '../../appversion'
import IntlMessage from '../util-components/IntlMessage'
import { gotoModule } from '../jarvisly-layouts/ModuleComponent/module-methods'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { isActiveModule } from 'configs/jarvisly-products'
import { rdxSetModule } from 'redux/actions/module'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const SystemVersion = props => {

  return (<Tooltip title={setLocale(true, 'system_version')}
                   placement={props?.placement}>
        <span className={`font-size-sm font-weight-bold ${props.className}`}
              style={props.style}>
          {!props?.navCollapsed ? <span>Jarvisly </span> : null}
          v{version}
        </span>
  </Tooltip>)
}

const SideNavContent = (props) => {

  // props deconstruction ------------------------------------------------------

  const {
    routerKey, groupKey, setGroupKey, onMenuClick, menu, sideNavTheme,
  } = props

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Menu items={menu}
          onClick={onMenuClick}

          style={{ height: '100%', borderRight: 0 }}
          mode="inline"
          theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}

          selectedKeys={routerKey}
          openKeys={groupKey}
          onOpenChange={value => setGroupKey(value)}
    />

    <Row justify="center" style={{ marginTop: -20 }}>
      <SystemVersion {...props} className="text-muted" placement="top"/>
    </Row>
  </>)
}

// COMPONENT *******************************************************************
// *****************************************************************************

const TopNavContent = (props) => {

  // props deconstruction ------------------------------------------------------

  const { routerKey, onMenuClick, menu, topNavColor } = props

  // UI COMPONENT --------------------------------------------------------------

  return (<>
    <Row justify={'space-between'} align={'middle'} className={`menu-with-version ml-1`}>


      <Menu mode="horizontal"
            style={{ backgroundColor: topNavColor }}
            selectedKeys={routerKey}
            onClick={onMenuClick}
            items={menu}/>

      <SystemVersion className="text-white-50"
                     style={{ paddingRight: 28 }}
                     placement="left"/>
    </Row>
  </>)
}

// COMPONENT *******************************************************************
// *****************************************************************************

const MenuContent = (props) => {

  const {
    routeInfo,
    rdxModule,
    navCollapsed,
    location,
    rdxSubscription,
    rdxSetModule,
  } = props

  const navigator = buildMenu(rdxSubscription)

  let menu = !rdxSubscription ? [navigator[0]] : navigator

  menu.map(m => {

    m.children = m.children.filter(c => {
      const mpModule = rdxSubscription?.marketplace?.find(mp => mp.name === c?.module)

      // const moduleName = rdxModule?.name || mpModule?.name;

      return isActiveModule(mpModule)

      // return isActiveModule(mpModule) &&
      //     checkUserGranted('A', moduleName, rdxSubscription);
    })

    return m
  })

  menu = parseMenu(menu)

  function parseMenu (menu) {

    return menu.filter(m => !m?.children || m.children.length > 0).map(m => {
      if (m?.value) m.title = setLocale(true, m.value)
      if (m.children) parseMenu(m.children)

      const marketplace = rdxSubscription?.marketplace?.find(mp => mp.name === m?.value)

      if ([-2, -3].includes(marketplace?.status) && m?.label) {
        m.label = (<Row justify="space-between">
          <Col>
            {m.label}
          </Col>
          <Col>
            <Badge count={<ExclamationCircleOutlined style={{ color: '#f5222d' }}/>}/>
          </Col>
        </Row>)
      }

      if (m.type !== 'divider') {
        m.type = (props.type === 'SIDE' && m._level === 0) ? 'group' : null
      }
      return m
    })
  }

  // component states ----------------------------------------------------------
  const [groupKey, setGroupKey] = useState([])
  const [routerKey, setRouterKey] = useState([])
  const router = useRouter()
  const dispatch = useDispatch()

  // local variables ------------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    const route = routeInfo?.key || rdxModule?.url || location?.pathname

    const routeArr = route?.split('/')       // to array
    routeArr?.pop()                          // remove last item
    const gKey = routeArr?.join('/')         // join without the last item
    setGroupKey(navCollapsed ? [] : [gKey])

    setRouterKey([route] || [])
  }, [routeInfo, rdxModule, navCollapsed]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onMenuClick = async item => {
    gotoModule(dispatch, rdxSetModule, router, item)
  }

  // UI COMPONENT --------------------------------------------------------------

  props = {
    ...props, menu, routerKey, groupKey, setGroupKey, router, onMenuClick,
  }

  return props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />
}

// EXPORT **********************************************************************
// *****************************************************************************

const mapDispatchToProps = {
  onMobileNavToggle, rdxSetModule,
}

const mapStateToProps = ({ moduleRedux, theme, auth }) => {
  const {
    rdxUser,
    rdxSubscription,
  } = auth

  const {
    sideNavTheme,
    topNavColor,
    locale,
    navCollapsed,
  } = theme

  const {
    rdxModule,
    rdxSetModuleSettings,
    rdxSetModuleParameters,
  } = moduleRedux

  return {
    sideNavTheme,
    topNavColor,
    locale,
    rdxUser,
    rdxModule,
    navCollapsed,
    rdxSubscription,
    rdxSetModuleSettings,
    rdxSetModuleParameters,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuContent))
