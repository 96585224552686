import {
  SIDE_NAV_LIGHT, DIR_LTR, NAV_TYPE_TOP,
} from 'constants/ThemeConstant';
import app from './jarvisly-products';
import {generateToken} from 'jarvisly-helper';
import {onProduction, sort} from 'utils/helpers';
import timezones from './timezones.json';
import moment from 'moment';

// Brazilian Culture
// OBS: como padrão, o local é o Brasil, para cada país deve ser incluso o LOCALE do país desejado e setar a linguagem para uso.
// O Locale 'en' já o padrão e não precisa ser importado
import 'moment/locale/pt';

moment.locale('pt');
moment.localeData('pt');

const timezoneoffset = onProduction() ? -180 : 0;

export const APP_NAME = app.NAME;
export const APP_TOKEN = app.TOKEN;
export const APP_LOCALE = app.LOCALE;
export const API_BASE_URL = app.API_ENDPOINT_URL;

export const ACCOUNT_PREFIX_PATH = '/account';
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: APP_LOCALE,
  // navType: NAV_TYPE_SIDE,
  navType: NAV_TYPE_TOP,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR,
  selectedRoute: null,
  APP: app,
};

export const SUBSCRIPTION_SETUP = {
  _id: null,
  type: 1,              // 1: legal Company, 2: clinic, 3: condominium, 4: association
  __type: 'company',
  organization: null,   // name
  filled: false,         // subscriptionSetup form completelly filled (able to go to the next step)
  team: [],
};

export const APP_HEADERS = {

  // [default]
  //----------------------------------------------------------------------------------------------------
  'Content-Type': 'application/json',

  // [safe, track and format]
  //----------------------------------------------------------------------------------------------------
  'x-token': APP_TOKEN,
  'x-requestcode': generateToken().split('-')[0],
  'x-resultasobject': false,
  'x-origin': 0,
  'x-summary': true,
  'x-forcefiled': 0,
  'x-forceremoved': 0,
  // 'x-clientsent': +new Date(),

  // [subscriber identification]
  // ----------------------------------------------------------------------------------------------------
  // 'x-accountid': null,  // accountId author
  'x-subscriptionid': null, // subscriber owner
  'x-legalid': null,    // companyId owner

  // [frontend culture identification/i18n]
  // ----------------------------------------------------------------------------------------------------
  'x-countrycode': 'br',
  'x-languagecode': 'pt-br',
  'x-localecode': 'br',
  'x-timezoneoffset': timezoneoffset,
};

export const timeZones = timezones.sort((a, b) => sort(a, b, 'offset'));

export const timeZone = moment.tz.guess();

export const locationTimezone = timezones.find(
    tz => tz.utc.includes(timeZone)) || {};

export const ORGANIZATION_SEED = {
  SUBSCRIPTIONS_SETTINGS: {

    'occurrences': {
      'levels': [
        {
          'value': 'Nível 1',
          'backColor': '#FFFFFF',
          'foreColor': '#000000',
        }, {
          'value': 'Nível 2',
          'backColor': '#FFFFFF',
          'foreColor': '#000000',
        }, {
          'value': 'Nível 3',
          'backColor': '#FFFFFF',
          'foreColor': '#000000',
        }], 'priorities': [
        {
          'title': 'Urgente',
          'deadlineValue': 1,
          'deadlineUnit': 'days',
          'backColor': '#cf1322',
          'foreColor': '#ffffff',
          'deadlineDays': 1,
          'deadlineHours': 24,
        }, {
          'title': 'Alta',
          'deadlineValue': 5,
          'deadlineUnit': 'days',
          'backColor': '#d46b08',
          'foreColor': '#ffffff',
          'deadlineDays': 5,
          'deadlineHours': 120,
        }, {
          'title': 'Moderada',
          'deadlineValue': 15,
          'deadlineUnit': 'days',
          'backColor': '#7cb305',
          'foreColor': '#ffffff',
          'deadlineDays': 15,
          'deadlineHours': 360,
        }, {
          'title': 'Baixa',
          'deadlineValue': 30,
          'deadlineUnit': 'days',
          'backColor': '#096dd9',
          'foreColor': '#ffffff',
          'deadlineDays': 30,
          'deadlineHours': 720,
        }], 'categories': [], 'tags': [
        {
          'value': 'Melhoria',
          'backColor': '#096dd9',
          'foreColor': '#ffffff',
        }, {
          'value': 'Bug', 'backColor': '#cf1322', 'foreColor': '#ffffff',
        }], 'analysts': [],
    },
  },
};

export const CONDOMINIUM_SEED = {
  SUBSCRIPTIONS_SETTINGS: {
    'occurrences': {
      'levels': [
        {
          'value': '1 - Administrativo',
          'backColor': '#FFFFFF',
          'foreColor': '#000000',
        }, {
          'value': '2 - Síndico',
          'backColor': '#FFFFFF',
          'foreColor': '#000000',
        }, {
          'value': '3 - Conselho',
          'backColor': '#FFFFFF',
          'foreColor': '#000000',
        }], 'priorities': [
        {
          'title': 'Urgente',
          'deadlineValue': 1,
          'deadlineUnit': 'days',
          'backColor': '#cf1322',
          'foreColor': '#ffffff',
          'deadlineDays': 1,
          'deadlineHours': 24,
        }, {
          'title': 'Alta',
          'deadlineValue': 5,
          'deadlineUnit': 'days',
          'backColor': '#d46b08',
          'foreColor': '#ffffff',
          'deadlineDays': 5,
          'deadlineHours': 120,
        }, {
          'title': 'Moderada',
          'deadlineValue': 15,
          'deadlineUnit': 'days',
          'backColor': '#7cb305',
          'foreColor': '#ffffff',
          'deadlineDays': 15,
          'deadlineHours': 360,
        }, {
          'title': 'Baixa',
          'deadlineValue': 30,
          'deadlineUnit': 'days',
          'backColor': '#096dd9',
          'foreColor': '#ffffff',
          'deadlineDays': 30,
          'deadlineHours': 720,
        }], 'categories': [], 'tags': [], 'analysts': [],
    },
  },
};



