import React, { forwardRef, useEffect, useRef } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import IntlMessage from 'components/util-components/IntlMessage'
import {
  FormInput,
  FormNextPrevious,
  FormPhone,
  FormSelect,
  FormSelectRelationship,
  FormTitle,
  FormWrapper,
} from '../../../FormComponets'
import { parsePhoneToDB, parsePhoneToDisplay } from 'utils/helpers'
import { ArrowLeftOutlined } from '@ant-design/icons'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

const HabitationStep = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    step,
    setStep,
  } = props

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------

  const formName = 'habitationForm'
  const [form] = Form.useForm()
  const intl = useIntl()
  const elRefs = buildElementsRefs(useRef)
  const profileOptions = buildProfileOptions()
  const contractOptions = buildContractOptions()

  // ant dataPicker workaround to fix issue about date
  if (_data?.ownerPhone) _data.ownerPhone = parsePhoneToDisplay(_data.ownerPhone)
  if (_data?.realEstateAgentPhone) _data.realEstateAgentPhone = parsePhoneToDisplay(_data.realEstateAgentPhone)
  if (_data?.renterPhone) _data.renterPhone = parsePhoneToDisplay(_data.renterPhone)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    handleFocus(undefined, 'all')
    setDataContext({ ...dataContext, formsChanged: [] })
  }, [_data, step]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  function handleFocus (field = 'profile', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  // validate profile
  const vp = () => {

    const value = form.getFieldValue('profile') || _data?.profile

    let profile

    switch (value) {

      case 'owner':
        profile = 1
        break

      case 'tenant':
        profile = 2
        break

      case 'non_tenant_resident':
        profile = 3
        break

      default:
        profile = 0
    }

    return profile
  }

  // validate contract
  const vc = () => {

    let contract

    const value = form.getFieldValue('contract') || _data?.contract

    switch (value) {
      case 'rental_through_agency':
        contract = 1
        break

      case 'rental_direct_owner':
        contract = 2
        break

      default:
        contract = 0
    }

    return contract
  }

  const onBeforeSave = values => {

    const data = { ...values }

    // format data to persint in database
    if (data?.ownerPhone) data.ownerPhone = parsePhoneToDB(data.ownerPhone)
    if (data?.realEstateAgentPhone) data.realEstateAgentPhone = parsePhoneToDB(data.realEstateAgentPhone)
    if (data?.renterPhone) data.renterPhone = parsePhoneToDB(data.renterPhone)

    const body = {
      _id: _dataRoots._id,
      habitation: data,
      habitationUpdated:false
    }

    if (_dataRoots.currentStep === step) body.currentStep = step + 1
    return body
  }

  const onAfterSave = async doc => {
    if (doc || _dataRoots.currentStep > step) setStep(prev => prev + 1)
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    {/* HABITATION */}
    <FormWrapper name={formName}
                 form={form}
                 elRefs={elRefs}
                 uriApi="/atualiza-info"
                 initialValues={_data}
                 dataContext={dataContext}
                 setDataContext={setDataContext}
                 onBeforeSave={onBeforeSave}
                 onAfterSave={onAfterSave}
                 showToast>

      {/* BASIC INFO */}
      <FormTitle title={'habitation'}/>


      <Row gutter={ROW_GUTTER}>

        {/* PROFILE */}
        <Col xs={24} md={12}>
          <FormSelect name="profile"
                      elRef={elRefs.profile}
                      options={profileOptions}
                      onChange={vp}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* PROFILE */}


        {/* CONTRACT */}
        {vp() > 1 ? <Col xs={24} md={12}>
          <FormSelect name="contract"
                      elRef={elRefs.contract}
                      options={contractOptions}
                      onChange={vc}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col> : null}
        {/* CONTRACT */}


      </Row>


      {/* RENTER DATA */}
      {vp() === 3 && vc() >= 1 ? <Row gutter={ROW_GUTTER}>


        {/* RENTER NAME */}
        <Col xs={24} md={8}>
          <FormInput name="renterName"
                     label="renter_name"
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.renterName}/>
        </Col>
        {/* RENTER NAME */}


        {/* RENTER PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone name="renterPhone"
                     label="phone"
                     form={form}
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.renterPhone}/>
        </Col>
        {/* RENTER PHONE */}


        {/* RENTER RELATIONSHIP */}
        <Col xs={24} md={8}>
          <FormSelectRelationship name="renterRelationship"
                                  label="relationship"
                                  elRef={elRefs.renterRelationship}
                                  disabled={_dataRoots?.updateSentAt}
                                  required/>
        </Col>
        {/* RENTER RELATIONSHIP */}


      </Row> : null}
      {/* RENTER DATA */}


      {/* AGENT DATA */}
      {vp() > 1 && vc() === 1 ? <Row gutter={ROW_GUTTER}>


        {/* AGENT NAME */}
        <Col xs={24} md={8}>
          <FormInput name="realEstateAgentName"
                     label="agent_name"
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.realEstateAgentName}/>
        </Col>
        {/* AGENT NAME */}


        {/* AGENT PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone name="realEstateAgentPhone"
                     label="phone"
                     form={form}
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.realEstateAgentPhone}/>
        </Col>
        {/* AGENT PHONE */}


        {/* REAL ESTATE */}
        <Col xs={24} md={8}>
          <FormInput name="realEstateCompanyName"
                     label="real_estate_name"
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.realEstateCompanyName}/>
        </Col>
        {/* REAL ESTATE */}


      </Row> : null}
      {/* AGENT DATA */}


      {/* OWNER DATA */}
      {vp() > 1 && vc() === 2 ? <Row gutter={ROW_GUTTER}>


        {/* OWNER NAME */}
        <Col xs={24} md={8}>
          <FormInput name="ownerName"
                     label="owner_name"
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.ownerName}/>
        </Col>
        {/* OWNER NAME */}


        {/* OWNER PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone name="ownerPhone"
                     label="phone"
                     form={form}
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.ownerPhone}/>
        </Col>
        {/* OWNER PHONE */}


        {/* OWNER RELATIONSHIP */}
        <Col xs={24} md={8}>
          <FormSelectRelationship name="ownerRelationship"
                                  label="relationship"
                                  elRef={elRefs.ownerRelationship}
                                  disabled={_dataRoots?.updateSentAt}
                                  required/>
        </Col>
        {/* OWNER RELATIONSHIP */}


      </Row> : null}
      {/* OWNER DATA */}


      <Row gutter={ROW_GUTTER}>

        {/* NOTE */}
        <Col xs={24}>
          <FormInput name="note"
                     label="observation"
                     disabled={_dataRoots?.updateSentAt}
                     elRef={elRefs.note}/>
        </Col>
        {/* NOTE */}

      </Row>

      <Row justify={{ xs: 'space-between', md: 'end' }}
           align="middle"
           className="mt-3">

        <Button type="default"
                onClick={() => setStep(step - 1)}
                icon={<ArrowLeftOutlined/>}
                className="mr-3">
          <span className="ml-2">
            {setLocale(true, 'previous')}
          </span>
        </Button>


        {/* PREVIOUS AND NEXT BUTTON */}
        <FormNextPrevious dataContext={dataContext}
                          step={step}
                          setStep={setStep}
                          formName={formName}
                          saveButton
                          _dataRoots={_dataRoots}/>
        {/* PREVIOUS AND NEXT BUTTON */}
      </Row>

    </FormWrapper>
    {/* HABITATION */}


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildProfileOptions () {

    const t = {
      owner: translate(intl, 'owner'),
      tenant: translate(intl, 'tenant'),
      non_tenant_resident: translate(intl, 'non_tenant_resident'),
    }

    return [
      {
        value: 'owner', text: t.owner, label: t.owner, disabled: false,
      }, {
        value: 'tenant', text: t.tenant, label: t.tenant, disabled: false,
      }, {
        value: 'non_tenant_resident', text: t.non_tenant_resident, label: t.non_tenant_resident, disabled: false,
      }]
  }

  function buildContractOptions () {

    const t = {
      rental_through_agency: translate(intl, 'rental_through_agency'),
      rental_direct_owner: translate(intl, 'rental_direct_owner'),
    }

    return [
      {
        value: 'rental_through_agency', text: t.rental_through_agency, label: t.rental_through_agency, disabled: false,
      }, {
        value: 'rental_direct_owner', text: t.rental_direct_owner, label: t.rental_direct_owner, disabled: false,
      }]
  }

  function buildElementsRefs (hookUseRef) {
    return {
      profile: hookUseRef(null),
      contract: hookUseRef(null),

      renterName: hookUseRef(null),
      renterPhone: hookUseRef(null),
      renterRelationship: hookUseRef(null),

      realEstateAgentName: hookUseRef(null),
      realEstateAgentPhone: hookUseRef(null),
      realEstateCompanyName: hookUseRef(null),

      ownerName: hookUseRef(null),
      ownerPhone: hookUseRef(null),
      ownerRelationship: hookUseRef(null),

      note: hookUseRef(null),
    }
  }
})

// EXPORT **********************************************************************
// *****************************************************************************

export default HabitationStep


