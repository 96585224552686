import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { ContactsOutlined } from '@ant-design/icons';
import { buildPhone, onFormFinishFailed, parseCpfToDB, parseDateToDisplay, parsePhoneToDisplay } from 'utils/helpers';
import IntlMessage from 'components/util-components/IntlMessage';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  FormAsyncAutoCompleteEntity,
  formDataChanges,
  FormDatePicker,
  FormEmail,
  FormInput,
  FormPhone
} from '../../../portals/autaliza-info/FormComponets';
import { addToSet, getV2, postByUriV2, putByUriV2, } from 'configs/ApiConfig';
import { translate } from 'utils/react-jarvisly-helper';
import { useIntl } from 'react-intl';
import { ENTITIES_API_URL } from '../entities-constants';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const EntitiesContactModal = React.forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------'
  const {
    _id,
    showModal,
    setShowModal,
    record,
    _module,
    onRefresh,
    setLoading,
    loading,
    isDisabled
  } = props;

  // component states ----------------------------------------------------------
  const [isFormChanged, setIsFormChanged] = useState(false);

  // local variables ------------------------------------------------------------
  if (record?.birthday) record.birthday = parseDateToDisplay(record.birthday);
  const fieldsToValidate = ['phone', 'email'];
  const intl = useIntl();
  const t = buildTranslations();
  const [form] = Form.useForm();
  const elRefs = buildElementsRefs(useRef);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {

    if (!showModal) return;
    form?.resetFields();

    if (record) {

      form?.setFieldsValue(record);
      setTimeout(() => {
        form?.validateFields(fieldsToValidate).then(x => x).catch(e => e);
      });

    }

    handleFocus();

  }, [record, form, showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  function handleFocus (field = 'name', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition });
  }

  const onClose = () => {
    setIsFormChanged(false);
    setShowModal(false);
    form.resetFields();
  };

  const onValuesChanges = () => {
    setIsFormChanged(formDataChanges(form, record));
  };

  const onEntitySelect = async entity => {

    if (!entity || !entity?._id) return;

    const config = { headers: { 'x-resultasobject': true } };
    const result = await getV2(`${_module.api}/${entity._id}`, config);
    const selEntity = result?.response?.data;

    if (!selEntity) return;

    const f = {
      _id: record?._id,
      entityId: selEntity?._id || entity._id,
      name: selEntity?.name,
      email: selEntity?.email,
    };

    if (selEntity?.phone) f.phone = parsePhoneToDisplay(selEntity.phone);
    if (selEntity?.birthday) f.birthday = parseDateToDisplay(selEntity.birthday);

    form.setFieldsValue(f);
  };

  const onSave = async values => {

    if (!isFormChanged) return onClose();

    setLoading(true);

    const entityId = values?.selectedEntity?.key;
    const entityBody = { ...values };
    const phone = buildPhone(entityBody?.phone);

    entityBody.name = values?.selectedEntity.value;
    entityBody.phoneDialCode = phone?.dialCode;
    entityBody.phone = phone?.number;
    entityBody._id = entityId;
    entityBody.cpf = parseCpfToDB(entityBody?.cpf);
    entityBody.dataProfile = 'contact';
    entityBody.dataType = 'person';

    delete entityBody.selectedEntity;
    delete entityBody.note;

    let result;

    if (!entityId) {
      result = await postByUriV2(ENTITIES_API_URL, entityBody);

    } else {
      result = await putByUriV2(ENTITIES_API_URL, entityId, entityBody);
    }

    const entity = result?.response?.data || {};

    let subdocument = {
      _id: record?._id,
      entityId: entity._id,
      note: values?.note
    };

    try {

      const result = await addToSet(_module.api, _id, '$push', 'contacts', subdocument);

      onRefresh(result.doc);
      onClose();

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Modal

      title={<><ContactsOutlined/><span className="ml-2">{t.title}</span></>}
      destroyOnClose={true}
      open={showModal}
      width={640}
      okText={setLocale(true, (isFormChanged || !record) ? 'save' : 'close')}
      onCancel={onClose}
      onOk={() => form?.submit()}
      cancelButtonProps={{ style: { display: 'none' } }}
      getContainer={false}
      okType={isFormChanged ? 'primary' : 'default'}
      confirmLoading={loading}>

      <div>

        <Form form={form}
              name="blocksForm"
              className="vertical-form-min"
              onFinish={onSave}
              onValuesChange={onValuesChanges}
              onFinishFailed={errorInfo => onFormFinishFailed(errorInfo, elRefs, setLoading)}
              layout="vertical"
              disabled={loading}
              autoComplete="off">

          <Row gutter={ROW_GUTTER} align="middle">

            {/* NAME */}
            <Col xs={24} sm={16}>
              <FormAsyncAutoCompleteEntity
                autoFocus
                name="selectedEntity"
                label="name"
                dataType="person"
                disabled={isDisabled}
                value={record?.selectedEntity}
                onSelect={onEntitySelect}
                required
                acceptNew
                elRef={elRefs.selectedEntity}/>
            </Col>
            {/* NAME */}


            {/* BIRTHDAY */}
            <Col xs={24} sm={8}>
              <FormDatePicker name="birthday"
                              disabled={isDisabled}
                              elRef={elRefs.birthday}/>
            </Col>
            {/* BIRTHDAY */}


          </Row>


          <Row gutter={ROW_GUTTER}>

            {/* PHONE */}
            <Col xs={24} sm={9}>
              <FormPhone name="phone"
                         form={form}
                         disabled={isDisabled}
                // required={!form?.getFieldValue(['email'])}
                         elRef={elRefs.phone}/>
            </Col>
            {/* PHONE */}


            {/* EMAIL */}
            <Col xs={24} sm={15}>
              <FormEmail name="email"
                         form={form}
                         disabled={isDisabled}
                // required={!form?.getFieldValue(['phone'])}
                         elRef={elRefs.email}/>
            </Col>
            {/* EMAIL */}


          </Row>


          <Row gutter={ROW_GUTTER}>

            {/* NOTE */}
            <Col xs={24}>
              <FormInput name="note"
                         elRef={elRefs.note}
                         disabled={isDisabled}
                         required/>
            </Col>
            {/* NOTE */}


          </Row>


        </Form>

      </div>

    </Modal>

  </>);

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildElementsRefs (hookUseRef) {
    return {

      // identification
      selectedEntity: hookUseRef(null),
      birthday: hookUseRef(null),

      // contacts
      phone: hookUseRef(null),
      email: hookUseRef(null),

      // note
      note: hookUseRef(null),
    };
  }

  function buildTranslations () {

    return {
      title: record?._id
        ? (isDisabled
          ? translate(intl, 'view')
          : translate(intl, 'edit_contact'))
        : translate(intl, 'new_contact')
    };
  }

});

// EXPORT **********************************************************************
// *****************************************************************************

export default EntitiesContactModal;



