import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Upload,
} from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import {ROW_GUTTER} from 'constants/ThemeConstant';
import {
  buildDataLikeForm, filterOption, // filterOption,
  isObjEquals, msg, onFormFinishFailed, substitution, // substitution,
} from 'utils/helpers';
import {translate} from 'utils/react-jarvisly-helper';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
  FormContext,
} from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/FormProvider';
import {
  postDocument, putDocument,
} from 'components/jarvisly-layouts/ModuleComponent/module-api';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {
  addSection, removeSection,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import TextArea from 'antd/es/input/TextArea';
import {UploadOutlined} from '@ant-design/icons';
import {DataContext} from "../../../../../components/jarvisly-layouts/ModuleComponent/ModuleForm/DataProvider";


// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const {Option, OptGroup} = Select;
const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const OccurrencesProfile = forwardRef((props, ref) => {

  const {formContext, setFormContext} = React.useContext(FormContext);
  const {dataContext} = React.useContext(DataContext);

  const data = dataContext?.data;


  // props deconstruction ------------------------------------------------------
  const {_module} = props;
  const initialState = {
    // uniqueKeyStatus: '',
    isFormChanged: formContext?.sectionsChanged?.includes(formContext?.selectedSection),
  };

  // rewrite the _settings after go back from module settings
  const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription);
  const _settings = rdxSubscription?.settings &&
      rdxSubscription.settings[_module.name];

  // const _parameters = rdxSubscription?.parameters;

  // component states ----------------------------------------------------------
  const [state, setState] = useState(initialState);
  const [initialFormData, setInitialFormData] = useState({});


  // local variables -----------------------------------------------------------

  // translations
  const intl = useIntl();
  const msgSaving = `${translate(intl, 'saving_document')}...`;
  const msgUpdating = `${translate(intl, 'updating_document')}...`;
  const msgSavingSuccess = `${translate(intl, 'document_saved_with_success')}!`;
  const msgUpdatingSuccess = `${translate(intl,
      'document_updated_with_success')}!`;

  const history = useHistory();

  const isLogDocument = data?.dataType === 'log';
  const [form] = Form.useForm();

  // required fields for person

  const environmentSeed = [
    {
      _id: 1, value: 'Churrasqueira',
    }, {
      _id: 2, value: 'Salão CB',
    }, {
      _id: 3, value: 'Piscina Adulto',
    }];
  const levelsSeed = [

    {
      _id: 1, value: '1 - Administrativo', analysts: [
        {
          _id: 1, value: 'Rick',
        }, {
          _id: 2, value: 'Ane',
        }, {
          _id: 3, value: 'Cacá',
        }, {
          _id: 4, value: 'Nini',
        }, {
          _id: 10, value: 'Onodera',
        }],
    },

    {
      _id: 2, value: '2 - Síndico', analysts: [
        {
          _id: 5, value: 'Beltrano',
        }],
    },

    {
      _id: 3, value: '3 - Conselho', analysts: [
        {
          _id: 6, value: 'Fulano',
        }, {
          _id: 7, value: 'Ciclano',
        }],
    },

  ];
  const prioritiesSeed = [
    {
      _id: 1, value: 'Urgente',
    }, {
      _id: 2, value: 'Alta',
    }, {
      _id: 3, value: 'Moderada',
    }, {
      _id: 4, value: 'Baixa',
    }];

  const elRefs = {
    name: useRef(null),
    unit: useRef(null),
    occurrenceDate: useRef(null),
    place: useRef(null),
    subject: useRef(null),
    description: useRef(null),
    analystId: useRef(null),
    levelId: useRef(null),
    category: useRef(null),
    priority: useRef(null),
    referenceCode: useRef(null),
  };

  const rules = {
    name: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }]
    , unit: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }], occurrenceDate: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }], subject: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }], description: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }], levelId: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],
  };

  /*  const modalButtons = [

      <Row justify="space-between" key="footerButtons">

        <Row align="middle">
          {/!* {data?._id ? <span>_id: {data._id}</span> : null} *!/}
        </Row>

        <Row>

          <Button key="cancel"
                  onClick={onClose}
                  disabled={updating}>
            <span>{setLocale(true, 'cancel')}</span>
          </Button>

          <Button key="ok"
                  type="primary"
                  loading={updating}
              // disabled={showModal && hasFormError(form)}
                  onClick={onOk}
          >
            <span>{setLocale(true, 'confirm')}</span>
          </Button>
        </Row>

      </Row>];*/

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (data) {

      form.setFieldsValue({...data});

      if (dataContext?.docLock) {
        const builtData = buildDataLikeForm(form, dataContext?.docLock);
        setInitialFormData(builtData);
      }

    } else {

      form.resetFields();
      setState({...initialState});
      setInitialFormData({});
      handleFocus('dataType');
    }

    // ---------------------------------------------------------------------- //
    // validate fields after change settings
    // ---------------------------------------------------------------------- //
    // const opt = {formContext, _settings, form};
    // const familyOptions = {...opt, formField: 'family', dbField: 'families'};
    // const occurrenceOptions = {
    //   ...opt,
    //   formField: 'occurrences',
    //   dbField: 'occurrences',
    // };
    // const brandOptions = {...opt, formField: 'brand', dbField: 'brands'};
    // validateFieldAfterSettingsChanges(familyOptions);
    // validateFieldAfterSettingsChanges(occurrenceOptions);
    // validateFieldAfterSettingsChanges(brandOptions);

    // form.setFieldsValue({lastInventory: moment(moment(), 'DD/MM/YYYY')});
    // ---------------------------------------------------------------------- //

  }, [dataContext?.docLock, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formContext.mode === 'add') handleFocus('dataType');
  }, [formContext?.data?.dataType]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    reloadDocument: memorize => reloadDocument(memorize),
    handleFocus: field => handleFocus(field),
  }));

  // METHODS -------------------------------------------------------------------

  function handleFocus(field) {
    let focus = field ? field : 'dataType';

    if ((!!data?.dataType && focus === 'dataType') ||
        _settings?.configurations?.dataTypes.length === 1) {
      focus = isLogDocument ? 'name' : 'uniqueKey';
    }

    setTimeout(() => elRefs[focus]?.current?.focus({cursor: 'all'}), 400);
  }

  function reloadDocument(memorize) {
    setState({...state, isFormChanged: memorize});
    setTimeout(() => form.resetFields());
  }

  const uploadOptions = {
    name: 'file',
    multiple: true,
    maxCount: 6,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76', // className: 'upload-list-inline',
    listType: 'picture', // defaultFileList:{[...fileList]}

    onChange(info) {
      const {status} = info.file;

      if (status !== 'uploading') {
        console.warn(info.file, info.fileList);
      }

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleAnalystChange = analystId => {
    const level = levelsSeed.find(
        l => l.analysts.find(a => a._id === analystId));
    form.setFieldsValue({levelId: level?._id});
    setState({...state, analystId: analystId, levelId: level?._id});
  };

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async values => {

    if (formContext?.data?.pictureUrl) {
      values.pictureUrl = formContext?.data?.pictureUrl;
      values.pictureAttachmentId = formContext?.data?.pictureAttachmentId;
    }

    // attachments
    values.attachmentsIds = formContext?.data?.attachmentsIds;

    try {

      let doc;

      if (formContext.mode === 'add') {
        msg('i', msgSaving, 'saving');
        doc = await postDocument(_module, values);

      } else {
        msg('i', msgUpdating, 'saving');
        doc = await putDocument(_module, data?._id, values);
      }

      message.destroy('saving');

      msg('s', formContext?.mode === 'add' ? msgSavingSuccess : msgUpdatingSuccess,
          'add');

      if (formContext?.mode === 'add') {
        history.replace({
          pathname: `${_module?.url}/${doc._id}/profile`,
          state: {isActive: true},
        });
      }

      setFormContext({
        ...formContext,
        mode: 'view',
        sectionsChanged: removeSection(formContext),
        data: doc,
        docLock: doc,
        dataMemory: _settings?.configurations?.dataMemory ? values : null,
      });

      setState({...state, isFormChanged: false});

    } catch (error) {

      let errorMessage;

      message.destroy('saving');

      if (error?.data?.code === 409) {

        const uniqueKey = isLogDocument
            ? translate(intl, 'name')
            : `${translate(intl, 'unique_key')} or ${translate(intl, 'name')}`;

        const m = translate(intl, 'response_error_409');
        errorMessage = substitution(m, ['%DOCUMENT_TYPE%'], [uniqueKey]);

      } else {
        errorMessage = `Erro ${error?.data?.code}: ${error?.data?.errorMessage}`;
      }

      msg('e', errorMessage, 'error', 4);
      // elRefs?.patrimony?.current?.focus({cursor: 'all'});

    }
  };

  const saveContext = _.debounce(value => {

    const currentData = {...initialFormData, ...value};
    const isChanged = !isObjEquals(currentData, initialFormData);

    setState({...state, isFormChanged: isChanged});

    setFormContext({
      ...formContext,
      data: {...formContext.data, ...value},
      sectionsChanged: isChanged ? addSection(formContext) : removeSection(formContext),
    });
  },600);

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <Form
        form={form}
        name="profile"
        layout="vertical"
        initialValues={data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={saveContext}
        validateTrigger="onChange"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        noValidate
    >


      {/* ORIGIN ***********************************************************/}

      <Row className="mb-2">
        <h4 className="text-muted">
          {setLocale(true, 'origin')}
        </h4>
      </Row>

      <Row gutter={ROW_GUTTER}>

        <Col xs={24} sm={10}>

          <Form.Item
              label={setLocale(true, 'name')}
              name="name"
              rules={rules.name}
          >
            <Input ref={elRefs.name} name="name" autoFocus/>
          </Form.Item>

        </Col>


        <Col xs={12} sm={7}>
          <Form.Item
              label={setLocale(true, 'occurrence_date')}
              name="occurrenceDate"
              rules={rules.occurrenceDate}
          >
            <DatePicker className="w-100 no-border-radius"
                        format="DD/MM/YYYY HH:mm"
                        placeholder=""
                        allowClear={false}
                        name="occurrenceDate"
            />
          </Form.Item>
        </Col>


        <Col xs={12} sm={7}>

          <Form.Item
              label={setLocale(true, 'place')}
              name="placeId">

            <Select
                value={state.placeId}
                showSearch
                allowClear
                onChange={v => setState({...state, placeId: v})}
                filterOption={filterOption}
                ref={elRefs.place}
            >
              {environmentSeed?.map(p => (
                  <Option key={p._id} value={p._id} text={p.value}>{p.value}</Option>))}
            </Select>
          </Form.Item>

        </Col>


      </Row>

      {/* OCCURRENCE *******************************************************/}

      <Row className="mb-2 mt-2">
        <h4 className="text-muted">
          {setLocale(true, 'occurrence')}
        </h4>
      </Row>

      <Row gutter={ROW_GUTTER}>

        <Col xs={24}>

          <Form.Item
              label={setLocale(true, 'subject')}
              name="subject"
              rules={rules.subject}
          >
            <Input ref={elRefs.subject} name="subject"/>
          </Form.Item>

        </Col>

      </Row>

      <Row gutter={ROW_GUTTER}>

        <Col xs={24}>

          <Form.Item
              label={setLocale(true, 'description')}
              name="description"
              rules={rules.description}
          >
            <TextArea rows={4} ref={elRefs.description} name="description"/>
          </Form.Item>

        </Col>

      </Row>

      <Upload {...uploadOptions}>
        <Button icon={<UploadOutlined/>}>Anexar Arquivos (Max. 6)</Button>
      </Upload>

      {/* CLASSIFICATION ***************************************************/}

      <Row className="mb-2 mt-4">
        <h4 className="text-muted">
          {setLocale(true, 'classification')}
        </h4>
      </Row>

      <Row gutter={ROW_GUTTER}>

        <Col xs={24} sm={10}>


          <Form.Item name="analystId"
                     label={setLocale(true, 'analyst')}>

            <Select
                value={document.analystId}
                onChange={handleAnalystChange}
                showSearch
                onClear={() => setState({...state, analystId: ''})}
                allowClear
                filterOption={filterOption}
                ref={elRefs.analystId}
            >

              {levelsSeed?.map(l => (

                  <OptGroup key={l._id} label={l.value}>

                    {l?.analysts?.map(a => (
                        <Option key={a._id} value={a._id} text={a.value}>
                          {a.value}
                        </Option>))}

                  </OptGroup>))}

            </Select>
          </Form.Item>

        </Col>


        <Col xs={12} sm={7}>

          <Form.Item
              label={setLocale(true, 'level')}
              name="levelId"
              rules={rules.levelId}>

            <Select ref={elRefs.levelId}
                    value={state.levelId}
                    showSearch
                    disabled={!!state.analystId}
                    filterOption={filterOption}
            >

              {levelsSeed?.map(
                  l => (<Option key={l._id} value={l._id} text={l.value}>
                    {l.value}
                  </Option>))}

            </Select>
          </Form.Item>

        </Col>


        <Col xs={12} sm={7}>

          <Form.Item
              label={setLocale(true, 'priority')}
              name="priorityId">
            <Select
                value={document.priorityId}
                onChange={v => setState({...state, priorityId: v})}
                showSearch
                allowClear
                onClear={() => setState({...state, priorityId: ''})}
                filterOption={filterOption}
                ref={elRefs.priority}
            >

              {prioritiesSeed?.map(
                  l => (<Option key={l._id} value={l._id} text={l.value}>
                    {l.value}
                  </Option>))}

            </Select>
          </Form.Item>

        </Col>

      </Row>


      <Row align="middle" justify="end">

        <Button type="primary"
                htmlType="submit"
                disabled={formContext.mode === 'view' || !state.isFormChanged}
                style={{minWidth: 100}}>
          {setLocale(true, 'save')}
        </Button>

      </Row>


    </Form>
  </div>);

});

// EXPORT **********************************************************************
// *****************************************************************************

export default OccurrencesProfile;
