import apiConfig, {del, get, post, put} from 'configs/ApiConfig';
import {useEffect, useState} from 'react';
import {isMongoObjectId} from 'utils/helpers';

// SCHEDULES *******************************************************************

// FETCH
export const useSchedulingFetch = () => {

  const [isFetching, setIsFetching] = useState(true);
  const [agendasData, setAgendasData] = useState(null);

  useEffect(function fetch() {
    (async function() {
      setAgendasData(await getScheduleList());
      setIsFetching(false);
    })();
  }, []);

  return [isFetching, agendasData];
};

// GET
export const getScheduleList = async query => {

  query && localStorage.setItem('schedules.query', JSON.stringify(query));

  const url = `/appdedalo/schedules-aggregate/list-between-dates`;
  const config = {
    headers: {'x-resultasobject': false}, params: query,
  };

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

export const getScheduleById = async _id => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  const url = `/appdedalo/schedules-aggregate/schedule-by-id/${_id}`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

export const getSchedule = async query => {

  const url = `/appdedalo/schedules-aggregate/between-dates-by-agendaid`;
  const config = {headers: {'x-resultasobject': true}, params: query};

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

export const getScheduleByCandidateCPF = async cpf => {

  if (!cpf) return null;

  const url = `/appdedalo/schedules-aggregate/schedules-by-candidate-cpf/${cpf}`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

// POST
export const postSchedule = async body => {

  const url = `/appdedalo/schedules`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    const result = await post(url, body, config);
    return result


  } catch (error) {
    throw error;
  }
};

// PUT
export const putSchedule = async (_id, body) => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  const url = `/appdedalo/schedules/${_id}`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

// PUT ADD CANDIDATE TO SCHEDULE
export const putCandidateToSchedule = async (_id, body) => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  const url = `/appdedalo/schedules-aggregate/push-candidate/${_id}`;
  const config = {headers: {'x-resultasobject': true}};

  body.office = apiConfig.defaults.headers['x-subscriptionid'] ===
  '6282e7831b7d873e20a479a1' ? 'RP' : 'SP';

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

// PUT REMOVE CANDIDATE FROM SCHEDULE
export const removeCandidateFromSchedule = async (_id, body) => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  const url = `/appdedalo/schedules-aggregate/pull-candidate/${_id}`;
  const config = {headers: {'x-resultasobject': true}};

  body.office = apiConfig.defaults.headers['x-subscriptionid'] ===
  '6282e7831b7d873e20a479a1' ? 'RP' : 'SP';

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

// DELETE
export const removeSchedule = async (_id, body) => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  const url = `/appdedalo/schedules/${_id}`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    return await del(url, config);

  } catch (error) {
    throw error;
  }
};

// AGENDAS *********************************************************************
// GET
export const getAgendaById = async (_id) => {

  const resultAsObject = true;
  const url = `/appdedalo/agendas/${_id}`;
  const config = {headers: {'x-resultasobject': resultAsObject}};

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};
