import React from 'react'
import { Badge, Button, Table, Tooltip } from 'antd'
import IntlMessage from 'components/util-components/IntlMessage'
import ImageDescription from 'components/jarvisly/ImageDescription'
import moment from 'moment/moment'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { DeviceTag, OnOffStatusTag } from '../Form/Components'
import { buildDevicesFilter, buildOnOffStatusFilter, getDevicePicture } from '../methods'
import { useIntl } from 'react-intl'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const IotList = props => {

  // props deconstruction ------------------------------------------------------
  const {
    loading,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    data,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
    onClickPreview,
  } = props

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const statusFilter = buildOnOffStatusFilter()
  const devicesFilter = buildDevicesFilter()
  const tableColumns = buildTableColumns()
  const intl = useIntl()

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (<>
    <Table key={tableKey}
           rowKey="_id"
           columns={tableColumns.filter(x => !x.hidden)}
           dataSource={data || []}
           pagination={pagination}
           onChange={onTableChanges}
           rowSelection={buildRowSelection}
           className="with-checkbox"
           resetTableSearch={resetTableSearch}
           loading={loading}
    />
  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns () {

    return [
      {
        hidden: !showNumberOfLine,
        title: setLocale(true, 'line_#'),
        width: 80,
        render: (item, record, index) => {

          if (!pagination) return

          const {
            current,
            pageSize,
          } = pagination

          return (<div className="text-left">
            {((current * pageSize) - pageSize + index + 1) || index}
          </div>)
        },
      },

      {
        title: setLocale(true, 'device'),
        dataIndex: 'title',
        ...columnSorter('title', 4),
        ...columnSearch('title'),
        className: 'text-truncate',
        render: (_, record) => {

          return (<div className="d-flex" style={{ width: 200 }}>

            <ImageDescription
              shape="square"
              imageUrl={getDevicePicture(record?.device, intl)}
              style={{ padding: 14 }}
              title={
                <Badge className="badge-size-12" color={record?.backColor || 'gray'}
                       text={record?.title}/>}
              subtitle={record?.description}
              backColor="#ecf2fe"
            />

          </div>)
        },
      },

      {
        title: setLocale(true, 'channel'),
        dataIndex: 'channel',
        ...columnSorter('channel', 5),
        ...columnSearch('channel'),
        render: (_, record) => (<strong>{record?.channel}</strong>),
      },

      {
        title: setLocale(true, 'type'),
        dataIndex: 'device',
        ...columnSorter('device', 3),
        ...columnFilters('device', devicesFilter),
        render: (_, record) => <DeviceTag record={record}/>,
      },

      {
        title: setLocale(true, 'status'),
        dataIndex: 'status',
        ...columnSorter('status', 2),
        ...columnFilters('status', statusFilter),
        width: 100,
        render: (_, record) => <OnOffStatusTag record={record}/>,
      },

      {
        title: setLocale(true, 'last_reading'),
        dataIndex: 'lastReadingAt',
        ...columnSorter('lastReadingAt', 1),
        width: 160,
        hidden: false,
        render: (_, record) => (<>
          {record?.lastReadingAt && moment(record.lastReadingAt).fromNow()}
        </>),
      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">


            <Tooltip title={setLocale(true, 'edit')}>
              <Button className="mr-2" icon={<EditOutlined/>}
                      onClick={() => onClickOpen(record)}
                      size="small"/>
            </Tooltip>


            <Tooltip title={setLocale(true, 'view')}>
              <Button className="mr-2" icon={<EyeOutlined/>}
                      onClick={() => onClickPreview(record)}
                      size="small"/>
            </Tooltip>
          </div>),
      },

    ].filter(x => !x.hidden)
  }

}

// EXPORT **********************************************************************
// *****************************************************************************

export default React.memo(IotList)
