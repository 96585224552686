import {
  SELECT_ROUTE,
  SELECT_MODULE,
  SHOW_MODULE_SETTINGS,
  SHOW_MODULE_SEARCH,
  MODULE_SEARCH,
  FORM_PROVIDER,
  DATA_PROVIDER,
  MODULE_SETTINGS,
  MODULE_PARAMETERS,
  MODULE_LIST,
  MODULE_DASHBOARD,
} from '../constants/module';


export function onSelectRoute(selectedRoute) {
  return {
    type: SELECT_ROUTE,
    selectedRoute,
  };
}

export const rdxSetModule = module => {
  return {
    type: SELECT_MODULE,
    payload: module,
  };
};

export const rdxSetShowModuleSettings = (show = false) => {
  return {
    type: SHOW_MODULE_SETTINGS,
    payload: show,
  };
};

export const rdxSetShowModuleFilter = (show = false) => {
  return {
    type: SHOW_MODULE_SEARCH,
    payload: show,
  };
};

export const rdxSetModuleQuery = (value = null) => {
  return {
    type: MODULE_SEARCH,
    payload: value,
  };
};

export const rdxSetFormProvider = (value = null) => {
  return {
    type: FORM_PROVIDER,
    payload: value,
  };
};

export const rdxSetDataProvider = (value = null) => {
  return {
    type: DATA_PROVIDER,
    payload: value,
  };
};


export const rdxSetModuleSettings = (value = null) => {
  return {
    type: MODULE_SETTINGS,
    payload: value,
  };
};


export const rdxSetModuleParameters = (value = null) => {
  return {
    type: MODULE_PARAMETERS,
    payload: value,
  };
};



export const rdxSetModuleList = (value = null) => {
  return {
    type: MODULE_LIST,
    payload: value,
  };
};


export const rdxSetModuleDashboard = (value = null) => {
  return {
    type: MODULE_DASHBOARD,
    payload: value,
  };
};
