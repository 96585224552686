import React from 'react';
import {
    decorateDocument,
    decorateNewDocument,
    dataProfileValidation,
    removeDocumentValidation,
    validateFilterFn,
    validateSearchFn,
} from './entities-methods';
import EntitiesMainList from './EntitiesMainList';
import DocumentDataProfiles
    from 'components/jarvisly-layouts/ModuleComponent/Components/DocumentDataProfiles';
import {
    EntitiesBodyPreviewActions,
    EntitiesBodyPreviewData, EntitiesBodyPreviewHeader,
} from './EntitiesPreview';
import {entitiesSettingsTabs} from './entities-settings';
import {
    EntitiesDocumentHeaderColumn2,
    EntitiesDocumentHeaderColumn3,
} from './EntitiesDocumentHeader';
import {
    FormOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {Route} from 'react-router-dom';
import EntitiesProfile from './sections/EntitiesProfile';
import DocumentNotes
    from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/DocumentNotes';
import {
    defaultDecorateSettings, defaultParseSettings,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

export const ENTITIES_API_URL = 'v2/entities';
const MODULE_COLLABORATORS_URL = '/app/records/entities/collaborators';


const INITIAL_FILTER = {
    folder: 'documents',
};

const INITIAL_SORT = {
    sortField: '_metadata.audit.updatedAt',
    sortOrder: 'descend',
};

const INITIAL_SEARCH = {
    documentCode: 'null',
    name: 'null',
    documentStatus: 'null',
};

const INITIAL_CONTEXT = {
    mode: 'add',
    data: null,
    docLock: null,
    selectedSection: null,
    sectionsChanged: [],
    dataMemory: false,
    hasPicture: true,
};

const METHODS = {
    parseSettings: defaultParseSettings,
    decorateSettings: defaultDecorateSettings,

    decorateDocument: decorateDocument,
    decorateNewDocument: decorateNewDocument,

    removeDocumentValidation: removeDocumentValidation,
    postDocumentValidation: dataProfileValidation,
    putDocumentValidation: dataProfileValidation,

    validateSearchFn: validateSearchFn,
    validateFilterFn: validateFilterFn,

    // refreshParameters : getModuleParameters
};

const COMPONENTS = {
    list: props => <EntitiesMainList {...props}/>,
    listPreviewHeader: props => <EntitiesBodyPreviewHeader {...props}/>,
    listPreviewBody: props => <EntitiesBodyPreviewData {...props}/>,
    listPreviewActions: props => <EntitiesBodyPreviewActions {...props}/>,

    documentSubtitle: props => <DocumentDataProfiles {...props}/>,
    documentHeaderColumn2: props => <EntitiesDocumentHeaderColumn2 {...props}/>,
    documentHeaderColumn3: props => <EntitiesDocumentHeaderColumn3 {...props}/>,

    // documentSectionsMenu: (props, ref) => <EntitiesDocumentSectionsMenu {...props} ref={ref}/>,
    // documentSectionsContent: (props, ref) => <EntitiesDocumentSectionsContent {...props} ref={ref}/>,

    // dedalo: {
    //   list: props => <DedaloEntitiesMainList {...props}/>,
    // },

    moduleSettings: entitiesSettingsTabs,
    // modalSettings: props => <EntitiesSettings {...props}/>,
};

const DATA_TYPES = [
    {
        key: 'person',  // database value
        label: 'individual_person',
        description: 'entities_individual_person_description',
        checked: true,
    },
    {
        key: 'company',
        label: 'legal_person',
        description: 'entities_legal_person_description',
        checked: true,
    }];

const DOCUMENT_SECTIONS_COLLABORATORS = [
    {
        key: 'profile',
        icon: <UserOutlined/>,
        label: 'profile',
        route: (props, ref, _id) => <Route key="profile"
                                           path={`${MODULE_COLLABORATORS_URL}/${_id}/profile`}
                                           render={() =>
                                               <EntitiesProfile ref={ref} {...props} />}/>,
    },
    {
        key: 'notes',
        icon: <FormOutlined/>,
        label: 'notes',
        route: (props, ref, _id) => <Route key="notes"
                                           path={`${MODULE_COLLABORATORS_URL}/${_id}/notes`}
                                           render={() =>
                                               <DocumentNotes ref={ref} {...props} />}/>,
    },
];

const REQUIRED_FIELDS = [
    {
        key: 'cpf',
        label: 'cpf',
        checked: true,
        disabled: false,
        forDataType: ['person'],
    },
    {
        key: 'birthday',
        label: 'birthdate',
        checked: false,
        disabled: false,
        forDataType: ['person'],
    },
    {
        key: 'name_or_nickname',
        label: 'name_or_nickname',
        checked: true,
        disabled: true,
        forDataType: ['person'],
    },

    {
        key: 'cnpj',
        label: 'cnpj',
        checked: true,
        disabled: false,
        forDataType: ['company'],
    },

    {
        key: 'rg',
        label: 'rg',
        checked: false,
        disabled: false,
        forDataType: ['person', 'company'],
    },

    {
        key: 'foundedAt',
        label: 'foundation_date',
        checked: false,
        disabled: false,
        forDataType: ['company'],
    },
    {
        key: 'trade_name',
        label: 'trade_name',
        checked: true,
        disabled: true,
        forDataType: ['company'],
    },

    {
        key: 'apartment',
        label: 'apartment',
        checked: true,
        disabled: true,
        forDataType: ['person', 'company'],
    },

    {
        key: 'mainPhone',
        label: 'phone',
        checked: true,
        disabled: false,
        dependence: 'mainEmail',
        dependence_msg: '',
        forDataType: ['person', 'company'],
    },

    {
        key: 'mainEmail',
        label: 'email',
        checked: false,
        disabled: false,
        dependence: 'mainPhone',
        forDataType: ['person', 'company'],
    },

];


// ************************************************************************** //
// SEEDS
// ************************************************************************** //


const SETTINGS_SEED_COLLABORATORS = {

    configurations: {
        dataTypes: ['person'],
        dataMemory: false,
    },

    requiredFields: [
        'cpf',
        'birthday',
        'name_or_nickname',
        'cnpj',
        'trade_name',
        'mainPhone',
    ],

};


const SETTINGS_SEED_PROVIDERS = {

    configurations: {
        dataTypes: ['person', 'company'],
        dataMemory: false,
    },

    requiredFields: [
        'cpf',
        'birthday',
        'apartment',
        'name_or_nickname',
        'cnpj',
        'rg',
        'trade_name',
        'mainPhone',
    ],

};


// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_ENTITIES_PARAMETERS = {

    PROVIDERS: {
        root: 'entities',
        name: 'providers',
        singular: 'provider',
        titleField: 'name',
        url: '/app/records/entities/providers',
        api: ENTITIES_API_URL,

        hasFilter: true,
        hasAccessControl: true,
        hasSettings: true,
        hasConfigurations: true,
        hasDataMemory: true,
        hasRequiredFields: true,

        methods: METHODS,
        components: COMPONENTS,
        dataTypes: DATA_TYPES,
        requiredFields: REQUIRED_FIELDS,

        initialSearch: INITIAL_SEARCH,
        initialContext: INITIAL_CONTEXT,
        initialFilter: {...INITIAL_FILTER, dataProfile: 'provider'},
        initialSort: INITIAL_SORT,

        labels: {
            new_document_title: 'new_provider',
        },

        seeds: {
            settings: SETTINGS_SEED_PROVIDERS
        }
    },

    COLLABORATORS: {
        root: 'entities',
        name: 'collaborators',
        singular: 'collaborator',
        titleField: 'name',
        url: MODULE_COLLABORATORS_URL,
        api: ENTITIES_API_URL,

        hasFilter: true,
        hasSettings: true,
        hasAccessControl: true,
        hasConfigurations: true,
        hasDataMemory: true,
        hasRequiredFields: true,

        methods: METHODS,
        components: COMPONENTS,
        dataTypes: DATA_TYPES.filter(x => x.key === 'person'),
        requiredFields: REQUIRED_FIELDS,

        initialSearch: INITIAL_SEARCH,
        initialContext: INITIAL_CONTEXT,
        initialFilter: {...INITIAL_FILTER, dataProfile: 'collaborator'},
        initialSort: INITIAL_SORT,

        labels: {
            new_document_title: 'new_collaborator',
        },

        documentSections: DOCUMENT_SECTIONS_COLLABORATORS,

        seeds: {
            settings: SETTINGS_SEED_COLLABORATORS
        }

    },
};
