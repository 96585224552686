import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Tooltip } from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import ImageDescription from '../../../../components/jarvisly/ImageDescription';
import DocumentStatusTag from '../../../../components/jarvisly-layouts/ModuleComponent/Components/DocumentStatusTag';
import moment from 'moment/moment';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const List = props => {

  // props deconstruction ------------------------------------------------------
  const {
    loading,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    data,
    buildRowSelection,
    showNumberOfLine,
    rdxModuleSettings,
    onClickOpen,
    onClickPreview,
  } = props;

  // component states ----------------------------------------------------------
  const [blocksFilter, setBlocksFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);

  // local variables -----------------------------------------------------------
  // const statusFilter = buildStatusFilter()
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {


    // filter for blocks column
    const blocks = rdxModuleSettings?.apartments?.blocks?.map(
      b => b?._id && b?.name && ({
        text: b.name,
        value: b._id
      }));

    setBlocksFilter(blocks || null);

  }, [rdxModuleSettings?.apartments?.blocks]);

  useEffect(() => {
    setStatusFilter(buildStatusFilter() || null);
  }, []);

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (<>
    <Table key={tableKey}
           rowKey="_id"
           columns={tableColumns.filter(x => !x.hidden)}
           dataSource={data || []}
           pagination={pagination}
           onChange={onTableChanges}
           rowSelection={buildRowSelection}
           className="with-checkbox"
           resetTableSearch={resetTableSearch}
           loading={loading}
    />
  </>);

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns () {

    return [
      {
        hidden: !showNumberOfLine,
        title: setLocale(true, 'line_#'),
        width: 80,
        render: (item, record, index) => {

          if (!pagination) return;

          const {
            current,
            pageSize
          } = pagination;

          return (<div className="text-left">
            {((current * pageSize) - pageSize + index + 1) || index}
          </div>);
        },
      },

      {
        title: setLocale(true, 'apartment'),
        dataIndex: 'roomNumber',
        ...columnSorter('roomNumber', 2),
        ...columnSearch('roomNumber'),
        className: 'text-truncate',
        render: (_, record) => {
          const subtitle = record?.dataResponsible?.name || record?.__titular?.name;
          return (<div className="d-flex" style={{ width: 200 }}>

            <ImageDescription
              shape="square"
              initials={record?.room}
              title={record?.__apartment}
              subtitle={subtitle}
              foreColor={record?.__block?.foreColor}
              backColor={record?.__block?.backColor}
            />
          </div>);
        },
      },

      {
        title: setLocale(true, 'block_unit'),
        dataIndex: 'blockId',
        ...columnFilters('blockId', blocksFilter),
        ...columnSorter('blockId', 1),
        // width: 168.5,
        render: (_, record) => (<>
          <Tag key={record._id} color={record?.__block?.backColor}>
                  <span className="text-uppercase" style={{ color: record?.__block?.foreColor }}>
                    {record?.__block?.name}
                  </span>
          </Tag>
        </>),
      },

      {
        title: setLocale(true, 'residents'),
        dataIndex: 'titularId',
        ...columnSorter('titularId', 10),
        render: (_, record) => (<>
          {!record?.titularId
            ? <Tag key={'unoccupied'} color={'gray'}>
                <span className="text-uppercase" style={{ color: 'white' }}>
                  {setLocale(true, 'unoccupied')}
                </span>
            </Tag>
            : null}

          {/*
          <Avatar.Group>
            <Avatar src="https://ui-avatars.com/api/?background=random"/>
            <Avatar src="https://joeschmoe.io/api/v1/random"/>
            <Avatar
                style={{
                    backgroundColor: '#f56a00',
                }}
            >
                K
            </Avatar>
            <Tooltip title="Ant User" placement="top">
                <Avatar
                    style={{
                        backgroundColor: '#87d068',
                    }}
                    icon={<UserOutlined/>}
                />
            </Tooltip>
            <Avatar
                style={{
                    backgroundColor: '#1890ff',
                }}
                icon={<AntDesignOutlined/>}
            />
          </Avatar.Group>
          */}

        </>),
      },

      {
        title: setLocale(true, 'status'),
        dataIndex: 'documentStatus',
        ...columnFilters('documentStatus', statusFilter),
        ...columnSorter('documentStatus', 11),
        width: 100,
        render: (_, record) => (<DocumentStatusTag record={record}/>),
      },

      {
        title: setLocale(true, 'last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        ...columnSorter('_metadata.audit.updatedAt', 12),
        width: 160,
        hidden: false,
        render: (_, record) => (<>
          {record?._metadata?.audit?.updatedAt &&
            // moment(record._metadata.audit.updatedAt).format('L LT')}
            moment(record._metadata.audit.updatedAt).fromNow()}
        </>),
      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">


            <Tooltip title={setLocale(true, 'edit')}>
              <Button className="mr-2" icon={<EditOutlined/>}
                      onClick={() => onClickOpen(record)}
                      size="small"/>
            </Tooltip>


            <Tooltip title={setLocale(true, 'view')}>
              <Button className="mr-2" icon={<EyeOutlined/>}
                      onClick={() => onClickPreview(record)}
                      size="small"/>
            </Tooltip>
          </div>),
      },

    ].filter(x => !x.hidden);
  }

  function buildStatusFilter () {
    return [
      // {
      //   text: setLocale(true, 'pending_update'), value: 'pending_update',
      // },
      // {
      //   text: setLocale(true, 'update_expired'), value: 'update_expired',
      // },
      // {
      //   text: setLocale(true, 'document_updated'), value: 'document_updated',
      // },
      {
        text: setLocale(true, 'in_analise'),
        value: 'in_analise',
      },
      {
        text: setLocale(true, 'with_reservation'),
        value: 'with_reservation',
      },
      {
        text: setLocale(true, 'revised'),
        value: 'revised',
      }
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default React.memo(List);
