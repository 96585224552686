import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
  AUTH_TOKEN, SIGNIN, SIGNOUT, SIGNUP, SIGNIN_WITH_GOOGLE, SIGNIN_WITH_FACEBOOK,
} from '../constants/auth'
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
} from '../actions/auth'

import FirebaseService from 'services/FirebaseService'
import { getFirebaseUser } from 'auth/auth-api'

let accountSuccess = false

const loadUser = async data => {
  const result = await getFirebaseUser(data)
  accountSuccess = !!result
}

export function * signOut () {

  yield takeEvery(SIGNOUT, function * () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest)
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN)
        localStorage.removeItem('accountId')

        yield put(signOutSuccess(signOutUser))
      } else {
        yield put(showAuthMessage(signOutUser.message))
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export function * signUpWithEmail () {

  yield takeEvery(SIGNUP, function * ({ payload }) {

    const {
      email,
      password,
      name,
      phoneNumber,
    } = payload

    try {
      const data = yield call(FirebaseService.signUpEmailRequest, email,
        password)

      if (data.message) {

        yield put(showAuthMessage(data.message))

      } else {

        if (name) data.name = name
        if (phoneNumber) data.phoneNumber = phoneNumber

        yield call(loadUser, {
          user: data,
          provider: 'email',
        })

        if (accountSuccess) {

          localStorage.setItem(AUTH_TOKEN, data.user.uid)
          yield put(signUpSuccess(data.user.uid))

        } else {
          localStorage.removeItem(AUTH_TOKEN)
          yield put(showAuthMessage('Usuário não encontrado!'))
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export function * signInWithEmail () {
  yield takeEvery(SIGNIN, function * ({ payload }) {

    const {
      email,
      password,
    } = payload

    try {

      const data = yield call(FirebaseService.signInEmailRequest, email,
        password)

      if (data.message) {
        yield put(showAuthMessage(data.message))

      } else {
        yield call(loadUser, {
          user: data,
          provider: 'jwt',
        })

        if (accountSuccess) {
          localStorage.setItem(AUTH_TOKEN, data.user.uid)
          yield put(authenticated(data.user.uid))

        } else {
          localStorage.removeItem(AUTH_TOKEN)
          yield put(showAuthMessage('Usuário não encontrado!'))
        }
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export function * signInWithGoogle () {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function * () {
    try {
      const data = yield call(FirebaseService.signInGoogleRequest)

      if (data.message) {
        yield put(showAuthMessage(data.message))

      } else {
        // localStorage.setItem(AUTH_TOKEN, data.user.uid);

        yield call(loadUser, {
          user: data,
          provider: 'google',
        })

        if (accountSuccess) {
          localStorage.setItem(AUTH_TOKEN, data.user.uid)
          yield put(signInWithGoogleAuthenticated(data.user.uid))

        } else {
          localStorage.removeItem(AUTH_TOKEN)
          yield put(showAuthMessage('Usuário não encontradado!'))
        }
      }

    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export function * signInWithFacebook () {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function * () {
    try {
      const data = yield call(FirebaseService.signInFacebookRequest)
      if (data.message) {
        yield put(showAuthMessage(data.message))
      } else {
        // localStorage.setItem(AUTH_TOKEN, data.user.uid);

        yield call(loadUser, {
          user: data,
          provider: 'facebook',
        })

        if (accountSuccess) {
          localStorage.setItem(AUTH_TOKEN, data.user.uid)
          yield put(signInWithFacebookAuthenticated(data.user.uid))

        } else {
          localStorage.removeItem(AUTH_TOKEN)
          yield put(showAuthMessage('Usuário não encontradado!'))
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(signInWithEmail),
    fork(signOut),
    fork(signUpWithEmail),
    fork(signInWithGoogle),
    fork(signInWithFacebook)])
}
