import React from 'react';
import {Tag} from 'antd';
import IntlMessage from '../../../util-components/IntlMessage';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const DocumentDataProfiles = props => {

  const {record, _module} = props;

  return (
      <>
        {record?.dataProfile && record?.dataProfile?.map((x, idx, arr) => {
          return (
              <Tag key={x}
                   className={`text-uppercase multi-tags ${arr.length === 1
                       ? 'unique'
                       : ''}`}
                   color={_module?.singular === x ? 'geekblue' : ''}>
                {x && setLocale(true, x)}
              </Tag>);
        })}
      </>
  );
};

export default DocumentDataProfiles;
