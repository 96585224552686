import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import Views from './layouts/routes'
import { Route, Switch } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { THEME_CONFIG } from './configs/AppConfig'
import Helmet from 'react-helmet'

global.ASYNC_VALIDATOR_NO_WARNING = 1

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
}

function App () {

  useEffect(() => {

    let input = ''
    let tmr

    const handleKeyDown = (event) => {

      input += event.key

      if (input.length === 10 && !isNaN(input)) {
        console.log('EXECUTA ROTA AQUI! CÓDIGO:', input)
        input = ''
      }

      if (tmr && tmr.cancel) tmr.cancel()
      tmr = setTimeout(() => (input = ''), 400)
    }

    const divElement = document.querySelector('html')
    divElement.addEventListener('keydown', handleKeyDown)

    // document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }

  }, [])

  return (

    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme}
                               insertionPoint="styles-insertion-point">

          <Helmet>
            <title>{THEME_CONFIG.APP.IS_PORTAL_ATUALIZA_INFO ? 'Atualiza.Info' : THEME_CONFIG.APP.NAME}</title>
            <link rel="icon" type="image/png" href={`/apps/${THEME_CONFIG.APP.ID}/favicon.ico`}/>
            <link rel="apple-touch-icon" sizes="180x180"
                  href={`/apps/${THEME_CONFIG.APP.ID}/apple-touch-icon.png`}/>
            <link rel="icon" type="image/png" sizes="32x32"
                  href={`/apps/${THEME_CONFIG.APP.ID}/favicon-32x32.png`}/>
            <link rel="icon" type="image/png" sizes="16x16"
                  href={`/apps/${THEME_CONFIG.APP.ID}/favicon-16x16.png`}/>
            <link rel="manifest" href={`/apps/${THEME_CONFIG.APP.ID}/site.webmanifest`}/>
            <link rel="mask-icon"
                  href={`/apps/${THEME_CONFIG.APP.ID}/safari-pinned-tab.svg" color="#5bbad5`}/>
          </Helmet>

          <Router>
            <Switch>
              <Route path="/" component={Views}/>
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  )
}

export default App
