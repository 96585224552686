import React, {useEffect, useState} from 'react';
import {Dropdown, Space} from 'antd';
import {connect, useDispatch} from 'react-redux';
import {SettingOutlined, LogoutOutlined} from '@ant-design/icons';
import {signOut} from 'redux/actions/auth';
import IntlMessage from '../util-components/IntlMessage';
import {NavLink, withRouter} from 'react-router-dom';
import RoundedAvatar from '../jarvisly/RoundedAvatar';
import {resetModule} from '../jarvisly-layouts/ModuleComponent/module-methods';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

export const NavProfile = props => {

  const {
    signOut,
    rdxUser,
    location,
  } = props;

  const [authUser, setAuthUser] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setAuthUser(rdxUser);
  }, [rdxUser]);

  const User = ({className}) => {
    return (<RoundedAvatar className={className} user={authUser} showIcon/>);
  };

  const items = [
    {
      key: 'user',
      disabled: true,
      label: (<div className="nav-profile-header cursor-text">
        <div className="d-flex">

          <User/>

          <div className="pl-3 text-truncate">
            <h4 className="mb-0">{authUser?.name}</h4>
            {/* <span className="text-muted">Síndico</span> */}
            <span className="text-muted">
                {authUser?.email}
              </span>
          </div>
        </div>
      </div>),
    }, {
      type: 'divider',
    }, {
      key: '/account/settings', label: (
          <NavLink to="/account/settings" onClick={() => resetModule(dispatch)}>
            <SettingOutlined className="mr-2"/>
            <span className="font-weight-normal">
                {setLocale(true, 'account_settings')}
              </span>
          </NavLink>),
    }, {
      key: 'exit', label: (<div onClick={e => signOut()}>
        <LogoutOutlined className="mr-2"/>
        <span className="font-weight-normal">
              {setLocale(true, 'sign_out')}
            </span>
      </div>),
    }];

  return (

      <Dropdown placement="bottomRight"
                trigger={['click']}
                menu={{
                  items, selectable: true, selectedKeys: [location.pathname],
                }}>
        <a href="/#" onClick={(e) => e.preventDefault()}>
          <Space>
            <User className="nav-profile-icon pl-3 pr-3"/>
          </Space>
        </a>
      </Dropdown>

  );
};

const mapStateToProps = ({auth}) => {
  const {rdxUser} = auth;
  return {rdxUser};
};

const mapDispatchToProps = {signOut};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(React.memo(NavProfile)));
