import React from 'react';
import {
  decorateDocument,
  decorateNewDocument,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
  parseSettings,
  getApartmentModuleParameters,
} from './methods';
import ApartmentsMainList from './List/List';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Route } from 'react-router-dom';
import {
  defaultDecorateSettings,
  defaultDataProfileValidation,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import ApartmentsProfile from './Form/sections/Profile';
import {
  ApartmentsDocumentHeaderColumn2,
  ApartmentsDocumentHeaderColumn3,
  ApartmentsDocumentSubtitle,
} from './Form/Header';
import ModuleSettingsBlocks from './Settings/Blocks';
import ModuleSettingsProfiles from './Settings/Profiles';
import IntlMessage from 'components/util-components/IntlMessage';
import ApartmentsTitular from './Form/sections/Titular';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString();

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const APARTMENTS_URL = '/app/records/condominium/apartments';
export const APARTMENTS_API_URL = '/v2/apartments';

const INITIAL_LIST_STATE = {
  showNumberOfLine: localStorage.getItem('showNumberOfLine') === 'true',
  showCheckBox: localStorage.getItem('showCheckBox') === 'true',
};
const INITIAL_REQUEST = {

  // textSearch: {
  //   default: 'mONTE aLto',
  //   enum: ['a', 'b'],
  // },

  folder: {
    default: 'documents',
    enum: ['documents', 'recycle', 'filed'],
  },

  // filter: {
  //   default: {
  //     color: { $in: ['black', 'white'] },
  //     city: { $in: ['Campinas'] },
  //   },
  //   enum: {
  //     color: ['branco', 'azul', 'amarelo'],
  //     city: ['Campinas', 'Itu'],
  //   },
  // },

  search: {},
  //   default: {
  //     age: 43,
  //     roomNumber: { $in: [11, 12, 43] },
  //     name: 'Ane Cardinalli',
  //   },
  //   enum: {
  //     age: [10, 30, 40],
  //     roomNumber: [22, 33, 54, 82],
  //   },
  // },

  sorter: {
    // roomNumber: 1,
    // blockId:-1
  },

  // project: {
  //   room: 1,
  //   blockId: 1,
  // },

  pagination: {
    pageNumber: 1,
    // pageSize: 10
    pageSize: ['10', '20', '50', '100'].includes(localStorage.getItem('pageSize'))
      ? Number(localStorage.getItem('pageSize'))
      : 10,
  },
};

const INITIAL_FILTER = {
  folder: 'documents',
  // dataProfile: 'plan',
};

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
};

const INITIAL_SEARCH = {
  roomNumber: 'null',
  blockId: 'null',
  documentStatus: 'null',
};

const INITIAL_CONTEXT = {
  hasPicture: false,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
};

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,

  refreshParameters: getApartmentModuleParameters,
};

const COMPONENTS = {
  list: props => <ApartmentsMainList {...props}/>,
  // tableListV2: props => <ApartmentsMainListV2 {...props}/>,
  // listPreviewHeader: props => <ApartmentsBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <ApartmentsBodyPreviewData {...props}/>,
  // listPreviewActions: props => <ApartmentsBodyPreviewActions {...props}/>,

  documentSubtitle: props => <ApartmentsDocumentSubtitle {...props}/>,
  // documentSubtitle: props => <div>{props?.record?.description}</div>,
  documentHeaderColumn2: props => <ApartmentsDocumentHeaderColumn2 {...props}/>,
  documentHeaderColumn3: props => <ApartmentsDocumentHeaderColumn3 {...props}/>,

  // moduleSettings: props => <apartmentsSettingsTabs {...props}/>,
  // moduleSettings: apartmentsSettingsTabs(),
};

const DATA_TYPES = [
  {
    key: 'document',  // database value
    label: 'document',
    description: 'document_description',
    disabled: true,
    checked: true,
  },
];

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <HomeOutlined/>,
    label: 'unit',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${APARTMENTS_URL}/${_id}/profile`}
                                       render={() =>
                                         <ApartmentsProfile ref={ref} {...props}
                                                            _data={props._dataRoots}
                                                            _dataLock={props._dataRootsLock}/>}/>,
    settingsComponent: [
      {
        key: 'blocks',
        label: (<span>{setLocale(true, 'blocks')}</span>),
        children: (<div style={{ padding: '4px 24px' }}>
          <ModuleSettingsBlocks/>
        </div>),
      },
      {
        key: 'profiles',
        label: (<span>{setLocale(true, 'profiles')}</span>),
        children: (<div style={{ padding: '4px 24px' }}>
          <ModuleSettingsProfiles/>
        </div>),
      },
    ],
  },

  {
    type: 'divider',
  },

  {
    key: 'titular',
    icon: <UserOutlined/>,
    label: 'titular_resident',
    sectionStatusEdit: '_update.titularUpdated',
    sectionStatusDone: '_update.titularAccepted',
    route: (props, ref, _id) => <Route key="titular"
                                       path={`${APARTMENTS_URL}/${_id}/titular`}
                                       render={() =>
                                         <ApartmentsTitular {...props}
                                                            ref={ref}
                                           // _data={props._data}
                                           // _dataLock={props._dataLock?._update?.titular}
                                         />}/>,
  },

  /*
    {
      key: 'titular',
      icon: <UserOutlined/>,
      label: 'titular_resident',
      route: (props, ref, _id) => <Route key="titular"
                                         path={`${MODULE_APARTMENTS_URL}/${_id}/titular`}
                                         render={() =>
                                           <ResidentsProfile ref={ref} {...props}
                                                             _data={props._data?.titular}
                                                             _dataLock={props._dataLock?.titular}/>}/>,
    },
    {
      key: 'habitation',
      icon: <AuditOutlined/>,
      label: 'housing_agreement',
      route: (props, ref, _id) => <Route key="habitation"
                                         path={`${MODULE_APARTMENTS_URL}/${_id}/habitation`}
                                         render={() =>
                                           <NoSection ref={ref} {...props} />}/>,
    },
    {
      key: 'residents',
      icon: <TeamOutlined/>,
      label: 'residents',
      route: (props, ref, _id) => <Route key="residents"
                                         path={`${MODULE_APARTMENTS_URL}/${_id}/residents`}
                                         render={() =>
                                           <NoSection ref={ref} {...props} />}/>,
    },
    {
      type: 'divider',
    },
    {
      key: 'owner',
      icon: <TrophyOutlined/>,
      label: 'owner',
      route: (props, ref, _id) => <Route key="owner"
                                         path={`${MODULE_APARTMENTS_URL}/${_id}/owner`}
                                         render={() =>
                                           <EntitiesProfile ref={ref}
                                                            {...props}
                                                            _data={props._data?.owner}
                                                            _dataLock={props._dataLock?.owner}/>}/>,
    },
  */
];

const REQUIRED_FIELDS = [
  {
    key: 'uniqueKey',
    label: 'unique_key',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'block',
    label: 'block',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'profile',
    label: 'profile',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'floor',
    label: 'floor',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
];

const CUSTOM_ACTIONS_FORM = ['askForUpdate'];
/*const CUSTOM_ACTIONS_FORM = [
  {
    key: 'askForUpdate',
    icon: <RocketOutlined/>,
    labelKey: 'request_update',
  },
]*/

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {

  configurations: {
    dataTypes: ['document'],
    dataMemory: true,
  },

  requiredFields: [
    'name',
    'block',
    'profile',
    'floor',
  ],
};

const AUTO_FILL_APARTMENTS = {
  blockId: '63e30416e92d2016f98a9ab1',
  dataProfile: 'plan',
  dataType: 'document',
  directCall: 3031,
  floor: 3,
  name: '31',
  number: 31,
  profileId: '63e3de2bc4e78cac557502f6',
  uniqueKey: '031 | #03 Campo Belo (CB) | Torre Verde',
};

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_APARTMENTS_PARAMETERS = {

  root: 'apartments',
  name: 'apartments',
  singular: 'apartment',
  titleField: '__apartment',
  url: APARTMENTS_URL,
  api: APARTMENTS_API_URL,
  initialRequest: INITIAL_REQUEST,
  initialListState: INITIAL_LIST_STATE,

  hasDashboard: true,
  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,
  documentActions: {
    archive: false,
    duplicate: false,
  },

  showNumberOfLine: true,

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,
  customActionsForm: CUSTOM_ACTIONS_FORM,

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_unit',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: AUTO_FILL_APARTMENTS,
  },

};


