import React from 'react'
import {
  decorateDocument,
  decorateNewDocument,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
  parseSettings,
  getResidentModuleParameters,
} from './residents-methods'
import ResidentsMainList from './ResidentsMainList'
import { UserOutlined } from '@ant-design/icons'
import { Route } from 'react-router-dom'
import {
  defaultDecorateSettings,
  defaultDataProfileValidation,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import ResidentsProfile from './sections/ResidentsProfile'
import {
  ResidentsDocumentHeaderColumn2,
  ResidentsDocumentHeaderColumn3,
  ResidentsDocumentSubtitle,
} from './ResidentsDocumentHeader'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_RESIDENTS_URL = '/app/records/condominium/residents'
export const RESIDENTS_API_URL = '/entities'
export const RESIDENTS_API_URL_V2 = '/v2/residents'

// for list v2
const INITIAL_REQUEST = {
  filters: {
    colors: ['black', 'white'],
  },
  search: {
    name: 'rick',
    age: 46,
  },
  sort: {
    name: 1,
    age: -1,
  },
  projection: {
    name: 1,
    age: 1,
    city: 1,
  },
  pagination: {
    page: 1,
    size: 10,
  },
}

const INITIAL_FILTER = {
  folder: 'documents',
  dataProfile: 'resident',
}

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
}

const INITIAL_SEARCH = {
  name: 'null',
  roomNumber: 'null',
  blockId: 'null',
  documentStatus: 'null',
}

const INITIAL_CONTEXT = {
  hasPicture: false,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
}

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,

  refreshParameters: getResidentModuleParameters,
}

const COMPONENTS = {
  list: props => <ResidentsMainList {...props}/>,
  // tableListV2: props => <ResidentsMainListV2 {...props}/>,
  // listPreviewHeader: props => <ResidentsBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <ResidentsBodyPreviewData {...props}/>,
  // listPreviewActions: props => <ResidentsBodyPreviewActions {...props}/>,

  documentSubtitle: props => <ResidentsDocumentSubtitle {...props}/>,
  documentHeaderColumn2: props => <ResidentsDocumentHeaderColumn2 {...props}/>,
  documentHeaderColumn3: props => <ResidentsDocumentHeaderColumn3 {...props}/>,

  // moduleSettings: props => <residentsSettingsTabs {...props}/>,
  // moduleSettings: residentsSettingsTabs(),
}

const DATA_TYPES = [
  {
    key: 'person',  // database value
    label: 'individual_person',
    description: 'entities_individual_person_description',
    checked: true,
  },
]

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <UserOutlined/>,
    label: 'resident',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_RESIDENTS_URL}/${_id}/profile`}
                                       render={() =>
                                         <ResidentsProfile ref={ref} {...props}
                                                            _data={props._dataRoots}
                                                            _dataLock={props._dataRootsLock}/>}/>,
  },
]

const REQUIRED_FIELDS = [
]

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {
}

const AUTO_FILL_RESIDENTS = {
}

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_RESIDENTS_PARAMETERS = {

  root: 'residents',
  name: 'residents',
  singular: 'resident',
  titleField: 'name',
  url: MODULE_RESIDENTS_URL,
  api: RESIDENTS_API_URL,
  apiV2: RESIDENTS_API_URL_V2,
  initialRequest: INITIAL_REQUEST,

  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,
  documentActions: {
    archive: false,
    duplicate: false,
  },

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_resident',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: AUTO_FILL_RESIDENTS,
  },

}


