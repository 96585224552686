import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'antd'
import { TagOutlined } from '@ant-design/icons'
import IntlMessage from 'components/util-components/IntlMessage'
import ResidentModalRegistrationTab from './ResidentModalRegistrationTab'
import { parseDateToDB, parsePhoneToDB, parsePhoneToDisplay } from 'utils/helpers'
import moment from 'moment/moment'
import { pushSubdocumentByUri, putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import { clone } from 'jarvisly-helper'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const ResidentModal = props => {

  // props deconstruction ------------------------------------------------------

  const {
    dataContext,
    setDataContext,
    _dataRoots,
    record,
    showModal,
    setShowModal,
    onCloseFn,
  } = props

  // component states -----------------------------------------------------------
  const [tabIdx, setTabIdx] = useState()
  const [isFormChanged, setIsFormChanged] = useState(false)

  // local variables ------------------------------------------------------------
  const ref = useRef(null)
  const tabItems = buildTabItems()
  const intl = useIntl()
  const title = record?._id
    ? (_dataRoots?.updateSentAt
      ? translate(intl, 'view')
      : translate(intl, 'edit_resident'))
    : translate(intl, 'new_resident')

  // ant dataPicker workaround to fix issue about date
  if (record?.birthday) record.birthday = moment(record.birthday)
  if (record?.entryAt) record.entryAt = moment(record.entryAt)
  if (record?.phone) record.phone = parsePhoneToDisplay(record?.phone)
  if (record?.birthday) record.age = moment().diff(record.birthday, 'years')

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    if (tabItems.length > 0 && !tabIdx) setTabIdx(tabItems[0].key)
  }, [tabItems]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onClose = () => {
    setIsFormChanged(false)
    setShowModal(false)
    if (tabItems.length > 0) setTabIdx(tabItems[0].key)
    onCloseFn && onCloseFn()
  }

  async function onSave (values) {

    if (!isFormChanged) return onClose()

    setDataContext({ ...dataContext, partialLoading: true })

    const body = { ...values }

    if (record?._id) body._id = record._id
    if (body?.phone) body.phone = parsePhoneToDB(body.phone)
    if (body?.birthday) body.birthday = parseDateToDB(body.birthday)
    if (body?.entryAt) body.entryAt = parseDateToDB(body.entryAt)

    if (body?.relationship === 'husband_wife') body.relationship = 'spouse_husband'

    const doc = await pushSubdocumentByUri('/atualiza-info', _dataRoots?._id, 'residents', body)
    await putByUri('/atualiza-info', _dataRoots?._id, { residentsUpdated: false })

    if (!onCloseFn) {
      setDataContext({
        ...dataContext, data: { ...doc }, dataLock: { ...clone(doc) }, partialLoading: false,
      })
    }

    onClose()
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Modal
      title={<><TagOutlined/><span className="ml-2">{title}</span></>}
      destroyOnClose={true}
      open={showModal}
      width={640}
      okText={setLocale(true, (isFormChanged || !record) ? 'save' : 'close')}
      onCancel={onClose}
      onOk={() => ref?.current?.onSubmit()}
      cancelButtonProps={{ style: { display: 'none' } }}
      getContainer={false}
      okType={isFormChanged ? 'primary' : 'default'}
      confirmLoading={dataContext?.partialLoading}>
      {/*<Tabs onChange={onTabClick} items={tabItems.filter(t => !t.hidden)} activeKey={tabIdx}/>*/}

      <ResidentModalRegistrationTab {...props}
                                    ref={ref}
                                    setIsFormChanged={setIsFormChanged}
                                    loading={dataContext?.partialLoading}
                                    onSave={onSave}/>

    </Modal>

  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItems () {
    return [
      {
        key: 'registrationTab',
        label: (<span>{setLocale(true, 'record')}</span>),
        children: (<div style={{ padding: '4px 24px' }}>
          <ResidentModalRegistrationTab {...props} ref={ref}
                                        loading={dataContext?.partialLoading}
                                        onSave={onSave}/>
        </div>),
      }, {
        key: 'detailsTab',
        hidden: true,
        label: (<span>{setLocale(true, 'additional_info')}</span>),
        children: (<div style={{ padding: '4px 24px' }}>
          <h1>More Info</h1>
        </div>),
      }]
  }

}

// EXPORT **********************************************************************
// *****************************************************************************

export default ResidentModal
