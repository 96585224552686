import React, { forwardRef, useState } from 'react'
import { Button, Grid, Popconfirm, Row, Table, Tooltip } from 'antd'
import ResidentModal from '../modals/ResidentModal'
import IntlMessage from 'components/util-components/IntlMessage'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import utils from 'utils'
import { translate } from 'utils/react-jarvisly-helper'
import { useIntl } from 'react-intl'
import { getInitials, parsePhoneToDisplay, substitution } from 'utils/helpers'
import { pullSubdocumentByUri, putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import moment from 'moment'
import ImageDescription from 'components/jarvisly/ImageDescription'
import { capitalize, clone } from 'jarvisly-helper'
import { FormNextPrevious, FormTitle } from '../../../FormComponets'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// STEP 2 RESIDENTS COMPONENT **************************************************
// *****************************************************************************

const ResidentsStep = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    step,
    setStep,
  } = props


  // local variables -----------------------------------------------------------

  const { useBreakpoint } = Grid
  const sm = utils.getBreakPoint(useBreakpoint()).includes('sm')
  const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const intl = useIntl()
  const t = buildTranslations()
  const columns = buildResidentsTableColumn()

  // component states ----------------------------------------------------------
  const [showResidentModal, setShowResidentModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState()


  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  const onAdd = () => {
    setSelectedRecord(null)
    setShowResidentModal(true)
  }

  const onEdit = (record) => {
    setSelectedRecord(record)
    setShowResidentModal(true)
  }

  const onDelete = async _id => {

    setDataContext({ ...dataContext, partialLoading: true })

    const doc = await pullSubdocumentByUri('/atualiza-info', _id, 'residents')
    await putByUri('/atualiza-info', _dataRoots?._id, { residentsUpdated: false })

    setDataContext({
      ...dataContext, data: { ...doc }, dataLock: { ...clone(doc) }, partialLoading: false,
    })
  }

  const onNextStep = async () => {

    if (_dataRoots.currentStep <= step) {

      setDataContext({ ...dataContext, partialLoading: true })

      const body = { currentStep: step + 1 }
      const doc = await putByUri('/atualiza-info', _dataRoots?._id, body)

      setDataContext({
        ...dataContext, data: { ...doc }, dataLock: { ...clone(doc) }, partialLoading: false,
      })
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <ResidentModal {...props}
                   showModal={showResidentModal}
                   setShowModal={setShowResidentModal}
                   record={selectedRecord}/>

    {_data?.length > 0 && <div>

      {/* RESIDENTS */}
      <FormTitle title={'dependents_residents'}/>


      <Table rowKey="_id"
             pagination={false}
             columns={columns?.filter(c => !c.hidden) || []}
             dataSource={_data}
             loading={dataContext?.partialLoading}/>
    </div>}


    <Row justify="center" align="middle" className="m-3 mt-4">

      <Button type="primary"
              disabled={_dataRoots?.updateSentAt}
              onClick={onAdd}>
        {setLocale(true, 'add_resident')}
      </Button>

    </Row>
    {/* RESIDENTS */}


    {/* PREVIOUS AND NEXT BUTTON */}
    <FormNextPrevious dataContext={dataContext}
                      step={step}
                      setStep={setStep}
      // formName="titularForm"
      // saveButton
                      onNextClick={onNextStep}
                      _dataRoots={_dataRoots}/>
    {/* PREVIOUS AND NEXT BUTTON */}


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    const remove_this_item = translate(intl, 'remove_this_item')
    const resident = translate(intl, 'resident')
    const t_remove_this_item = substitution(remove_this_item, ['%ITEM%'], [resident])

    return {
      remove_this_item: t_remove_this_item,
    }
  }

  function buildResidentsTableColumn () {
    return [

      {
        title: setLocale(true, 'name'), render: (_, record) => {

          const age = record?.birthday ? record.birthday && moment().diff(record.birthday, 'years') : null

          const name = age ? `${record?.name}, ${age}` : record?.name
          const subtitle = capitalize(translate(intl, record?.relationship || 'n_a'))

          return (<div className="text-left">
            <ImageDescription
              shape="circle"
              // imageUrl={record?.type === 'motorcycle' ? imgMotorcycle : imgResident}
              title={capitalize(name)}
              initials={getInitials(record?.name, 1)}
              subtitle={subtitle}
              // style={{ padding: 10 }}
              // backColor="#ecf2fe"
              backColor={record?.backColor || '#ecf2fe'}
              foreColor={record?.foreColor || '#ffffff'}
            />
          </div>)
        },
      },

      {
        title: setLocale(true, 'mobile'),
        hidden: !md,
        render: (_, record) => {

          const phone = record?.phone ? parsePhoneToDisplay(record?.phone) : ''

          return (<div className="text-left">
            {phone}
          </div>)
        },
      },

      {
        title: setLocale(true, 'living'),
        hidden: !sm,
        render: (_, record) => (<div className="text-left text-lowercase">
          {record?.entryAt && moment(record?.entryAt).fromNow()}
        </div>),
      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 120, //113
        className: 'text-center',
        render: (_, record) => {
          return (<>
            <Tooltip title={setLocale(true, !_dataRoots?.updateSentAt ? 'edit' : 'view')}>
              <Button size="small"
                // className={!isMobile ? 'mr-2' : 'mb-2'}
                      className="m-2"
                      icon={!_dataRoots?.updateSentAt ? <EditOutlined/> : <EyeOutlined/>}
                      onClick={() => onEdit(record)}/>
            </Tooltip>

            {!_dataRoots?.updateSentAt && <Popconfirm title={t.remove_this_item}
                                                      cancelButtonProps={{ className: 'fixed-with-90' }}
                                                      okButtonProps={{ className: 'fixed-with-90' }}
                                                      onConfirm={() => onDelete(record?._id)}>
              <Button size="small" icon={<DeleteOutlined/>}/>
            </Popconfirm>}
          </>)
        },
      }]
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default ResidentsStep


