import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';

import {Button, Col, DatePicker, Form, Input, message, Row, Select} from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import {ROW_GUTTER} from 'constants/ThemeConstant';
import {
  buildDataLikeForm,
  filterOption,
  isObjEquals,
  msg,
  onFormFinishFailed,
  substitution,
} from 'utils/helpers';
import {translate} from 'utils/react-jarvisly-helper';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
  FormContext,
} from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/FormProvider';
import {
  postDocument, putDocument,
} from 'components/jarvisly-layouts/ModuleComponent/module-api';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import FormItemDataType
  from 'components/jarvisly-layouts/ModuleComponent/Components/FormItemDataType';
import {
  addSection, removeSection,
  validateFieldAfterSettingsChanges,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import {normalize} from 'jarvisly-helper';
import {DataContext} from "../../../../../components/jarvisly-layouts/ModuleComponent/ModuleForm/DataProvider";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const {Option} = Select;

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const ProductsProfile = forwardRef((props, ref) => {

  const {formContext, setFormContext} = React.useContext(FormContext);
  const {dataContext} = React.useContext(DataContext);

  const data = dataContext?.data;

  // props deconstruction ------------------------------------------------------
  const {_loading, _module} = props;
  const initialState = {
    isFormChanged: formContext?.sectionsChanged?.includes(formContext?.selectedSection),
  };

  // rewrite the _settings after go back from module settings
  const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription);
  const _settings = rdxSubscription?.settings &&
      rdxSubscription.settings[_module.name];
  const _parameters = rdxSubscription?.parameters;

  // component states ----------------------------------------------------------
  const [state, setState] = useState(initialState);
  const [initialFormData, setInitialFormData] = useState({});

  // local variables -----------------------------------------------------------

  // translations
  const intl = useIntl();
  const msgSaving = `${translate(intl, 'saving_document')}...`;
  const msgUpdating = `${translate(intl, 'updating_document')}...`;
  const msgSavingSuccess = `${translate(intl, 'document_saved_with_success')}!`;
  const msgUpdatingSuccess = `${translate(intl,
      'document_updated_with_success')}!`;

  const history = useHistory();

  const isDisabled = formContext.mode === 'view' || _loading;
  const [form] = Form.useForm();

  const requiredLastInventory = _settings?.requiredFields?.inventory?.find(
      x => x.field === 'inventory' && x.required) || false;

  const elRefs = {
    dataType: useRef(null),
    ambienceId: useRef(null),
    familyId: useRef(null),
    lastInventory: useRef(null),

    productId: useRef(null),
    brandId: useRef(null),
    model: useRef(null),
    patrimony: useRef(null),

    serialNumber: useRef(null),
    description: useRef(null),
  };

  const rules = {

    dataType: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    familyId: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    ambienceId: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    lastInventory: [
      {
        required: requiredLastInventory,
        message: setLocale(true, 'required_field'),
      }],

    productId: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    brandId: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    model: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    patrimony: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    serialNumber: [],

    description: [],
  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (data) {

      form.setFieldsValue({...data});

      if (dataContext?.docLock) {
        const builtData = buildDataLikeForm(form, dataContext?.docLock);
        setInitialFormData(builtData);
      }

    } else {

      form.resetFields();
      setState({...initialState});
      setInitialFormData({});
      handleFocus('dataType');
    }

    // ---------------------------------------------------------------------- //
    // validate fields after change settings
    // ---------------------------------------------------------------------- //
    const opt = {formContext, _settings, form};
    const familyOptions = {...opt, formField: 'family', dbField: 'families'};
    const productOptions = {...opt, formField: 'product', dbField: 'products'};
    const brandOptions = {...opt, formField: 'brand', dbField: 'brands'};
    validateFieldAfterSettingsChanges(familyOptions);
    validateFieldAfterSettingsChanges(productOptions);
    validateFieldAfterSettingsChanges(brandOptions);

    // form.setFieldsValue({lastInventory: moment(moment(), 'DD/MM/YYYY')});
    // ---------------------------------------------------------------------- //

  }, [dataContext?.docLock, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formContext.mode === 'add') handleFocus('dataType');
  }, [formContext?.data?.dataType]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    reloadDocument: memorize => reloadDocument(memorize),
    handleFocus: field => handleFocus(field),
  }));

  // METHODS -------------------------------------------------------------------

  function handleFocus(field) {
    let focus = field ? field : 'dataType';
    if (focus === 'dataType' &&
        (!data?.dataType || _module?.dataTypes?.length === 1)) {
      focus = 'ambienceId';
    }

    setTimeout(() => elRefs[focus]?.current?.focus({cursor: 'all'}), 400);
  }

  function reloadDocument(memorize) {
    setState({...state, isFormChanged: memorize});
    setTimeout(() => form.resetFields());
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async values => {

    const product = _settings?.products?.find(x => x._id === values.productId);
    const brand = _settings?.brands?.find(x => x._id === values.brandId);

    // values.tmpProductIndex = `${product.value} ${brand.value} ${values.model}`;
    values['_metadata.indexes.product'] = normalize(
        `${product.value} ${brand.value} ${values.model}`, 'lower');

    if (formContext?.data?.pictureUrl) {
      values.pictureUrl = formContext?.data?.pictureUrl;
      values.pictureAttachmentId = formContext?.data?.pictureAttachmentId;
    }

    // attachments
    values.attachmentsIds = formContext?.data?.attachmentsIds;

    try {

      let doc;

      if (formContext.mode === 'add') {
        msg('i', msgSaving, 'saving');
        doc = await postDocument(_module, values);

      } else {
        msg('i', msgUpdating, 'saving');
        doc = await putDocument(_module, data?._id, values);
      }

      message.destroy('saving');

      msg('s', formContext?.mode === 'add' ? msgSavingSuccess : msgUpdatingSuccess,
          'add');

      if (formContext?.mode === 'add') {
        history.replace({
          pathname: `${_module?.url}/${doc._id}/profile`,
          state: {isActive: true},
        });
      }

      setFormContext({
        ...formContext,
        mode: 'view',
        sectionsChanged: removeSection(formContext),
        data: doc,
        docLock: doc,
        dataMemory: _settings?.configurations?.dataMemory ? values : null,
      });

      setState({...state, isFormChanged: false});

    } catch (error) {

      let errorMessage;

      message.destroy('saving');

      if (error?.data?.code === 409) {
        const patrimonyNumber = translate(intl, 'patrimony_number');
        const m = translate(intl, 'response_error_409');
        errorMessage = substitution(m, ['%DOCUMENT_TYPE%'], [patrimonyNumber]);

      } else {
        errorMessage = `Erro ${error?.data?.code}: ${error?.data?.errorMessage}`;
      }

      msg('e', errorMessage, 'error', 4);
      elRefs?.patrimony?.current?.focus({cursor: 'all'});

    }
  };

  const saveContext = _.debounce(value => {

    const currentData = {...initialFormData, ...value};
    const isChanged = !isObjEquals(currentData, initialFormData);

    setState({...state, isFormChanged: isChanged});

    setFormContext({
      ...formContext,
      data: {...formContext.data, ...value},
      sectionsChanged: isChanged ? addSection(formContext) : removeSection(formContext),
    });
  });

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <Form
        form={form}
        name="profile"
        layout="vertical"
        initialValues={data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={saveContext}
        validateTrigger="onChange"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        noValidate
    >

      <Row className="mb-2">
        <h5 className="text-muted">
          {setLocale(true, 'basic_info')}
        </h5>
      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* PRODUCT TYPE */}
        <Col xs={24} md={6}>
          <FormItemDataType {...props}
                            label={'product_type'}
                            elRef={elRefs.dataType}
                            rule={rules.dataType}
                            data={data}
                            isDisabled={isDisabled}
                            formContext={formContext}
                            _settings={_settings}/>
        </Col>
        {/* PRODUCT TYPE */}


        {/* AMBIENCE */}
        <Col xs={24} md={6}>

          <Form.Item
              label={setLocale(true, 'ambience')}
              required
              rules={rules.ambienceId}
              name="ambienceId">

            <Select ref={elRefs.ambienceId}
                    value={data?.ambienceId}
                    showSearch
                    disabled={isDisabled}
                    filterOption={filterOption}>

              {_parameters?.ambiences && _parameters?.ambiences?.map(
                  t => <Option key={t._id} value={t._id}>{t.name}</Option>)}
            </Select>
          </Form.Item>

        </Col>
        {/* AMBIENCE */}


        {/* FAMILY */}
        <Col xs={24} md={6}>

          <Form.Item
              label={setLocale(true, 'family')}
              required
              rules={rules.familyId}
              name="familyId">

            <Select ref={elRefs.familyId}
                    value={data?.__family}
                    showSearch
                    disabled={isDisabled}
                    filterOption={filterOption}>

              {_settings?.families && _settings.families.map(
                  t => <Option key={t._id} value={t._id}>{t.value}</Option>)}
            </Select>
          </Form.Item>

        </Col>
        {/* FAMILY */}


        {/* DATE OF LAST INVENTORY */}
        <Col xs={24} md={6}>

          <Form.Item
              label={setLocale(true, 'last_inventory')}
              value={data?.lastInventory}
              name="lastInventory"
              rules={rules.lastInventory}
          >

            <DatePicker className="w-100 no-border-radius"
                        format="DD/MM/YYYY"
                        disabled={isDisabled}
                        placeholder=""
                        allowClear={false}
                        ref={elRefs.birthday}/>
          </Form.Item>
        </Col>
        {/* DATE OF LAST INVENTORY */}

      </Row>


      <Row gutter={ROW_GUTTER}>


        {/* PATRIMONY */}
        <Col xs={24} md={6}>

          <Form.Item
              label={setLocale(true, 'patrimony')}
              name="uniqueKey"
              rules={rules.patrimony}
          >
            <Input ref={elRefs.patrimony}
                   disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* PATRIMONY */}

        {/* PRODUCT */}
        <Col xs={24} md={6}>

          <Form.Item
              label={setLocale(true, 'product')}
              required
              rules={rules.productId}
              name="productId">

            <Select ref={elRefs.productId}
                    value={data?.__product}
                    showSearch
                    disabled={isDisabled}
                    filterOption={filterOption}>

              {_settings?.products && _settings.products.map(
                  t => <Option key={t._id} value={t._id}>{t.value}</Option>)}
            </Select>
          </Form.Item>

        </Col>
        {/* PRODUCT */}

        {/* BRAND */}
        <Col xs={24} md={6}>

          <Form.Item
              label={setLocale(true, 'brand')}
              required
              rules={rules.brandId}
              name="brandId">

            <Select ref={elRefs.brandId}
                    value={data?.__brand}
                    showSearch
                    disabled={isDisabled}
                    filterOption={filterOption}>

              {_settings?.brands && _settings.brands.map(
                  t => <Option key={t._id} value={t._id}>{t.value}</Option>)}
            </Select>
          </Form.Item>

        </Col>
        {/* BRAND */}

        {/* MODEL */}
        <Col xs={24} md={6}>

          <Form.Item
              label={setLocale(true, 'model')}
              name="model"
              rules={rules.model}
          >
            <Input ref={elRefs.model}
                   disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* MODEL */}


      </Row>


      <Row gutter={ROW_GUTTER}>


        {/* SERIAL NUMBER */}
        <Col xs={24} md={6}>

          <Form.Item
              label={setLocale(true, 'serial_number')}
              name="serialNumber"
              rules={rules.serialNumber}
          >
            <Input ref={elRefs.serialNumber}
                   disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* SERIAL NUMBER */}


        {/* DESCRIPTION */}
        <Col xs={24} md={18}>

          <Form.Item
              label={setLocale(true, 'description')}
              name="description"
              rules={rules.description}
          >
            <Input ref={elRefs.description}
                   disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* DESCRIPTION */}


      </Row>


      <Row align="middle" justify="end">

        <Button type="primary"
                htmlType="submit"
                disabled={formContext.mode === 'view' || !state.isFormChanged}
                style={{minWidth: 100}}>
          {setLocale(true, 'save')}
        </Button>

      </Row>


    </Form>
  </div>);

});

// EXPORT **********************************************************************
// *****************************************************************************

export default ProductsProfile;
