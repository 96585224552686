import { Button, Card, Col, Form, Input, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { InputPhone, translate } from 'utils/react-jarvisly-helper'
import { MobileOutlined, PropertySafetyOutlined } from '@ant-design/icons'
import IntlMessage from 'components/util-components/IntlMessage'
import { useIntl } from 'react-intl'
import { msg, onProduction, isValidPhoneNumber } from 'utils/helpers'
import { returnOnlyNumbers } from 'jarvisly-helper'
import { postSendSmsCode, postValidateSmsCode } from 'utils/tools-api'
import { getAtualizaInfoByPhone } from './atualiza-api'
import { rdxSetPortalData, rdxSetPortalStatus } from 'redux/actions/auth'
import { connect } from 'react-redux'
import { putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// const testingMode = '(19) 9.8232-0000'
const testingMode = false
const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// LOGIN COMPONENT *************************************************************
// *****************************************************************************

const LoginComponent = props => {

  // props deconstruction ------------------------------------------------------

  const {
    name,
    rdxPortalStatus,
    rdxSetPortalStatus,
    rdxSetPortalData,
  } = props

  // local variables -----------------------------------------------------------

  const intl = useIntl()
  const [form] = Form.useForm()
  const elRefs = buildElementsRefs(useRef)
  const elRules = buildElementsRules()

  // component states ----------------------------------------------------------
  const [mobileStatus, setMobileStatus] = useState('')
  const [mobile, setMobile] = useState(null)
  const [smsCode, setSmsCode] = useState(null)
  const [smsCodeStatus, setSmsCodeStatus] = useState(null)
  const [smsCodeId, setSmsCodeId] = useState(null)
  const [smsCounter, setSmsCounter] = useState(0)

  const [lastSmsCode, setLastSmsCode] = useState('')
  const [lastSmsStatus, setLastSmsStatus] = useState('')
  const [tmr, setTmr] = useState(0)

  // hooks ---------------------------------------------------------------------

  // FORCE LOGIN TMP
  useEffect(() => {

    if (onProduction() || !testingMode) return

    (async () => {

      const data = await getAtualizaInfoByPhone(testingMode)

      if (!data) {
        setMobileStatus('error')
        rdxSetPortalStatus(-1)
        return
      }

      rdxSetPortalData(data)
      rdxSetPortalStatus(1)
    })()

  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const initSmsCounter = () => {

    setSmsCounter(60)

    setTmr(setInterval(() => {
      setSmsCounter(prev => {
        if (prev === 0) return clearInterval(tmr)
        return prev - 1
      })
    }, 1000))
  }

  const sendSmsCode = async (event, result) => {

    const value = event.target.value
    setMobile(value)

    if (mobile === value) return

    if (isValidPhoneNumber(value, 'mobile')) {

      setMobileStatus('validating')

      const data = await getAtualizaInfoByPhone(value)

      if (!data || data?.length === 0) {
        setMobileStatus('error')
        rdxSetPortalStatus(-1)
        return
      }

      rdxSetPortalData(data)

      message.destroy('sending')
      msg('l', translate(intl, 'sending_sms'), 'sending', 5)

      $sendingSms(data)
        .then(messengerId => {
          setMobileStatus('success')
          elRefs.smsCode.current.focus()
          setSmsCodeId(messengerId)
          msg('s', translate(intl, 'sms_sent_successfully'), 'sending')
        })
        .catch(() => {
          setMobileStatus('error')
          msg('f', translate(intl, 'sms_sent_failure'), 'sending')
          message.destroy('sending')
          elRefs.mobile.current.focus({ cursor: 'end' })
        })
    }

    // internal function
    function $sendingSms (data) {

      initSmsCounter() // freeze the phone number for one minute to avoid multiple sends
      return new Promise(async (resolve, reject) => {
        const pn = `+55${returnOnlyNumbers(value)}`
        const text = 'Código AtualizaInfo'

        const messengerId = await postSendSmsCode(pn, text, name)

        // update the moves with the last messengerId
        data.map(async i => i?.lkq_moves?._id && await putByUri('/moves', i.lkq_moves._id, { messengerId }))

        messengerId ? resolve(messengerId) : reject()
      })
    }
  }

  const validateSmsCode = async event => {

    const value = event.target.value

    if (lastSmsCode === value) {
      setSmsCodeStatus(lastSmsStatus)
      return
    }

    if (smsCode === value) return

    if ((value && value.length === 4)) {

      setSmsCodeStatus('validating')
      setLastSmsCode(value)
      setSmsCode(value)

      $validatingSmsCode(value)
        .then(() => {
          clearInterval(tmr)
          setSmsCodeStatus('success')
          msg('s', translate(intl, 'code_validated_successfully'))
          setLastSmsStatus('success')
          setSmsCounter(0)
          rdxSetPortalStatus(1)
        })
        .catch(() => {
          setSmsCodeStatus('error')
          msg('e', translate(intl, 'invalid_code'))
          message.destroy('validating')
          setLastSmsStatus('error')
          elRefs.smsCode.current.focus({ cursor: 'end' })

          form.setFields([
            {
              name: 'smsCode', errors: [translate(intl, 'invalid_code')],
            }])

        })
    }

    // internal function
    function $validatingSmsCode (code) {
      return new Promise(async (resolve, reject) => {
        const result = await postValidateSmsCode(smsCodeId, code)
        result ? resolve(result) : reject()
      })
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (

    <Col>

      {rdxPortalStatus === -1 ? <div className="p-3 text-center">
          <h1>{mobile}</h1>
          <h4 className="text-muted">Nenhuma atualização disponível neste momento para este telefone</h4>
          <Button type="primary" onClick={() => rdxSetPortalStatus(0)} className="mt-4">Voltar</Button>
        </div>

        : <Card className="p-3">

          <Form
            form={form}
            name="atualiza"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: 240 }}
            noValidate>


            <Form.Item
              name="mobile"
              label={setLocale(true, 'mobile')}
              rules={elRules.mobile}
              validateStatus={mobileStatus}
              hasFeedback>

              <InputPhone ref={elRefs.mobile}
                          autoFocus
                          antd="Input"
                          sendsmscode="true"
                          mobileownername={name}
                          profile="MOBILE"
                          disabled={mobileStatus === 'validating' || smsCodeStatus === 'success' || smsCounter > 0}
                          onChange={sendSmsCode}
                          prefix={<MobileOutlined className="text-primary"/>}/>
            </Form.Item>


            <div className="sending-sms-atualiza">
              {smsCounter > 0 && <span>
                                    Reenviar novamente em <strong>{smsCounter}</strong> segundos...
                                </span>}
            </div>


            <Form.Item
              name="smsCode"
              label={setLocale(true, 'inform_sms_code')}
              rules={elRules.smsCode}
              validateStatus={smsCodeStatus}
              hasFeedback
            >
              <Input ref={elRefs.smsCode}
                     onChange={validateSmsCode}
                     disabled={mobileStatus !== 'success' || smsCodeStatus === 'validating' || smsCodeStatus ===
                       'success'}
                     minLength={4}
                     maxLength={4}
                     prefix={<PropertySafetyOutlined className="text-primary"/>}/>

            </Form.Item>

          </Form>

        </Card>}

    </Col>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildElementsRules () {

    const rfMessage = translate(intl, 'required_field')

    return {
      mobile: [
        { required: true, message: rfMessage },

        {
          validator: (rule, pn) => {

            if (pn && !isValidPhoneNumber(pn, 'MOBILE')) {

              setMobileStatus('error')
              return Promise.reject(setLocale(true, 'invalid_phone_number'))

            } else {

              if (mobileStatus !== 'validating') {
                setMobileStatus(pn ? 'success' : 'error')
              }

              return Promise.resolve()

            }
          },
        }],

      smsCode: [
        { required: true, message: rfMessage },

        {
          minlength: 4, message: setLocale(true, 'invalid_code'),
        },

        {
          validator: (rule, value) => {
            if (value && value.length === 4 && lastSmsStatus !== 'error') {
              return Promise.resolve()
            } else {
              return Promise.reject(setLocale(true, 'invalid_code'))
            }
          },
        }],
    }
  }

  function buildElementsRefs (hookUseRef) {

    return {
      mobile: hookUseRef(null), smsCode: hookUseRef(null),
    }
  }
}

// EXPORT **********************************************************************
// *****************************************************************************

// export default LoginComponent

const mapDispatchToProps = {
  rdxSetPortalStatus,
  rdxSetPortalData,
}

const mapStateToProps = ({ auth }) => {
  const { rdxPortalStatus } = auth
  return { rdxPortalStatus }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)

