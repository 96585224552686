import React, { useState } from 'react'
import { rdxSetFormProvider } from 'redux/actions/module'
import { useDispatch } from 'react-redux'

export const FormContext = React.createContext({})

export const FormProvider = ({ _module, children }) => {

  const [state, setState] = useState(_module.initialContext)
  const dispatch = useDispatch()

  const setFormContext = context => {

    // create initial data
    if (context?.data && !context?.docLock && !state?.docLock) {
      context.docLock = { ...context.data }
    }

    const nextState = { ...state, ...context }

    setState(nextState)
    dispatch(rdxSetFormProvider(nextState))
  }

  return (<FormContext.Provider value={{ formContext: state, setFormContext }}>
    {children}
  </FormContext.Provider>)
}
