import React, {useEffect, useState} from 'react';
import {Row, Select, Tooltip} from 'antd';
import {connect} from 'react-redux';
import {rdxSetSubscription, rdxSetUser} from 'redux/actions/auth';
import {
  LockTwoTone,
  SafetyCertificateTwoTone,
  UnlockTwoTone,
} from '@ant-design/icons';
import IntlMessage from '../util-components/IntlMessage';
import {translate} from 'utils/react-jarvisly-helper';
import {useIntl} from 'react-intl';
import {THEME_CONFIG} from 'configs/AppConfig';

const {Option} = Select;

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

const SelectSubscription = ({
                              rdxSetUser,
                              rdxUser,
                              navCollapsed,
                              rdxSetSubscription,
                              rdxSubscription,
                              routeInfo,
                              style,
                              enableSubscriptionSelector,
                            }) => {

  const [firstSubscription, setFirstSubscription] = useState(rdxSubscription);

  const intl = useIntl();

  const availableSubscriptions = rdxUser?.subscriptions.filter(
      x => x.status === 2);

  // if (!style) style = {width: '100%'};

  useEffect(() => {
    if (!rdxSubscription && availableSubscriptions > 0) {
      setFirstSubscription(availableSubscriptions[0]);
    }
  // }, [rdxUser?._id]); // eslint-disable-line react-hooks/exhaustive-deps
  }, [rdxSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  function onChange(value) {
    // const subscription = rdxUser.subscriptions.find(x => x._id === value);
    const subscription = availableSubscriptions.find(x => x._id === value);

    // if (subscription) {
    //   subscription.accessProfile = subscription.team?.find(
    //       x => x.accountId === rdxUser._id);
    // }

    rdxSetSubscription(subscription);

/*
    const userSubscription = subscription?.team?.find(
        x => x.accountId === rdxUser._id);

    if (userSubscription) {
      rdxUser.profile = userSubscription?.profile || {};
      rdxUser.granted = userSubscription?.granted || {};
    }
    rdxSetUser({...rdxUser});
*/

  }

  const searchFn = (input, option) => {
    return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const getIcon = subscription => {

    if (!subscription || !rdxUser) return;

    const member = subscription?.team?.find(x => x.accountId === rdxUser._id);

    if (member?.profile === 'owner') {

      return (
          <Tooltip title={setLocale(true, 'owner')}>
            <SafetyCertificateTwoTone size="small"/>
          </Tooltip>
      );

    } else if (member?.profile === 'keyUser') {

      return (
          <Tooltip title={setLocale(true, 'key_user')}>
            <UnlockTwoTone size="small"/>
          </Tooltip>
      );

    } else { //(member?.profile === 'guest')

      return (
          <Tooltip title={setLocale(true, 'common_user')}>
            <LockTwoTone twoToneColor="red" size="small"/>
          </Tooltip>
      );

    }

  };

  const placeholder = `${translate(intl, THEME_CONFIG.APP.PROFILE.SELECT_MESSAGE)}`;

  return (<Row style={{padding: 16}}
               justify="end"
               align="middle"
               className="select-subscription-header">

    <Select style={style}
            showSearch
            placeholder={placeholder}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={searchFn}
            defaultValue={rdxSubscription?._id}
            disabled={!routeInfo && !enableSubscriptionSelector}
            value={rdxSubscription?._id || firstSubscription?._id}
    >
      {availableSubscriptions?.map(subscription => {
        return (
            <Option key={subscription._id} value={subscription._id} title={subscription.organization}>
              {getIcon(subscription)}
              &nbsp;
              {/*<span className="text-uppercase">{subscription.organization}</span>*/}
              <span>{subscription.organization}</span>
            </Option>
        );
      })}
    </Select>
  </Row>);

};

const mapDispatchToProps = {
  rdxSetSubscription,
  rdxSetUser,
};

const mapStateToProps = ({auth, theme}) => {

  const {
    rdxUser, rdxSubscription,
  } = auth;

  const {navCollapsed} = theme;

  return {
    rdxUser, navCollapsed, rdxSubscription,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectSubscription);
