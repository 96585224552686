import React, { forwardRef, useEffect, useRef } from 'react'
import { Col, Form, Row } from 'antd'
import {
  FormInput,
  FormSelectGender,
  FormTitle,
  FormWrapper,
  FormCpf,
  FormDatePicker,
  FormPhone,
  FormEmail,
  FormNextPrevious,
} from '../../../FormComponets'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import {
  parseDateToDisplay,
  parsePhoneToDisplay,
  parseDateToDB,
  parsePhoneToDB,
} from 'utils/helpers'
import { translate } from 'utils/react-jarvisly-helper'
import { useIntl } from 'react-intl'

// STEP 0 TITULAR COMPONENT ****************************************************
// *****************************************************************************

const TitularStep = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    step,
    setStep,
  } = props

  // local variables -----------------------------------------------------------

  const formName = 'titularForm'
  const [form] = Form.useForm()
  const elRefs = buildElementsRefs(useRef)
  const intl = useIntl()
  const t = buildTranslations()

  // ant dataPicker workaround to fix issue about date
  if (_data?.birthday) _data.birthday = parseDateToDisplay(_data.birthday)
  if (_data?.entryAt) _data.entryAt = parseDateToDisplay(_data.entryAt)
  if (_data?.phone) _data.phone = parsePhoneToDisplay(_data.phone)

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    handleFocus(undefined, 'all')
    setDataContext({ ...dataContext, formsChanged: [] })
  }, [_data, step]) // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  function handleFocus (field = 'name', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  const onBeforeSave = values => {

    const data = { ...values }

    // format data to persint in database
    if (data?.birthday) data.birthday = parseDateToDB(data.birthday)
    if (data?.entryAt) data.entryAt = parseDateToDB(data.entryAt)
    if (data?.phone) data.phone = parsePhoneToDB(data.phone)

    const body = {
      _id: _dataRoots._id,
      titular: data,
      titularUpdated:false
    }

    if (_dataRoots.currentStep === step) body.currentStep = step + 1
    return body
  }

  const onAfterSave = async doc => {
    if (doc || _dataRoots.currentStep > step) setStep(prev => prev + 1)
  }

  // UI COMPONENT --------------------------------------------------------------

  return (

    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi="/atualiza-info"
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      showToast>

      {/* BASIC INFO */}
      <FormTitle title={'titular_resident'}/>

      <Row gutter={ROW_GUTTER}>

        {/* NAME */}
        <Col xs={24} sm={16}>
          <FormInput name="name"
                     label="full_name"
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.name}/>
        </Col>
        {/* NAME */}

        {/* GENDER */}
        <Col xs={24} sm={8}>
          <FormSelectGender elRef={elRefs.gender}
                            disabled={_dataRoots?.updateSentAt}
                            required/>
        </Col>
        {/* GENDER */}

      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* CPF / CNPJ */}
        <Col xs={24} sm={8}>
          <FormCpf elRef={elRefs.cpf}
                   disabled={_dataRoots?.updateSentAt}
                   required
                   form={form}/>
        </Col>
        {/* CPF / CNPJ */}

        {/* RG */}
        <Col xs={24} sm={8}>
          <FormInput name="rg"
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.rg}/>
        </Col>
        {/* RG */}

        {/* BIRTHDAY / FOUNDATION DATE */}
        <Col xs={24} sm={8}>
          <FormDatePicker name="birthday"
                          disabled={_dataRoots?.updateSentAt}
                          required
                          elRef={elRefs.birthday}/>
        </Col>
        {/* BIRTHDAY / FOUNDATION DATE */}

      </Row>


      {/* CONTACTS */}
      <FormTitle title={'contacts'}/>

      <Row gutter={ROW_GUTTER}>

        {/* PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone form={form}
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.phone}/>
        </Col>
        {/* PHONE */}

        {/* EMAIL */}
        <Col xs={24} sm={16}>
          <FormEmail form={form}
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.email}/>
        </Col>
        {/* EMAIL */}


      </Row>
      {/* CONTACTS */}


      {/* ENTRANCE */}
      <FormTitle title={'period'}/>

      <Row gutter={ROW_GUTTER}>

        {/* ENTRY DATE */}
        <Col xs={24} sm={8}>
          <FormDatePicker name="entryAt"
                          label="move_date"
                          disabled={_dataRoots?.updateSentAt}
                          tooltip={t.approximate_date}
                          required
                          elRef={elRefs.entryAt}/>
        </Col>
        {/* ENTRY DATE */}

      </Row>
      {/* ENTRANCE */}


      {/* PREVIOUS AND NEXT BUTTON */}
      <FormNextPrevious dataContext={dataContext}
                        step={step}
                        setStep={setStep}
                        formName={formName}
                        saveButton
                        _dataRoots={_dataRoots}/>
      {/* PREVIOUS AND NEXT BUTTON */}


    </FormWrapper>

  )

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {
    return {
      approximate_date: translate(intl, 'approximate_date'),
    }
  }

  function buildElementsRefs (hookUseRef) {
    return {
      name: hookUseRef(null),
      gender: hookUseRef(null),
      cpf: hookUseRef(null),
      rg: hookUseRef(null),
      birthday: hookUseRef(null),
      phone: hookUseRef(null),
      email: hookUseRef(null),
      entryAt: hookUseRef(null),
    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default TitularStep


