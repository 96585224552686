import React, { useEffect, useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import DocumentStatusTag from 'components/jarvisly-layouts/ModuleComponent/Components/DocumentStatusTag';
import moment from 'moment/moment';
import { ApiTwoTone, EditOutlined, EyeOutlined } from '@ant-design/icons';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const MultiPlansList = props => {

  // props deconstruction ------------------------------------------------------
  const {
    loading,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    data,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
    onClickPreview,
    rdxModuleParameters
  } = props;

  // component states ----------------------------------------------------------
  const [operatorsFilter, setOperatorsFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);

  // local variables -----------------------------------------------------------
  // const statusFilter = buildStatusFilter();
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {

    // filter for blocks column
    const operators = rdxModuleParameters?.operators?.map(x => x?._id && x?.name && ({
      text: x.name,
      value: x._id
    }));

    setOperatorsFilter(operators || null);

  }, [rdxModuleParameters?.operators]);

  useEffect(() => {
    setStatusFilter(buildStatusFilter() || null);
  }, []);

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (<>
    <Table key={tableKey}
           rowKey="_id"
           columns={tableColumns.filter(x => !x.hidden)}
           dataSource={data || []}
           pagination={pagination}
           onChange={onTableChanges}
           rowSelection={buildRowSelection}
           className="with-checkbox"
           resetTableSearch={resetTableSearch}
           loading={loading}
    />
  </>);

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns () {

    return [
      {
        hidden: !showNumberOfLine,
        title: setLocale(true, 'line_#'),
        width: 80,
        render: (item, record, index) => {

          if (!pagination) return;

          const {
            current,
            pageSize
          } = pagination;

          return (<div className="text-left">
            {((current * pageSize) - pageSize + index + 1) || index}
          </div>);
        },
      },

      {
        title: setLocale(true, 'plan'),
        dataIndex: 'name',
        ...columnSorter('name', 2),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => (<>{record?.name}</>),
      },

      {
        title: setLocale(true, 'operator'),
        dataIndex: 'operatorId',
        ...columnFilters('operatorId', operatorsFilter),
        ...columnSorter('operatorId', 1),
        render: (_, record) => (<>{record?.__operator?.name}</>),
      },

      {
        title: setLocale(true, 'exchange'),
        dataIndex: 'quotation',
        width: 100,
        render: (_, record) => (<div className="text-center">
          {
            record?.quotation
              ? record.quotation.toUpperCase()
              : 'RBL'
          }
        </div>),
      },

      {
        title: setLocale(true, 'integrated'),
        dataIndex: 'integrated',
        width: 100,
        render: (_, record) => (<div className="text-center">
          {
            record?.integrated &&
            <Tooltip
              title={setLocale(true, 'integrated_by_webservice')}>
              <ApiTwoTone className="font-size-lg mr-2 font-weight-bold" twoToneColor="red"/>
            </Tooltip>
          }
        </div>),
      },

      {
        title: setLocale(true, 'status'),
        dataIndex: 'documentStatus',
        ...columnFilters('documentStatus', statusFilter),
        ...columnSorter('documentStatus', 11),
        width: 100,
        render: (_, record) => (<DocumentStatusTag record={record}/>),
      },

      {
        title: setLocale(true, 'last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        ...columnSorter('_metadata.audit.updatedAt', 12),
        width: 160,
        hidden: false,
        render: (_, record) => (<>
          {record?._metadata?.audit?.updatedAt &&
            // moment(record._metadata.audit.updatedAt).format('L LT')}
            moment(record._metadata.audit.updatedAt).fromNow()}
        </>),
      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">


            <Tooltip title={setLocale(true, 'edit')}>
              <Button className="mr-2" icon={<EditOutlined/>}
                      onClick={() => onClickOpen(record)}
                      size="small"/>
            </Tooltip>


            <Tooltip title={setLocale(true, 'view')}>
              <Button className="mr-2" icon={<EyeOutlined/>}
                      onClick={() => onClickPreview(record)}
                      size="small"/>
            </Tooltip>
          </div>),
      },

    ].filter(x => !x.hidden);
  }

  function buildStatusFilter () {
    return [
      {
        text: setLocale(true, 'in_analise'),
        value: 'in_analise',
      }, {
        text: setLocale(true, 'with_reservation'),
        value: 'with_reservation',
      }, {
        text: setLocale(true, 'revised'),
        value: 'revised',
      }];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default React.memo(MultiPlansList);
