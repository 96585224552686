import { clone } from 'jarvisly-helper';
import { Button, Input, message, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  getSettingsById,
  postByUri,
  putByUri,
  putSettings,
} from './module-api';
import IntlMessage from 'components/util-components/IntlMessage';
import {
  hasObjectProperties,
  isMongoObjectId,
  isObjEquals,
  jarvislyDebounce,
  modal,
  msg,
  isValidPhoneNumber,
} from 'utils/helpers';
import {
  rdxSetFormProvider,
  rdxSetDataProvider,
  rdxSetModuleQuery,
  rdxSetShowModuleFilter,
  rdxSetShowModuleSettings,
  rdxSetModuleList,
} from 'redux/actions/module';
import { delV2, getV2, postV2, put, putV2 } from 'configs/ApiConfig';
import React from 'react';
import {
  isValidCnpj,
  isValidCpf,
  isValidEmail,
  isValidUrl,
  translate,
} from 'utils/react-jarvisly-helper';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { MODULE_PROCEDURES } from 'views/app-views/records/procedures/procedures-constants';
import { MODULE_AMBIENCES_PARAMETERS } from 'views/app-views/records/ambiences/ambiences-constants';
import { MODULE_ENTITIES_PARAMETERS } from 'views/app-views/_old-modules/entities/entities-constants';
import { MODULE_PRODUCTS_PARAMETERS } from 'views/app-views/records/products/products-constants';
import { MODULE_FOOS_PARAMETERS } from 'views/app-views/foos/constants';
import { MODULE_OCCURRENCES } from 'views/app-views/operational/occurrences/occurrences-constants';
import { MODULE_APARTMENTS_PARAMETERS } from 'views/app-views/apartments/constants';
import { MODULE_RESIDENTS_PARAMETERS } from 'views/app-views/residents/residents-constants';
import { MODULE_ATUALIZA_PARAMETERS } from 'views/app-views/portals/autaliza-info/atualiza-constants';
import { MODULE_MOVES_PARAMETERS } from 'views/app-views/moves/moves-constants';
import { MODULE_VEHICLES_PARAMETERS } from 'views/app-views/vehicles/vehicles-constants';
import { MODULE_BIKES_PARAMETERS } from 'views/app-views/bikes/bikes-constants';
import { MODULE_PETS_PARAMETERS } from 'views/app-views/pets/pets-constants';
import { MODULE_COMPANIES_PARAMETERS } from 'views/app-views/companies/constants';
import { MODULE_MULTIOPERATORS_PARAMETERS } from 'views/app-views/_apps/multi/multiOperators/constants';
import { MODULE_PROVIDERS_PARAMETERS } from 'views/app-views/providers/providers-constants';
import { MODULE_MULTIPLANS_PARAMETERS } from 'views/app-views/_apps/multi/multiPlans/constants';
import { MODULE_PERSONS_PARAMETERS } from 'views/app-views/entities/persons/constants';
import { MODULE_ORDERS_PLANS_PARAMETERS } from 'views/app-views/_apps/multi/multiOrders/constants';
import { MODULE_LEADS_PARAMETERS } from 'views/app-views/leads/constants';
import { MODULE_INVOICES_PARAMETERS } from 'views/app-views/invoices/constants';
import { MODULE_VOUCHERS_PARAMETERS } from 'views/app-views/vouchers/constants';
import { MODULE_PAYABLE_PARAMETERS } from 'views/app-views/payable/constants';
import { MODULE_RECEIVABLE_PARAMETERS } from 'views/app-views/receivable/constants';
import { MODULE_IOT_PARAMETERS } from 'views/app-views/iot/constants';

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

export const buildListQueryString = (options) => {
  const pageSize = localStorage.getItem('gridPageSize') || '10';

  const {
    // required
    router,
    _module,
    rdxModuleQuery,

    // optional
    sideNavFilter,
    gridSearch,
    searchReset,
  } = options;

  const apiHeaders = { 'x-resultasobject': false };
  const defaultPage = {
    current: '1',
    pageSize: pageSize
  }; // table list
  const defaultSort = _module?.initialSort || {
    sortField: 'name',
    sortOrder: 'ascend',
  };

  const defaultFilter = _module?.initialFilter || {};
  const defaultSearch = _module?.initialSearch || {};

  const defaultQuery = {
    ...defaultPage,
    ...defaultSort,
    ...defaultFilter,
    ...defaultSearch,
  };

  const q = hasObjectProperties(router?.query)
    ? router?.query
    : rdxModuleQuery?.currentQuery;

  const routerQuery = !searchReset ? q || {} : null;

  // ------------------------------------------------------------------------ //
  // check filter from url query string
  // ------------------------------------------------------------------------ //
  const f = sideNavFilter || rdxModuleQuery?.currentFilter || routerQuery;

  const filter = {};
  Object.entries(defaultFilter).map((keyValueArr) => {
    const [k] = keyValueArr;
    const value =
      f &&
      _module?.methods?.validateFilterFn &&
      _module?.methods?.validateFilterFn([k, f[k]], defaultFilter);
    if (value) filter[k] = value;
    return keyValueArr;
  });
  // ------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------ //
  // check search from url query string
  // ------------------------------------------------------------------------ //
  const s =
    hasObjectProperties(gridSearch?.search) ||
    hasObjectProperties(rdxModuleQuery?.currentSearch) ||
    hasObjectProperties(routerQuery) ||
    defaultSearch;

  const search = {};
  Object.entries(defaultSearch).map((keyValueArr) => {
    const [k] = keyValueArr;
    const value =
      _module?.methods?.validateSearchFn &&
      _module.methods.validateSearchFn([k, s[k]], searchReset);
    if (typeof value !== 'undefined') search[k] = value;
    return keyValueArr;
  });

  // ------------------------------------------------------------------------ //

  const page = gridSearch?.page ? gridSearch?.page : {};
  const sort = gridSearch?.sort ? gridSearch?.sort : {};
  const _id = router.match?.params?._id;
  const query = _id
    ? clone({})
    : {
      ...defaultQuery,
      ...routerQuery,
      ...page,
      ...sort,
      ...search,
      ...filter,
    };

  let qsStr;

  if (hasObjectProperties(query)) {
    // update API_URL query string
    qsStr = Object.keys(query).length > 0 && new URLSearchParams(query);
    qsStr = `?${qsStr}`;
    // router.replace({search: qsStr});

    window.history.replaceState(
      undefined,
      undefined,
      `${router.pathname}${qsStr}`
    );

    switch (query.folder) {
      case 'recycle': {
        apiHeaders['x-forceremoved'] = '2';
        break;
      }
      case 'archive': {
        apiHeaders['x-forcefiled'] = '2';
        break;
      }
      default: // 'documents
    }
  } else {
    qsStr = `/${_id}`;
    apiHeaders['x-resultasobject'] = true;
  }

  return {
    currentQuery: query,
    defaultQuery,
    str: qsStr,
    apiHeaders,
    defaultFilter,
    currentFilter: filter,
    defaultSearch,
    currentSearch: search,
  };
};

export const showGridNumberOfLines = (value, set) => {
  localStorage.setItem('showNumberOfLines', value);
  set(value);
};

export const setGridPageSize = (value) => {
  localStorage.setItem('gridPageSize', value);
};

export const buildTableRowSelection = {
  type: 'checkbox',
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};

export const buildTableColumnSearch = (dataIndex, searchInputRef) => {
  const onConfirm = (dataIndex, selectedKeys, confirm, clearFilters) => {
    if (!selectedKeys) clearFilters();
    confirm();
  };

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div className="m-3" onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInputRef}
            placeholder={'Localizar'}
            value={selectedKeys}
            onChange={(e) => {
              const value = e?.target?.value?.toLocaleString();
              setSelectedKeys(value);
            }}
            onPressEnter={() =>
              onConfirm(dataIndex, selectedKeys, confirm, clearFilters)
            }
            allowClear
            style={{
              width: 188,
              marginBottom: 8,
              display: 'block'
            }}
          />

          <Row justify="end">
            <Button
              className="mt-2"
              type="primary"
              onClick={() =>
                onConfirm(dataIndex, selectedKeys, confirm, clearFilters)
              }
              // onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              // icon={<SearchOutlined />}
              size="small"
              // style={{
              //   width: 90,
              // }}
            >
              OK
            </Button>
          </Row>
        </div>
      );
    },

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() =>
          searchInputRef.current.focus({
            cursor: 'all',
          })
        );
      }
    },
  };
};

export const resetModule = (dispatch) => {
  dispatch(rdxSetShowModuleSettings(false));
  dispatch(rdxSetShowModuleFilter(false));
  // dispatch(rdxSetModule(null))
  dispatch(rdxSetModuleQuery(null));
  dispatch(rdxSetModuleList(null));
  dispatch(rdxSetFormProvider(null));
  dispatch(rdxSetDataProvider(null));
};

export const gotoModule = (dispatch, rdxSetModule, router, item) => {
  resetModule(dispatch);

  const _module = getModuleByUrl(item.key);

  rdxSetModule(_module);
  router.push(item.key);
};

export const defaultParseModuleSettings = async (_module) => {
  let settings = await getSettingsById();

  const rfDb = settings[_module.name]?.requiredFields || [];

  let rf =
    _module?.requiredFields
      ?.filter((f) => f?.disabled && f?.checked && !rfDb?.includes(f?.key))
      ?.map((f) => f?.key) || [];

  if (rf.length > 0) {
    const body = { [`${_module.name}.requiredFields`]: [...rfDb, ...rf] };
    settings = await putSettings(settings._id, body);
  }

  return settings;
};

export const parseModuleSettings_BKP_AP = async (
  dataContext,
  setDataContext
) => {
  const {
    subscription,
    module
  } = dataContext;

  const _id = subscription._id;
  const url = `/subscriptions/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  if (!isMongoObjectId(_id)) {
    return console.error('Invalid _id! validateModuleSettings()');
  }

  // ************************************************************************ //
  // Module Settings
  // ************************************************************************ //
  if (!subscription?.settings) subscription.settings = {};
  if (!subscription?.settings[module.name]) {
    subscription.settings[module.name] = {};
  }

  const settings = subscription.settings[module.name];
  const body = module.methods.parseSettings(module, settings);
  // ************************************************************************ //

  if (!hasObjectProperties(body)) {
    if (dataContext && setDataContext) {
      setDataContext({
        ...dataContext,
        subscription: subscription,
        settings: settings,
      });
    }

    return settings;
  }

  try {
    const result = await put(url, body, config);
    const subscriptionDecorated = module.methods.decorateSettings({
      ...dataContext,
      result,
    });

    subscription.settings[module.name] =
      subscriptionDecorated.settings[module.name];

    return subscription.settings[module.name] || null;
  } catch (error) {
    throw error;
  }
};

export const decorateDataType = (_settings, data, _module) => {
  if (
    _settings?.configurations?.dataTypes?.length === 1 ||
    _module?.dataTypes?.length === 1
  ) {
    data.dataType =
      _settings?.configurations?.dataTypes[0] || _module?.dataTypes[0]?.key;
  }
};

export const validateFieldAfterSettingsChanges = (options) => {
  const {
    formContext,
    _settings,
    form,
    formField,
    dbField
  } = options;
  const f = form.getFieldsValue([formField]);

  const hasFamilyId = !!(
    f &&
    f[formField] &&
    _settings[dbField]?.find((x) => x._id === f[formField])
  );

  if (formContext.mode === 'add') {
    if (f && f[formField] && !hasFamilyId) {
      form.setFieldsValue({ [formField]: null });
    }
  }
};

export const onClickRemoveItem = (record, intl, callback) => {
  const remove_item_title = `${translate(intl, 'remove_item_title')}`;
  const remove_item_ask = `${translate(intl, 'remove_item_ask')}`;
  const yes = `${translate(intl, 'yes')}`;
  const no = `${translate(intl, 'no')}`;
  const removing_item = `${translate(intl, 'removing_item')}...`;
  const item_removed = `${translate(intl, 'item_removed')}!`;

  const options = {
    type: 'confirm',
    title: remove_item_title,
    message: remove_item_ask,
    okText: yes,
    cancelText: no,
    onOk: async () => {
      msg('l', removing_item, 'removing');
      callback();
      setTimeout(() => {
        message.destroy('removing');
        msg('s', item_removed);
      }, 300);
    },
  };

  modal(options);
};

export const getModuleByUrl = (url) => {
  // ------------------------------------------------------------------------
  // CORE
  // ------------------------------------------------------------------------
  if (url.includes('app/records/entities/companies')) {
    return MODULE_COMPANIES_PARAMETERS;
  } else if (url.includes('app/records/entities/providers')) {
    return MODULE_PROVIDERS_PARAMETERS;
  } else if (url.includes('app/records/entities/customers-persons')) {
    return MODULE_PERSONS_PARAMETERS;

    // ------------------------------------------------------------------------
    // SINDICO
    // ------------------------------------------------------------------------
  } else if (url.includes('app/records/condominium/apartments')) {
    return MODULE_APARTMENTS_PARAMETERS;
  } else if (url.includes('app/records/condominium/residents')) {
    return MODULE_RESIDENTS_PARAMETERS;
  } else if (url.includes('app/records/condominium/vehicles')) {
    return MODULE_VEHICLES_PARAMETERS;
  } else if (url.includes('app/records/condominium/bikes')) {
    return MODULE_BIKES_PARAMETERS;
  } else if (url.includes('app/records/condominium/pets')) {
    return MODULE_PETS_PARAMETERS;
  } else if (url.includes('app/records/condominium/iot')) {

    return MODULE_IOT_PARAMETERS;

    // ------------------------------------------------------------------------
    // LEKKUS ERP
    // ------------------------------------------------------------------------
  } else if (url.includes('app/records/iot')) {
    return MODULE_IOT_PARAMETERS;

    // ------------------------------------------------------------------------
    // MULTI
    // ------------------------------------------------------------------------
  } else if (url.includes('app/records/entities/operators')) {
    return MODULE_MULTIOPERATORS_PARAMETERS;
  } else if (url.includes('app/records/multi-plans')) {
    return MODULE_MULTIPLANS_PARAMETERS;
  } else if (url.includes('app/commercial/orders-plans')) {
    return MODULE_ORDERS_PLANS_PARAMETERS;
  } else if (url.includes('app/commercial/leads')) {
    return MODULE_LEADS_PARAMETERS;
  } else if (url.includes('app/financial/vouchers')) {
    return MODULE_VOUCHERS_PARAMETERS;
  } else if (url.includes('app/financial/invoices')) {
    return MODULE_INVOICES_PARAMETERS;
  } else if (url.includes('app/financial/receivable')) {
    return MODULE_RECEIVABLE_PARAMETERS;
  } else if (url.includes('app/financial/payable')) {
    return MODULE_PAYABLE_PARAMETERS;

    // ------------------
  } else if (url.includes('app/records/condominium/ambiences')) {
    return MODULE_AMBIENCES_PARAMETERS;
  } else if (url.includes('app/records/condominium/procedures')) {
    return MODULE_PROCEDURES;
  } else if (url.includes('app/records/entities/collaborators')) {
    return MODULE_ENTITIES_PARAMETERS.COLLABORATORS;
  } else if (url.includes('app/records/products/assets')) {
    return MODULE_PRODUCTS_PARAMETERS.ASSETS;
  } else if (url.includes('app/operational/occurrences')) {
    return MODULE_OCCURRENCES;
  } else if (url.includes('atualiza-index')) {
    return MODULE_ATUALIZA_PARAMETERS;
  } else if (url.includes('app/operational/moves')) {
    return MODULE_MOVES_PARAMETERS;
  } else if (url.includes('app/scaffolding/foos')) {
    // foos exemplo route for module activation
    return MODULE_FOOS_PARAMETERS;
  } else {
    console.error('*** NO MODULE FOUND FROM API_URL ->', url, '***');
  }
};

export const removeSection = (formContext, sectionKey) => {
  if (!sectionKey) sectionKey = formContext?.selectedSection;

  if (formContext?.sectionsChanged?.includes(sectionKey)) {
    return [...formContext?.sectionsChanged?.filter((x) => x !== sectionKey)];
  } else {
    return [...formContext?.sectionsChanged];
  }
};

export const addSection = (formContext, sectionKey) => {
  if (!sectionKey) sectionKey = formContext?.selectedSection;

  if (!formContext?.sectionsChanged?.includes(sectionKey)) {
    return [...formContext?.sectionsChanged, sectionKey];
  } else {
    return [...formContext?.sectionsChanged];
  }
};

export const saveContext = (_dataRoot, value, props) => {
  const { dataContext } = props;
  const {
    setDataContext,
    formContext,
    setFormContext
  } = props;

  const k = Object.keys(value)[0];
  const v = Object.values(value)[0];

  let newDoc;

  if (typeof v === 'object') {
    if (_dataRoot) {
      if (!dataContext.doc[_dataRoot]) dataContext.doc[_dataRoot] = {};

      newDoc = {
        ...dataContext.doc,
        [_dataRoot]: {
          ...dataContext.doc[_dataRoot],
          [k]: {
            ...dataContext.doc[_dataRoot][k],
            ...v,
          },
        },
      };
    } else {
      newDoc = {
        ...dataContext.doc,
        [k]: {
          ...dataContext.doc[k],
          ...v,
        },
      };
    }
  } else {
    if (_dataRoot) {
      newDoc = {
        ...dataContext.doc,
        [_dataRoot]: {
          ...dataContext.doc[_dataRoot],
          ...value,
        },
      };
    } else {
      newDoc = {
        ...dataContext.doc,
        ...value,
      };
    }
  }

  const isChanged = !isObjEquals(newDoc, dataContext.docLock);

  jarvislyDebounce(() => {
    setDataContext({
      ...dataContext,
      doc: newDoc
    });
    setFormContext({
      ...formContext,
      sectionsChanged: isChanged
        ? addSection(formContext)
        : removeSection(formContext),
    });
  }, 200);
};

export const saveModuleForm = async (props, body, intl) => {
  const t = {
    saving: `${translate(intl, 'saving_document')}...`,
    updating: `${translate(intl, 'updating_document')}...`,
    savingSuccess: `${translate(intl, 'document_saved_with_success')}!`,
    updatingSuccess: `${translate(intl, 'document_updated_with_success')}!`,
  };

  const {
    formContext,
    dataContext,
    setDataContext,
    uri = props?._module.api,
  } = props;

  setDataContext({
    ...dataContext,
    loading: true
  });

  try {
    let doc;

    if (!body?._id) {
      msg('i', t.saving, 'saving');

      doc = await postByUri(uri, body);
    } else {
      msg('i', t.updating, 'saving');

      doc = await putByUri(uri, body._id, body);
    }

    message.destroy('saving');
    msg(
      's',
      formContext?.mode === 'add' ? t.savingSuccess : t.updatingSuccess,
      'add'
    );

    return doc;
  } catch (error) {
    message.destroy('saving');
    const errorMessage = `Erro ${error?.data?.code}: ${error?.data?.errorMessage}`;

    msg('e', errorMessage, 'error', 5);
    setDataContext({
      ...dataContext,
      loading: false
    });
  }
};

// DEFAULT MODULES FUNCTIONS
// --------------------------------------------------------------------- //

export function defaultParseSettings (_module, _settings) {
  const body = {};
  // const seed = _module?.seeds?.settings

  // dataType
  // if (!_settings?.configurations?.dataTypes) {
  //     body[`settings.${_module.name}.configurations.dataTypes`] = seed.configurations.dataTypes ||
  //         [];
  // }

  // dataMemory -> será sempre falso como padrão - usuario deve setar como TRUE caso deseje
  // if (!_settings?.configurations?.dataMemory) {
  //     body[`settings.${_module.name}.configurations.dataMemory`] = seed?.configurations?.dataMemory ||
  //         false;
  // }

  // required fields
  if (_module?.requiredFields) {
    let requiredFields = !_settings?.requiredFields
      ? _module?.requiredFields.filter((f) => f.checked).map((f) => f.key)
      : _module?.requiredFields
        .filter(
          (f) =>
            f.disabled &&
            f.checked &&
            !_settings?.requiredFields.includes(f.key)
        )
        .map((f) => f.key);

    if (requiredFields?.length > 0) {
      if (_settings?.requiredFields) {
        requiredFields = [..._settings?.requiredFields, ...requiredFields];
      }
      body[`settings.${_module.name}.requiredFields`] = requiredFields;
    }
  }

  return body;
}

export function defaultValidateSearchFn (keyValueArr, resetSearch) {
  const [k, v] = keyValueArr;

  if (resetSearch || !v) return 'null';

  switch (k) {
    case 'documentCode':
      if (v === 'null') return 'null';
      return v;

    case 'documentStatus': {
      const vArr = Array.isArray(v) ? v : v.split(',');
      const newV = [];

      if (vArr.length === 0) {
        return 'null';
      } else if (vArr[0] === null || vArr[0] === 'null') {
        return 'null';
      } else {
        if (vArr.includes('in_analise')) newV.push('in_analise');
        if (vArr.includes('with_reservation')) newV.push('with_reservation');
        if (vArr.includes('revised')) newV.push('revised');
      }

      if (newV.length === 0) return 'null';

      return newV;
    }

    default:
      return 'null';
  }
}

export function defaultValidateFilterFn (keyValueArr, defaultFilter) {
  const { dataProfile } = defaultFilter;

  const [k, v] = keyValueArr;

  switch (k) {
    case 'folder':
      return ['documents', 'archive', 'recycle'].includes(v) ? v : 'documents';

    case 'dataProfile':
      return dataProfile;

    default:
      return 'null';
  }
}

export function defaultDataProfileValidation (_module, body) {
  if (!body || !_module || !_module?.name) return;

  const dataProfile = _module?.initialFilter?.dataProfile;

  if (!body.uniqueKey) body.uniquekey = body.name;
  if (!body.dataProfile) body.dataProfile = [];
  if (!body.dataProfile.includes(dataProfile)) {
    body.dataProfile.push(dataProfile);
  }
}

export function defaultDecorateSettings (options) {
  const { result } = options;
  return result;
}

export function useDocumentForm (_settings, refHook, intlHook, elRefs) {
  const rdxModule = useSelector((s) => s.moduleRedux.rdxModule);

  // TRANSLATED
  // ========================================================================================== //

  const intl = intlHook();

  const translated = {
    saving: `${translate(intl, 'saving_document')}...`,
    updating: `${translate(intl, 'updating_document')}...`,
    savingSuccess: `${translate(intl, 'document_saved_with_success')}!`,
    updatingSuccess: `${translate(intl, 'document_updated_with_success')}!`,

    error409_name: translate(intl, 'error_409_person_name'),
    error409_cpf: translate(intl, 'error_409_person_cpf'),
    error409: translate(intl, 'response_error_409'),

    name: translate(intl, 'name'),
    uniqueKey: translate(intl, 'unique_key'),
    cpf: translate(intl, 'cpf'),
    cnpj: translate(intl, 'cnpj'),
  };

  // REQUIRED FIELDS
  // ========================================================================================== //

  const rf =
    _settings && rdxModule?.name && _settings[rdxModule.name]?.requiredFields;
  const requiredFields = {
    // default profile
    dataType: rf?.includes('dataType'),
    name: rf?.includes('name'),
    cnpj: rf?.includes('cnpj'),
    cpf: rf?.includes('cpf'),
    rg: rf?.includes('rg'),
    gender: rf?.includes('gender'),
    birthday: rf?.includes('birthday'),
    foundedAt: rf?.includes('foundedAt'),
    mainPhone: rf?.includes('mainPhone'),
    mainEmail: rf?.includes('mainEmail'),
    mainWebsite: rf?.includes('mainWebsite'),
    zip: rf?.includes('zip'),

    // residents profile
    apartmentId: rf?.includes('apartmentId'),
    type: rf?.includes('type'),
    relationshipId: rf?.includes('relationshipId'),
    profile: rf?.includes('profile'),
    contractType: rf?.includes('contractType'),
    responsibleName: rf?.includes('responsibleName'),
    responsibleProfile: rf?.includes('responsibleProfile'),
    responsiblePhone: rf?.includes('responsiblePhone'),
    responsibleRelationshipId: rf?.includes('responsibleRelationshipId'),
    realEstateAgentName: rf?.includes('realEstateAgentName'),
    realEstateAgentPhone: rf?.includes('realEstateAgentPhone'),
    realEstateName: rf?.includes('realEstateName'),
    note: rf?.includes('note'),

    // apartments profile
    blockId: rf?.includes('blockId'),
    profileId: rf?.includes('profileId'),
    floor: rf?.includes('floor'),
    directCall: rf?.includes('directCall'),
  };

  // DOM ELEMENT REFERENCES
  // ========================================================================================== //

  const elementRefs = {
    ...elRefs,

    // default profile
    dataType: refHook(null),
    name: refHook(null),
    cnpj: refHook(null),
    cpf: refHook(null),
    rg: refHook(null),
    gender: refHook(null),
    birthday: refHook(null),
    foundedAt: refHook(null),
    ['mainContact.__phone']: refHook(null), // eslint-disable-line no-useless-computed-key
    ['mainContact.email']: refHook(null), // eslint-disable-line no-useless-computed-key
    ['mainContact.website']: refHook(null), // eslint-disable-line no-useless-computed-key
    zip: refHook(null),

    // residents profile
    apartmentId: refHook(null),
    type: refHook(null),
    relationshipId: refHook(null),
    profile: refHook(null),
    contractType: refHook(null),
    responsibleName: refHook(null),
    responsibleProfile: refHook(null),
    responsiblePhone: refHook(null),
    responsibleRelationshipId: refHook(null),
    realEstateAgentName: refHook(null),
    realEstateAgentPhone: refHook(null),
    realEstateName: refHook(null),
    note: refHook(null),

    // apartments profile
    blockId: refHook(null),
    profileId: refHook(null),
    floor: refHook(null),
    directCall: refHook(null),
  };

  // RULES
  // ========================================================================================== //

  const requiredFieldMessage = setLocale(true, 'required_field');
  const invalidFieldMessage = setLocale(true, 'invalid_field');

  const rules = {
    // ------------------------------------------------------------------------------------------
    // default profile
    // ------------------------------------------------------------------------------------------
    dataType: [
      {
        required: requiredFields?.dataType,
        message: requiredFieldMessage
      },
    ],

    name: [{
      required: requiredFields?.name,
      message: requiredFieldMessage
    }],

    cnpj: [
      {
        required: requiredFields?.cnpj,
        message: requiredFieldMessage
      },
      {
        validator: (rule, cnpj) => {
          if (!cnpj && !requiredFields?.cnpj) return Promise.resolve();
          if (cnpj && !isValidCnpj(cnpj)) {
            return Promise.reject(setLocale(true, 'invalid_cnpj'));
          }
          return Promise.resolve();
        },
      },
    ],

    cpf: [
      {
        required: requiredFields?.cpf,
        message: requiredFieldMessage
      },
      {
        validator: (rule, cpf) => {
          if (!cpf && !requiredFields?.cpf) return Promise.resolve();
          if (cpf && !isValidCpf(cpf)) {
            return Promise.reject(setLocale(true, 'invalid_cpf'));
          }
          return Promise.resolve();
        },
      },
    ],

    rg: [{
      required: requiredFields?.rg,
      message: requiredFieldMessage
    }],

    gender: [
      {
        required: requiredFields?.gender,
        message: requiredFieldMessage
      },
    ],

    birthday: [
      {
        required: requiredFields?.birthday,
        message: requiredFieldMessage
      },
    ],

    foundedAt: [
      {
        required: requiredFields?.foundedAt,
        message: requiredFieldMessage
      },
    ],

    mainPhone: [
      {
        required: requiredFields?.mainPhone,
        message: requiredFieldMessage
      },
      {
        validator: (rule, pn) => {
          if (!pn && !requiredFields?.mainPhone) return Promise.resolve();
          if (pn && !isValidPhoneNumber(pn)) {
            return Promise.reject(setLocale(true, 'invalid_phone_number'));
          }
          return Promise.resolve();
        },
      },
    ],

    mainEmail: [
      {
        required: requiredFields?.mainEmail,
        message: requiredFieldMessage
      },
      {
        validator: (rule, email) => {
          if (!email && !requiredFields?.mainEmail) return Promise.resolve();
          if (email && !isValidEmail(email)) {
            return Promise.reject(setLocale(true, 'invalid_email'));
          }
          return Promise.resolve();
        },
      },
    ],

    mainWebsite: [
      {
        required: requiredFields?.mainWebsite,
        message: requiredFieldMessage
      },
      {
        validator: (rule, website) => {
          if (!website && !requiredFields?.mainWebsite) {
            return Promise.resolve();
          }
          if (website && !isValidUrl(website)) {
            return Promise.reject(setLocale(true, 'invalid_website'));
          }
          return Promise.resolve();
        },
      },
    ],

    zip: [
      {
        required: requiredFields?.zip,
        message: requiredFieldMessage
      },
      {
        min: 9,
        message: invalidFieldMessage
      },
    ],

    // ------------------------------------------------------------------------------------------
    // residents profile
    // ------------------------------------------------------------------------------------------
    apartmentId: [
      {
        required: requiredFields?.apartmentId,
        message: requiredFieldMessage
      },
    ],

    type: [{
      required: requiredFields?.type,
      message: requiredFieldMessage
    }],

    relationshipId: [
      {
        required: requiredFields?.relationshipId,
        message: requiredFieldMessage,
      },
    ],

    profile: [
      {
        required: requiredFields?.profile,
        message: requiredFieldMessage
      },
    ],

    contractType: [
      {
        required: requiredFields?.contractType,
        message: requiredFieldMessage
      },
    ],

    responsibleName: [
      {
        required: requiredFields?.responsibleName,
        message: requiredFieldMessage,
      },
    ],

    responsibleProfile: [
      {
        required: requiredFields?.responsibleProfile,
        message: requiredFieldMessage,
      },
    ],

    responsiblePhone: [
      {
        required: requiredFields?.responsiblePhone,
        message: requiredFieldMessage,
      },
    ],

    responsibleRelationshipId: [
      {
        required: requiredFields?.responsibleRelationshipId,
        message: requiredFieldMessage,
      },
    ],

    realEstateAgentName: [
      {
        required: requiredFields?.realEstateAgentName,
        message: requiredFieldMessage,
      },
    ],

    realEstateAgentPhone: [
      {
        required: requiredFields?.realEstateAgentPhone,
        message: requiredFieldMessage,
      },
    ],

    realEstateName: [
      {
        required: requiredFields?.realEstateName,
        message: requiredFieldMessage,
      },
    ],

    note: [{
      required: requiredFields?.note,
      message: requiredFieldMessage
    }],

    // ------------------------------------------------------------------------------------------
    // apartments profile
    // ------------------------------------------------------------------------------------------
    blockId: [
      {
        required: requiredFields?.blockId,
        message: requiredFieldMessage
      },
    ],

    profileId: [
      {
        required: requiredFields?.profileId,
        message: requiredFieldMessage
      },
    ],

    floor: [{
      required: requiredFields?.floor,
      message: requiredFieldMessage
    }],

    directCall: [
      {
        required: requiredFields?.directCall,
        message: requiredFieldMessage
      },
    ],
  };

  return {
    translated,
    requiredFields,
    elementRefs,
    rules,
  };
}

export function handleValidateStatus (
  field,
  form,
  formContext,
  _data,
  requiredFields
) {
  switch (field) {
    case 'cpf': {
      if (!form.isFieldTouched(['cpf']) && formContext.mode === 'add') return;
      if (!_data?.cpf) {
        return requiredFields?.cpf ? 'error' : '';
      } else {
        return isValidCpf(_data?.cpf) ? 'success' : 'error';
      }
    }

    case 'phone': {
      if (
        !form.isFieldTouched(['mainContact', '__phone']) &&
        formContext.mode === 'add'
      ) {
        return;
      }
      if (!_data?.mainContact?.__phone) {
        return requiredFields?.mainPhone ? 'error' : '';
      } else {
        return isValidPhoneNumber(_data?.mainContact?.__phone)
          ? 'success'
          : 'error';
      }
    }

    case 'email': {
      if (
        !form.isFieldTouched(['mainContact', 'email']) &&
        formContext.mode === 'add'
      ) {
        return;
      }
      if (!_data?.mainContact?.email) {
        return requiredFields?.mainEmail ? 'error' : '';
      } else {
        return isValidEmail(_data?.mainContact?.email) ? 'success' : 'error';
      }
    }

    case 'responsiblePhone': {
      if (
        !form.isFieldTouched(['responsiblePhone']) &&
        formContext.mode === 'add'
      ) {
        return;
      }
      if (!_data?.responsiblePhone) {
        return 'error';
      } else {
        return isValidPhoneNumber(_data?.responsiblePhone)
          ? 'success'
          : 'error';
      }
    }

    case 'realEstateAgentPhone': {
      if (
        !form.isFieldTouched(['realEstateAgentPhone']) &&
        formContext.mode === 'add'
      ) {
        return;
      }
      if (!_data?.realEstateAgentPhone) {
        return 'error';
      } else {
        return isValidPhoneNumber(_data?.realEstateAgentPhone)
          ? 'success'
          : 'error';
      }
    }

    default:
      console.warn('field not found!', field);
      return 'error';
  }
}

export const cloneObjectRecursively = (obj) => {
  if (typeof obj !== 'object') return;

  const newObj = {};

  Object.keys(obj).forEach((k) => {
    const value = obj[k];

    if (moment.isMoment(value)) {
      newObj[k] = moment(value).toISOString();
    } else if (!isNaN(value)) {
      newObj[k] = Number(value);
    } else if (Array.isArray(value)) {
      for (let i of value) newObj[k] = cloneObjectRecursively(i);
    } else if (typeof value === 'object') {
      newObj[k] = cloneObjectRecursively(value);
    } else {
      newObj[k] = value;
    }
  });

  return newObj;
};

export const getUrlQueryString = (
  location,
  returnAsObject,
  queryStringAlternative
) => {
  if (queryStringAlternative) {
    try {
      // query string alternative
      const qsa = parseUrlQueryString(queryStringAlternative);
      // current query string
      const cqs = parseUrlQueryString(location.search.replace('?', ''));

      if (
        Object.keys(qsa).length > 0 &&
        Object.keys(cqs).length < Object.keys(qsa).length
      ) {
        return queryStringAlternative;
      }

      Object.keys(qsa)?.map((k) => {
        if (!cqs[k] || cqs[k] === 'undefined') {
          return queryStringAlternative;
        } else {
          if (
            ['filter', 'search', 'sort', 'project', 'pagination'].includes(k)
          ) {
            if (typeof cqs[k] === 'string') {
              JSON.parse(cqs[k]);
            } else if (typeof cqs[k] === 'object') {
              JSON.stringify(cqs[k]);
            }
          }
        }

        return k;
      });
    } catch (e) {
      console.error(`error parsing url query string ->`, e);
      return queryStringAlternative;
    }
  }

  const params = new URLSearchParams(location.search);
  const paramObj = {};

  for (const [key, value] of params.entries()) {
    if (value && value !== 'undefined') paramObj[key] = value;
  }

  if (Object.keys(paramObj).length === 0) return queryStringAlternative;
  // return returnAsObject ? paramObj : paramObj.toString()
  return returnAsObject ? paramObj : buildUrlQueryString(paramObj);
};

export const parseUrlQueryString = (str) => {
  // Decodifica a string URI
  const decodedQueryString = decodeURIComponent(str);
  const parsedQueryParams = {};

  decodedQueryString.split('&').forEach((queryParam) => {
    const [key, value] = queryParam.split('=');

    try {
      parsedQueryParams[key] = JSON.parse(value);
    } catch {
      parsedQueryParams[key] = value;
    }
  });

  return parsedQueryParams;
};

export const buildUrlQueryString = (query) => {
  const queryObj = {};

  for (const [key, value] of Object.entries(query)) {
    if (Array.isArray(value)) {
      queryObj[key] = value.join(',');
    } else if (typeof value === 'object') {
      queryObj[key] = JSON.stringify(value);
    } else {
      queryObj[key] = value;
    }
  }

  const params = new URLSearchParams(queryObj);

  return params.toString();
};

export const api = {
  // GET
  get: async (url, _id, queryString, returnAsObject, config = {}) => {
    if (typeof returnAsObject === 'undefined') returnAsObject = !!_id;
    if (_id) url += `/${_id}`;
    if (queryString) url += `?${queryString}`;

    if (typeof config?.headers?.['x-resultasobject'] === 'undefined') {
      if (!config?.headers) config.headers = {};
      config.headers['x-resultasobject'] = returnAsObject;
    }

    try {
      return await getV2(url, config);
    } catch (error) {
      throw error;
    }
  },

  // POST
  post: async (url, body, config = {}) => {
    if (typeof config?.headers?.['x-resultasobject'] === 'undefined') {
      if (!config?.headers) config.headers = {};
      config.headers['x-resultasobject'] = true;
    }

    try {
      return await postV2(url, body, config);
    } catch (error) {
      throw error;
    }
  },

  // PUT
  put: async (url, body, config = {}, ignoreNotation) => {
    const _id = body?._id;

    if (!isMongoObjectId(_id)) return console.error('Invalid _id! putByUri()');
    if (body?._id) delete body._id;

    if (typeof config?.headers?.['x-resultasobject'] === 'undefined') {
      if (!config?.headers) config.headers = {};
      config.headers['x-resultasobject'] = true;
    }

    try {
      return await putV2(`${url}/${_id}`, body, config, ignoreNotation);
    } catch (error) {
      throw error;
    }
  },

  // DELETE
  del: async (url, body, config = {}, ignoreNotation) => {
    const _id = body?._id;

    if (!isMongoObjectId(_id)) return console.error('Invalid _id! putByUri()');

    if (typeof config?.headers?.['x-resultasobject'] === 'undefined') {
      if (!config?.headers) config.headers = {};
      config.headers['x-resultasobject'] = true;
    }

    try {
      return await delV2(`${url}/${_id}`, config);
    } catch (error) {
      throw error;
    }
  },
};

export const buildDocumentStatusFilter = () => {
  return [
    {
      text: setLocale(true, 'in_analise'),
      value: 'in_analise',
    },
    {
      text: setLocale(true, 'with_reservation'),
      value: 'with_reservation',
    },
    {
      text: setLocale(true, 'revised'),
      value: 'revised',
    },
  ];
};
