import {Form, Select} from 'antd';
import {filterOption} from 'utils/helpers';
import {translate} from 'utils/react-jarvisly-helper';
import React from 'react';
import IntlMessage from '../../../util-components/IntlMessage';
import {useIntl} from 'react-intl';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const {Option} = Select;

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const FormItemDataType = props => {

  // props deconstruction ------------------------------------------------------
  const {
    elRef,
    isDisabled,
    data,
    _settings,
    _module,
    rule,
    label,

    dataContext,
  } = props;

  // local variables -----------------------------------------------------------
  const intl = useIntl();


  // UI COMPONENT --------------------------------------------------------------

  const sdt = _settings?.configurations?.dataTypes;
  const mdt = _module?.dataTypes;
  const dataTypes = mdt?.filter(x => sdt && x?.key && sdt.includes(x.key)) ||
      [];
  const docLock = dataContext?.docLock;

  if (docLock?.dataType &&
      !dataTypes?.find(x => x.key === docLock.dataType)) {
    const tmp = mdt?.find(x => x.key === docLock.dataType);
    if (tmp) dataTypes.push(tmp);
  }

  return (
      <Form.Item
          label={setLocale(true, label)}
          required
          rules={rule}
          name="dataType">

        <Select ref={elRef}
                // value={data?.dataType || autoSelectDataType}
                value={data?.dataType}
                autoFocus={dataTypes.length > 1}
                showSearch
                disabled={isDisabled || dataTypes.length <= 1}
                filterOption={filterOption}>

          {dataTypes?.map(x => (
              <Option key={x.key} value={x.key}>
                {translate(intl, x.label)}
              </Option>))
          }

        </Select>
      </Form.Item>
  );
};

export default FormItemDataType;
