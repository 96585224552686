import React, { useState } from 'react';
import { AutoComplete, Spin } from 'antd';
import { normalize } from 'jarvisly-helper';
import { jarvislyDebounce } from 'utils/helpers';

export const AsyncAutoComplete = React.forwardRef(({
  searchFn,
  debounceTimeout: delay = 400,
  ...props
}, ref) => {

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [valueSearched, setValueSearched] = useState();

  const debounceSearch = (searchValue) => {

    if (!searchFn || searchValue.length < 3) return;

    const sv = normalize(searchValue, 'lower');
    if (sv?.includes(valueSearched)) return;

    setOptions([]);
    setLoading(true);

    jarvislyDebounce(async () => {
      searchFn(searchValue).then(result => {

        if (!result) {
          const v = normalize(searchValue, 'lower');
          setValueSearched(v);
        }

        setOptions(result || []);
        setLoading(false);
      });
    }, delay);
  };

  return (

    <AutoComplete ref={ref}
                  labelInValue
                  allowClear
                  backfill
                  onSearch={debounceSearch}
                  notFoundContent={loading ? <Spin size="small"/> : null}
                  {...props}
                  options={options}
    />

  );
});
