import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import {Button, Col, Form, Input, message, Row} from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import {ROW_GUTTER} from 'constants/ThemeConstant';
import {
  buildDataLikeForm,
  getRedux,
  isObjEquals,
  msg,
  onFormFinishFailed,
} from 'utils/helpers';
import {translate} from 'utils/react-jarvisly-helper';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
  FormContext,
} from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/FormProvider';
import {
  postDocument, putDocument,
} from 'components/jarvisly-layouts/ModuleComponent/module-api';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import FormItemDataType
  from 'components/jarvisly-layouts/ModuleComponent/Components/FormItemDataType';
import {
  addSection,
  removeSection,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import {DataContext} from "../../../../../components/jarvisly-layouts/ModuleComponent/ModuleForm/DataProvider";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const AmbiencesProfile = forwardRef((props, ref) => {

  const {formContext, setFormContext} = React.useContext(FormContext);
  const {dataContext} = React.useContext(DataContext);

  const data = dataContext?.data;

  // props deconstruction ------------------------------------------------------
  const {_loading, _module} = props;
  const initialState = {
    cpfStatus: '',
    cnpjStatus: '',
    isFormChanged: formContext?.sectionsChanged?.includes(formContext?.selectedSection),
  };

  // rewrite the _settings after go back from module settings
  const rdxSubscription = useSelector(s => s?.auth?.rdxSubscription);
  const _settings = rdxSubscription?.settings &&
      rdxSubscription.settings[_module.name];

  // component states ----------------------------------------------------------
  const [state, setState] = useState(initialState);
  const [initialFormData, setInitialFormData] = useState({});

  // local variables -----------------------------------------------------------

  // translations
  const intl = useIntl();
  const msgSaving = `${translate(intl, 'saving_document')}...`;
  const msgUpdating = `${translate(intl, 'updating_document')}...`;
  const msgSavingSuccess = `${translate(intl, 'document_saved_with_success')}!`;
  const msgUpdatingSuccess = `${translate(intl,
      'document_updated_with_success')}!`;

  const history = useHistory();

  const isDisabled = formContext.mode === 'view' || _loading;
  const [form] = Form.useForm();
  const elRefs = {
    dataType: useRef(null),
    name: useRef(null),
    localization: useRef(null),
    description: useRef(null),
  };

  const rules = {

    dataType: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    name: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    // localization: [
    //   {
    //     required: true, message: setLocale(true, 'required_field'),
    //   }],

  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (data) {

      form.setFieldsValue({...data});

      if (dataContext?.docLock) {
        const builtData = buildDataLikeForm(form, dataContext?.docLock);
        setInitialFormData(builtData);
      }

    } else {
      form.resetFields();
      setState({...initialState});
      setInitialFormData({});
      handleFocus('dataType');
    }

  }, [dataContext?.docLock, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    reloadDocument: memorize => reloadDocument(memorize),
    handleFocus: field => handleFocus(field),
  }));

  // METHODS -------------------------------------------------------------------

  function handleFocus(field) {
    let focus = field ? field : 'dataType';
    if (!!data?.dataType && focus === 'dataType') focus = 'name';
    setTimeout(() => elRefs[focus]?.current?.focus({cursor: 'all'}), 400);
  }

  function reloadDocument(memorize) {
    setState({...state, isFormChanged: memorize});
    setTimeout(() => form.resetFields());
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async values => {

    // values.uniqueKey = normalize(values.name, 'lower');

    try {

      let doc;

      if (formContext.mode === 'add') {
        msg('i', msgSaving, 'saving');
        doc = await postDocument(_module, values);

        // update the subscriptions parameters
        const rdxSubscription = getRedux('auth', 'rdxSubscription');
        rdxSubscription.parameters.ambiences.push(
            {_id: doc._id, name: doc.name, localization: doc.localization});

      } else {
        msg('i', msgUpdating, 'saving');
        doc = await putDocument(_module, data?._id, values);
      }

      message.destroy('saving');

      msg('s', formContext?.mode === 'add' ? msgSavingSuccess : msgUpdatingSuccess,
          'add');

      if (formContext?.mode === 'add') {
        history.replace({
          pathname: `${_module?.url}/${doc._id}/profile`,
          state: {isActive: true},
        });
      }

      setFormContext({
        ...formContext,
        mode: 'view',
        sectionsChanged: removeSection(formContext),
        data: doc,
        docLock: doc,
        dataMemory: _settings?.configurations?.dataMemory ? values : null,
      });

      setState({...state, isFormChanged: false});



    } catch (error) {

      let errorMessage;

      message.destroy('saving');

      if (error?.data?.code === 409) {
        errorMessage = `Erro 409: Já existe um documento com este 'CPF' ou 'CNPJ'!`;

      } else {
        errorMessage = `Erro ${error?.data?.code}: ${error?.data?.errorMessage}`;
      }

      msg('e', errorMessage, 'error');

    }
  };

  const saveContext = _.debounce(value => {

    const currentData = {...initialFormData, ...value};
    const isChanged = !isObjEquals(currentData, initialFormData);

    setState({...state, isFormChanged: isChanged});

    setFormContext({
      ...formContext,
      data: {...formContext.data, ...value},
      sectionsChanged: isChanged ? addSection(formContext) : removeSection(formContext),
    });
  });

  // UI COMPONENT --------------------------------------------------------------

  return (<div>
    <Form
        form={form}
        name="profile"
        layout="vertical"
        initialValues={data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={saveContext}
        validateTrigger="onChange"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
    >

      <Row className="mb-2">
        <h5 className="text-muted">
          {setLocale(true, 'basic_info')}
        </h5>
      </Row>

      <Row gutter={ROW_GUTTER}>


        <Col xs={24} sm={8}>
          <FormItemDataType {...props}
                            label={'ambience_type'}
                            elRef={elRefs.dataType}
                            rule={rules.dataType}
                            data={data}
                            isDisabled={isDisabled}
                            _settings={_settings}/>
        </Col>


        <Col xs={24} sm={8}>

          <Form.Item
              label={setLocale(true, 'name')}
              name="name"
              rules={rules.name}>

            <Input ref={elRefs.name}
                   disabled={isDisabled}
                   name="name"/>

          </Form.Item>

        </Col>


        <Col xs={24} sm={8}>

          <Form.Item
              label={setLocale(true, 'localization')}
              name="localization"
              rules={rules.localization}>

            <Input ref={elRefs.localization}
                   disabled={isDisabled}
                   name="localization"/>

          </Form.Item>

        </Col>


        <Col xs={24}>

          <Form.Item
              label={setLocale(true, 'description')}
              name="description">

            <Input ref={elRefs.description}
                   disabled={isDisabled}
                   name="description"/>

          </Form.Item>

        </Col>

      </Row>


      <Row align="middle" justify="end">

        <Button type="primary"
                htmlType="submit"
                disabled={formContext.mode === 'view' || !state.isFormChanged}
                style={{minWidth: 100}}>
          {setLocale(true, 'save')}
        </Button>

      </Row>


    </Form>
  </div>);

});

// EXPORT **********************************************************************
// *****************************************************************************

export default AmbiencesProfile;

