import React from 'react'
import IntlMessage from 'components/util-components/IntlMessage'
import { Col, Row, Tooltip } from 'antd'
import { Icon } from 'components/util-components/Icon'
import {
  MobileOutlined,
  HomeOutlined,
  UserOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import { buildCarBrandOptions, buildMotorcycleBrandOptions, parsePhoneToDisplay } from '../../../utils/helpers'
import { useIntl } from 'react-intl'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

export const BikesDocumentSubtitle = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const _data = dataContext?.dataLock
  const intl = useIntl()

  const brands = _data?.type === 'car'
    ? buildCarBrandOptions(intl)
    : buildMotorcycleBrandOptions(intl)

  const selectedBrand = brands?.find(b => b?.value === _data?.brand)

  return (<>
    {selectedBrand?.label}
  </>)
})

// EMAIL AND PHONE
export const BikesDocumentHeaderColumn2 = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const driver = dataContext?.dataLock?.driver
  const apartment = dataContext?.dataLock?.__apartmentTitle

  return (<>
    <Row style={{
      display: driver?.fullName
        ? 'initial'
        : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          {driver?.fullName &&
            <Tooltip placement="right" title={setLocale(true, 'driver')}>
              <Icon type={UserOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
                {driver?.fullName}
              </span>
            </Tooltip>}
        </Row>
      </Col>
    </Row>

    <Row style={{
      display: apartment
        ? 'initial'
        : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          {apartment &&
            <Tooltip placement="right" title={setLocale(true, 'apartment')}>
              <Icon type={HomeOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
                {apartment}
              </span>
            </Tooltip>}
        </Row>
      </Col>
    </Row>
  </>)

})

// ADDRESS AND WEBSITE
export const BikesDocumentHeaderColumn3 = React.forwardRef((props, ref) => {

  const { dataContext } = props
  const directCall = dataContext?.dataLock?.__apartment?.directCall
  const mobile = parsePhoneToDisplay(dataContext?.dataLock?.driver?.phone)

  return (<>
    <Row style={{
      display: directCall
        ? 'initial'
        : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          {directCall &&
            <Tooltip placement="right" title={setLocale(true, 'direct_call')}>
              <Icon type={PhoneOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
                {directCall}
              </span>
            </Tooltip>}
        </Row>
      </Col>
    </Row>

    <Row style={{
      display: mobile
        ? 'initial'
        : 'none',
    }}>
      <Col>
        <Row className="p-1" style={{ minHeight: 29 }}>
          {mobile &&
            <Tooltip placement="right" title={setLocale(true, 'mobile')}>
              <Icon type={MobileOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
              <span className="ml-2 font-weight-semibold text-truncate">
                {mobile}
              </span>
            </Tooltip>}
        </Row>
      </Col>
    </Row>
  </>)

})
