import React from 'react'
import {
  decorateDocument,
  decorateNewDocument,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
} from './providers-methods'
import ProvidersMainList from './ProvidersMainList'
import DocumentDataProfiles from 'components/jarvisly-layouts/ModuleComponent/Components/DocumentDataProfiles'
import { ShopOutlined } from '@ant-design/icons'
import { Route } from 'react-router-dom'
import {
  defaultDataProfileValidation,
  defaultDecorateSettings, defaultParseSettings,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import ProvidersProfile from './sections/ProvidersProfile'

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const PROVIDERS_API_URL = '/entities'
export const PROVIDERS_URL = '/app/records/entities/providers'

const INITIAL_FILTER = {
  folder: 'documents',
}

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
}

const INITIAL_SEARCH = {
  documentCode: 'null',
  name: 'null',
  documentStatus: 'null',
}

const INITIAL_CONTEXT = {
  hasPicture: false,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
}

const METHODS = {
  parseSettings: defaultParseSettings,
  decorateSettings: defaultDecorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,

  // refreshParameters : getModuleParameters
}

const COMPONENTS = {
  list: props => <ProvidersMainList {...props}/>,
  // listPreviewHeader: props => <ProvidersBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <ProvidersBodyPreviewData {...props}/>,
  // listPreviewActions: props => <ProvidersBodyPreviewActions {...props}/>,

  documentSubtitle: props => <DocumentDataProfiles {...props}/>,
  // documentHeaderColumn2: props => <ProvidersDocumentHeaderColumn2 {...props}/>,
  // documentHeaderColumn3: props => <ProvidersDocumentHeaderColumn3 {...props}/>,

  // documentSectionsMenu: (props, ref) => <ProvidersDocumentSectionsMenu {...props} ref={ref}/>,
  // documentSectionsContent: (props, ref) => <ProvidersDocumentSectionsContent {...props} ref={ref}/>,

  // dedalo: {
  //   list: props => <DedaloProvidersMainList {...props}/>,
  // },

  // moduleSettings: providersSettingsTabs,
  // modalSettings: props => <ProvidersSettings {...props}/>,
}

const DATA_TYPES = [
  {
    key: 'provider',
    label: 'legal_person',
    description: 'providers_legal_person_description',
    checked: true,
  }]

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <ShopOutlined/>,
    label: 'provider',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${PROVIDERS_URL}/${_id}/profile`}
                                       render={() =>
                                         <ProvidersProfile ref={ref} {...props}
                                                            _data={props._dataRoots}
                                                            _dataLock={props._dataRootsLock}/>}/>,
  },

  // {
  //   key: 'notes',
  //   icon: <FormOutlined/>,
  //   label: 'notes',
  //   route: (props, ref, _id) => <Route key="notes"
  //                                      path={`${PROVIDERS_URL}/${_id}/notes`}
  //                                      render={() =>
  //                                        <DocumentNotes ref={ref} {...props} />}/>,
  // },
]

const REQUIRED_FIELDS = [

  {
    key: 'cnpj',
    label: 'cnpj',
    checked: true,
    disabled: false,
    forDataType: ['company'],
  },

  {
    key: 'foundedAt',
    label: 'foundation_date',
    checked: false,
    disabled: false,
    forDataType: ['company'],
  },
  {
    key: 'trade_name',
    label: 'trade_name',
    checked: true,
    disabled: true,
    forDataType: ['company'],
  },

  {
    key: 'phone',
    label: 'phone',
    checked: true,
    disabled: false,
    dependence: 'email',
    dependence_msg: '',
    forDataType: ['company'],
  },

  {
    key: 'email',
    label: 'email',
    checked: false,
    disabled: false,
    dependence: 'phone',
    forDataType: ['company'],
  },

]

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {

  configurations: {
    dataTypes: ['provider'],
  },

  requiredFields: [
    'cnpj',
    'rg',
    'trade_name',
    'mainPhone',
  ],

}

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_PROVIDERS_PARAMETERS = {

  root: 'providers',
  name: 'providers',
  singular: 'provider',
  titleField: 'name',
  url: PROVIDERS_URL,
  api: PROVIDERS_API_URL,

  hasFilter: true,
  hasAccessControl: true,
  hasSettings: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: true,
  documentActions: {
    archive: false,
    duplicate: false,
  },

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_provider',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
  },
}


