import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  TimePicker,
  Col,
  Form,
  Input,
  Row,
  InputNumber,
  Checkbox,
} from 'antd';
import {
  isDecimals,
  onFormFinishFailed,
  sort,
} from 'utils/helpers';
import IntlMessage from 'components/util-components/IntlMessage';
import {ROW_GUTTER} from 'constants/ThemeConstant';
import moment from 'moment-timezone';
import {clone} from 'jarvisly-helper';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const {TextArea} = Input;

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const MainProcessTab = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {onClose, process, record} = props;

  let maxOrder = process?.filter(x => !x.__type && !x.isInterval).length;
  if (!record?._id) maxOrder += 1;
  if (!maxOrder) maxOrder = 1;
  const order = (record?.order && Math.round(record.order)) || maxOrder;
  if (record?.order) record.order = order;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------

  const isDisabled = false; // formContext.mode === 'view' || _loading;
  const [form] = Form.useForm();

  const elRefs = {
    name: useRef(null),
    estimatedTime: useRef(null),
    order: useRef(null),
    description: useRef(null),
    isInterval: useRef(null),
    time: useRef(null),
  };

  const rules = {

    name: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    order: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    estimatedTime: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    description: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (record) {
      form.setFieldsValue({...record});

    } else {
      form.resetFields();
    }

    handleFocus();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
    submitForm: () => form.submit(),
  }));

  // METHODS -------------------------------------------------------------------

  function handleFocus(field) {
    let focus = field ? field : 'name';
    setTimeout(() => elRefs[focus]?.current?.focus({cursor: 'all'}), 400);
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async values => {

    const body = clone(values);

    body._id = record?._id;
    body.estimatedTime = moment(body.__estimatedTime).format('HH:mm');
    body.order = Math.round(values.order);

    if (body.isInterval) {
      body.order -= 0.5;
      if (body?.__time) body.time = moment(body.__time).format('HH:mm');

    } else {
      body.time = null;
    }

    let processArr = clone(process)
        .filter(x => !x.__type && x._id !== record?._id);
    const from = record?.order || maxOrder; // 2
    const to = body.order; // 7
    let counter = 1; // to === 1 ? 2 : 1;

    if (from !== to || body.isInterval !== record?.isInterval) {
      processArr = processArr.filter(x => x._id !== record?._id);
      processArr.forEach((x, idx, arr) => {
        if (!isDecimals(x.order)) {
          if (counter === to) counter++;
          x.order = counter;
          counter++;
        }
      });
    }

    processArr.push(body);
    processArr = processArr.sort((a, b) => sort(a, b, 'order'));

    onClose(null, {process: processArr});

  };

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <Form
        form={form}
        name="profile"
        layout="vertical"
        initialValues={record || {order: order}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // onFormValuesChange={saveContext}
        validateTrigger="onChange"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        noValidate>

      {/* BASIC INFO */}
      {/*<Row className="mb-2">*/}
      {/*  <h5 className="text-muted">*/}
      {/*    {setLocale(true, 'basic_info')}*/}
      {/*  </h5>*/}
      {/*</Row>*/}


      <Row gutter={ROW_GUTTER}>


        {/* NAME */}
        <Col xs={24} sm={12}>

          <Form.Item
              label={setLocale(true, 'name')}
              name="name"
              rules={rules.name}
          >
            <Input ref={elRefs.name}
                   disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* NAME */}


        {/* ESTIMATED TIME */}
        <Col xs={24} sm={6}>

          <Form.Item
              label={setLocale(true, 'estimated_time')}
              name="__estimatedTime"
              valuePropName="value"
              className="form-item-hour"
              rules={rules.estimatedTime}>

            <TimePicker
                ref={elRefs.estimatedTime}
                style={{width: '100%'}}
                disabled={isDisabled}
                disabledTime={() => [
                  0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]}
                format="HH:mm"
                showNow={false}
                minuteStep={5}
            />

          </Form.Item>

        </Col>
        {/* ESTIMATED TIME */}


        {/* ORDER */}
        <Col xs={24} sm={6}>

          <Form.Item
              label={setLocale(true, 'order')}
              name="order"
              rules={rules.order}>

            <InputNumber ref={elRefs.order}
                         disabled={isDisabled}
                         style={{width: '100%'}}
                         min={1}
                         max={maxOrder}/>

          </Form.Item>

        </Col>
        {/* ORDER */}

      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* DESCRIPTION */}
        <Col xs={24}>

          <Form.Item
              label={setLocale(true, 'description')}
              name="description"
              rules={rules.description}>

            <TextArea ref={elRefs.description}
                      rows={3}
                      disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* DESCRIPTION */}


      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* IS INTERVAL */}
        <Col xs={24}>

          <Form.Item name="isInterval" valuePropName="checked">
            <Checkbox ref={elRefs.isInterval} disabled={isDisabled}>
              {setLocale(true, 'set_as_range')}
            </Checkbox>
          </Form.Item>

        </Col>
        {/* IS INTERVAL */}

      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* TIME */}
        <Col xs={24}>

          <Form.Item
              label={setLocale(true, 'fixed_time')}
              name="__time"
              valuePropName="value"
              className="form-item-hour"
          >

            <TimePicker
                ref={elRefs.time}
                style={{width: 160}}
                disabled={isDisabled}
                disabledTime={() => [
                  0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]}
                format="HH:mm"
                showNow={false}
                minuteStep={5}
            />

          </Form.Item>

        </Col>
        {/* TIME */}


      </Row>

    </Form>
  </div>);

});

// EXPORT **********************************************************************
// *****************************************************************************

export default MainProcessTab;
