import {
  MODULE_SEARCH,
  SELECT_ROUTE,
  SELECT_MODULE,
  SHOW_MODULE_SETTINGS,
  SHOW_MODULE_SEARCH,
  FORM_PROVIDER,
  DATA_PROVIDER,
  MODULE_SETTINGS,
  MODULE_PARAMETERS,
  MODULE_LIST,
  MODULE_DASHBOARD,
} from '../constants/module';

const initModule = {};

const moduleRedux = (state = initModule, action) => {

  switch (action.type) {

    case SELECT_ROUTE:
      return {
        ...state,
        selectedRoute: action.selectedRoute,
      };

    case SELECT_MODULE:
      return {
        ...state,
        rdxModule: action.payload,
      };

    case SHOW_MODULE_SETTINGS:
      return {
        ...state,
        rdxShowModuleSettings: action.payload,
      };

    case SHOW_MODULE_SEARCH:
      return {
        ...state,
        rdxShowModuleFilter: action.payload,
      };

    case MODULE_SEARCH:
      return {
        ...state,
        rdxModuleQuery: action.payload,
      };

    case FORM_PROVIDER:
      return {
        ...state,
        rdxFormProvider: action.payload,
      };

    case DATA_PROVIDER:
      return {
        ...state,
        rdxDataProvider: action.payload,
      };

    case MODULE_SETTINGS:
      return {
        ...state,
        rdxModuleSettings: action.payload,
      };

    case MODULE_PARAMETERS:
      return {
        ...state,
        rdxModuleParameters: action.payload,
      };

    case MODULE_LIST:
      return {
        ...state,
        rdxModuleList: action.payload,
      };

    case MODULE_DASHBOARD:
      return {
        ...state,
        rdxModuleDashboard: action.payload,
      };

    default:
      return state;
  }
};

export default moduleRedux;
