import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Input, InputNumber, Modal, Row } from 'antd'
import { InsertRowRightOutlined } from '@ant-design/icons'
import { generateHexColor, getInitials, onFormFinishFailed } from 'utils/helpers'
import IntlMessage from 'components/util-components/IntlMessage'
import AntdReactColor from 'components/jarvisly/AntdReactColor'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import {
  postValueToSettings,
  putValuesInSettings,
} from 'components/jarvisly-layouts/ModuleComponent/module-api'
import { useFocus, useRefreshRecord } from 'components/jarvisly-layouts/ModuleComponent/module-hooks'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

const initialBackColor = '#ffffff'
const initialForeColor = '#000000'
const initialStateColor = { backColor: initialBackColor, foreColor: initialForeColor }

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const BlocksAdd = React.forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------'
  const { showModal, setShowModal, record, _module, settingsId, onRefresh } = props

  // component states ----------------------------------------------------------
  const [colors, setColors] = useState(initialStateColor)
  const [loading, setLoading] = useState(false)

  // local variables ------------------------------------------------------------

  const [form] = Form.useForm()

  const elRefs = {
    name: useRef(null),
    abbreviation: useRef(null),
    reference: useRef(null),
    previxNumber: useRef(null),
    floors: useRef(null),
  }

  const rules = {
    name: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],
    abbreviation: [
      {
        required: true, message: setLocale(true, 'required'),
      }],
    reference: [],
    prefixNumber: [],
    floors: [
      {
        required: true, message: setLocale(true, 'required'),
      }],
  }

  // hooks ---------------------------------------------------------------------
  useRefreshRecord(form, record)
  useFocus(showModal, elRefs?.name)

  useEffect(() => {
    if (record) setColors({ backColor: record.backColor, foreColor: record.foreColor })
  }, [record]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const handleChangeColor = color => {
    const foreColor = generateHexColor(color, true)

    setTimeout(() => {
      setColors({ ...colors, backColor: color, foreColor: foreColor })
    }, 100)
  }

  const onChange = value => {
    if (value.name || value.name === '') {
      const str = getInitials(value.name)
      form?.setFieldValue('abbreviation', str)
    }
  }

  const onClose = () => {
    setShowModal(false)
    form.resetFields()
    setColors(initialStateColor)
    setLoading(false)
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs, setLoading)

  const onFinish = async values => {

    setLoading(true)

    let body = values

    body._id = record?._id
    body.backColor = colors?.backColor
    body.foreColor = colors?.foreColor

    if (!settingsId) body = { 'apartments.blocks': body }

    const options = {
      settingsId,
      _module,

      body,
      valueId: record?._id,
      valueType: 'blocks',
    }

    try {

      let doc

      if (!record?._id) {
        doc = await postValueToSettings(options)

      } else {
        doc = await putValuesInSettings(options)
      }

      onRefresh(doc)
      onClose()

    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <Modal
      title={<>
        <InsertRowRightOutlined/>
        <span className="ml-2">
                    {setLocale(true, !record?._id ? 'new_block' : 'edit_block')}
                </span>
      </>}
      destroyOnClose={true}
      open={showModal}
      okText={setLocale(true, 'save')}
      onCancel={onClose}
      onOk={form?.submit}
      getContainer={false}
      confirmLoading={loading}
    >

      <div className="p-4">


        <Form form={form}
              name="blocksForm"
              className="vertical-form-min"
              onFinish={onFinish}
              onValuesChange={onChange}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              disabled={loading}
              autoComplete="off">

          <Row gutter={ROW_GUTTER} align="middle">

            {/* NAME */}
            <Col span={14}>
              <Form.Item name="name" rules={rules.name}
                         label={setLocale(true, 'name')}>
                <Input ref={elRefs.name} autoFocus/>
              </Form.Item>
            </Col>
            {/* NAME */}


            {/* ABBREVIATION */}
            <Col span={6}>
              <Form.Item name="abbreviation" rules={rules.abbreviation}
                         label={setLocale(true, 'initials')}>
                <Input ref={elRefs.abbreviation}/>
              </Form.Item>
            </Col>
            {/* ABBREVIATION */}


            {/* COLOR */}
            <Col span={4} style={{ alignSelf: 'end' }} className="text-right">
              <Form.Item name="backColor">
                <AntdReactColor isCompact={true}
                                initialBackColor={colors?.backColor}
                                initialForeColor={colors?.foreColor}
                                setSelectedColor={handleChangeColor}/>
              </Form.Item>
            </Col>
            {/* COLOR */}

          </Row>


          <Row gutter={ROW_GUTTER}>


            {/* REFERENCE */}
            <Col span={12}>
              <Form.Item name="reference" rules={rules.reference}
                         label={setLocale(true, 'reference')}>
                <Input ref={elRefs.reference}/>
              </Form.Item>
            </Col>
            {/* REFERENCE */}


            {/* PREFIX NUMBER */}
            <Col span={6}>
              <Form.Item name="prefixNumber" rules={rules.prefixNumber}
                         label={setLocale(true, 'branch_number')}>
                <InputNumber ref={elRefs.prefixNumber} style={{ width: '100%' }}
                             min={0}/>
              </Form.Item>
            </Col>
            {/* PREFIX NUMBER */}


            {/* FLOORS */}
            <Col span={6}>
              <Form.Item name="floors" rules={rules.floors}
                         label={setLocale(true, 'floors')}>
                <InputNumber ref={elRefs.floors} style={{ width: '100%' }}
                             min={1}/>
              </Form.Item>
            </Col>
            {/* FLOORS */}

          </Row>


        </Form>

      </div>
    </Modal>

  </>)

})

// EXPORT **********************************************************************
// *****************************************************************************

export default BlocksAdd



