import React from 'react';
import { Avatar, Col, Row, Tooltip } from 'antd';
import utils from 'utils';
import moment from 'moment-timezone';

const ImageDescription = ({
  imageUrl = null,
  initials = null,
  title = null,
  subtitle = null,
  tooltip = null,
  foreColor = null,
  backColor = null,
  shape = 'square',
  size = 'large',
  className = null,
  upperCase = null,
  center = false,
  avatarClassName = null,
  showAge = false,
  style = {},
  icon = null,
}) => {

  const itemStyle = {
    ...style,
    color: foreColor || '#000',
    backgroundColor: backColor || '#c0c0c0',
  };

  const age = showAge && moment(showAge).isValid() && moment().diff(showAge, 'years');

  return (

    <Row align="middle"
         justify={center ? 'center' : 'start'}
         className={`${className}`}
         wrap={center}>

      <Col xs={center ? 24 : ''}>

        <Tooltip title={tooltip} placement="top" open={!!tooltip}>

          <Avatar size={size}
                  shape={shape}
                  className={avatarClassName}
                  style={itemStyle}
                  icon={icon}
                  src={imageUrl}>

              <span className={`font-weight-semibold font-size-${size ===
              'small' ? 'sm' : ''}`}>
                {initials
                  ? initials
                  : title?.match
                    ? utils.getNameInitial(title)
                    : ''
                }
              </span>
          </Avatar>

        </Tooltip>

      </Col>


      <Col xs={center ? 24 : ''} className={`${center && 'mt-2 mb-2'}`}>

        <div className={`ml-2 font-weight-bold ${upperCase && 'text-uppercase'}`}>
          {title}
          {showAge && <span>, {age}</span>}
        </div>

        <div className={`ml-2`}>
          {subtitle}&nbsp;
        </div>

      </Col>

    </Row>

  );
};

export default ImageDescription;

